import React from 'react';
import { Button } from 'reactstrap';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
import fetch from '../../config/service';
import moment from 'moment';


class SprintModal extends React.Component {
    static propTypes = {
    };
    constructor(props) {
        super(props);
        this.state = {
            sprintData: null,
            sprintStatus: [{ label: 'Not Started', value: 'Not Started' },
            { label: 'In Progress', value: 'In Progress' },
            { label: 'Completed', value: 'Completed' }]
        };
    }
    componentDidMount = () => {
        this.getSprintData();
    }

    //Function for getting data from server
    getSprintData = () => {
        let filters = {
            limit: 20, page: 1, criteria: [{
                key: "projectId", value: this.props.editRowDataID, type: "in"
            }], direction: "desc", sortfield: "created"
        }
        let url = `sprints?filter=${JSON.stringify(filters)}`;
        fetch('GET', url)
            .then(async (response) => {
                if (response && response.sprints) {
                    const formattedSprintData = this.formatDate(response.sprints)
                    this.setState({ sprintData: formattedSprintData });
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };
    //Below are form fields functions and formatting data
    formatDate = (sprints) => {
        let data = sprints.map(sprint => {
            return {
                ...sprint,
                startDate: sprint.startDate ? moment(sprint.startDate).format('DD-MM-YYYY') : null,
                endDate: sprint.endDate ? moment(sprint.endDate).format('DD-MM-YYYY') : null
            };
        });
        return data;
    }

    textEditor = (options, field) => {
        const onInputChange = (e) => {
            const updatedData = [...this.state.sprintData];
            updatedData[options.rowIndex][field] = e.target.value;
            this.setState({ sprintData: updatedData });
        };
        return (<div>
            <InputText type="text" value={options.rowData[field]} onChange={onInputChange} />
        </div>)
    };

    handleDateChange = (e, rowIndex, field) => {
        let _data = [...this.state.sprintData];
        let newDate = moment(e.value).format('DD-MM-YYYY');
        _data[rowIndex][field] = newDate;

        this.setState({
            sprintData: _data,
        });
    };

    dateEditor = (options) => {
        let rowIndex = options.rowIndex;
        let _date = options.value[rowIndex][options.field];
        let formattedDate = _date ? moment(_date, 'DD-MM-YYYY').toDate() : null;

        return (
            <div>
                <Calendar
                    monthNavigator={true} yearNavigator={true} yearRange="1940:2530" dateFormat="dd-mm-yy"
                    value={formattedDate} onChange={(e) => this.handleDateChange(e, rowIndex, options.field)} showIcon />
            </div>
        );
    };

    statusEditor = (options) => {
        const rowData = options.rowData;
        const selectedStatus = rowData[options.field];
        return (
            <Dropdown
                value={selectedStatus}
                options={this.state.sprintStatus}
                onChange={(e) => this.handleStatusChange(options, e.value)}
                placeholder='Select Value'
            />
        );
    };

    handleStatusChange = (options, selectedValue) => {
        let updatedData = [...this.state.sprintData];
        updatedData[options.rowIndex][options.field] = selectedValue;

        this.setState({
            sprintData: updatedData,
        });
    };
    //after editing or creating submitting data into server
    onRowEditComplete = (e) => {
        let data = e.data;
        let method, apiUrl;
        this.setState({
            sprintData: JSON.parse(JSON.stringify([...this.state.sprintData]))
        })
        if (data && data._id) {
            method = 'PUT';
            apiUrl = `sprints/${data._id}`;
        } else if (data && !data._id) {
            method = 'POST';
            apiUrl = `sprints`;
            data.projectId = this.props.editRowDataID;
        }
        if (data.startDate) {
            data.startDate = moment(data.startDate, 'DD-MM-YYYY').format('YYYY-MM-DD[T]00:00:00.000[Z]');
        } else {
            data.startDate = null
        }
        if (data.endDate) {
            data.endDate = moment(data.endDate, 'DD-MM-YYYY').format('YYYY-MM-DD[T]00:00:00.000[Z]');
        } else {
            data.endDate = null
        }

        return fetch(method, apiUrl, data)
            .then(async (response) => {
                if (response && response.respCode && response.respCode === 204) {
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            });
    };
    //Adding new row to data table
    addNewRow = () => {
        const newRow = {
            name: '',
            startDate: null,
            endDate: null,
        };
        this.setState(prevState => ({
            sprintData: [newRow, ...prevState.sprintData]
        }));
    };

    render() {
        return (
            <div style={{ border: '1px solid grey', padding: '20px' }}>
                <span style={{ display: 'flex' }}>
                    <p style={{ fontSize: '15px' }}>Sprint</p>
                    <Button color='primary' type="button" style={{ marginLeft: 'auto' }} onClick={this.addNewRow}>Add</Button>
                </span>
                <div className="card p-fluid">
                    <DataTable value={this.state.sprintData} editMode="row" dataKey="id" onRowEditSave={this.onRowEditComplete} tableStyle={{ minWidth: '100%' }}>
                        <Column field="name" header="Sprint Name" editor={(options) => this.textEditor(options, 'name')} style={{ width: '20%' }} ></Column>
                        <Column field="startDate" header="Start Date" editor={(options) => this.dateEditor(options)} style={{ width: '10%' }} ></Column>
                        <Column field="endDate" header="End Date" editor={(options) => this.dateEditor(options)} style={{ width: '10%' }} ></Column>
                        <Column field="status" header="Status" editor={(options) => this.statusEditor(options)} style={{ width: '10%', textAlign: 'center' }}></Column>
                        <Column rowEditor header="Actions" headerStyle={{ width: '8%', minWidth: '2rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>
                </div>

            </div>
        )
    }
}

export default (SprintModal);