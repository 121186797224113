import React from 'react';
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, CardHeader, Button } from 'reactstrap';
import classnames from 'classnames';

import WorkHoursModal from '../../CommonDataTable/WorkHoursModal';
import fetch from '../../../../config/service';
import configImages from '../../../../config/configImages';
import config from '../../../../config/config';
import BulkUploadModal from '../../CommonDataTable/BulkUploadModal';
import FileUpload from '../../../Form/components/FileUpload';
import EditorModal from '../../CommonDataTable/EditorModal';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import dateFormats from '../../../UI/FormatDate/formatDate';
import Loader from '../../../App/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import EyeIcon from 'mdi-react/EyeIcon';

// config file
class TaskDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            taskDetailsArray: [
                { name: 'projectName', label: 'task_details.labels.project', value: '' },
                { name: 'name', label: 'task_details.labels.task', value: '', },
                { name: 'screen', label: 'task_details.labels.screen', value: '', },
                { name: 'assignedToName', label: 'task_details.labels.assigned_to', value: '', },
                { name: 'startDate', label: 'task_details.labels.start_date', value: '' },
                { name: 'endDate', label: 'task_details.labels.end_date', value: '' },
                { name: 'taskStatus', label: 'task_details.labels.task_status', value: '', },
                { name: 'status', label: 'task_details.labels.status', value: '' },
                { name: 'priority', label: 'task_details.labels.priority', value: '' },
                { name: 'createdBy.name', label: 'task_details.labels.created_by', value: '' },
                { name: 'actualWorkingHours', label: 'task_details.labels.worked_hours', value: '' },
            ],
            taskDetails: {},
            taskHistory: [],
            taskComments: [],
            taskAttachments: [],
            activeTab: '1',
            openEditorModal: false,
            isLoading: false,
            openViewModal: false,
            taskViewId: '',
        };
    }
    componentDidMount = () => {
        this.getRowDataBasedOnId();
    }
    toggle = (tab) => {
        const { activeTab } = this.state;
        if (activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    getFileName = (fileName) => {
        let data = {}
        data.contextId = { 'task': this.props.taskId };
        data.contextType = "task";
        data.eventType = "attachments";
        data.attachment = fileName;
        fetchMethodRequest('POST', 'events', data)
            .then(async (response) => {
                if (response && response.respCode) {
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }

    sendCommentsAttchementsDataToServer = (body, type) => {
        let data = {};
        data.contextId = { 'task': this.props.match.params.id };
        data.contextType = "task";
        if (type === 'comments') {
            data.eventType = "COMMENTS";
            data.comments = body;
        } else {
            data.eventType = "attachments";
            data.attachment = body;
        }
        return fetch('POST', 'events', data)
            .then(async (response) => {
                if (response && response.respCode) {
                    this.closeEditorModal();
                    showToasterMessage(response.respMessage, 'success');
                    this.getRowDataBasedOnId();
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }

    getRowDataBasedOnId = () => {
        this.setState({ isLoading: true });
        fetch('GET', `tasks/${this.props.match.params.id}?types=[COMMENTS,ATTACHMENTS]`)
            .then(async (response) => {
                if (response && (response.details || response.history || response.comments || response.actualWorkingHours)) {
                    let taskDetails = response.details;
                    let taskHistory = response.history;
                    let taskComments = response.comments;
                    let workedHours = response.hours;
                    let taskAttachments = response.attachments;
                    let taskViewId = response.details._id;
                    let keys = Object.keys(taskDetails)
                    let formData = []
                    for (let item of this.state.taskDetailsArray) {
                        if (keys.indexOf(item.name) !== -1) {
                            if (item.name == 'startDate' || item.name == 'endDate') {
                                item.value = dateFormats.formatDate(taskDetails[item.name], config.dateDashFormat);
                            } else {
                                item.value = taskDetails[item.name];
                            }
                        }
                        formData.push(item);
                    }
                    this.setState({
                        taskDetails: taskDetails, taskDetailsArray: formData,
                        taskHistory: taskHistory, taskComments: taskComments,
                        taskAttachments: taskAttachments, workedHours: workedHours,
                        taskViewId: taskViewId
                    })
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
                this.setState({ isLoading: false });
            }).catch((err) => {
                return err;
            });
    }

    openEditorModal = () => {
        this.setState({ openEditorModal: true })
    }
    closeEditorModal = () => {
        this.setState({ openEditorModal: false })
    }

    //handle previous page event 
    handleGoBack = () => {
        const { history } = this.props;
        history.goBack(); // Go back to the previously opened page
    };


    //Delete Attachments ||CodeByTej
    deleteAttachment = async (item) => {
        this.setState({ isLoading: true });
        let id = item._id;
        let url = `${id}?contextType=${item.contextType}&eventType=${item.eventType}`
        return fetch('DELETE', `events/${url}`)
            .then(async (response) => {
                if (response && response.respMessage) {
                    showToasterMessage(response.respMessage, 'success');
                    this.getRowDataBasedOnId();
                }
                else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                    this.setState({ isLoading: false });
                }
            }).catch((err) => {
                return err;
            });
    }
    //fetch call for working hours based on task, timesheet filling
    getWorkedHours = () => {
        fetch('GET', `tasks/employeeWise/${this.state.taskViewId}`)
            .then(async (response) => {
                if (response) {
                    const workedHours = response.map((obj, index) => {
                        if (obj._id) {
                            return { ...obj, ...obj._id, Sno: index + 1 };
                        } else {
                            return { ...obj, Sno: index + 1 };
                        }
                    });
                    this.setState({ tablefields: workedHours })
                    showToasterMessage(response.successMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    };

    openViewModal = () => {
        this.getWorkedHours();
        this.setState({
            openViewModal: true
        });
    }
    closeViewModal = async () => {
        await this.setState({
            openViewModal: false
        });
    }
    render() {
        const { t } = this.props;
        const { activeTab } = this.state;
        return (
            <div>
                <Card className='p-3'>
                    <CardHeader className='cardHeader'>
                        <div className="row m-0">
                            <div className='col-4 pl-0 pr-0' >{t('task_details.title')}
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody className='cardHeader'>
                        <div>
                            <Button color='primary' outline className='mt-0 mb-2 pt-0 pb-0 pr-2 pl-2'
                                onClick={this.openEditorModal}>{t('task_details.add_comments')}
                            </Button>
                            <Button color='primary' outline className='mt-0 mb-2 pt-0 pb-0 pr-2 pl-2' onClick={this.handleGoBack}>
                                <FontAwesomeIcon icon='arrow-left' className='mr-1' />
                                {t('task_details.back')}
                            </Button>
                        </div>
                        <div className='row'>
                            {this.state.taskDetailsArray.map((item, i) => {
                                return (item.value ?
                                    <div key={i} className='col-sm-auto'>
                                        <div className='row'>
                                            <span className='col-sm-auto labelFont mb-1'
                                                style={{ fontWeight: 'bold' }}>
                                                {t(item.label) + " : "}
                                            </span>
                                            {/* showing the working hours details related to task
                                             */}
                                            {item.name === "actualWorkingHours" ? (
                                                <span style={{ color: 'blue' }} className='col-sm-auto LabelValueFont text-capitalize'
                                                    onClick={() => this.openViewModal(item)}>
                                                    {item.value}
                                                    <EyeIcon style={{ width: '15px', marginBottom: '2px', marginLeft: '2px' }} />
                                                </span>
                                            ) : (
                                                <span className='col-sm-auto LabelValueFont text-capitalize'>
                                                    {item.value}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    : null
                                )
                            })}
                        </div>
                        {this.state.taskDetails['description'] ?
                            <div className='row mt-3'>
                                <span className='col-sm-auto labelFont' style={{ fontWeight: 'bold' }}>{t('task_details.labels.desc')} :</span>
                                <span className='col-sm-auto LabelValueFont'
                                    dangerouslySetInnerHTML={{ __html: this.state.taskDetails['description'] }}>
                                </span>
                            </div>
                            : null
                        }

                        <div className="tabs mt-4" style={{ backgroundColor: '#EAECEF', borderRadius: '5px' }}>
                            <div className="tabs__wrap">
                                <Nav tabs style={{ backgroundColor: 'white', borderColor: 'orange' }}>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { this.toggle('1') }}>{t('task_details.nav_tabs.history')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { this.toggle('2') }}>{t('task_details.nav_tabs.comments')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => { this.toggle('3') }}>{t('task_details.nav_tabs.attachments')}</NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} style={{ overflow: 'auto' }}>
                                    <TabPane tabId="1">
                                        <div style={{ maxHeight: 600 }}>
                                            {this.state.taskHistory && this.state.taskHistory.length > 0 ?
                                                this.state.taskHistory.map((item, i) => {
                                                    return (
                                                        <div key={i} className='row  p-2 m-0' >
                                                            <div className="timeline__content col-sm-10">
                                                                <div className='row justify-content-between' style={{ padding: '0px 10px 0px 10px' }}>
                                                                    <h5 className='col-sm-auto text-capitalize'>{item.createdBy.employee.displayName}</h5>
                                                                    <h6 className='col-sm-auto'> {dateFormats.timeConversion(item.created)}</h6>
                                                                    <p className='col-12'
                                                                        dangerouslySetInnerHTML={{ __html: item.description }}>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="timeline__icon ml-3">
                                                                {item.createdBy.employee.photo ?
                                                                    <img src={`${config.imgUrl}employee/${item.createdBy.employee.photo}`} className='detailsImgStyle '
                                                                    />
                                                                    : <img src={configImages.defaultImg} style={{ borderRadius: '100px' }} alt='img' />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : null
                                            }
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div style={{ maxHeight: 600 }}>
                                            {this.state.taskComments && this.state.taskComments.length > 0 ?
                                                this.state.taskComments.map((item, i) => {
                                                    return (
                                                        <div key={i} className='row  p-2 m-0'>
                                                            <div className="timeline__content col-sm-10">
                                                                <div className='row justify-content-between' style={{ padding: '0px 10px 0px 10px' }}>
                                                                    <h5 className='col-sm-auto text-capitalize'>{item.createdBy.name}</h5>
                                                                    <h6 className='col-sm-auto'>{dateFormats.formatDate(item.created, config.dateDmyFormat)}</h6>
                                                                    <p className='col-12' dangerouslySetInnerHTML={{ __html: item.comments }}></p>
                                                                </div>
                                                            </div>
                                                            <div className="timeline__icon ml-3">
                                                                {item.createdBy.employee.photo ?
                                                                    <img src={`${config.imgUrl}employee/${item.createdBy.employee.photo}`} className='detailsImgStyle '
                                                                    />
                                                                    : <img src={configImages.defaultImg} style={{ borderRadius: '100px' }} alt='img' />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : null
                                            }
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Loader loader={this.state.isLoading} />
                                        <div className='row p-2 m-0'>
                                            <div className='col-12 text-right'>
                                                <div className='dragdrop mt-1'>
                                                    <FileUpload
                                                        onRef={(ref) => (this.fileUploadRef = ref)}
                                                        url={`events/uploadAttachment`}
                                                        chooseLabel={t('task_details.choose_file')}
                                                        taskId={this.props.match.params.id}
                                                        taskType='attach'
                                                        getFileName={this.sendCommentsAttchementsDataToServer}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.taskAttachments && this.state.taskAttachments.length > 0 ?
                                                this.state.taskAttachments.map((item, i) => (
                                                    <div key={i} className='col-sm-2' style={{ padding: '20px' }}>
                                                        <div style={{ justifyContent: 'center' }}>
                                                            {item && item.attachment && item.attachment.map((attachment, j) => (
                                                                <div key={j}>
                                                                    <FontAwesomeIcon
                                                                        className='genderIconAlignment'
                                                                        color='white'
                                                                        icon='trash-alt'
                                                                        data-toggle="tool-tip"
                                                                        title="Delete"
                                                                        style={{ color: '#bf1725', width: '13', marginRight: '10px', cursor: 'pointer' }}
                                                                        onClick={() => this.deleteAttachment(item)}
                                                                    />
                                                                    {attachment && attachment.path ? (
                                                                        <img
                                                                            src={`${config.imgUrl}attachment/${attachment.name}`}
                                                                            className='detailsImgStyle'
                                                                        />
                                                                    ) : (
                                                                        <img src={configImages.defaultImg} className='detailsImgStyle' />
                                                                    )}
                                                                    <a target="_blank" href={`${config.imgUrl}attachment/${attachment.name}`}>{attachment.name}</a>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))
                                                : null
                                            }
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </CardBody>
                </Card >
                <BulkUploadModal
                    type='Attachments'
                    url={this.getApiUrl}
                    openBulkUploadModal={this.state.openBulkUploadModal}
                    closeBulkModal={this.closeBulkModal} />
                <EditorModal
                    commentsData={this.sendCommentsAttchementsDataToServer}
                    openEditorModal={this.state.openEditorModal}
                    closeEditorModal={this.closeEditorModal} />
                <WorkHoursModal
                    onRef={(ref) => (this.workHoursModalRef = ref)}
                    openViewModal={this.state.openViewModal}
                    closeViewModal={this.closeViewModal}
                    tablefields={this.state.tablefields}
                />
            </div >
        );
    }
}
export default withTranslation('common')(TaskDetails);