import React from 'react';
import TableRender from '../../CommonDataTable/TableRender';
import config from '../../../../config/config';
import configImages from '../../../../config/configImages';
import RolePermissions from '../../CommonDataTable/Permissions';
import { withTranslation } from 'react-i18next';

// config file
class PublicHolidays extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getTableFields = () => {
        let data = [
            { textAlign: 'center', width: 40, field: 'Sno', header: 'public_holidays.table_fields.s_no', filter: false, sortable: false, },
            { textAlign: 'center', width: 60, field: 'date', header: 'public_holidays.table_fields.date', filter: true, sortable: true, placeholder: config.dateDmyFormat },
            { textAlign: 'center', width: 60, field: 'day', header: 'public_holidays.table_fields.day', filter: true, sortable: true, placeholder: 'public_holidays.table_fields.ph_search' },
            { textAlign: 'center', width: 150, field: 'description', header: 'public_holidays.table_fields.desc', filter: true, placeholder: 'public_holidays.table_fields.ph_search' ,sortable: true},
            { textAlign: 'center', width: 60, field: 'Actions', header: 'public_holidays.table_fields.actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('PublicHolidays');
        if (screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };


    getFormFields = () => {
        const { t } = this.props;
        return ([
            { required: true, value: '', type: 'date', name: 'date', label: t('public_holidays.form_fields.date'), id: 'date', placeholder: 'dd/mm/yyyy' },
            { required: true, value: '', type: 'textarea', name: 'description', label: t('public_holidays.form_fields.desc'), id: 'description' },

        ]);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <TableRender type='Public Holidays'
                    globalSearch={t('public_holidays.globalsearch')}
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    sample={configImages.publicHolidaysSampleXL}
                    bulkapi='publicHolidays'
                />
            </div>
        );
    }
}
export default withTranslation('common')(PublicHolidays);