import React, { PureComponent } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import config from '../../../config/config';
// import '../../../scss/dashboardStyles.css';
import { withTranslation } from 'react-i18next';

const noProfileImage = require('../../../assets/img/profile/userProfile.png');
const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;


class TopbarProfile extends PureComponent {
    constructor() {
        super();
        this.state = {
            collapse: false,
            userData: {},
        };
        this.getUserData();
    }

    getUserData = async () => {
        let userData = await localStorage.getItem('loginCredentials');
        userData = JSON.parse(userData);
        await this.setState(({ userData: userData }));

    }

    handleUserProfile = () => {
        this.setState(prevState => ({ collapse: !prevState.collapse }));
    };

    toggle = () => {
        this.setState(prevState => ({ collapse: !prevState.collapse }));
    };

    render() {
        const { t } = this.props;
        const { collapse } = this.state;
        const profileImage = this.state.userData && this.state.userData.profileImage ? this.state.userData.profileImage : null;

        // Get all elements with the class "topbar__avatar"
        let titleElements = document.getElementsByClassName("topbar__avatar");
        if (titleElements.length > 0) {
            let width = titleElements[0].offsetWidth;

            let menuElement = document.querySelector(".topbar__menu");
            if (menuElement) {
                // Apply width to the "topbar__menu" element
                menuElement.style.width = width + "px";
            }
        }

        return (
            <div className="topbar__profile">
                <button className="topbar__avatar" type="button" onClick={this.toggle}>
                    <img className="topbar__avatar-img" src={this.state.userData.photo ? `${config.imgUrl}employee/${this.state.userData.photo}` : noProfileImage}
                    />
                    <p className="topbar__avatar-name">{this.state.userData && this.state.userData.displayName ?
                        this.state.userData.displayName : 'null'}</p>
                    <DownIcon className="topbar__icon" />
                </button>
                {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
                <Collapse isOpen={collapse} className="topbar__menu-wrap">
                    <div className="topbar__menu topbarMenu">
                        <div className='topBarImageAlignment'>
                            <img src={this.state.userData.photo ? `${config.imgUrl}employee/${this.state.userData.photo}` : noProfileImage}
                                className='topBarImage' />
                            <div >
                                {this.state.userData && this.state.userData.displayName ?
                                    this.state.userData.displayName : 'No Name'}
                                <div className='themeColorText'>{this.state.userData &&
                                    this.state.userData.role ?
                                    this.state.userData.role : ''}</div>
                            </div>
                        </div>

                        <div className="topbar__menu-divider" />
                        <TopbarMenuLink title={t('topbar_link.profile')} icon="user" path="/profile" toggle={this.toggle} />
                        <TopbarMenuLink title={t('topbar_link.password')} icon="inbox" path="/changepassword" toggle={this.toggle} />
                        <div className="topbar__menu-divider" />
                        <TopbarMenuLink title={t('topbar_link.logout')} icon="exit" path="/logout" toggle={this.toggle} />
                    </div>
                </Collapse>
            </div >
        );
    }
}
export default withTranslation('common')(TopbarProfile);