import React from 'react';
import TableRender from '../../CommonDataTable/TableRender';
import config from '../../../../config/config';
import configImages from '../../../../config/configImages';
import RolePermissions from '../../CommonDataTable/Permissions';
import fetch from '../../../../config/service';
import { withTranslation } from 'react-i18next';

// config file
class AttendanceTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            roleType: []
        };
    }

    getTableFields = async () => {
        const { t } = this.props;

        let data = [
            { textAlign: 'center', width: 50, field: 'Sno', header: 'attendance.table_fields.s_no' },
            { textAlign: 'center', width: 90, field: 'companyEmpId', header: 'attendance.table_fields.employeeId', filter: true, sortable: true, placeholder: 'timesheet.table_fields.ph_search' },
            { textAlign: 'left', width: 150, field: 'employeeName', header: 'attendance.table_fields.name', filter: true, sortable: true, placeholder: 'attendance.table_fields.ph_search' },
            { textAlign: 'center', width: 99, field: 'type', header: 'attendance.table_fields.work_type', filter: false, sortable: false, },
            { textAlign: 'center', width: 99, field: 'date', header: 'attendance.table_fields.date', filter: true, sortable: true, },
            { textAlign: 'center', width: 90, field: 'inTime', header: 'attendance.table_fields.in_time', sortable: true, },
            { textAlign: 'center', width: 90, field: 'outTime', header: 'attendance.table_fields.out_time', sortable: true, },
            { textAlign: 'center', width: 75, field: 'checkouts', header: 'attendance.table_fields.no_of_checkouts' },
            { textAlign: 'center', width: 90, field: 'workingHours', header: 'attendance.table_fields.working_hours' },
            { textAlign: 'center', width: 75, field: 'timesheetHours', header: 'attendance.table_fields.timesheet_hours' },
            { textAlign: 'left', width: 160, field: 'punchRecords', header: 'attendance.table_fields.punch_records' },
            { textAlign: 'center', width: 99, field: 'created', header: 'attendance.table_fields.created', filter: true, sortable: false, placeholder: config.dateDmyFormat },
            { textAlign: 'center', width: 80, field: 'Actions', header: 'attendance.table_fields.actions' },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Attendance');
        if (screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        const { t } = this.props;
        return ([
            { required: false, value: '', type: 'text', name: 'employeeName', label: t('attendance.form_fields.emp_name'), id: 'employeeId', placeholder: t('attendance.form_fields.emp_name') },
            { required: false, value: '', type: 'date', name: 'date', label: t('attendance.form_fields.date'), id: 'date', placeholder: config.dateDashFormat },
            { required: false, value: '', type: 'date', name: 'inTime', label: t('attendance.form_fields.in_time'), id: 'inTime', },
            { required: false, value: '', type: 'date', name: 'outTime', label: t('attendance.form_fields.out_time'), id: 'outTime', },
            { required: false, value: '', type: 'textarea', name: 'comments', label: t('attendance.form_fields.comments'), id: 'comments', },
        ]);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <TableRender type='Attendance'
                    globalSearch={t('attendance.table.globalsearch')}
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    sample={configImages.attendenceSampleXL}
                    bulkapi='attendence'
                />
            </div>
        );
    }
}
export default withTranslation('common')(AttendanceTable);