import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import WorkFromHomePage from './components/WorkFromHome';

const WorkFromHome = ({ t }) => (
    <Container>
        <WorkFromHomePage />
    </Container>
);

WorkFromHome.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(WorkFromHome);
