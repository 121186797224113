/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react';
import { Container, Card, CardBody, Col, Row } from 'reactstrap';
import {
	BarChart, Bar, Cell, ResponsiveContainer,
} from 'recharts';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Alert from '../../../shared/components/Alert';
import { Link } from 'react-router-dom'

const data = [
	{ name: 'Page A', uv: 4000 },
	{ name: 'Page B', uv: 3000 },
	{ name: 'Page C', uv: 2000 },
	{ name: 'Page D', uv: 2780 },
	{ name: 'Page E', uv: 1890 },
	{ name: 'Page F', uv: 2390 },
	{ name: 'Page G', uv: 3490 },
	{ name: 'Page H', uv: 2000 },
	{ name: 'Page I', uv: 2780 },
	{ name: 'Page J', uv: 1890 },
];

class CountDisplay extends PureComponent {
	static propTypes = {
		t: PropTypes.func.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			activeIndex: 0,
		};
	}

	componentDidMount() {
		if (localStorage.getItem('loginCredentials')) {
			let user = JSON.parse(localStorage.getItem('loginCredentials'));
			if (user.role) {
				this.setState({ employeeRole: user.role });
			}
		}
	}

	handleClick = (index) => {
		this.setState({
			activeIndex: index,
		});
	};

	render() {
		const { activeIndex } = this.state;
		const { data, errorMsg } = this.props;
		const { t } = this.props;

		return (
			<div>
				{/* If user is Admin or Director or superAdmin  */}
				{this.state.employeeRole == 'Admin' || this.state.employeeRole == 'Director' || this.state.employeeRole == 'superAdmin' ?
					<Row>
						<Col md={12} xl={3} lg={6} xs={12}>
							<Card>
								<Link to="/Employees">
									<CardBody className="dashboard__card-widget">

										<div className="card__title">
											<h5 className="bold-text">{t('dashboard.employees')}</h5>
										</div>
										<div className="dashboard__total">
											<TrendingUpIcon className="dashboard__trend-icon" />
											<p className="dashboard__total-stat">
												<span style={{ color: "#007ad9" }}>{data.totalEmployees}</span>

											</p>
										</div>
									</CardBody>
								</Link>
							</Card>
						</Col>
						<Col md={12} xl={3} lg={6} xs={12}>
							<Card>
								<Link to="/Projects">
									<CardBody className="dashboard__card-widget">
										<div className="card__title">
											<h5 className="bold-text">{t('dashboard.projects')}</h5>
										</div>
										<div className="dashboard__total">
											<TrendingUpIcon className="dashboard__trend-icon" />
											<p className="dashboard__total-stat">
												<span style={{ color: "#007ad9" }}>{data.totalProjects}</span>

											</p>
										</div>
									</CardBody>
								</Link>
							</Card>
						</Col>

						<Col md={12} xl={3} lg={6} xs={12}>
							<Card>
								<Link to="/Tasks">
									<CardBody className="dashboard__card-widget">
										<div className="card__title">
											<h5 className="bold-text">{t('dashboard.tasks')}</h5>
										</div>
										<div className="dashboard__total">
											<TrendingUpIcon className="dashboard__trend-icon" />
											<p className="dashboard__total-stat">
												<span style={{ color: "#007ad9" }}>{data.totalTasks}</span>
											</p>
										</div>
									</CardBody>
								</Link>
							</Card>
						</Col>

					</Row>
					:
					<Row>

						<Col xs={12} md={4} lg={6} xl={2}>
							<Card>
								<Link to="/Projects">
									<CardBody className="dashboard__card-widget-small text-center">
										<img className="dashboard_image" src={require('../../../assets/img/dashboard/project.png')} />
										<div className="card__title">
											<h5 className="bold-text">{t('dashboard.projects')}</h5>
										</div>
										<div className="dashboard__total">
											<p className="dashboard__total-stat">
												<span style={{ color: "#007ad9" }}>{data.totalProjects}</span>
											</p>
										</div>
									</CardBody>
								</Link>
							</Card>
						</Col>

						<Col xs={12} md={4} lg={6} xl={2}>
							<Card>
								<Link to={"/Tasks/redirect"}>
									{/* <Link to={{ pathname: `/tasks`, params: { redirect: true } }}> */}
									<CardBody className="dashboard__card-widget-small text-center">
										<img className="dashboard_image" src={require('../../../assets/img/dashboard/tasks.png')} />
										<div className="card__title">
											<h5 className="bold-text">{t('dashboard.tasks')}</h5>
										</div>
										<div className="dashboard__total">
											<p className="dashboard__total-stat">
												<span style={{ color: "#007ad9" }}>{data.totalTasks == 0 ? data.totalTasks : data.totalTasks}</span>
											</p>
										</div>
									</CardBody>
								</Link>
							</Card>
						</Col>

						<Col xs={12} md={4} lg={6} xl={2}>
							<Card>
								<Link to="/Leaves">
									<CardBody className="dashboard__card-widget-small text-center">
										<img className="dashboard_image" src={require('../../../assets/img/dashboard/leaves.png')} />
										<div className="card__title">
											<h6 className="bold-text">{t('dashboard.leave_balance')}</h6>
										</div>
										<div className="dashboard__total">
											<p className="dashboard__total-stat">
												<span style={{ color: "#007ad9" }}>{data && data.leaveBalance ?
													data.leaveBalance
													: data.leaveBalance === 0 ? data.leaveBalance
														: null
												}</span>
											</p>
										</div>
									</CardBody>
								</Link>
							</Card>
						</Col>

						{errorMsg ?
							<Col xs={12} md={12} lg={6} xl={6}>
								<Card>
									<CardBody className="dashboard__card-widget">
										<div className="card__title">
											<h5 className="bold-text">{t('dashboard.notification')}</h5>
										</div>
										<Alert color="danger" className="alert--colored" icon>
											<p>
												<span className="bold-text">Warning!</span> {errorMsg}
											</p>
										</Alert>
									</CardBody>
								</Card>
							</Col>
							: null}
					</Row>
				}
			</div>
		);
	}
}

export default withTranslation('common')(CountDisplay);

