/* eslint indent: "off" */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';

//Table Routes
import Employees from '../Tables/Employees';
import Projects from '../Tables/Projects';
import Leaves from '../Tables/Leaves';
import LeaveHistoryTable from '../Tables/LeaveHistory/components/LeaveHistoryTable'
import ProjectDetails from '../Tables/Projects/components/ProjectDetails';
import Tasks from '../Tables/Tasks';
import TaskDetails from '../Tables/Tasks/components/TaskDetails';
import Roles from '../Tables/Roles';
import Dashboard from '../Dashboard/index'
import FormModal from '../Form/FormModal';
import WorkFromHome from '../Tables/WorkFromHome';
import Attendance from '../Tables/Attendance';
import Issues from '../Tables/Issues';
import IssueDetails from '../Tables/Issues/components/IssueDetails';
import TimeSheet from '../Tables/TimeSheet';
import PublicHolidays from '../Tables/PublicHolidays';
import Inventory from '../Tables/Inventory';
import InventoryDetails from '../Tables/Inventory/components/InventoryDetails';
import InventoryHistory from '../Tables/InventoryHistory/components/InventoryHistory';
import Activities from '../Tables/Activities'
import Apraisals from '../Tables/Apraisals';
import ScreensPage from '../Tables/Screens';
import Settings from '../Tables/Settings';
import KPI from '../Tables/KPI';
import EmailTemplates from '../Tables/EmailTemplates';
import Presentations from '../Tables/Presentations';
import Clients from '../Tables/Clients';
import LateComers from '../Tables/LateComers';
import DailyStatus from '../Tables/DailyStatus';


// Account Related
import ChangePassword from '../Account/ChangePassword';
import ForgotPassword from '../Account/ForgotPassword';
import ResetPassword from '../Account/ResetPassword';
import Profile from '../Account/Profile';
import Calendar from '../Account/Calendar';
import Project from '../Account/Project';
import Landing from '../Landing/LogIn';
import ErrorNotFound from './404'


const Tables = () => {
  let permissions = JSON.parse(localStorage.getItem('rolePermissions'));

  return <Switch>
    <Route path="/formModal" component={permissions.FormModal != 'noView' ? FormModal : ErrorNotFound} />
    <Route path="/employees" component={permissions.Employees != 'noView' ? Employees : ErrorNotFound} />
    <Route path="/projects" component={permissions.Projects != 'noView' ? Projects : ErrorNotFound} />
    <Route path="/leaves" component={permissions.Leaves != 'noView' ? Leaves : ErrorNotFound} />
    <Route path="/leavehistory" component={permissions.leavehistories != 'noView' ? LeaveHistoryTable : ErrorNotFound} />
    <Route isExact="true" path="/leaves/:id" component={permissions.Leaves != 'noView' ? Leaves : ErrorNotFound} />
    <Route path="/projectDetails/:id" component={permissions.Projects != 'noView' ? ProjectDetails : ErrorNotFound} />
    <Route path="/tasks" component={permissions.Tasks != 'noView' ? Tasks : ErrorNotFound} />
    <Route path="/taskDetails/:id" component={permissions.Tasks != 'noView' ? TaskDetails : ErrorNotFound} />
    <Route path="/roles" component={permissions.Roles != 'noView' ? Roles : ErrorNotFound} />
    <Route path="/workFromHome" component={permissions.WorkFromHome != 'noView' ? WorkFromHome : ErrorNotFound} />
    <Route path="/attendance" component={permissions.Attendance != 'noView' ? Attendance : ErrorNotFound} />
    <Route path="/issues" component={permissions.Issues != 'noView' ? Issues : ErrorNotFound} />
    <Route path="/issueDetails/:id" component={permissions.Issues != 'noView' ? IssueDetails : ErrorNotFound} />
    <Route path="/timesheet" component={permissions.TimeSheet != 'noView' ? TimeSheet : ErrorNotFound} />
    <Route path="/publicHolidays" component={permissions.PublicHolidays != 'noView' ? PublicHolidays : ErrorNotFound} />
    <Route path="/inventory" component={permissions.Inventory != 'noView' ? Inventory : ErrorNotFound} />
    <Route path="/inventoryDetails/:id" component={permissions.Inventory != 'noView' ? InventoryDetails : ErrorNotFound} />
    <Route path="/inventoryHistory" component={permissions.inventoryhistory != 'noView' ? InventoryHistory : ErrorNotFound} />
    <Route path="/activities" component={permissions.Activities != 'noView' ? Activities : ErrorNotFound} />
    <Route path="/screens" component={permissions.Screens != 'noView' ? ScreensPage : ErrorNotFound} />
    <Route path="/Apraisals" component={permissions.Apraisals != 'noView' ? Apraisals : ErrorNotFound} />
    <Route path="/Settings" component={permissions.Settings != 'noView' ? Settings : ErrorNotFound} />
    <Route path="/KPI" component={permissions.KPI != 'noView' ? KPI : ErrorNotFound} />
    <Route path="/EmailTemplates" component={permissions.EmailTemplates != 'noView' ? EmailTemplates : ErrorNotFound} />
    <Route path="/presentations" component={permissions.Presentations != 'noView' ? Presentations : ErrorNotFound} />
    <Route path="/Clients" component={permissions.Clients != 'noView' ? Clients : ErrorNotFound} />
    <Route path="/LateComers" component={permissions.LateComers != 'noView' ? LateComers : ErrorNotFound} />
    <Route path="/dailyStatus" component={permissions.DailyStatus != 'noView' ? DailyStatus : ErrorNotFound} />


  </Switch>
}

const Account = () => (
  <Switch>
    <Route path="/changepassword" component={ChangePassword} />
    <Route path="/profile" component={Profile} />
    <Route path="/calender" component={Calendar} />
    <Route path="/project" component={Project} />
  </Switch>
);

const wrappedRoutes = () => {
  let permissions = JSON.parse(localStorage.getItem('rolePermissions'));
  return <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/" component={Tables} />
      <Route path="/dashboard" component={permissions.Dashboard != 'noView' ? Dashboard : ErrorNotFound} />
      <Route path="/" component={Account} />
    </div>
  </div>
}

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/changerecoverpassword/:encodedEmailId/:active" component={ResetPassword} />
        <Route path="/changerecoverpassword/:encodedEmailId" component={ResetPassword} />
        <Route exact path="/" component={Landing} />
        <Route path="/log_in" component={Landing} />
        <Route path="/forgot_password" component={ForgotPassword} />
        <Route path="/" component={wrappedRoutes} />
        <Route path="*" component={ErrorNotFound} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
