import React from 'react';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CountDisplay from './components/CountDisplay';
import Projects from './components/Projects';
import Tasks from './components/Tasks';
import Issues from './components/Issues';
import fetch from '../../config/service';
import Loader from '../App/Loader';

import dateFormats from '../UI/FormatDate/formatDate';
import config from '../../config/config';


class Dashboard extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };


  constructor(props) {
    super(props);
    this.state = {
      dashboardData: {},
      dashboardCounts: {},
      totalDoctors: '',
      totalEmployees: '',
      totalUsers: '',
      totalRequests: '',
      isLoading: false,
      birthDays: [],
      presentationsData: [],
      marqueeText: '',
      prDate: null
    };
  }

  componentDidMount = () => {

    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    this.setState({ loginData: user, role: user.role })
    this.getCountData();
    this.getProjects();
    this.getLeavesData();
    this.getTimeSheetAlert();
    this.getPresentationsData();
  }
  getCountData() {
    this.setState({ isLoading: true });
    fetch('GET', `dashboard`)
      .then(async (response) => {
        if (response) {
          this.setState({ dashboardData: response });
          // format projects chart data
          let projectsData = [], birthDays = [];
          const colours = ['#4ce1b6', '#70bbfd', '#f6da6e', '#ff4861', '#4ce1b6', '#70bbfd', '#f6da6e', '#ff4861'];
          if (response && response.projectsBasedOnStatus) {
            response.projectsBasedOnStatus.forEach((element, index) => {
              projectsData.push({ name: element._id, value: element.count, fill: colours[index] });
            });
          }

          if (response && response.birthDay && response.birthDay.length > 0) {
            birthDays = response.birthDay;
          }

          this.setState({ birthDays: birthDays, isLoading: false });
        } else {
          this.setState({ isLoading: false });
        }
      }).catch((err) => {
        return err;
      });
  }


  getProjects() {
    let url;
    let filterObj = {
      'sortfield': 'created', 'direction': 'desc',
      "criteria": [{ 'key': 'status', 'value': 'Active', 'type': 'eq' }]
    };
    url = 'projects' + '?filter=' + JSON.stringify(filterObj) + '';
    fetch('GET', url)
      .then(async (response) => {
        if (response && response.projects && response.projects.length) {
          this.setState({ projectsList: response.projects });
        }
      }).catch((err) => {
        return err;
      });
  }



  getProjectData = (project, type) => {
    let url;
    url = 'dashboard/tasksBasedOnStatus?projectId=' + project._id;
    fetch('GET', url)
      .then(async (response) => {
        let tasksData = []; let isssues = [];
        const colours = ['#4ce1b6', '#70bbfd', '#f6da6e', '#ff4861'];
        if (response && response.tasks && type == 'tasks') {
          response.tasks.forEach((element, index) => {
            tasksData.push({ name: element._id.status, value: element.count, fill: colours[index] });
          });
          this.setState({ tasksData: tasksData, taskprojectName: project.name });
        }

        if (response && response.isssues && type == 'issues') {
          response.isssues.forEach((element, index) => {
            isssues.push({ name: element._id.status, value: element.count, fill: colours[index] });
          });
          this.setState({ isssues: isssues, issueprojectName: project.name });
        }



      }).catch((err) => {
        return err;
      });
  }


  getLeavesData() {
    let url;
    let today = new Date();
    today = dateFormats.formatDate(today, config.dateDashFormat);
    let filterObj = {
      'sortfield': 'fromDate', 'direction': 'desc',
      "criteria": [
        { 'key': 'toDate', 'value': today, 'type': 'gte' }
      ]
    };
    url = 'leaves?type=DashBoard' + '&filter=' + JSON.stringify(filterObj) + '';
    return fetch('GET', url)
      .then(async (response) => {
        if (response && response.leaves && response.leaves.length > 0) {
          response.leaves.forEach((leave) => {
            leave.fromDate = dateFormats.formatDate(leave.fromDate, config.dateDmyFormat);
          })
          this.setState({ leaves: response.leaves });
        }

      }).catch((err) => {
        return err;
      });
  }

  getTimeSheetAlert = async () => {
    let filterCriteria = {};
    filterCriteria['sortfield'] = 'timeSheetUpdatedDate';
    filterCriteria['direction'] = 'desc';

    return fetch('GET', `dashboard/timeSheetChecking?filter=${JSON.stringify(filterCriteria)}`)
      .then(async (response) => {
        if (response && response.errorMessage) {
          this.setState({ msg: response.errorMessage });
        } if (response && response.details) {
          response.details.forEach((sheet) => {
            sheet.timeSheetUpdatedDate = dateFormats.formatDate(sheet.timeSheetUpdatedDate, config.dateDmyFormat);
          })

          this.setState({ timeSheetDetails: response.details });
        }

      }).catch((err) => {
        return err;
      });
  }

  //Fetching presenters data || CodeByTej
  getPresentationsData = async () => {
    return fetch('GET', `presentations/getLatestRecord`)
      .then(async response => {
        let prDate = '';
        if (response && response.presentations && response.presentations.length > 0) {
          prDate = response.presentations[0].date;
          await this.setState({ presentationsData: response.presentations, prDate: prDate });
          await this.stringifyPrData(response.presentations);
        }
      })
  }
  //Text Format : Presentations data for Marquee scroll
  stringifyPrData = async (data) => {
    let marqueeText = '';
    if (data && data.length > 0) {
      data.map(item => {
        marqueeText = marqueeText +
          (item.assignedToName ?
            (("<strong style='text-transform:capitalize'>" + item.assignedToName + "</strong>") +
              (item.name ?
                (" presents about " + "<strong style='text-transform:capitalize'>" + item.name + "</strong>")
                : ''
              )
              + " || "
            )
            : ''
          )
      })
      marqueeText = marqueeText.substring(0, marqueeText.length - 4);
      marqueeText = "<p style='color:black'>" + marqueeText + "</p>"
      await this.setState({ marqueeText: marqueeText })
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Container className="dashboard">
        <Loader loader={this.state.isLoading} />
        <Row>
          <Col md={12}>
            <h3 className="page-title" style={{ marginBottom: '5px' }}>{t('dashboard.page_title')}</h3>
          </Col>
        </Row>

        {/* Marquee text for Presentation details || CodeByTej */}
        {this.state.presentationsData && this.state.presentationsData.length > 0 ?
          <Row>
            <Col md={12}>
              <Card className='pb-3'>
                <CardBody className='py-1' style={{ backgroundColor: '#fff' }}>
                  <h5 style={{ fontWeight: 'bold', color: '#f17038' }}>{t('dashboard.presentations_title')} {dateFormats.formatDate(this.state.prDate, config.dateDmyFormat)}</h5>
                  <marquee
                    width="100%"
                    direction="left"
                    height="18px"
                    color='blue'
                    scrollamount={4}
                    style={{ fontSize: '12px' }}
                    dangerouslySetInnerHTML={{ __html: this.state.marqueeText }} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          : null
        }

        <Row>
          {(this.state.birthDays && this.state.birthDays.length > 0) ?
            // <Row>
            <Col md={6}>
              <div className="row birthDayBackground mb-3 mx-1">
                <div className='ApraisalWrngText col-6'>
                  {this.state.birthDays.map((item, index) => {
                    return (
                      <div key={index} style={{ color: '#f17038' }}>
                        <span className="bold-text" style={{ fontSize: '16px' }}>{t('dashboard.birthDay')}</span><br />
                        <span>{item.firstName}</span>
                      </div>
                    )
                  })}
                </div>
                <div className='col-6'>
                  <img src="https://i.pinimg.com/originals/ef/ec/56/efec56ff4b0abc76b8750cc700633ccd.gif" alt="" style={{ width: '200px', height: '80px', borderRadius: '5px' }} />
                </div>
              </div>
            </Col>
            // </Row>
            : null
          }
          {this.state.loginData && (this.state.loginData.appraisalStatus == 'Initiated' || this.state.loginData.appraisalStatus == 'Saved') ?
            // <Row>
            <Col lg={6}>
              <div className="redBackground mb-3" style={{ height: '120px' }}>
                <div className='ApraisalWrngText' style={{ fontWeight: 'bolder', color: 'red' }}>
                  {t('dashboard.appraisalInitiated')} 23-12-2019.</div>
              </div>
            </Col>
            // </Row>
            : null}
        </Row>

        <CountDisplay errorMsg={this.state.msg ? this.state.msg : null} data={this.state.dashboardData} />


        <Row>
          {this.state.projectsList && this.state.projectsList.length > 0 ?
            <Col lg={6} xl={6} >
              <Card className="dashBoardCardWithScroll">
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">{t('dashboard.active_projects')}</h5>
                  </div>
                  <Table className="table--bordered table--head-accent table-fixed" responsive hover>
                    <thead>
                      <tr>
                        <th style={{ width: 8 + '%' }}>#</th>
                        <th style={{ width: 30 + '%' }}>{t('dashboard.project')}</th>
                        <th style={{ width: 16 + '%' }}>{t('dashboard.tasks')}</th>
                        <th style={{ width: 16 + '%' }}>{t('dashboard.issues')}</th>
                        <th style={{ width: 30 + '%' }}>{t('dashboard.teamLead')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.projectsList.map((project, i) => {
                        return <tr key={i}>
                          <td style={{ width: 8 + '%' }}>{i + 1} </td>
                          <td style={{ width: 30 + '%', textTransform: "capitalize" }}>{project.name}</td>
                          <td style={{ width: 16 + '%', paddingLeft: '25px' }}> <span style={project.taskCount && project.taskCount > 0 ? { color: 'blue' } : null} onClick={() => this.getProjectData(project, 'tasks')}>
                            {project.taskCount}</span>
                          </td>
                          <td style={{ width: 16 + '%', paddingLeft: '30px' }}>
                            <span style={project.issueCount && project.issueCount > 0 ? { color: 'blue' } : null} onClick={() => this.getProjectData(project, 'issues')}>
                              {project.issueCount}</span>
                          </td>
                          <td style={{ width: 30 + '%', textTransform: 'capitalize', paddingLeft: '20px' }}>{project.teamLead && project.teamLead.displayName ? project.teamLead.displayName : project.teamLeadName ? project.teamLeadName : null}</td>
                        </tr>
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
            : null}


          <Col lg={6} xl={6} >
            <Card className="dashBoardCardWithScroll">
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">{t('dashboard.recent_leaves')}</h5>
                </div>
                <Table className="table--bordered table--head-accent table-fixed" responsive hover>
                  <thead>
                    <tr>
                      <th style={{ width: 8 + '%' }}>#</th>
                      <th style={{ width: 40 + '%' }}>{t('dashboard.employee')}</th>
                      <th style={{ width: 30 + '%' }}>{t('dashboard.fromDate')}</th>
                      <th style={{ width: 22 + '%' }}>{t('dashboard.noOfDays')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.leaves && this.state.leaves.length > 0 ?
                      this.state.leaves.map((leave, i) => {
                        return <tr key={i}>
                          <td style={{ width: 8 + '%' }}>{i + 1} </td>
                          <td style={{ width: 40 + '%', textTransform: "capitalize" }}>{leave.employeeName}</td>
                          <td style={{ width: 30 + '%', paddingLeft: '15px' }}>{leave.fromDate}</td>
                          <td style={{ width: 22 + '%', paddingLeft: '50px' }}>{leave.numberOfDays}</td>
                        </tr>
                      })
                      :
                      <tr className="bold-text">
                        <td style={{ width: 100 + '%', textAlign: 'center', paddingTop: '95px' }} colSpan="4">
                          {t('dashboard.noRecentLeaves')}
                        </td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          {this.state.projectsData && this.state.projectsData.length > 0 ? <Projects projectsData={this.state.projectsData} title={t('dashboard.projects')} /> : ''}
          {this.state.tasksData && this.state.tasksData.length > 0 ? <Tasks projectName={this.state.taskprojectName} tasksData={this.state.tasksData} title={t('dashboard.tasks')} /> : ''}
          {this.state.isssues && this.state.isssues.length > 0 ? <Issues projectName={this.state.issueprojectName} isssues={this.state.isssues} title={t('dashboard.issues')} /> : ''}
        </Row>
        {this.state.role && (this.state.role == 'Admin' || this.state.role == 'Director' || this.state.role == 'TeamLead') ?
          <Row>
            <Col
              lg={6}
              xl={6}
              md={12}
            // subhead="What browsers are most popular"
            >
              <Card className="dashBoardCardWithScroll">
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">{t('dashboard.timeSheet_report')}</h5>
                  </div>
                  <Table responsive hover className="table--head-accent table-fixed">
                    <thead>
                      <tr>
                        <th style={{ width: 8 + '%' }}>#</th>
                        <th style={{ width: 34 + '%' }}>{t('dashboard.tr_name')}</th>
                        <th style={{ width: 34 + '%' }}>{t('dashboard.tr_email')}</th>
                        <th style={{ width: 24 + '%', paddingLeft: '0px' }}>{t('dashboard.tr_updated')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.timeSheetDetails && this.state.timeSheetDetails.length > 0 ?
                        this.state.timeSheetDetails.map((emp, i) => {
                          return <tr key={i}>
                            <td style={{ width: 8 + '%' }}>{i + 1} </td>
                            <td style={{ width: 34 + '%', textTransform: "capitalize" }}>{emp.displayName}</td>
                            <td style={{ width: 34 + '%' }}>{emp.email}</td>
                            <td style={{ width: 24 + '%' }}>{emp.timeSheetUpdatedDate}</td>
                          </tr>
                        })
                        :
                        <tr className="bold-text">
                          <td style={{ width: 100 + '%', textAlign: 'center', paddingTop: '95px' }} colSpan="4">
                            {t('dashboard.noTimesheetUpdates')}
                          </td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          : null}
      </Container >
    );
  }
}

export default withTranslation('common')(Dashboard);