import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import renderDropZoneField from '../../../Form/components/DropZone';
import renderSelectField from '../../../../shared/components/form/Select';
import RenderFileInputField from '../../../Form/components/FileUpload';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import validate from './validate';
import config from '../../../../config/config';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { load as loadAccount } from './../../../../redux/reducers/commonReducer';
import fetch from '../../../../config/service';

const renderField = ({
  input, placeholder, type, isDisable, meta: { touched, error },
}) => (
  <div className="form__form-group-input-wrap">
    <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
    {touched && error && <span className="form__form-group-error">{error}</span>}
  </div>
);



renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisable: false
};

class Profile extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      loginUserRole: '',
    };
  }

  componentDidMount = async () => {
    await this.getUserData();
  }


  // get data from server based on Id
  getUserData = async () => {
    if (localStorage.getItem('loginCredentials')) {
      let user = JSON.parse(localStorage.getItem('loginCredentials'));
      this.setState({ userId: user._id, loginUserRole: user.role });
      let Url = 'employees/' + user._id;
      fetch('GET', Url, '')
        .then(async (response) => {
          if (response && response.details) {
            let respDetails = response.details;
            if (response.details.photo) {
              this.setState({ pic: response.details.photo });
            }
            if (response.details.laptopSNO && response.details.laptopSNO.length > 0) {
              let lapArray = response.details.laptopSNO;
              let laptopSnoStr = ' ';

              for (var item of lapArray) {
                if (item.name) {
                  laptopSnoStr = laptopSnoStr + item.name + ',';
                }
              }
              let lapString = laptopSnoStr.slice(0, -1);
              respDetails.laptopSNO = lapString;
            }
            this.props.load(respDetails);
          } else if (response && response.errorMessage) {
          }
        }).catch((err) => {
          return err;
        });

    } else {
      return;
    }
  }

  getFileName = (file) => {
    this.setState({ pic: file });
  }

  render() {
    const { handleSubmit, reset, t } = this.props;
    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">{t('profile.title')}</h5>
            </div>
            {this.state.loginUserRole == 'Client' ?
              <form className="form" onSubmit={handleSubmit}>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.first_name')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="firstName"
                        component={renderField}
                        type="text"
                        placeholder="First Name"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.email')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="email"
                        component={renderField}
                        type="text"
                        placeholder="email"
                        isDisable={true}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.client_id')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="employeeId"
                        component={renderField}
                        type="text"
                        isDisable={true} />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.address')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="address"
                        component="textarea"
                        type="text"
                        placeholder="write you address"
                      />
                    </div>
                  </div>
                </div>

                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.last_name')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="lastName"
                        component={renderField}
                        type="text"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.phone_number')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="phoneNumber"
                        component={renderField}
                        type="text"
                        placeholder="phoneNumber"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.skype_id')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="skypeId"
                        component={renderField}
                        type="text"
                        placeholder="skypeId"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.profile_pic')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="photo"
                        component={RenderFileInputField}
                        onRef={(ref) => (this.profileUploadRef = ref)}
                        url={'employees/uploadProfilePicture/' + this.state.userId}
                        chooseLabel={t('profile.btn_upload')}
                        type='profile'
                        acceptType="image/*"
                        getFileName={this.getFileName}
                      />
                    </div>
                    <div className='col-md-2' style={{ padding: '20px' }}>
                      <div style={{ justifyContent: 'center' }}>
                        {(this.state.pic) ?
                          <img src={`${config.imgUrl}employee/${this.state.pic}`} className='detailsImgStyle' />
                          : null
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <ButtonToolbar className="form__button-toolbar">
                  <Link to={'/Dashboard'}>
                    <Button style={{ marginLeft: '0px' }} color='primary'
                    >{t('profile.btn_cancel')}</Button>
                  </Link>
                  <Button color="primary" type="submit">{t('profile.btn_submit')}</Button>
                </ButtonToolbar>
              </form>

              : <form className="form" onSubmit={handleSubmit}>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.first_name')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="firstName"
                        component={renderField}
                        type="text"
                        placeholder="First Name"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.dob')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="dateOfBirth"
                        component={renderDatePickerField}
                      />
                      {/* <div className="form__form-group-icon">
                        <CalendarBlankIcon />
                      </div> */}
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.bankName')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="bankName"
                        component={renderField}
                        type="text"
                        placeholder="bank name"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.bank_ac_no')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="accountNo"
                        component={renderField}
                        type="text"
                        placeholder="accountNo"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.uanNumber')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="uanNumber"
                        component={renderField}
                        type="text"
                        placeholder="UAN number"
                      // isDisable={true}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.skype_id')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="skypeId"
                        component={renderField}
                        type="text"
                        placeholder="skypeId"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.phone_number')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="phoneNumber"
                        component={renderField}
                        type="text"
                        placeholder="phone Number"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.aadhar')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="aadharcardNumber"
                        component={renderField}
                        type="text"
                        placeholder="Aadhar number"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.previousCompaney')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="previousCompaney"
                        component={renderField}
                        type="text"
                        placeholder="Companey Name"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.gender')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="gender"
                        component={renderRadioButtonField}
                        label="Male"
                        radioValue="Male"
                        defaultChecked
                      />
                      <Field
                        name="gender"
                        component={renderRadioButtonField}
                        label="Female"
                        radioValue="Female"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.temporaryAddress')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="temporaryAddress"
                        component="textarea"
                        type="text"
                        placeholder="write your address"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.permanentAddress')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="permanentAddress"
                        component="textarea"
                        type="text"
                        placeholder="write your address"
                      />
                    </div>
                  </div>
                </div>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.last_name')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="lastName"
                        component={renderField}
                        type="text"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.email')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="email"
                        component={renderField}
                        type="text"
                        placeholder="email"
                      // isDisable={true}
                      />
                    </div>
                  </div>
                  {/* <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.doj')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="joinDate"
                        component={renderDatePickerField}
                      />
                      // <div className="form__form-group-icon">
                      //   <CalendarBlankIcon />
                      // </div>
                    </div>
                  </div> */}
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.branch')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="branch"
                        component={renderField}
                        type="text"
                        placeholder="branch"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.ifsc')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="ifscCode"
                        component={renderField}
                        type="text"
                        placeholder="ifscCode"
                      // isDisable={true}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.laptop_sno')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="laptopSNO"
                        component={renderField}
                        type="text"
                        placeholder="Laptop Serial number"
                        isDisable={true}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.bit_id')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="bitbuketId"
                        component={renderField}
                        type="text"
                        placeholder="Bit Bucket ID"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.emergencyContact')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="emergencyContact"
                        component={renderField}
                        type="Number"
                        placeholder="phone Number"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.pan')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="pancardNumber"
                        component={renderField}
                        type="text"
                        placeholder="Pancard number"
                      // isDisable={true}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.previousExperience')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="previousExperience"
                        component={renderField}
                        type="text"
                        placeholder="experience"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.qualification')}</span>
                    <div className="form__form-group-field">
                      <Field
                        name="qualification"
                        component={renderField}
                        type="text"
                        placeholder="qualification"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{t('profile.profile_pic')}</span>
                    <div className="form__form-group-field">

                      <Field
                        name="photo"
                        component={RenderFileInputField}
                        onRef={(ref) => (this.profileUploadRef = ref)}
                        url={'employees/uploadProfilePicture/' + this.state.userId}
                        chooseLabel={t('profile.btn_upload')}
                        type='profile'
                        acceptType="image/*"
                        getFileName={this.getFileName}
                      />
                    </div>
                    <div className='col-md-2' style={{ padding: '20px' }}>
                      <div style={{ justifyContent: 'center' }}>
                        {(this.state.pic) ?
                          <img src={`${config.imgUrl}employee/${this.state.pic}`} className='detailsImgStyle' />
                          : null
                        }
                      </div>
                    </div>
                  </div>


                  <ButtonToolbar className="form__button-toolbar">
                    <Link to={this.state.loginUserRole == 'Client' ? '/issues' : '/Dashboard'}>
                      <Button style={{ marginLeft: '50px' }} color='primary'
                      >{t('profile.btn_cancel')}</Button>
                    </Link>
                    <Button color="primary" type="submit">{t('profile.btn_submit')}</Button>
                  </ButtonToolbar>
                </div>
              </form>
            }
          </CardBody>
        </Card>
      </Col>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Profile = reduxForm({
  form: "ProfileForm", // a unique identifier for this form
  enableReinitialize: true,
  validate
})(Profile);

// You have to connect() to any reducers that you wish to connect to yourself
Profile = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(Profile);

export default withTranslation('common')(Profile);