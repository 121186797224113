import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ActivitiesPage from './components/Activities';

const Activities = ({ t }) => (
    <Container>
        <ActivitiesPage />
    </Container>
);

Activities.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(Activities);
