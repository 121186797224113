import React from "react";
import TableRender from "../../CommonDataTable/TableRender";
import config from "../../../../config/config";
import configImages from "../../../../config/configImages";
import RolePermissions from "../../CommonDataTable/Permissions";
import fetch from '../../../../config/service';
import { withTranslation } from "react-i18next";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

// config file
class EmployeesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roleType: []
    };
  }
  getDropdownValues = async () => {
    let roleType = [];
    roleType.push({ 'label': 'All', 'value': null });

    await fetch('GET', 'roles')
      .then(async (response) => {
        if (response && response.roles) {
          let roles = response.roles
          for (let roleItem of roles) {
            if (roleItem && roleItem.role && roleItem.role != 'Client') {
              roleType.push({ 'label': roleItem.role, 'value': roleItem.role })
            }
          }
          this.setState({ roleType: roleType })
        }
      }).catch((err) => {
        return err;
      });
  }

  //function for displaying inventory data in the employee view modal
  handleViewData(response, formFields) {
    let inventoryData = [];
    for (let i = 0; i < response.laptopSNO.length; i++) {
      let data = "";
      if (response.laptopSNO[i].type) {
        data += response.laptopSNO[i].type
      } if (response.laptopSNO[i].brand) {
        data += "-" + response.laptopSNO[i].brand
      } if (response.laptopSNO[i].name) {
        data += "-" + response.laptopSNO[i].name
      }
      if (data && data.length > 0) {
        inventoryData.push(data);
      }
    }
    if (inventoryData && inventoryData.length) {
      formFields.push({
        id: 'inventoryData',
        label: 'Inventory Data',
        name: "inventoryData",
        required: "false",
        type: "text",
        value: inventoryData.length > 0 ? inventoryData : ""
      })
    }
  }

  //get monthly data of all employees based on leaves
  getMonthlyReport = async () => {
    await fetch('GET', 'employees/attendanceReport')
      .then(async (response) => {
        if (response) {
          const workbook = new ExcelJS.Workbook();

          const headers = [
            { label: 'employee ID', key: 'employeeID' },
            { label: 'employee Name', key: 'employeeName' },
            { label: 'employmentType', key: 'employmentType' },
            { label: 'joinDate', key: 'joinDate' },
            { label: 'leaveBalance', key: 'leaveBalance' },
            { label: 'leaveDates', key: 'leaveDates' },
            { label: 'totalLeaves', key: 'totalLeaves' },
            { label: 'workingDays', key: 'workingDays' },
          ];

          // Function to format a row based on headers
          const formatRow = (employee) => headers.map(header => {
            let value = employee[header.key];

            if (header.key === 'joinDate') {
              value = moment(value).format('DD-MM-YYYY');
            } else if (header.key === 'leaveDates' && Array.isArray(value)) {
              value = value.join('\n');
            }
            return value;
          });

          // Add Current Month sheet
          const sheet1 = workbook.addWorksheet('Current Month');
          sheet1.addRow(headers.map(header => header.label));
          response.currentMonth.forEach(employee => sheet1.addRow(formatRow(employee)));

          // Add Previous Month sheet
          const sheet2 = workbook.addWorksheet('Previous Month');
          sheet2.addRow(headers.map(header => header.label));
          response.previousMonth.forEach(employee => sheet2.addRow(formatRow(employee)));

          // Save the workbook to a blob
          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          // Using FileSaver to trigger the download
          saveAs(blob, 'Report.xlsx');
        }
      }).catch((error) => {
        return error;
      })
  }

  getTableFields = async () => {
    const { t } = this.props;
    await this.getDropdownValues();
    let roleType = this.state.roleType;

    let statusTypes = [
      { label: 'Active', value: 'Active' },
      { label: 'Pending', value: 'Pending' },
      { label: 'Inactive', value: 'Inactive' },
    ];
    let statusValue = ['Active', 'Pending'];

    let employmentTypes = [
      { label: 'All', value: null },
      { label: 'Permanent', value: 'Permanent' },
      { label: 'Contractor', value: 'Contractor' }
    ]

    let data = [
      { textAlign: 'center', width: 45, selectionMode: 'multiple', field: 'Sno', filter: false, sortable: false, placeholder: 'Search' },
      { textAlign: 'left', width: 190, field: 'displayName', header: 'employees.table_fields.name', filter: true, sortable: true, placeholder: 'employees.table_fields.ph_search' },
      { textAlign: 'center', width: 95, field: 'employeeId', header: 'employees.table_fields.emp_id', sortable: true, filter: true, placeholder: 'Search' },
      { textAlign: 'center', width: 120, field: 'joinDate', header: 'employees.table_fields.join_date', sortable: true },
      { textAlign: 'left', width: 115, field: 'phoneNumber', header: 'employees.table_fields.phone', filter: true, placeholder: 'employees.table_fields.ph_search' },
      { textAlign: 'left', width: 200, field: 'email', header: 'employees.table_fields.email', filter: true, sortable: true, placeholder: 'employees.table_fields.ph_search' },
      { textAlign: 'left', width: 120, field: 'appraisalStatus', header: 'employees.table_fields.appraisal_status', filter: true, sortable: true, placeholder: 'employees.table_fields.ph_search' },
      { textAlign: 'center', width: 55, field: 'totalLeaves', header: 'employees.table_fields.total_leaves', sortable: false, },
      { textAlign: 'center', width: 115, field: 'employmentType', header: 'employees.table_fields.employmentType', filterElement: employmentTypes, filter: true, sortable: false, },
      { textAlign: 'center', width: 90, field: 'role', header: 'employees.table_fields.role', filterElement: roleType, filter: true, sortable: false, },
      { textAlign: 'left', width: 110, field: 'reportingToName', header: 'employees.table_fields.reporting_to', filter: true, sortable: true, placeholder: 'employees.table_fields.ph_search' },
      { textAlign: 'center', width: 70, field: 'status', header: 'employees.table_fields.status', filterElement: statusTypes, filter: true, sortable: false, defaultValues: statusValue },
      { textAlign: 'center', width: 90, field: 'Actions', header: 'employees.table_fields.actions', filter: false, sortable: false },
    ];
    let screenPermissions = RolePermissions.screenPermissions('Employees');
    if (screenPermissions != 'edit') {
      data.pop();
    }
    return [...data];
  };

  getFormFields = () => {
    const { t } = this.props;
    let roleType = this.state.roleType;
    roleType.map((item, index) => {
      if (item.label == 'Client' || item.label == 'All') {
        roleType.splice(index, 1);
      }
    });

    let statusTypes = [
      // { label: 'All', value: null },
      { label: 'Active', value: 'Active' },
      { label: 'Pending', value: 'Pending' },
      { label: 'Inactive', value: 'Inactive' },
    ];

    let employmentTypes = [
      { label: 'Permanent', value: 'Permanent' },
      { label: 'Contractor', value: 'Contractor' }
    ];

    let originalDocuments = [
      { label: '10th Certificate', value: '10th Certificate' },
      { label: 'Inter', value: 'Inter' },
      { label: 'Diploma', value: 'Diploma' },
      { label: 'Degree', value: 'Degree' },
      { label: 'BE/B-Tech', value: 'BE/B-Tech' },
      { label: 'MBA', value: 'MBA' },
      { label: 'ME/M-Tech', value: 'ME/M-Tech' },
      { label: 'MCA', value: 'MCA' },
    ]

    return ([
      { required: true, value: '', type: 'text', name: 'firstName', label: t('employees.form_fields.firstname'), id: 'firstName' },
      { required: true, value: '', type: 'text', name: 'lastName', label: t('employees.form_fields.lastname'), id: 'lastName' },
      { required: true, value: '', type: 'date', name: 'dateOfBirth', label: t('employees.form_fields.dob'), id: 'dateOfBirth', placeholder: config.dateFormat },
      { required: true, value: '', type: 'date', name: 'joinDate', label: t('employees.form_fields.join_date'), id: 'joinDate', placeholder: config.dateFormat },
      { required: true, value: '', type: 'email', name: 'email', label: t('employees.form_fields.email'), id: 'email', placeholder: 'email@yopmail.com' },
      { required: true, value: '', type: 'email', name: 'companyEmail', label: t('employees.form_fields.company_mail'), id: 'companyemail', placeholder: 'companyEmail@yopmail.com', disable: true },
      { required: false, value: '', type: 'text', name: 'pancardNumber', label: t('employees.form_fields.pan'), id: 'PAN' },
      { required: true, value: '', type: 'text', name: 'aadharcardNumber', label: t('employees.form_fields.aadhar'), id: 'adharNo', maxLength: 12 },
      { required: true, value: '', type: 'text', name: 'employeeId', label: t('employees.form_fields.emp_id'), id: 'employeeId' },
      { required: false, value: '', type: 'text', name: 'biometricId', label: t('employees.form_fields.biometric_Id'), id: 'biometricId', },
      { required: true, value: '', type: 'text', name: 'phoneNumber', label: t('employees.form_fields.phone'), id: 'phoneNumber', maxLength: 10 },
      { required: false, value: '', type: 'Number', name: 'emergencyContact', label: t('employees.form_fields.emergency_contact'), id: 'emergencyContact' },
      { required: false, value: '', type: 'text', name: 'skypeId', label: t('employees.form_fields.skype_id'), id: 'skypeId' },
      { required: false, value: '', type: 'text', name: 'bitbuketId', label: t('employees.form_fields.bit_id'), id: 'bitBucketID   ' },
      { required: false, value: '', type: 'text', name: 'qualification', label: t('employees.form_fields.qualification'), id: 'qualification' },
      { required: false, value: '', type: 'text', name: 'consultancy', label: t('employees.form_fields.consultancy'), id: 'consultancy' },
      { required: false, value: '', type: 'text', name: 'previousExperience', label: t('employees.form_fields.previousExperience'), id: 'previousExperience' },
      { required: false, value: '', type: 'text', name: 'previousCompaney', label: t('employees.form_fields.previousCompaney'), id: 'previousCompaney' },
      { required: false, value: '', type: 'date', name: 'terminationDate', label: t('employees.form_fields.terminationDate'), id: 'terminationDate' },
      { required: false, value: '', type: 'date', name: 'orientationDate', label: t('employees.form_fields.orientationDate'), id: 'orientationDate' },
      { required: true, value: '', type: 'dropDown', name: 'employmentType', label: t('employees.form_fields.employmentType'), id: 'employmentType', options: employmentTypes, placeholder: 'Employment Type' },
      { required: true, value: '', type: 'dropDown', name: 'role', label: t('employees.form_fields.role'), id: 'role', options: roleType },
      { required: true, value: '', type: 'autoComplete', name: 'reportingTo', label: t('employees.form_fields.reporting_to'), id: 'reportingTo', searchApi: 'employees', searchField: 'displayName', placeholder: 'Search Employee', isMultiple: false, otherField: 'reportingToName', filterField: 'role', filterValue: ['TeamLead', 'Admin', 'Director'] },
      {
        required: true, value: '', type: 'radio', name: 'gender', label: t('employees.form_fields.gender'), id: 'gender',
        options: [{ value: 'Male', label: 'Male' }, { value: 'Female', label: 'Female' }],
      },
      { required: false, value: '', type: 'textarea', name: 'temporaryAddress', label: t('employees.form_fields.temporaryAddress'), id: 'temporaryAddress' },
      { required: true, value: '', type: 'textarea', name: 'permanentAddress', label: t('employees.form_fields.permanentAddress'), id: 'permanentAddress' },
      { required: false, value: '', type: 'multiSelect', name: 'document', label: t('employees.form_fields.document'), id: 'document', options: originalDocuments },
      { required: false, value: '', type: 'text', name: 'bankName', label: t('employees.form_fields.bank_name'), id: 'bankName' },
      { required: false, value: '', type: 'text', name: 'branch', label: t('employees.form_fields.branch_name'), id: 'branchName' },
      { required: false, value: '', type: 'text', name: 'accountNo', label: t('employees.form_fields.bank_ac_no'), id: 'accountNo' },
      { required: false, value: '', type: 'text', name: 'uanNumber', label: t('employees.form_fields.uanNumber'), id: 'uanNumber' },
      { required: false, value: '', type: 'text', name: 'ifscCode', label: t('employees.form_fields.ifsc'), id: 'ifscCode' },
      { required: false, value: '', type: 'link', name: 'employeeDocumentDriveLinks', label: t('employees.form_fields. employee_document_drive_link'), id: 'employeeDocumentDriveLinks' },

      {
        required: false, value: '', type: 'radio', name: 'leavesAccruedBlocked', label: t('employees.form_fields.leaves_accrued_blocked'), id: 'leavesAccruedBlocked',
        options: [{ value: true, label: 'Yes' }, { value: false, label: 'No', defaultChecked: true }],
      },
      {
        required: false, value: '', type: 'radio', name: 'idCardReceived', label: t('employees.form_fields.icard_rec'), id: 'idCardReceived',
        options: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }],
      },
      { required: false, value: '', type: 'dropDown', name: 'status', label: t('employees.form_fields.status'), id: 'status', options: statusTypes, placeholder: 'Status' },
      // { required: false, value: '', type: 'text', name: 'laptopSNO', label: "laptopSNO", id: 'laptopSNO', display: false },
      { required: false, value: '', type: 'file', name: 'file', label: t('employees.form_fields.attatch_file'), id: 'file', chooseLabel: t('tasks.form_fields.choose_file') },

      // { required: false, value: '', type: 'text', name: 'totalLeaves', label: t('employees.form_fields.leave_bal'), id: 'totalLeaves' },
    ]);
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <TableRender
          type='Employees'
          globalSearch={t('employees.table.globalsearch')}
          tableFields={() => this.getTableFields()}
          formFields={this.getFormFields}
          sample={configImages.employeesSampleXL}
          bulkapi='employee'
          formName='Employee'
          handleViewData={this.handleViewData}
          getMonthlyReport={this.getMonthlyReport}
        />
      </div>
    );
  }
}
export default withTranslation('common')(EmployeesTable);