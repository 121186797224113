const imagePath = '../assets/';
const configImages = {
    defaultImg: require('../assets/img/profile/userProfile.png'),
    employeesSampleXL: require('../assets/files/employees.xlsx'),
    attendenceSampleXL: require('../assets/files/attendence.xlsx'),
    tasksSampleXL: require('../assets/files/tasks.xlsx'),
    inventorySampleXL: require('../assets/files/Inventory.xlsx'),
    issuesSampleXL: require('../assets/files/issues.xlsx'),
    issuesSampleXLclient: require('../assets/files/issuesC.xlsx'),
    publicHolidaysSampleXL: require('../assets/files/publicHolidays.xlsx'),
    timeSheetSampleXL: require('../assets/files/timeSheet.xlsx'),
    PresentationsSampleXL: require('../assets/files/PresentationsSampleXL.xlsx')
};
export default configImages;
