import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import TimeSheetTable from './components/TimeSheetTable';

const TimeSheet = ({ t }) => (
    <Container>
        <TimeSheetTable />
    </Container>
);

TimeSheet.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(TimeSheet);
