import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, ButtonToolbar, Input, FormGroup, Label, Col, FormFeedback, FormText } from 'reactstrap';
import classNames from 'classnames';
import { Field, reduxForm, FormSection } from 'redux-form';
import renderRadioButtonField from '../../../shared/components/form/RadioButton';
import fetch from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';
import PropTypes, { element } from 'prop-types';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { connect } from 'react-redux';
import DefaultInput from '../../../shared/components/form/DefaultInput';
import { withTranslation } from 'react-i18next';


class RolesModal extends React.Component {
    static defaultProps = {
        name: 'permissions'
    }
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: true,
            colored: false,
            header: true,
            role: '',
            rowId: '',
            menuList: []
        };
    }

    componentDidMount = () => {
        this.props.onRef(this);
    }

    componentWillUnmount = () => {
        this.props.onRef(null);
    }

    //submitting role and permissions
    submit = (values) => {
        this.setState({ submitted: true });
        if (!this.state.role) {
            return;
        }
        let method, apiUrl;
        if (this.props.formType == 'edit') {
            method = 'PUT';
            apiUrl = `${this.props.apiUrl}/${this.state.rowId}`;
        } else {
            method = 'POST';
            apiUrl = this.props.apiUrl;
        }

        let permissions = {};
        let keys = Object.keys(values);
        this.state.menuList.forEach((item, index) => {

            keys.forEach((key) => {
                if (item.label == key) {
                    permissions[item.label] = values[key]
                }
            })
        })
        let body = {}
        body = { permissions, role: values.role }
        return fetch(method, apiUrl, body)
            .then(async (response) => {
                if (response && response.respCode && response.respCode == 204) {
                    this.props.getDataFromServer();
                    if (response.respMessage) {
                        showToasterMessage(response.respMessage, 'success');
                    }
                    this.closeRolesModal()
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }

    // Display Selected row data permissions in modal
    getRowData = async (rowData, formType) => {
        let data = {}, permissionsArray = []
        if (formType == 'edit') {
            data.role = rowData.role;
        }
        else {
            data.role = '';
        }
        let permissions = rowData.permissions
        if (permissions) {
            let keys = Object.keys(permissions);
            keys.forEach((element) => {
                if (element) {
                    data[element] = permissions[element];
                    let permissonObj = {
                        label: element,
                    }
                    if (formType == 'edit') {
                        if (data[element] == 'edit') {
                            permissonObj.edit = false;
                        } else if (data[element] == 'view') {
                            permissonObj.view = false;
                        } else if (data[element] == 'noView') {
                            permissonObj.noView = false;
                        }
                    }
                    else {
                        if (data[element] == 'edit') {
                            data[element] = 'noView';
                            permissonObj.noView = false;
                        }
                    }
                    permissionsArray.push(permissonObj);
                }
            });
        }
        this.props.load(data);
        this.setState({
            rowId: rowData._id,
            role: rowData.role,
            defaultValue: false,
            menuList: permissionsArray
        })
    }

    // Close Modal
    closeRolesModal = async () => {
        this.props.load({});
        this.props.closeRolesModal();
    }

    // Handle input field
    handleData = (e) => {
        this.setState({ role: e.target.value });
    }

    render() {
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        const { handleSubmit, t } = this.props;
        return (
            <div>
                <Modal
                    isOpen={this.props.openRolesModal}
                    className={`modal-dialog-centered modal-dialog--primary ${modalClass}`}
                >
                    <ModalHeader className="modal__header">
                        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.closeRolesModal} />
                        <span className="bold-text modal__title" style={{ fontSize: '16px' }}>{this.props.formType && this.props.formType == 'edit' ? t('forms.edit') : t('forms.add')} {this.props.type}</span>
                    </ModalHeader>
                    <ModalBody className="modal__body" style={{ justifyContent: 'center' }}>
                        <form className="form form--horizontal " onSubmit={handleSubmit(this.submit)}>
                            <div className="form__form-group">
                                <span className="form__form-group-label">{t('forms.rolesModal_title')}</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name='role'
                                        component={DefaultInput}
                                        type="text"
                                        id='role'
                                        placeholder='Role'
                                        onChange={e => this.handleData(e)}
                                    />
                                </div>
                                {!this.state.role && this.state.submitted && <p className="form__form-group-error">{t('forms.error_messages.empty_basic')}</p>}
                            </div>

                            <div className="form__form-group row">
                                <div className='col-sm-6' style={{ paddingLeft: '119px' }}>
                                    <span className='pr-3 pl-5'>{t('forms.pagePermissions_edit')}</span>
                                    <span className='pr-3 pl-2'>{t('forms.pagePermissions_view')}</span>
                                    <span className='pr-2 '>{t('forms.pagePermissions_noView')}</span>
                                </div>
                                <div className='col-sm-6' style={{ paddingLeft: '119px' }}>
                                    <span className='pr-3 pl-5'>{t('forms.pagePermissions_edit')}</span>
                                    <span className='pr-3 pl-2'>{t('forms.pagePermissions_view')}</span>
                                    <span className='pr-2 '>{t('forms.pagePermissions_noView')}</span>
                                </div>
                            </div>

                            {this.state.menuList ?
                                this.state.menuList.map((item, index) => {
                                    return <div key={index} className="form__form-group col-sm-6">
                                        <span className="form__form-group-label text-capitalize">{item.label}</span>
                                        <div className="form__form-group-field ">
                                            <Field
                                                name={item.label}
                                                component={renderRadioButtonField}
                                                radioValue={!item.edit ? 'edit' : ''} />
                                            <Field
                                                name={item.label}
                                                component={renderRadioButtonField}
                                                radioValue={!item.view ? 'view' : ''}
                                                defaultChecked={this.state.defaultValue && index == 0 ? true : false}
                                            />
                                            <Field
                                                name={item.label}
                                                component={renderRadioButtonField}
                                                radioValue={!item.noView ? 'noView' : ''}
                                                defaultChecked={this.state.defaultValue && index > 0 ? true : false}
                                            />
                                        </div>
                                    </div>
                                }) : null
                            }
                            <div className='col-sm-12 '>
                                <ButtonToolbar className="modal__footer footerData mb-2">
                                    <Button color='primary' outline onClick={this.closeRolesModal}>{t('forms.cancel')}</Button>
                                    {this.props.formType && this.props.formType == 'edit' ?
                                        <Button color='primary' type='submit' outline >{t('forms.update')}</Button> :
                                        <Button color='primary' type='submit' outline>{t('forms.save')}</Button>
                                    }
                                </ButtonToolbar>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>
            </div >
        );
    }
}
RolesModal = reduxForm({
    form: "Roles Modal",
    enableReinitialize: true, // a unique identifier for this form
})(RolesModal);
RolesModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(RolesModal);
export default withTranslation('common')(RolesModal);