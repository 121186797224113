import React from 'react';
import { Button, Modal, ModalBody, ButtonToolbar } from 'reactstrap';
import fetch from '../../../../config/service';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
import validate from '../../../Validations/validate';
import configMessages from '../../../../config/configMessages';
import DefaultInput from '../../../../shared/components/form/DefaultInput';
import { withTranslation } from 'react-i18next';

const required = value => (value && value.length > 0 && typeof value === 'string' ? undefined : configMessages.fillField)

class KpiEditModal extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = async () => {
        this.props.onRef(this);
    }

    getRowData = async (rowData) => {
        if (this.props.load) {
            this.props.load(rowData)
        }
    }

    submitValues = async (values) => {
        let method, url, body;
        if (this.props.type == 'Category') {
            let selectedRow = this.props.rowData;
            let id = selectedRow._id;

            if (this.props.formType == 'Edit') {
                method = 'PUT';
                url = `kpi/${id}`;
                body = values;
            }
            else if (this.props.formType == 'delete') {
                method = 'DELETE';
                url = `kpi/${id}`;
                body = null;
            }
            else {
                method = 'POST';
                url = 'kpi';
                body = values;
            }
        }
        else {
            let array = this.props.subCategories;
            let id = this.props.categoryId;

            if (this.props.formType == 'Add') {
                array.push(values.subCategory);
                body = { 'subCategory': array }
            }
            else if (this.props.formType == 'Edit') {
                array.splice(this.props.subCategoryIndex, 1, values.subCategory);
                body = { 'subCategory': array }
            }
            else {
                array.splice(this.props.subCategoryIndex, 1);
                body = { 'subCategory': array }
            }
            url = `kpi/${id}`, method = 'PUT'
        }
        return fetch(method, url, body)
            .then(async (response) => {
                if (response && response.respCode == 204) {
                    showToasterMessage(this.props.type + " " + this.props.formType + "ed Successfully", 'success');
                    this.props.reset();
                    this.props.closeFormModal();
                }
            }).catch((err) => {
                return err;
            });
    }

    render() {
        const { handleSubmit, t } = this.props;
        return (
            <Modal isOpen={this.props.isFormModalOpen} className='deleteModalHeight'>
                <ModalBody className='deleteModalBody'>
                    <form className="form form--horizontal" onSubmit={handleSubmit(this.submitValues)}>
                        <div className="form__form-group">
                            <span className="form__form-group-label" style={{ fontSize: '13px', textTransform: 'uppercase', textAlign: 'left' }}>{this.props.formType} {this.props.type}</span>
                            <div className="form__form-group-field">
                                <Field
                                    name={this.props.type == 'Category' ? 'category' : 'subCategory'}
                                    component={DefaultInput}
                                    type="text"
                                    placeholder={this.props.formType == 'Add' ? this.props.placeholder : ''}
                                    validate={required}
                                    isDisable={this.props.formType == 'delete' ? true : null}
                                />
                            </div>
                        </div>
                        <div className="form__form-group" style={{ textAlign: "left" }}>
                            {(this.props.formType == 'kpi.delete' || this.props.formType == 'delete') ?
                                <div className="form__form-group" style={{ fontSize: '13px', textTransform: 'uppercase', textAlign: 'left' }}>{t('kpi_edit_modal.delete_conf')}</div> : null}
                            <ButtonToolbar className="form__button-toolbar m-0">
                                <Button color="primary" type="submit">{
                                    this.props.formType == 'Edit' ? t('kpi_edit_modal.update') :
                                        this.props.formType == 'Add' ? t('kpi_edit_modal.add') :
                                            t('kpi_edit_modal.delete')}</Button>
                                <Button type="button" style={{ zIndex: 'revert' }} onClick={this.props.closeFormModal}>{t('kpi_edit_modal.cancel')}</Button>
                            </ButtonToolbar>
                        </div>
                    </form>
                </ModalBody>
            </Modal >
        )
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
KpiEditModal = reduxForm({
    form: "KpiEditModal", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(KpiEditModal);

// You have to connect() to any reducers that you wish to connect to yourself
KpiEditModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(KpiEditModal);

export default withTranslation('common')(KpiEditModal);