import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import config from '../../../config/config';
import dateFormats from '../../UI/FormatDate/formatDate';
import { withTranslation } from "react-i18next";
import fetch from '../../../config/service';

class ViewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectsList: '',
    };
  }

  componentDidMount = () => {
    this.props.onRef(this);
    if (this.props.type === 'Employees') {
      this.getProjects();
    }
  };
  getRowData = rowData => {
    this.setState({ rowData: rowData });
  };

  componentWillUnmount() {
    this.props.onRef(null);
  }

  getProjects() {
    let url = `employees/employeesProjects/${this.props.idForProjectList}`;

    fetch('GET', url)
      .then(async (response) => {
        if (response && response.length > 0) {

          this.setState({ projectsList: response[0].projectNames });
        }
      }).catch((err) => {
        return err;
      });
  }

  render() {
    const { t } = this.props;

    const boldStyle = {
      fontWeight: "bold",
      textAlign: "left",
      padding: "5px 0px",
    };

    const labelStyle = {
      textTransform: "capitalize",
    };

    const valueStyle = {
      wordWrap: "break-word", // Allow the text to wrap within the field
      textAlign: "left",
      padding: "5px 0px",
    };

    return (
      <div>
        <Modal isOpen={this.props.openViewModal} className="ViewModalHeight">
          <ModalHeader style={{ justifyContent: "center" }}>
            {this.props.type} {t('forms.details_title')}
          </ModalHeader>
          <ModalBody className="ViewModalBody">
            <div className="row form">
              {this.state.rowData && this.state.rowData.length > 0
                ? this.state.rowData.map((item, i) => {
                  if (item.label) {
                    if (item.value === "" || (typeof item.value === 'object' && item.label !== 'Inventory Data')) {
                      return null;
                    } else {
                      if (item.name === 'checkouts') {
                        return (
                          <div key={i} className='row'>
                            <div style={boldStyle} className='col-12 text-capitalize'>
                              {item.label}
                            </div>
                            {item.value.map((el, index) => (
                              <div className="col-6" key={i + index}>
                                <div className='row col-12'>
                                  <div style={boldStyle} className="col-5">
                                    {'Entry ' + (index + 1) + ":"}
                                  </div>
                                  <div style={valueStyle} className="col-7">
                                    {dateFormats.formatDate(el.time, config.timeFormat)}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        );
                      }
                      //displaying inventory data in the employee view modal
                      else if (item.name === 'inventoryData') {
                        return (
                          <div className="col-sm-6">
                            <div className="row">
                              <div style={boldStyle} className='col-5 text-capitalize'>
                                {item.label}
                              </div>
                              <div style={valueStyle} className="col-7">
                                {item.value.map((inventory, index) => (
                                  <p>{inventory}</p>
                                ))}
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="col-sm-6" key={i}>
                            <div className="row">
                              <div style={{ ...boldStyle, labelStyle }} className="col-sm-5">
                                {item.label}
                              </div>
                              <div style={valueStyle} className="col-sm-7">
                                {item && item.type === 'link' ?
                                  <a target="_blank" href={item.value}>{item.value}</a>
                                  : item.label === 'Email' || item.label === 'Company Email' ?
                                    <span className='text-lowercase'>{item.value}</span> :
                                    <span className='text-capitalize'>{item.value}</span>
                                }
                              </div>
                            </div>
                          </div>
                        );
                      }
                    }
                  }

                })
                : null
              }
              {this.state.projectsList && this.state.projectsList.length ? (
                <div className="col-sm-6">
                  <div className="row">
                    <div style={{ ...boldStyle, labelStyle }} className="col-5">
                      {'Assigned Projects'}
                    </div>
                    <div style={{ ...valueStyle, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginTop: '-5px' }} className="col-7">
                      {this.state.projectsList.map((project, index) => (
                        <div style={{ ...valueStyle, whiteSpace: "nowrap" }} key={index}>
                          {project}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </ModalBody>
          <ModalFooter style={{ width: "100%", height: "auto", padding: 10 }}>
            <Button color="primary" onClick={this.props.closeViewModal}>
              {t('forms.close')}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withTranslation('common')(ViewModal);
