import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import EmployeesTable from './components/EmployeesTable';

const BasicTables = ({ t }) => (
  <Container>
    <EmployeesTable />
  </Container>
);

BasicTables.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(BasicTables);
