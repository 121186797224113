import React from 'react';
import TableRender from '../../CommonDataTable/TableRender';
import config from '../../../../config/config';
import configImages from '../../../../config/configImages';
import RolePermissions from '../../CommonDataTable/Permissions';
import fetch from '../../../../config/service';
import { withTranslation } from 'react-i18next';

// config file
class IssuesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statusTypes: [{}]
        };
        this.getSettingsDataFromServer();
    }

    //Get 'Status Types' from Settings
    getSettingsDataFromServer = async () => {
        await fetch('GET', 'settings')
            .then(async (response) => {
                if (response && response.settings && response.settings[0]) {
                    let data = [...response.settings[0].issueStatus];
                    // to set default status values
                    let statusValue = [];
                    if (data && data.length > 0) {
                        data.forEach(element => {
                            statusValue.push(element.value);
                        });
                    }
                    await this.setState({ statusTypes: data, statusValue: statusValue });
                }
            }).catch((err) => {
                return err;
            });
    }

    getTableFields = async () => {
        await this.getSettingsDataFromServer();
        let statusTypes = this.state.statusTypes;
        let statusValue = this.state.statusValue;

        let priorityTypes = [
            { label: 'All', value: null },
            { label: 'Blocker', value: 'blocker' },
            { label: 'Minor', value: 'minor' },
            { label: 'Major', value: 'major' },
            { label: 'Critical', value: 'critical' }
        ];

        let data = [
            { textAlign: 'center', width: 45, selectionMode: 'multiple', field: 'Sno', filter: false, sortable: false },
            { textAlign: 'left', width: 110, field: 'issueId', header: 'issues.table_fields.issue_id', filter: true, sortable: true, placeholder: 'issues.table_fields.ph_search' },
            { textAlign: 'left', width: 100, field: 'projectName', header: 'issues.table_fields.project', filter: true, sortable: true, placeholder: 'issues.table_fields.ph_search' },
            { textAlign: 'left', width: 100, field: 'taskName', header: 'issues.table_fields.task', filter: true, sortable: true, placeholder: 'issues.table_fields.ph_search' },
            { textAlign: 'left', width: 100, field: 'screenName', header: 'issues.table_fields.screen', filter: true, sortable: true, placeholder: 'issues.table_fields.ph_search' },
            { textAlign: 'left', width: 120, field: 'description', header: 'issues.table_fields.desc', placeholder: 'issues.table_fields.ph_search' },
            { textAlign: 'left', width: 100, field: 'assignedToName', header: 'issues.table_fields.assigned_to', filter: true, sortable: true, placeholder: 'issues.table_fields.ph_search' },
            { textAlign: 'center', width: 70, field: 'priority', header: 'issues.table_fields.priority', filterElement: priorityTypes, filter: true, sortable: false },
            { textAlign: 'center', width: 95, field: 'status', header: 'issues.table_fields.status', filterElement: statusTypes, filter: true, sortable: false, defaultValues: statusValue },
            { textAlign: 'left', width: 100, field: 'createdBy', header: 'issues.table_fields.created_by', filter: true, sortable: false, placeholder: 'issues.table_fields.ph_search' },
            { textAlign: 'center', width: 120, field: 'startDate', header: 'issues.table_fields.start_date', filter: true, sortable: true, placeholder: config.dateDmyFormat, type: 'date' },
            { textAlign: 'center', width: 120, field: 'endDate', header: 'issues.table_fields.end_date', filter: true, sortable: true, placeholder: config.dateDmyFormat, type: 'date' },
            { textAlign: 'center', width: 120, field: 'created', header: 'issues.table_fields.created_date', filter: true, sortable: true, placeholder: config.dateDmyFormat, type: 'date' },
            { textAlign: 'center', width: 120, field: 'updated', header: 'issues.table_fields.updated_date', filter: true, sortable: true, placeholder: config.dateDmyFormat, type: 'date' },
            { textAlign: 'center', width: 90, field: 'Actions', header: 'issues.table_fields.actions', filter: false, sortable: false },
        ];

        let screenPermissions = RolePermissions.screenPermissions('Issues');
        if (screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };
    getFormFields = () => {
        const { t } = this.props;
        // await this.getSettingsDataFromServer();
        let statusTypes = this.state.statusTypes;

        let priorityTypes = [
            { label: 'Blocker', value: 'blocker' },
            { label: 'Minor', value: 'minor' },
            { label: 'Major', value: 'major' },
            { label: 'Critical', value: 'critical' }
        ]
        return ([
            { required: true, value: '', type: 'autoComplete', name: 'projectId', label: t('issues.form_fields.project'), id: 'Project', searchApi: 'projects', searchField: 'name', placeholder: t('issues.form_fields.ph_projects'), otherField: 'projectName' },
            { required: true, value: '', type: 'autoComplete', name: 'taskId', label: t('issues.form_fields.task'), id: 'taskName', searchApi: 'tasks', searchField: 'name', placeholder: t('issues.form_fields.ph_tasks'), otherField: 'projectName', filterField: 'projectName' },
            { required: false, value: '', type: 'text', name: 'screenName', label: t('issues.form_fields.screen'), id: 'name' },
            { required: true, value: '', type: 'autoComplete', name: 'assignedTo', label: t('issues.form_fields.assigned_to'), id: 'AssignedTo', searchApi: 'employees', searchField: 'displayName', placeholder: t('issues.form_fields.ph_assigned_to'), otherField: 'assignedToName' },
            { required: false, value: '', type: 'dropDown', name: 'status', label: t('issues.form_fields.status'), id: 'status', options: statusTypes, defaultValue: true },
            { required: false, value: '', type: 'dropDown', name: 'priority', label: t('issues.form_fields.priority'), id: 'priority', options: priorityTypes },
            { required: false, value: '', type: 'date', name: 'startDate', label: t('issues.form_fields.start_date'), id: 'startDate', placeholder: config.dateDashFormat },
            { required: false, value: '', type: 'date', name: 'endDate', label: t('issues.form_fields.end_date'), id: 'endDate', placeholder: config.dateDashFormat },
            { required: true, value: '', type: 'textarea', name: 'description', label: t('issues.form_fields.desc'), id: 'description' },
            { required: false, value: '', type: 'number', name: 'estimatedHours', label: t('issues.form_fields.est_hrs'), id: 'estimatedHours' },
            { required: false, value: '', type: 'editor', name: 'comments', label: t('issues.form_fields.comments'), id: 'comments' },
        ]);
    }

    render() {
        const { t } = this.props;
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        return (
            <div>
                <TableRender type='Issues'
                    globalSearch={t('issues.table.globalsearch')}
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                    sample={user.role == 'Client' ? configImages.issuesSampleXLclient : configImages.issuesSampleXL}
                    bulkapi='issue'
                />
            </div>
        );
    }
}
export default withTranslation('common')(IssuesTable);