import React from 'react';
import { Input, FormFeedback, Button } from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Redirect } from 'react-router';
// login styles
// import '../../../../scss/loginStyles.css';

// fecth method from service.js file
import fetch from '../../../../config/service';

// show message 
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

// config file
import config from '../../../../config/config';
import Loader from '../../../App/Loader';
export default class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.buttonActionType = null;
        this.state = {
            email: '',
            validate: {
                newPassword: false,
                confirmPassword: false
            },
            isActivated: false,
            newPassword: '',
            confirmPassword: '',
            isLoading: false
        };
    }

    // on value change in input
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    // handle login user data
    handleUserPassword = () => {
        this.setState({ isLoading: true });
        let userBody = {
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword,
            enEmail: this.props.match.params.encodedEmailId
        }
        let url;
        if (this.props.match.params && this.props.match.params.active) {
            url = 'employees/changeRecoverPassword?active=true';
        } else {
            url = 'employees/changeRecoverPassword';
        }
        return fetch('POST', url, userBody)
            .then((response) => {
                if (response && response.respCode && response.respCode === 200) {
                    this.setState({ isLoading: false });
                    if (response.details && response.details._id) {
                        let userDetails = response.details;
                        if (response.accessToken) {
                            let tokenInfo = {
                                accessToken: response.accessToken,
                                refreshToken: response.refreshToken,
                                tokenExpires: response.tokenExpires,
                            };
                            userDetails = { ...userDetails, ...tokenInfo };
                        }
                        // save user credentials in storage
                        localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
                    }
                    this.setState({ isActivated: true });
                    // display message
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    this.setState({ isLoading: false });
                    // display message
                    showToasterMessage(response.errorMessage, 'error');
                } else {
                    this.setState({ isLoading: false });
                }
                this.clearInputFields();
            }).catch((err) => {
                return err;
            });
    }

    // check input fields empty or not
    checkCredentials = () => {
        const { validate } = this.state
        if (!this.state.newPassword) {
            validate.newPassword = 'has-danger';
        }
        if (!this.state.confirmPassword) {
            validate.confirmPassword = 'has-danger'
        }
        this.setState({ validate })
    }

    // validate password
    validatePassword = (e) => {
        let validate = this.state.validate;
        if (e.target.value.length > 6) {
            var test = config.passwordRegex.test(e.target.value);
            if (test) {
                validate[e.target.name] = 'has-success';
            } else {
                validate[e.target.name] = 'has-danger';
            }
            this.setState({ validate: validate })
        }
    }

    // clear input data after submit password changes
    clearInputFields = () => {
        let validate = {
            newPassword: false,
            confirmPassword: false
        }
        this.setState({
            newPassword: '',
            confirmPassword: '',
            validate: validate
        })
    }

    render() {
        return (
            <form className="form">
                <Loader loader={this.state.isLoading} />
                <div className='row form--group'>
                    <Input className='inputLogin'
                        type="password"
                        name="newPassword"
                        valid={this.state.validate.newPassword === 'has-success'}
                        invalid={this.state.validate.newPassword === 'has-danger'}
                        placeholder="New Password"
                        value={this.state.newPassword}
                        onChange={(e) => {
                            this.validatePassword(e)
                            this.handleChange(e)
                        }}
                    />
                    {!this.state.newPassword ?
                        <FormFeedback className='formErrorText'>New Password is Required</FormFeedback> :
                        null}
                    <Input className='inputLogin'
                        type="password"
                        name="confirmPassword"
                        valid={this.state.validate.confirmPassword === 'has-success'}
                        invalid={this.state.validate.confirmPassword === 'has-danger'}
                        placeholder="Re-enter New Password"
                        value={this.state.confirmPassword}
                        onChange={(e) => {
                            this.validatePassword(e)
                            this.handleChange(e)
                        }}
                    />
                    {!this.state.confirmPassword && this.state.newPassword ?
                        <FormFeedback className='formErrorText' >Please Re-enter new password</FormFeedback> :
                        null}
                    <Button className="loginBtn" block color='primary'
                        onClick={this.state.newPassword && this.state.confirmPassword
                            ? this.handleUserPassword : this.checkCredentials}>Submit</Button>
                </div>
                {this.state.isActivated ?
                    <Redirect to="/log_in" /> : null}
            </form>
        );
    }
}