import React from 'react';

// table styles
import '../../../scss/table.css';
// import '../../../scss/dashboardStyles.css'

import DataTables from './DataTable';

export default class TableRender extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeData: [],
            isOpenDeleteModal: false,
            isOpenEditModal: false,
            singleRowData: {}
        };
    }

    render() {
        return (
            <div key={this.props.type}>
                <DataTables {...this.props}
                    AllData={this.state.employeeData}
                    globalSearch={this.props.globalSearch}
                    editSelectedRow={this.editSelectedRow}
                    deleteSelectedRow={this.deleteConfirmAction}
                    onRef={(ref) => (this.DataTablesRef = ref)}
                    type={this.props.type}
                    tablefields={this.props.tableFields}
                    formFields={this.props.formFields}
                    sample={this.props.sample}
                    bulkapi={this.props.bulkapi}
                    handleViewData={this.props.handleViewData}
                    getTimesheetReports={this.props.getTimesheetReports}
                    getMonthlyReport={this.props.getMonthlyReport}
                />
            </div>
        );
    }
}