import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

class SelectField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
  };

  static defaultProps = {
    placeholder: '',
    options: [],
  };

  constructor(props) {
    super(props)
    this.state = {
      value: null,
      defaultValue: (typeof this.props.defaultValue === 'boolean') ? this.props.options[0] : null
    }

  }
  handleChange = (selectedOption) => {
    if (selectedOption.label == "Half Day" && this.props.type !== 'Employees') {
      this.props.leavesFormData();
    } else if (selectedOption.label == 'In Stock' || selectedOption.label == 'Repair') {
      this.props.inventoryStatus();
    } else if (selectedOption.label != "First Half" && selectedOption.label != "Second Half" && this.props.type != 'Tasks' && this.props.type != 'Leave Update Balance') {
      this.props.getFormFields();
    }
    const { onChange, value } = this.props.input;
    this.setState({ value: selectedOption });
    onChange(selectedOption.value);
  };

  render() {
    const { placeholder, options, isDisable, formType
    } = this.props;
    const { name, value } = this.props.input;
    let selectedOption = this.props.options.find(option => option.label === this.props.defaultValue);
    if (selectedOption) {
      this.setState({ defaultValue: selectedOption })
    }

    const isValue = { label: formType === "edit" && typeof value !== "string" ? value.labelName : value, value: formType === "edit" && typeof value !== "string" ? value.labelName : value };
    const selectValue = this.state.value ? this.state.value : isValue && isValue.value ? isValue : this.state.defaultValue ? this.state.defaultValue : null
    if (!value && !this.state.value) {
      if (this.props.defaultValue && this.state.defaultValue) {
        this.handleChange(this.state.defaultValue);
      }
    }

    return (
      <Select
        name={name}
        value={selectValue}
        onChange={this.handleChange}
        options={options}
        clearable={false}
        className="react-select"
        placeholder={placeholder}
        classNamePrefix="react-select"
        style={isDisable ? { background: 'lightgray', border: "none" } : { background: 'white' }} {...placeholder} isDisabled={isDisable}
      />
    );
  }
}

const renderSelectField = (props) => {
  const { meta } = props;
  return (
    <div className="form__form-group-input-wrap">
      <SelectField
        {...props}
      />
      {meta.touched && meta.error && <span className="form__form-group-error mt-3">{meta.error}</span>}
    </div>
  );
};

renderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
};

export default renderSelectField;
