import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import KPIndex from './components/kpi';

const KPIpage = () => (
    <Container>
        <KPIndex />
    </Container>
);

// KPIpage.propTypes = {
//     t: PropTypes.func.isRequired,
// };

export default withTranslation('common')(KPIpage);
