import React from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane, CardHeader, Button } from 'reactstrap';
import classnames from 'classnames';
import fetch from '../../../../config/service';
import configImages from '../../../../config/configImages';
import config from '../../../../config/config';
import BulkUploadModal from '../../CommonDataTable/BulkUploadModal';
import FileUpload from '../../../Form/components/FileUpload';
import EditorModal from '../../CommonDataTable/EditorModal';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import dateFormats from '../../../UI/FormatDate/formatDate';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../App/Loader';
// config file
class InventoryDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inventoryDetailsArray: [
                { name: 'type', label: 'inv_details.labels.type', value: '' },
                { name: 'brand', label: 'inv_details.labels.brand', value: '' },
                { name: 'operatingSystem', label: 'inv_details.labels.operatingSystem', value: '' },
                { name: 'name', label: 'inv_details.labels.serialNumber', value: '' },
                { name: 'status', label: 'inv_details.labels.status', value: '', },
                { name: 'assignedToName', label: 'inv_details.labels.assignedToName', value: '' },
                { name: 'purchaseDate', label: 'inv_details.labels.purchaseDate', value: '' },
                { name: 'assignedDate', label: 'inv_details.labels.assignedDate', value: '' },
                { name: 'repairDate', label: 'inv_details.labels.repairDate', value: '' },
                { name: 'soldDate', label: 'inv_details.labels.soldDate', value: '' },
                { name: 'createdBy', label: 'inv_details.labels.created_by', value: '' },
                { name: 'invoiceNumber', label: 'inv_details.labels.invoiceNumber', value: '' },
                { name: 'purchaseDocumentLink', label: 'inv_details.labels.purchaseDocumentLink', value: '', type: 'link' },
                { name: 'assignedDocumentLink', label: 'inv_details.labels.assignedDocumentLink', value: '', type: 'link' }
            ],
            inventoryDetails: {},
            inventoryHistory: [],
            inventoryComments: [],
            inventoryAttachments: [],
            activeTab: '2',
            openEditorModal: false,
            active: 1,
            isLoading: false
        };
    }
    componentDidMount = () => {
        this.getRowDataBasedOnId();
    }
    toggle = (tab) => {
        const { activeTab } = this.state;
        if (activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };


    //back button 

    handleGoBack = () => {
        const { history } = this.props
        history.goBack() // go back is the previously opend page 
    }


    sendCommentsAttchementsDataToServer = (body, type) => {
        let data = {};
        data.contextId = { 'inventory': this.props.match.params.id };
        data.contextType = "inventory";
        if (type === 'comments') {
            data.eventType = "COMMENTS";
            data.comments = body;
        } else {
            data.eventType = "attachments";
            data.attachment = body;
        }
        return fetch('POST', 'events', data)
            .then(async (response) => {
                if (response && response.respCode) {
                    this.closeEditorModal();
                    showToasterMessage(response.respMessage, 'success');
                    this.getRowDataBasedOnId();
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }

    getRowDataBasedOnId = async () => {
        await this.setState({ isLoading: true })
        fetch('GET', `inventories/${this.props.match.params.id}?types=[COMMENTS,ATTACHMENTS]`)
            .then(async (response) => {
                if (response && (response.details || response.history || response.comments)) {
                    let inventoryDetails = response.details;
                    let inventoryHistory = response.history;
                    let inventoryComments = response.comments;
                    let inventoryAttachments = response.attachments
                    let keys = Object.keys(inventoryDetails)
                    let formData = []
                    for (let item of this.state.inventoryDetailsArray) {
                        if (keys.indexOf(item.name) !== -1) {
                            if (item.name == 'from' || item.name == 'assignedDate' || item.name == 'purchaseDate' || item.name == 'repairDate') {
                                item.value = dateFormats.formatDate(inventoryDetails[item.name], config.dateDmyFormat);
                            } else {
                                if (item.name == 'createdBy') {
                                    item.value = inventoryDetails.createdBy.name;
                                }
                                else {
                                    item.value = inventoryDetails[item.name]
                                }
                            }
                        }
                        formData.push(item);
                    }
                    await this.setState({
                        inventoryDetails: inventoryDetails, inventoryDetailsArray: formData,
                        inventoryHistory: inventoryHistory, inventoryComments: inventoryComments,
                        inventoryAttachments: inventoryAttachments, isLoading: false
                    })
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                    await this.setState({ isLoading: false })
                }
            }).catch((err) => {
                this.setState({ isLoading: false });
                return err;
            });
    }

    openEditorModal = () => {
        this.setState({ openEditorModal: true })
    }
    closeEditorModal = () => {
        this.setState({ openEditorModal: false })
    }

    //Delete Attachments ||CodeByTej
    deleteAttachment = async (item) => {
        this.setState({ isLoading: true });
        let id = item._id;
        let url = `${id}?contextType=${item.contextType}&eventType=${item.eventType}`
        return fetch('DELETE', `events/${url}`)
            .then(async (response) => {
                if (response && response.respMessage) {
                    showToasterMessage(response.respMessage, 'success');
                    this.getRowDataBasedOnId();
                }
                else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                    this.setState({ isLoading: false });
                }
            }).catch((err) => {
                return err;
            });
    }
    render() {
        const { activeTab } = this.state;
        const { t } = this.props;
        return (
            <div>
                <Card className='p-3'>
                    <CardHeader className='cardHeader'>
                        <div className="row m-0">
                            <div className='col-4 pl-0 pr-0' >{t('inv_details.title')}</div>
                        </div>
                    </CardHeader>
                    <CardBody className='cardHeader'>
                        <Button color='primary' outline className='mt-0 mb-2 pt-0 pb-0 pr-2 pl-2'
                            onClick={this.openEditorModal}>{t('inv_details.add_comments')}
                        </Button>
                        <Button color='primary' outline className='mt-0 mb-2 pt-0 pb-0 pr-2 pl-2' onClick={this.handleGoBack}>
                            <FontAwesomeIcon icon='arrow-left' className='mr-1' />
                            {t('Back')}
                        </Button>
                        <div className='row'>
                            {
                                this.state.inventoryDetailsArray.map((item, i) => {
                                    return item.value ? (
                                        <div className='col-sm-4' key={i}>
                                            <div className='row'>
                                                <div className='col-sm-4 labelFont mb-1' style={{ fontWeight: 'bold' }}>
                                                    {t(item.label)}:
                                                </div>
                                                <div className='col-sm-8 LabelValueFont'>
                                                    {item.type === 'link' ? (
                                                        <a target="_blank" href={item.value}>
                                                            <span>{item.value}</span>
                                                        </a>
                                                    ) : (
                                                        <span>{item.value}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : null;
                                })
                            }

                        </div>
                        {this.state.inventoryDetails['description'] ?
                            <div className='row mt-3'>
                                <span className='col-sm-auto labelFont' style={{ fontWeight: 'bold' }}>{t('inv_details.desc')} :</span>
                                <span className='col-sm-auto LabelValueFont'
                                    dangerouslySetInnerHTML={{ __html: this.state.inventoryDetails['description'] }}>
                                </span>
                            </div>
                            : null
                        }
                        <div className="tabs mt-4" style={{ backgroundColor: '#EAECEF', borderRadius: '5px' }} >
                            <div className="tabs__wrap">
                                <Nav tabs style={{ backgroundColor: 'white', borderColor: 'orange' }}>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { this.toggle('1') }}>{t('inv_details.nav_tabs.history')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { this.toggle('2') }}>{t('inv_details.nav_tabs.comments')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => { this.toggle('3') }}>{t('inv_details.nav_tabs.attachments')}</NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} style={{ overflow: 'auto' }}>
                                    <TabPane tabId="1" >
                                        <div style={{ maxHeight: 200 }}>
                                            {this.state.inventoryHistory && this.state.inventoryHistory.length > 0 ?
                                                this.state.inventoryHistory.map((item, i) => {
                                                    return (
                                                        <div key={i} className='row  p-2 m-0'>
                                                            <div className="timeline__content col-sm-10">
                                                                <div className='row justify-content-between' style={{ padding: '0px 10px 0px 10px' }}>
                                                                    <h5 className='col-sm-auto text-capitalize'>{item.createdBy.employee.displayName ? item.createdBy.employee.displayName : null}</h5>
                                                                    <h6 className='col-sm-auto'> {dateFormats.timeConversion(item.created)}</h6>
                                                                    <p className='col-12' dangerouslySetInnerHTML={{ __html: item.description }}></p>
                                                                </div>
                                                            </div>
                                                            <div className="timeline__icon ml-3">
                                                                {item.createdBy.employee.photo ?
                                                                    <img src={`${config.imgUrl}employee/${item.createdBy.employee.photo ? item.createdBy.employee.photo : null}`} className='detailsImgStyle rounded-circle'
                                                                    />
                                                                    : <img src={configImages.defaultImg} style={{ borderRadius: '100px' }} alt='img' />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : null
                                            }
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div style={{ maxHeight: 200 }}>
                                            {this.state.inventoryComments && this.state.inventoryComments.length > 0 ?
                                                this.state.inventoryComments.map((item, i) => {
                                                    return (
                                                        <div key={i} className='row  p-2 m-0'>
                                                            <div className="timeline__content col-sm-10">
                                                                <div className='row justify-content-between' style={{ padding: '0px 10px 0px 10px' }}>
                                                                    <h5 className='col-sm-auto text-capitalize'>{item.createdBy.name ? item.createdBy.name : null}</h5>
                                                                    <h6 className='col-sm-auto'>{dateFormats.formatDate(item.created, config.dateDmyFormat)}</h6>
                                                                    <p className='col-12' dangerouslySetInnerHTML={{ __html: item.comments }}></p>
                                                                </div>
                                                            </div>
                                                            <div className="timeline__icon ml-3">
                                                                {item.createdBy.employee.photo ?
                                                                    <img src={`${config.imgUrl}employee/${item.createdBy.employee.photo ? item.createdBy.employee.photo : null}`} className='detailsImgStyle rounded-circle'
                                                                    />
                                                                    : <img src={configImages.defaultImg} style={{ borderRadius: '100px' }} alt='img' />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : null
                                            }
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Loader loader={this.state.isLoading} />
                                        <div className='row p-2 m-0' >
                                            <div className="col-12 text-right">
                                                <div className='dragdrop mt-1'>
                                                    <FileUpload
                                                        onRef={(ref) => (this.fileUploadRef = ref)}
                                                        url={`events/uploadAttachment`}
                                                        label='Choose Files'
                                                        taskId={this.props.match.params.id}
                                                        taskType='attach'
                                                        getFileName={this.sendCommentsAttchementsDataToServer}
                                                        isLoading={this.state.isLoading}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.inventoryAttachments && this.state.inventoryAttachments.length > 0 ?
                                                this.state.inventoryAttachments.map((item, i) => {
                                                    return (
                                                        <div key={i} className='col-sm-2' style={{ padding: '20px' }}>
                                                            <div style={{ justifyContent: 'center' }}>
                                                                <FontAwesomeIcon className='genderIconAlignment'
                                                                    color='white'
                                                                    icon='trash-alt'
                                                                    data-toggle="tool-tip"
                                                                    title="Delete"
                                                                    style={{ color: '#bf1725', marginRight: '10px', width: '13', cursor: 'pointer' }}
                                                                    onClick={() => this.deleteAttachment(item)}
                                                                />
                                                                {item.attachment[0].path ?
                                                                    <img src={`${config.imgUrl}attachment/${item.attachment[0].name}`} className='detailsImgStyle' />
                                                                    :
                                                                    <img src={configImages.defaultImg} className='detailsImgStyle' />
                                                                }
                                                            </div>
                                                            <a target="_blank" href={`${config.imgUrl}attachment/${item.attachment[0].name}`} style={{ fontSize: 11 }}>{item.attachment[0].name}</a>
                                                        </div>
                                                    )
                                                })
                                                : null
                                            }
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </CardBody>
                </Card >
                <BulkUploadModal
                    type='Attachments'
                    url={this.getApiUrl}
                    openBulkUploadModal={this.state.openBulkUploadModal}
                    closeBulkModal={this.closeBulkModal} />
                <EditorModal
                    commentsData={this.sendCommentsAttchementsDataToServer}
                    openEditorModal={this.state.openEditorModal}
                    closeEditorModal={this.closeEditorModal} />
            </div >
        );
    }
}
export default withTranslation('common')(InventoryDetails);