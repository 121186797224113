import React from 'react';
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, ButtonToolbar, Input, FormGroup, Label, Col, FormFeedback, FormText } from 'reactstrap';
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, CardHeader, Button, ButtonToolbar } from 'reactstrap';
import classnames from 'classnames';
// import { Editor } from 'primereact/editor';
import { Link } from 'react-router-dom';
import fetch from '../../../../config/service';
import configImages from '../../../../config/configImages';
import config from '../../../../config/config';
import configMessages from '../../../../config/configMessages';
import BulkUploadModal from '../../CommonDataTable/BulkUploadModal';
import FileUpload from '../../../Form/components/FileUpload';
import EditorModal from '../../CommonDataTable/EditorModal';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import dateFormats from '../../../UI/FormatDate/formatDate';
import Loader from '../../../App/Loader';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
import validate from '../../../Validations/validate';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import Select from '../../../../shared/components/form/Select';
import RadioButton from '../../../../shared/components/form/RadioButton';
import DefaultInput from '../../../../shared/components/form/DefaultInput';
import DefaultTextArea from '../../../../shared/components/form/DefaultTextArea';
import Editor from '../../../../containers/Form/components/Editor';
import DatePicker from '../../../../shared/components/form/DatePicker';
import AutoComplete from '../../../Form/components/AutoComplete';
import { parseISO } from 'date-fns';
import CheckBox from '../../../../shared/components/form/CheckBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField);

class IssueDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            IssueDetailsArray: [
                { name: 'projectName', label: 'Project', value: '' },
                { name: 'name', label: 'Issue', value: '', },
                { name: 'assignedToName', label: 'AssignedTo', value: '', },
                { name: 'startDate', label: 'Start Date', value: '' },
                { name: 'endDate', label: 'End Date', value: '' },
                { name: 'status', label: 'Status', value: '' },
                { name: 'priority', label: 'Priority', value: '' },
                { name: 'createdBy.name', label: 'CreatedBy', value: '' },

            ],
            issueId: '',
            currentIssueId: '',
            IssueDetails: {},
            IssueHistory: [],
            IssueComments: [],
            IssueAttachments: [],
            activeTab: '2',
            openEditorModal: false,
            assignedTo: '',
            isClientAdded: false,
            statusTypes: [],
            isLoading: false
        };

    }
    componentDidMount = async () => {
        let formFields = await this.getFormFields();
        let user;
        if (localStorage.getItem('loginCredentials')) {
            user = JSON.parse(localStorage.getItem('loginCredentials'));
        }
        if (formFields) {

            //If user is client remove few fields
            if (user.role == 'Client') {
                formFields.splice(3, 1);
                formFields.splice(8, 1);
            }

            //If user other than client add client added checkbox
            if (user.role != 'Client') {
                formFields.splice(8, 0,
                    { required: false, value: '', type: 'checkBox', name: 'isClientAdded', label: 'Client Added', id: 'isClientAdded' }
                )
            }
            this.setState({ formFields: formFields });
        }
        this.getRowDataBasedOnId();
    }

    //Fetching issue-status dropdowns from server||CodeByTej
    getSettingsDataFromServer = async () => {
        await fetch('GET', 'settings')
            .then(async (response) => {
                if (response && response.settings && response.settings[0]) {
                    let data = [...response.settings[0].issueStatus];
                    await this.setState({ statusTypes: data });
                }
            }).catch((err) => {
                return err;
            });
    }

    getFormFields = async () => {
        const { t } = this.props;
        await this.getSettingsDataFromServer();
        let statusTypes = this.state.statusTypes;
        let priorityTypes = [
            { label: 'Blocker', value: 'blocker' },
            { label: 'Minor', value: 'minor' },
            { label: 'Major', value: 'major' },
            { label: 'Critical', value: 'critical' }
        ]
        return ([
            { required: true, value: '', type: 'autoComplete', name: 'projectId', label: 'issue_details.form_fields.project', id: 'Project', searchApi: 'projects', searchField: 'name', placeholder: 'issue_details.form_fields.ph_project', otherField: 'projectName' },
            { required: false, value: '', type: 'autoComplete', name: 'taskId', label: 'issue_details.form_fields.task', id: 'taskName', searchApi: 'tasks', searchField: 'name', placeholder: 'issue_details.form_fields.ph_task', otherField: 'projectName', filterField: 'projectName' },
            { required: false, value: '', type: 'text', name: 'screenName', label: 'issue_details.form_fields.screen', id: 'name', placeholder: 'issue_details.form_fields.ph_screen' },
            { required: true, value: '', type: 'autoComplete', name: 'assignedTo', label: 'issue_details.form_fields.assigned_to', id: 'AssignedTo', searchApi: 'employees', searchField: 'displayName', placeholder: 'issue_details.form_fields.ph_assigned_to', otherField: 'assignedToName' },
            { required: false, value: '', type: 'dropDown', name: 'status', label: 'issue_details.form_fields.status', id: 'status', options: statusTypes, defaultValue: true },
            { required: false, value: '', type: 'dropDown', name: 'priority', label: 'issue_details.form_fields.priority', id: 'priority', options: priorityTypes },
            { required: false, value: '', type: 'date', name: 'startDate', label: 'issue_details.form_fields.start_date', id: 'startDate', placeholder: config.dateDashFormat },
            { required: false, value: '', type: 'date', name: 'endDate', label: 'issue_details.form_fields.end_date', id: 'endDate', placeholder: config.dateDashFormat },
            { required: true, value: '', type: 'textarea', name: 'description', label: 'issue_details.form_fields.desc', id: 'description', placeholder: 'issue_details.form_fields.ph_desc' },
            { required: false, value: '', type: 'number', name: 'estimatedHours', label: 'issue_details.form_fields.est_hrs', id: 'estimatedHours', placeholder: 'issue_details.form_fields.ph_est_hrs' },
            { required: false, value: '', type: 'editor', name: 'comments', label: 'issue_details.form_fields.comments', id: 'comments' },
        ]);
    }

    toggle = (tab) => {
        const { activeTab } = this.state;
        if (activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    getFileName = (fileName) => {
        let data = {}
        data.contextId = { 'Issue': this.props.IssueId };
        data.contextType = "Issue";
        data.eventType = "attachments";
        data.attachment = fileName;
        fetchMethodRequest('POST', 'events', data)
            .then(async (response) => {
                if (response && response.respCode) {
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }

    sendCommentsAttchementsDataToServer = (body, type) => {
        let data = {};
        data.contextId = { 'issue': this.props.match.params.id };
        data.contextType = "issue";
        if (type === 'comments') {
            data.eventType = "COMMENTS";
            data.comments = body;
        } else {
            data.eventType = "attachments";
            data.attachment = body;
        }
        return fetch('POST', 'events', data)
            .then(async (response) => {
                if (response && response.respCode) {
                    this.closeEditorModal();
                    showToasterMessage(response.respMessage, 'success');
                    this.getRowDataBasedOnId();
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }

    getRowDataBasedOnId = (type) => {
        this.setState({ isLoading: true });

        let url;
        if (type == 'next') {
            let issueId = this.state.currentIssueId;
            url = `issues/getIssuesBasedOnIssueId?issueId=${issueId}&type=Next`;
        }
        else if (type == 'prev') {
            let issueId = this.state.currentIssueId;
            url = `issues/getIssuesBasedOnIssueId?issueId=${issueId}&type=Prev`;
        }
        else {
            url = `issues/${this.props.match.params.id}?types=[COMMENTS,ATTACHMENTS]`;
            // url = `issues/getIssuesBasedOnIssueId?issueId=${issueId}&type=current`;

        }
        fetch('GET', url)
            .then(async (response) => {

                if (response && (response.details || response.Issue || response.history || response.comments)) {
                    this.setState({ isLoading: false });
                    let IssueDetails;
                    let data;

                    if (response && response.details) {
                        IssueDetails = response.details;
                        data = response.details;
                    }
                    if (response && response.Issue) {
                        IssueDetails = response.Issue;
                        data = response.Issue;
                    }

                    if (data && data.taskName) {
                        data.taskId = { _id: data.taskId, name: data.taskName };
                    }
                    let assignEmp = [];
                    if (data.projectId.assignEmployees && data.projectId.assignEmployees.length > 0) {
                        assignEmp = data.projectId.assignEmployees;
                    }
                    if (data.projectId && data.projectId.teamLead && data.projectId.teamLeadName) {
                        assignEmp.push({ _id: data.projectId.teamLead, displayName: data.projectId.teamLeadName });
                    }

                    setTimeout(() => {
                        this.setState({ assignedEmps: assignEmp });
                    }, 0)


                    this.props.load(data);
                    let IssueHistory = response.history;
                    let IssueComments = response.comments;
                    let IssueAttachments = response.attachments
                    let keys = Object.keys(IssueDetails)
                    let formData = [];
                    for (let item of this.state.IssueDetailsArray) {
                        if (keys.indexOf(item.name) !== -1) {
                            if (item.name == 'startDate' || item.name == 'endDate') {
                                item.value = dateFormats.formatDate(IssueDetails[item.name], config.dateDashFormat);
                            } else {
                                item.value = IssueDetails[item.name];
                            }
                        }
                        formData.push(item);
                    }
                    this.setState({
                        IssueDetails: IssueDetails, IssueDetailsArray: formData, currentIssueId: IssueDetails.issueId,
                        IssueHistory: IssueHistory, IssueComments: IssueComments, IssueAttachments: IssueAttachments,
                        issueId: IssueDetails._id, assignedTo: IssueDetails.assignedToName
                    })

                }

                else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
                this.setState({ isLoading: false });
            }).catch((err) => {
                this.setState({ isLoading: false });
                return err;
            });
    }

    openEditorModal = () => {
        this.setState({ openEditorModal: true })
    }
    closeEditorModal = () => {
        this.setState({ openEditorModal: false })
    }


    //send data to server
    submit = async (formValues) => {

        if (formValues.estimatedHours) {
            formValues.estimatedHours = parseFloat(formValues.estimatedHours);
        }
        if (formValues.taskId && formValues.taskId.name) {
            formValues.taskName = formValues.taskId.name;
        }
        if (formValues.assignedTo && formValues.assignedTo.displayName) {
            formValues.assignedToName = formValues.assignedTo.displayName;
        } if (formValues.projectId && formValues.projectId.name) {
            formValues.projectName = formValues.projectId.name;
        }
        if (formValues.startDate) {
            formValues.startDate = parseISO(formValues.startDate);
        }
        if (formValues.endDate) {
            formValues.endDate = parseISO(formValues.endDate);
        }
        if (formValues) {
            let userBody = formValues;
            this.setState({ isLoading: true });
            return fetch('PUT', `issues/${this.props.match.params.id}`, userBody)
                .then(async (response) => {
                    if (response && response.respCode && response.respCode === 204) {
                        if (this.state.comments) {
                            this.sendCommentsAttchementsDataToServer(this.state.comments, 'comments');
                        }


                        // this.clearFormFields();
                        this.setState({ isLoading: false, comments: null });
                        this.setState({ formData: {} });
                        this.getRowDataBasedOnId();
                        showToasterMessage(response.respMessage, 'success');
                        this.props.resetForm();
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                        this.setState({ isLoading: false });
                    } else {
                        this.setState({ isLoading: false });
                    }
                }).catch((err) => {
                    return err;
                });
        } else {
            return;
        }
    }

    getEditorText = (e, name) => {
        this.setState({ comments: e.htmlValue });
        this.setState({ editorData: { [name]: e.htmlValue } });
    }


    handleAutoData = (value, name) => {
        if (name == 'taskId') {
            this.setState({ isTaskSet: true });
        }
        if (name == 'projectId') {
            if (this.props.type == 'Tasks' || this.props.type == 'Issues') {
                let assignEmp = [];
                if (value.assignEmployees && value.assignEmployees.length > 0) {
                    assignEmp = value.assignEmployees;
                } if (value.teamLead) {
                    assignEmp.push(value.teamLead);
                }
                setTimeout(() => {
                    this.setState({ projectId: value._id, assignedEmps: assignEmp });
                }, 0)
            }
        }
    }

    // Onchange checkbox
    onChange = (value) => {
        if (value == 'isClientAdded' && this.state.isClientAdded) {
            this.setState({
                isClientAdded: false
            })
        }
    }

    //Delete Attachments ||CodeByTej
    deleteAttachment = async (item) => {
        this.setState({ isLoading: true });
        let id = item._id;
        let url = `${id}?contextType=${item.contextType}&eventType=${item.eventType}`
        return fetch('DELETE', `events/${url}`)
            .then(async (response) => {
                if (response && response.respMessage) {
                    showToasterMessage(response.respMessage, 'success');
                    this.getRowDataBasedOnId();
                }
                else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
                this.setState({ isLoading: false });
            }).catch((err) => {
                return err;
            });
    }

    render() {
        const { activeTab } = this.state;
        const { handleSubmit, reset, t } = this.props;

        return (
            <div>
                <Card className='px-3'>
                    <CardHeader className='cardHeader'>
                        <div className="row m-0">
                            <div className='col-4 pl-0 pr-0' >{t('issue_details.title')}</div>
                        </div>
                    </CardHeader>
                    <CardBody className='cardHeader'>
                        <Loader loader={this.state.isLoading} />
                        <div className="row m-0">
                            <div className='col-4 pl-0 pr-0 bold-text' >{t('issue_details.issue_id')} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;{this.state.currentIssueId}</div>
                        </div>
                        <div className="row m-0">
                            <div className='col-4 pl-0 pr-0 bold-text' >{t('issue_details.assigned_to')} :&nbsp;{this.state.assignedTo}</div>
                        </div>
                        <form key={'edit'} onSubmit={handleSubmit(this.submit)} >
                            <div className="row py-3 justify-content-center" >
                                <div className="col-sm-6">
                                    <ButtonToolbar>
                                        <Button color='primary' outline type="button" onClick={() => { this.getRowDataBasedOnId('prev') }}>{t('issue_details.buttons.prev')}</Button>
                                        <Button color='primary' outline type="button" onClick={() => { this.getRowDataBasedOnId('next') }}>{t('issue_details.buttons.next')}</Button>
                                    </ButtonToolbar>
                                </div>
                                <div className='col-sm-6'>
                                    <ButtonToolbar className=" modal__footer footerData">
                                        <Link to={`/issues`}>
                                            <Button color='primary' type="button" onClick={reset} >{t('issue_details.buttons.back')}</Button>
                                        </Link>
                                        <Button color='primary' outline type="submit" >{t('issue_details.buttons.update')}</Button>
                                    </ButtonToolbar>
                                </div>
                            </div>

                            <div className="row form" >
                                {this.state.formFields && this.state.formFields.length > 0 ? this.state.formFields.map((item, i) => {
                                    return <div key={'edit' + i} className={item.type == 'editor' ? 'col-sm-12' : 'col-sm-6'}  >
                                        <div className="form__form-group" >
                                            <label className="form__form-group-label text-capitalize">{item.label == 'Client Added' ? null : t(item.label)}</label>
                                            {item.type == 'dropDown' ?
                                                <div className="form__form-group-field">
                                                    <Field key={i}
                                                        name={item.name}
                                                        component={Select}
                                                        options={item.options}
                                                        placeholder={t(item.placeholder)}
                                                        leavesFormData={this.formattedLeavesFormData}
                                                        getFormFields={this.getFormFields}
                                                    />
                                                </div>
                                                : item.type == 'date' ?
                                                    <div className="form__form-group">
                                                        <div className="form__form-group-field">
                                                            <Field key={i}
                                                                name={item.name ? item.name : null}
                                                                placeholder={item.placeholder ? t(item.placeholder) : null}
                                                                id={item.id ? item.id : null}
                                                                component={DatePicker}
                                                                minDate={this.state.minDate ? this.state.minDate : null}
                                                                maxDate={item.name == 'startDate' ? new Date() : this.state.maxDate ? this.state.maxDate : null}
                                                                screen={'edit'}
                                                            />
                                                        </div>
                                                        <div style={{ color: '#dc3545' }}>{item.message ? item.message : null}</div>
                                                    </div>
                                                    : item.type == 'empty' ? <div> </div> : item.type == 'radio' ?
                                                        <div className='mb-0'>
                                                            <div className='row'>
                                                                {item.options.map((option, ind) => {
                                                                    return <div className="form__form-group col-sm-6 mb-0">
                                                                        <div className="form__form-group-field">
                                                                            <Field key={'edit' + i + ind}
                                                                                name={item.name ? item.name : null}
                                                                                component={RadioButton}
                                                                                label={option.value}
                                                                                radioValue={option.value}
                                                                                defaultChecked={option.defaultChecked ? option.defaultChecked : this.state.checked ? this.state.checked : null}
                                                                                onChange={this.checkProjectOrNot}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                })
                                                                }
                                                            </div>
                                                            <div style={{ color: '#dc3545' }}>{item.message ? item.message : null}</div>
                                                        </div>
                                                        : item.type == 'autoComplete' ?
                                                            <Field key={i}
                                                                name={item.name ? item.name : null}
                                                                component={AutoComplete}
                                                                type={item.type}
                                                                filterField={item.filterField}
                                                                filterValue={item.filterValue}
                                                                multiple={item.isMultiple}
                                                                placeholder={item.placeholder ? t(item.placeholder) : ''}
                                                                searchApi={item.searchApi}
                                                                searchField={item.searchField}
                                                                otherField={item.otherField}
                                                                handleAutocompleteData={this.handleAutocompleteData}
                                                                assignedEmps={(this.state.assignedEmps && this.state.assignedEmps.length) ? this.state.assignedEmps : null}
                                                                filterIds={this.state.filterIds}
                                                                onChange={e => this.handleAutoData(e, item.name)}
                                                                projectId={this.state.projectId ? this.state.projectId : null}
                                                                screen={'edit'}
                                                            />
                                                            : item.type == 'textarea' ?
                                                                <Field key={i}
                                                                    name={item.name ? item.name : null}
                                                                    component={DefaultTextArea}
                                                                    type={item.type ? item.type : null}
                                                                    placeholder={item.placeholder ? t(item.placeholder) : null}
                                                                // validate={!this.state.isTaskSet && item.name == 'otherTask' ? [required] : null}
                                                                />
                                                                : item.type == 'editor' ?
                                                                    <Editor key={i}
                                                                        getEditorText={this.getEditorText} name={item.name} value={item.value} />
                                                                    : item.type == 'time' ?
                                                                        <Field key={i}
                                                                            name={item.name ? item.name : null}
                                                                            component={TimePicker}
                                                                            timeMode
                                                                            placeholder={item.placeholder ? item.placeholder : null}
                                                                        />
                                                                        : item.type == 'checkBox' ?
                                                                            <Field key={i}
                                                                                name={item.name ? item.name : null}
                                                                                component={CheckBox}
                                                                                checked={this.state.isClientAdded}
                                                                                onChange={() => this.onChange('isClientAdded')}
                                                                                label={t('issue_details.form_fields.is_client_added')}
                                                                            />
                                                                            : <Field key={i}
                                                                                name={item.name ? item.name : null}
                                                                                component={DefaultInput}
                                                                                isDisable={this.props.formType && this.props.formType == 'edit' ? item.disable : null}
                                                                                type="text"
                                                                                disabled
                                                                                id={item.id ? item.id : null}
                                                                                placeholder={item.placeholder ? t(item.placeholder) : null}
                                                                            />
                                            }
                                        </div>
                                    </div>
                                })
                                    : null}
                            </div>
                        </form>

                        <div className="tabs mt-4" style={{ backgroundColor: '#EAECEF', borderRadius: '5px' }} >
                            <div className="tabs__wrap">
                                <Nav tabs style={{ backgroundColor: 'white', borderColor: 'orange' }}>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { this.toggle('1') }}>{t('issue_details.nav_tabs.comments')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { this.toggle('2') }}>{t('issue_details.nav_tabs.attachments')}</NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} style={{ overflow: 'auto' }}>
                                    <TabPane tabId="1">
                                        <div style={{ maxHeight: 200 }}>
                                            {this.state.IssueComments && this.state.IssueComments.length > 0 ?
                                                this.state.IssueComments.map((item, i) => {
                                                    return (
                                                        <div key={i} className='row  p-2 m-0'>
                                                            <div className="timeline__content col-sm-10">
                                                                <div className='row justify-content-between' style={{ padding: '0px 10px 0px 10px' }}>
                                                                    <h5 className='col-sm-auto text-capitalize'>{item.createdBy.name}</h5>
                                                                    <h6 className='col-sm-auto'>{dateFormats.formatDate(item.created, config.dateDashFormat)}</h6>
                                                                    <p className='col-12' dangerouslySetInnerHTML={{ __html: item.comments }}></p>
                                                                </div>
                                                            </div>
                                                            <div className="timeline__icon ml-3">
                                                                {item.createdBy.employee.photo ?
                                                                    <img src={`${config.imgUrl}employee/${item.createdBy.employee.photo}`} className='detailsImgStyle rounded-circle'
                                                                    />
                                                                    : <img src={configImages.defaultImg} style={{ borderRadius: '100px' }} alt='img' />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : null
                                            }
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Loader loader={this.state.isLoading} />
                                        <div className='row p-2 m-0' >
                                            <div className="col-12 text-right">
                                                <div className='dragdrop mt-1'>
                                                    <FileUpload
                                                        onRef={(ref) => (this.fileUploadRef = ref)}
                                                        url={`events/uploadAttachment`}
                                                        chooseLabel={t('issue_details.buttons.choose_file')}
                                                        maxFileSize={100000}
                                                        IssueId={this.props.match.params.id}
                                                        IssueType='attach'
                                                        getFileName={this.sendCommentsAttchementsDataToServer}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.IssueAttachments && this.state.IssueAttachments.length > 0 ?
                                                this.state.IssueAttachments.map((item, i) => {
                                                    return (
                                                        <div key={i} className='col-sm-2' style={{ padding: '20px' }}>
                                                            <div style={{ justifyContent: 'center' }}>
                                                                <FontAwesomeIcon className='genderIconAlignment'
                                                                    color='white'
                                                                    icon='trash-alt'
                                                                    data-toggle="tool-tip"
                                                                    title="Delete"
                                                                    style={{ color: '#bf1725', width: '13', marginRight: '10px', cursor: 'pointer' }}
                                                                    onClick={() => this.deleteAttachment(item)}
                                                                />

                                                                {item.attachment[0].path ?
                                                                    <img src={`${config.imgUrl}attachment/${item.attachment[0].name}`} className='detailsImgStyle' />
                                                                    :
                                                                    <img src={configImages.defaultImg} className='detailsImgStyle' />
                                                                }
                                                            </div>
                                                            <a target="_blank" href={`${config.imgUrl}attachment/${item.attachment[0].name}`} style={{ fontSize: 11 }}>{item.attachment[0].name}</a>
                                                        </div>
                                                    )
                                                })
                                                : null}
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>


                    </CardBody>
                </Card >
                <BulkUploadModal
                    type='Attachments'
                    url={this.getApiUrl}
                    openBulkUploadModal={this.state.openBulkUploadModal}
                    closeBulkModal={this.closeBulkModal} />
                <EditorModal
                    commentsData={this.sendCommentsAttchementsDataToServer}
                    openEditorModal={this.state.openEditorModal}
                    closeEditorModal={this.closeEditorModal} />
            </div >
        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
IssueDetails = reduxForm({
    form: "Common Form", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(IssueDetails);

// You have to connect() to any reducers that you wish to connect to yourself
IssueDetails = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(IssueDetails);

export default withTranslation('common')(IssueDetails);