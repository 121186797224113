import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import EditTable from './apraisal';

const EditableTable = ({ t }) => (
    <Container>
        <Row>
            <EditTable />
        </Row>
    </Container>
);

EditableTable.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(EditableTable);