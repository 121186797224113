/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import DatePicker from 'react-datepicker';
import MinusIcon from 'mdi-react/MinusIcon';
import PropTypes from 'prop-types';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';

class IntervalDatePickerField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount = () => {
    this.props.onRef(this);
  };

  componentWillUnmount() {
    this.props.onRef(null);
  }

  clearSelectedDates = () => {
    this.setState({ startDate: "", endDate: "" });
  }
  handleChangeStart = startDate => this.handleChange({ startDate });

  handleChangeEnd = endDate => this.handleChange({ endDate });

  handleChange({ startDate, endDate }) {
    const { startDate: stateStartDate, endDate: stateEndDate } = this.state;

    // const { onChange } = this.props;

    startDate = startDate || stateStartDate;
    endDate = endDate || stateEndDate;

    // if (startDate.isAfter(endDate)) {
    //   endDate = startDate;
    // }
    this.setState({ startDate, endDate });

    this.props.onChange(startDate, endDate);
  }

  render() {
    const { startDate, endDate } = this.state;

    return (
      <div className="date-picker date-picker--interval col-sm-6 p-1">
        <CalendarBlankIcon />
        <DatePicker
          selected={startDate}
          selectsStart
          showMonthDropdown
          showYearDropdown
          startDate={startDate}
          endDate={endDate}
          onChange={this.handleChangeStart}
          dateFormat="dd-MM-yyyy"
          // isClearable={true}
          placeholderText="From"
          dropdownMode="select"
        />
        <MinusIcon className="date-picker__svg" style={{ marginLeft: '30px' }} />
        <CalendarBlankIcon />
        <DatePicker
          selected={endDate}
          selectsEnd
          showMonthDropdown
          showYearDropdown
          startDate={startDate}
          minDate={this.state.startDate ? this.state.startDate : null}
          endDate={endDate}
          onChange={this.handleChangeEnd}
          dateFormat="dd-MM-yyyy"
          placeholderText="To"
          dropdownMode="select"
        />
      </div>
    );
  }
}

const renderIntervalDatePickerField = (props) => {
  const { input, onRef } = props;
  return (
    <IntervalDatePickerField
      {...input}
      onRef={onRef}
      onChange={props.handleDateValueInParent}
    />
  );
};

renderIntervalDatePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }),
};

export default renderIntervalDatePickerField;
