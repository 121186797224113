// CodeByTej
import React from 'react';
import TableRender from '../../CommonDataTable/TableRender';
import RolePermissions from '../../CommonDataTable/Permissions';
import configImages from '../../../../config/configImages';
import { withTranslation } from 'react-i18next';

class Presentations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getTableFields = () => {
        const { t } = this.props;
        let data = [
            { textAlign: 'center', width: 15, field: 'Sno', header: 'presentations.table_fields.s_no' },
            { textAlign: 'left', width: 30, field: 'assignedToName', header: 'presentations.table_fields.assigned_to',sortable: true  },
            { textAlign: 'center', width: 50, field: 'name', header: 'presentations.table_fields.topic_name',sortable: true  },
            { textAlign: 'center', width: 25, field: 'date', header: 'presentations.table_fields.presentation_date',sortable: true  },
            { textAlign: 'center', width: 20, field: 'Actions', header: 'presentations.table_fields.actions', },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Presentations');
        if (screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        const { t } = this.props;
        return ([
            { value: '', type: 'text', name: 'name', label: t('presentations.form_fields.topic_name'), id: 'topicName' },
            { required: true, value: '', type: 'autoComplete', name: 'assignedTo', label: t('presentations.form_fields.assigned_to'), id: 'AssignedTo', searchApi: 'employees', searchField: 'displayName', placeholder: t('presentations.form_fields.ph_assigned_to'), otherField: 'assignedToName', filterField: 'role', filterValue: ["Developer", "Trainee", "QA", "TeamLead"] },
            { required: true, value: '', type: 'date', name: 'date', label: t('presentations.form_fields.presentation_date'), id: 'date', placeholder: 'dd-mm-yyyy' },
        ]);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <TableRender type='Presentations'
                    globalSearch={t('presentations.table.globalsearch')}
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    sample={configImages.PresentationsSampleXL}
                    bulkapi='presentation'
                />
            </div>
        );
    }
}
export default withTranslation('common')(Presentations);