import React from 'react';
import ResetPasswordPage from './components/ResetPassword';
const ResetPassword = (props) => (
    <div className="account">
        <div className="account__wrapper">
            <div className="account__card">
                <div style={{ textAlign: 'center', marginBottom: 15 }}>
                    <h3>ResetPassword</h3>
                </div>
                <ResetPasswordPage {...props} onSubmit />
            </div>
        </div>
    </div>
);

export default ResetPassword;
