import React from 'react';
import TableRender from '../../CommonDataTable/TableRender';
import config from '../../../../config/config';
import configImages from '../../../../config/configImages';
import RolePermissions from '../../CommonDataTable/Permissions';
import { withTranslation } from 'react-i18next';

// config file
class EmployeesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getTableFields = () => {
        const { t } = this.props;
        let type = [
            { label: 'All', value: null },
            { label: 'Laptop', value: 'laptop' },
            { label: 'Ipad', value: 'ipad' },
            { label: 'MacBook', value: 'MacBook' },
            { label: 'Furniture', value: 'furniture' },
            { label: 'Desktop', value: 'desktop' },
            { label: 'Projector', value: 'projector' },
            { label: 'Others', value: 'others' },

        ];
        let brandNames = [
            { label: t('inventory.form_fields.all'), value: null },
            { label: t('inventory.form_fields.labels.lenovo'), value: 'Lenovo' },
            { label: t('inventory.form_fields.labels.dell'), value: 'Dell' },
            { label: t('inventory.form_fields.labels.hp'), value: 'HP' },
            { label: t('inventory.form_fields.labels.samsung'), value: 'Samsung' },
            { label: t('inventory.form_fields.labels.microsoft'), value: 'Microsoft' },
            { label: t('inventory.form_fields.labels.apple'), value: 'Apple' },
            { label: t('inventory.form_fields.labels.mi'), value: 'MI' },
            { label: t('inventory.form_fields.labels.acer'), value: 'Aser' },
            { label: t('inventory.form_fields.labels.asus'), value: 'ASUS' },
            { label: t('inventory.form_fields.labels.msi'), value: 'MSI' },
            { label: t('inventory.form_fields.labels.sony'), value: 'Sony' },
        ]
        let statusTypes = [
            { label: 'In Stock', value: 'In Stock' },
            { label: 'Assigned', value: 'Assigned' },
            { label: 'Repair', value: 'Repair' },
            { label: 'Sold', value: 'Sold' },
        ];
        let statusValue = ['In Stock', 'Assigned', 'Repair', 'Sold'];

        let osType = [
            { label: 'All', value: null },
            { label: 'Windows', value: 'Windows' },
            { label: 'Ubuntu', value: 'Ubuntu' },
            { label: 'MacOS', value: 'MacOS' },
        ]

        let data = [
            { textAlign: 'center', width: 59, field: 'Sno', header: 'inventory.table_fields.s_no', filter: false, sortable: false, },
            { textAlign: 'left', width: 70, field: 'type', header: 'inventory.table_fields.type', filter: true, sortable: true, filterElement: type },
            { textAlign: 'left', width: 70, field: 'brand', header: 'inventory.table_fields.brand', filter: true, sortable: true, filterElement: brandNames },
            { textAlign: 'left', width: 100, field: 'name', header: 'inventory.table_fields.serialNumber', filter: true, sortable: true, placeholder: 'inventory.table_fields.ph_search' },
            { textAlign: 'left', width: 100, field: 'operatingSystem', header: 'inventory.table_fields.OS', filter: true, sortable: true, filterElement: osType },
            { textAlign: 'left', width: 100, field: 'assignedToName', header: 'inventory.table_fields.assigned_to', filter: true, sortable: true, placeholder: 'inventory.table_fields.ph_search' },
            { textAlign: 'center', width: 80, field: 'status', header: 'inventory.table_fields.status', filterElement: statusTypes, filter: true, sortable: true, defaultValues: statusValue, placeholder: 'inventory.table_fields.ph_search' },
            { textAlign: 'center', width: 80, field: 'fromDate', header: 'inventory.table_fields.updated', filter: true, sortable: true, placeholder: config.dateDmyFormat },
            { textAlign: 'center', width: 60, field: 'Actions', header: 'inventory.table_fields.actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Inventory');
        if (screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        const { t } = this.props;
        let statusTypes = [
            { label: 'In Stock', value: 'In Stock' },
            { label: 'Assigned', value: 'Assigned' },
            { label: 'Repair', value: 'Repair' },
            { label: 'Sold', value: 'Sold' },
        ];
        let type = [
            { label: 'Laptop', value: 'laptop' },
            { label: 'Ipad', value: 'ipad' },
            { label: 'MacBook', value: 'MacBook' },
            { label: 'Furniture', value: 'furniture' },
            { label: 'Desktop', value: 'desktop' },
            { label: 'Projector', value: 'projector' },
            { label: 'Others', value: 'others' },
        ];
        let brandNames = [
            { label: t('inventory.form_fields.labels.lenovo'), value: 'Lenovo' },
            { label: t('inventory.form_fields.labels.dell'), value: 'Dell' },
            { label: t('inventory.form_fields.labels.hp'), value: 'HP' },
            { label: t('inventory.form_fields.labels.samsung'), value: 'Samsung' },
            { label: t('inventory.form_fields.labels.microsoft'), value: 'Microsoft' },
            { label: t('inventory.form_fields.labels.apple'), value: 'Apple' },
            { label: t('inventory.form_fields.labels.mi'), value: 'MI' },
            { label: t('inventory.form_fields.labels.acer'), value: 'Aser' },
            { label: t('inventory.form_fields.labels.asus'), value: 'ASUS' },
            { label: t('inventory.form_fields.labels.msi'), value: 'MSI' },
            { label: t('inventory.form_fields.labels.sony'), value: 'Sony' },
        ]
        let osType = [
            { label: 'Windows', value: 'Windows' },
            { label: 'Ubuntu', value: 'Ubuntu' },
            { label: 'MacOS', value: 'MacOS' },
        ]

        return ([
            { required: false, value: '', type: 'dropDown', name: 'type', label: t('inventory.form_fields.type'), id: 'type', options: type, placeholder: t('inventory.form_fields.type') },
            { value: '', type: 'dropDown', name: 'operatingSystem', label: t('inventory.form_fields.operatingSystem'), id: 'operatingSystem', options: osType, placeholder: t('inventory.form_fields.operatingSystem') },
            { value: '', type: 'dropDown', name: 'brand', label: t('inventory.form_fields.brand'), id: 'brand', options: brandNames, placeholder: t('inventory.form_fields.brand') },
            { required: true, value: '', type: 'text', name: 'name', label: t('inventory.form_fields.serialNumber'), id: 'serialNumber' },
            { value: '', type: 'text', name: 'invoiceNumber', label: t('inventory.form_fields.invoiceNumber'), id: 'invoiceNumber' },
            { required: true, value: '', type: 'date', name: 'purchaseDate', label: t('inventory.form_fields.purchase_date'), id: 'date', placeholder: 'dd/mm/yyyy', disable: true },
            { required: true, value: '', type: 'dropDown', name: 'status', label: t('inventory.form_fields.status'), id: 'status', options: statusTypes, placeholder: t('inventory.form_fields.status') },
            { required: false, value: '', type: 'autoComplete', name: 'assignedTo', label: t('inventory.form_fields.assigned_to'), id: 'AssignedTo', searchApi: 'employees', searchField: 'displayName', placeholder: t('inventory.form_fields.ph_assigned_to'), otherField: 'assignedToName' },
            { required: false, value: '', type: 'link', name: 'purchaseDocumentLink', label: t('inventory.form_fields.purchaseInventoryLink'), id: 'purchaseDocumentLink' },
            { required: false, value: '', type: 'link', name: 'assignedDocumentLink', label: t('inventory.form_fields.assignedInventoryLink'), id: 'assignedDocumentLink' },
            { required: true, value: '', type: 'textarea', name: 'description', label: t('inventory.form_fields.desc'), id: 'description' },
            { required: true, value: '', type: 'editor', name: 'comments', label: t('inventory.form_fields.comments'), id: 'comments' },
        ]);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <TableRender
                    type='Inventory'
                    globalSearch={t('inventory.table.globalsearch')}
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields}{...this.props}
                    sample={configImages.inventorySampleXL}
                    bulkapi='inventory'

                />
            </div>
        );
    }
}
export default withTranslation('common')(EmployeesTable);