import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import configImages from '../../../config/configImages';
import FileUpload from '../../Form/components/FileUpload';
import { withTranslation } from 'react-i18next';
// config file
class BulkUploadModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    uploadToServer = () => {
        this.fileUploadRef.UploadFileToServer();
    }

    render() {
        const { t } = this.props;
        return (
            <Modal isOpen={this.props.openBulkUploadModal} className='bulkUploadModalHeight'>
                <ModalHeader >
                    {t('forms.bulk_upload')}
                </ModalHeader>
                <ModalBody >
                    <div className='row'>
                        <div className='col-sm-5'>
                            {t('forms.sample_document')}
                        </div>
                        <div className='col-sm-7'>
                            <a className='btn btn-primary outline ' href={this.props.sample}>{t('forms.download')}</a>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-5'>
                            {t('forms.attach_file')}
                        </div>
                        <div className='col-sm-7'>
                            <FileUpload type="bulkUpload"
                                onRef={(ref) => (this.fileUploadRef = ref)}
                                url={'employees/uploadCsv?type=' + this.props.type}
                                close={this.props.closeBulkModal}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{ width: '450px' }}>
                    <Button color='primary' outline onClick={this.props.closeBulkModal}>{t('forms.close')}</Button>
                    <Button color='primary' outline onClick={this.uploadToServer}>{t('forms.upload')} {this.props.type}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

export default withTranslation('common')(BulkUploadModal);