import React from 'react';
import { Card, CardBody, CardHeader, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import config from '../../../../config/config';
import DateTimePicker from '../../../../shared/components/form/DateTimePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
import DefaultTextArea from '../../../../shared/components/form/DefaultTextArea';
import { connect } from 'react-redux';
import PropTypes, { element } from 'prop-types';
import dateFormats from '../../../UI/FormatDate/formatDate';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import fetch from '../../../../config/service';
import { Redirect } from 'react-router';
import validate from '../../../Validations/validate';
import moment from 'moment';

class WorkFromHome extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            responseSuccess: false
        };
    }

    //sending values to server
    submit = (values) => {
        let body = {}
        body.date = dateFormats.formatDate(values.date, config.dateDashFormat);
        body.reason = values.description;
        body.WFH = true;
        body.inTime = (moment(values.inTime).format('YYYY-MM-DD HH:mm:ss'));
        body.outTime = (moment(values.outTime).format('YYYY-MM-DD HH:mm:ss'));

        return fetch('POST', 'attendances', body)
            .then(async (response) => {
                if (response && response.respCode && response.respCode == 204) {
                    if (response.respMessage) {
                        showToasterMessage(response.respMessage, 'success');
                        this.setState({ responseSuccess: true })
                    }
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }

    redirectToAttendancePage = () => {
        this.setState({ responseSuccess: true })
    }

    render() {

        const { handleSubmit } = this.props;
        return (
            <div>
                <Card>
                    <CardHeader className='cardHeader'>
                        <div className="row m-0">
                            <div className='col-4 pl-0 pr-0' >Work From Home
                        </div>
                        </div>
                    </CardHeader>
                    <CardBody className='cardHeader'>
                        <form className="pt-3 form form--horizontal" onSubmit={handleSubmit(this.submit)} >

                            <div className="form__form-group col-sm-8">
                                <span className="form__form-group-label">In Time</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name='inTime'
                                        id='inTime'
                                        component={DateTimePicker}
                                    />
                                    <div className="form__form-group-icon">
                                        <CalendarBlankIcon />
                                    </div>
                                </div>
                            </div>
                            <div className="form__form-group col-sm-8">
                                <span className="form__form-group-label">Out Time</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name='outTime'
                                        component={DateTimePicker}
                                    />
                                    <div className="form__form-group-icon">
                                        <CalendarBlankIcon />
                                    </div>
                                </div>
                            </div>
                            <div className="form__form-group col-sm-8">
                                <span className="form__form-group-label">Description</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name='description'
                                        component={DefaultTextArea}
                                        id='description'
                                    />
                                </div>
                            </div>
                            <div className='col-sm-12 '>
                                <ButtonToolbar className="modal__footer footerData mt-5">
                                    <Button color='primary' outline onClick={this.redirectToAttendancePage}>Cancel</Button>
                                    <Button color='primary' type='submit' outline >Save</Button>
                                </ButtonToolbar>
                            </div>
                            {this.state.responseSuccess ?
                                <Redirect to="/attendance" /> : null}
                        </form>
                    </CardBody>
                </Card >
            </div >
        );
    }
}
WorkFromHome = reduxForm({
    form: "Work Form", // a unique identifier for this form
    enableReinitialize: true,
    validate,
})(WorkFromHome);

// You have to connect() to any reducers that you wish to connect to yourself
WorkFromHome = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(WorkFromHome);
export default WorkFromHome;