
const configMessages = {
    email: 'Please enter valid email address',
    fillField: 'Please fill above field',
    checkRadio: 'Please select any one radio',
    aadharcardNumber: 'Please enter valid Aadhar number (no spaces in between)',
    phoneNumber: 'please enter valid phone number',
    InvalidDate: 'Invalid Date',
    noRecords: 'Found No Details',
    loading: 'Loading...',
    number: 'please enter valid number contact number',
    firstName: 'please enter valid first name',
    lastName: 'please enter valid last name',
    employeeId: 'please enter valid employee id',
    accountNo: "Enter valid Account number",
    ifscCode: "Enter valid Ifsc code",
    biometricId: "Enter valid biometric id",
    bankName: "please enter valid bank name",
    branchName: 'please enter valid branch name',
    bitbuketId: 'please enter valid bitbucket id',
    biometricId: 'please enter valid biometric id',
    skypeId: 'please enter valid skype id',
    uanNumber: 'please enter 12 digit valid uan number',
    consultancy: 'please enter valid consultancy name',
    previousCompaney: 'please enter valid previous companey name',
    previousExperience: 'please enter valid previous experience ',
    qualification: 'please enter valid qualification',
    pancardNumber: 'please Enter valid pan number',
    hours: 'Please enter valid number with in 24 hours',
    clientPhoneNumber: 'please enter valid phone number',
};
export default configMessages;
