import config from '../../config/config';
import configMessages from '../../config/configMessages';
import dateFormats from '../UI/FormatDate/formatDate';

const validate = (values, props) => {
  let fields = [];
  // get formFields from props and set validation  fields
  if (props.formFields) {
    fields = [];
    let formFields = props.formFields();
    formFields.forEach(formField => {
      if (formField.required) {
        fields.push(formField.name);
      }
      if (formField.name === "estimatedHours") {
        fields.push(formField.name)
      }
    });
  }
  const errors = {};

  const checkRegExpFieldsArray = ['aadharcardNumber', 'serialNumber', 'hours', 'estimatedHours', 'email', 'companyEmail', 'phoneNumber', 'password', 'firstName', 'lastName', 'employeeId', 'emergencyContact'];

  fields.forEach((field) => {
    if (!values[field] && field !== "serialNumber" && field !== "estimatedHours") {
      errors[field] = configMessages.fillField;
    } else if (checkRegExpFieldsArray.includes(field)) {
      let regex = config[field + "Regex"];
      if (field === 'companyEmail' || field === 'email') {
        regex = config['email' + "Regex"];
      } else if (field === 'emergencyContact' || field === 'phoneNumber') {
        regex = config['number' + "Regex"];
      } else if (field === 'estimatedHours' || field === 'hours') {
        regex = config['hours' + "Regex"];
      } else if (field === 'firstName' || field === 'lastName') {
        regex = config['name' + "Regex"];
      } else if (field === 'employeeId') {
        regex = config['employeeId' + "Regex"]
      } else if (field === 'aadharcardNumber') {
        regex = config['aadharcardNumber' + "Regex"]
      }

      if (values && values[field] && typeof values[field] === 'string' && regex.test(values[field].trim()) === false) {
        if (field === 'companyEmail' || field === 'email') {
          errors[field] = configMessages['email'];
        } else if (field === 'estimatedHours' || field === 'emergencyContact' || field === 'phoneNumber') {
          errors[field] = configMessages['number'];
        } else if (field === 'hours') {
          errors[field] = configMessages['hours']
        } else if (field === 'firstName') {
          errors[field] = configMessages['firstName'];
        } else if (field === 'lastName') {
          errors[field] = configMessages['lastName'];
        } else if (field === 'employeeId') {
          errors[field] = configMessages['employeeId']
        } else if (field === 'aadharcardNumber') {
          errors[field] = configMessages['aadharcardNumber']
        } else {
          errors[field] = configMessages[field];
        }
      }
    }
  });

  let todayDate = dateFormats.formatDate(new Date(), config.dateDashFormat);
  let compare;
  if (values.dateOfBirth) {
    compare = dateFormats.datesComparisionBefore(todayDate, values.dateOfBirth);
    if (compare) {
      errors.dateOfBirth = configMessages.InvalidDate;
    }
  }

  if (values.endDate && values.startDate) {
    compare = dateFormats.datesComparisionBefore(values.endDate, values.startDate);
    if (compare) {
      errors['endDate'] = configMessages.InvalidDate;
    } else {
      errors['endDate'] = '';
    }
  }
  if (values.toDate && values.fromDate) {
    compare = dateFormats.datesComparisionBefore(values.toDate, values.fromDate);
    if (compare) {
      errors['toDate'] = configMessages.InvalidDate;
    } else {
      errors['toDate'] = '';
    }
  }
  if (values.joinDate) {
    if (values.dateOfBirth) {
      let years = dateFormats.differenceBetweenDatesInYears(values.joinDate, values.dateOfBirth);
      if (years < 15) {
        errors.joinDate = configMessages.InvalidDate;
      } else {
        errors.joinDate = '';
      }
    }
  }
  return errors;
};


export default validate;

