import React from 'react';
import TableRender from '../../CommonDataTable/TableRender';
import config from '../../../../config/config';
import RolePermissions from '../../CommonDataTable/Permissions';
import { withTranslation } from 'react-i18next';


// config file
class RolesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getTableFields = () => {
        const { t } = this.props;
        let data = [
            { textAlign: 'center', width: 59, field: 'Sno', header: 'roles.table_fields.s_no', filter: false, sortable: false, },
            { textAlign: 'center', width: 130, field: 'created', type: 'date', header: 'roles.table_fields.created', filter: true, sortable: true, placeholder: config.dateDmyFormat, },
            { textAlign: 'center', width: 100, field: 'role', header: 'roles.table_fields.role', filter: true, sortable: true, placeholder: 'roles.table_fields.ph_search' },
            { textAlign: 'left', width: 100, field: 'createdBy', header: 'roles.table_fields.created_by', filter: false, sortable: false, },
            { textAlign: 'left', width: 100, field: 'updatedBy', header: 'roles.table_fields.updated_by', filter: false, sortable: false, },
            { textAlign: 'center', width: 130, field: 'updated', type: 'date', header: 'roles.table_fields.updated_date', filter: true, sortable: true, placeholder: config.dateDmyFormat, },
            { textAlign: 'center', width: 90, field: 'Actions', header: 'roles.table_fields.actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Roles');
        if (screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        return ([
            { required: true, value: '', type: 'text', name: 'name', label: 'Project Name', id: 'employeeId', placeholder: 'name' },
            { required: true, value: '', type: 'date', name: 'startDate', label: 'Start Date', id: 'startDate', placeholder: config.dateDashFormat },
            { required: true, value: '', type: 'date', name: 'endDate', label: 'End Date', id: 'lastName', placeholder: config.dateDashFormat },
            { required: true, value: '', type: 'text', name: 'teamSize', label: 'Team Size', id: 'teamSize', },
            { required: true, value: '', type: 'autoComplete', name: 'teamLead', label: 'Team Lead', id: 'teamLead', searchApi: 'employees', searchField: 'displayName', placeholder: 'Search TeamLead', isMultiple: false, otherField: 'TeamLeadName' },
            // { required: false, value: '', type: 'dropDown', name: 'status', label: 'Status', id: 'status', options: statusTypes },
            { required: true, value: '', type: 'autoComplete', name: 'assignEmployees', label: 'Team Members', id: 'teamMembers', searchApi: 'employees', searchField: 'displayName', placeholder: 'Search TeamMembers', isMultiple: true },
            { required: false, value: '', type: 'textarea', name: 'description', label: 'Description', id: 'description', },
        ]);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <TableRender type='Roles'
                    globalSearch={t('roles.globalsearch')}
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields}
                />
            </div>
        );
    }
}
export default withTranslation('common')(RolesTable);