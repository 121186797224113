import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ClientsTable from './components/ClientsTable';

const ClientsPage = ({ t }) => (
  <Container>
    <ClientsTable />
  </Container>
);

ClientsPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(ClientsPage);
