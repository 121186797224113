import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ProjectsTable from './components/ProjectsTable';

const BasicTables = ({ t }) => (
    <Container>
        <ProjectsTable />
    </Container>
);

BasicTables.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(BasicTables);
