import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import CheckBox from '../../../shared/components/form/CheckBox';

import { Field, reduxForm } from 'redux-form';

// config file
class CsvFieldSelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            exportFields: JSON.parse(JSON.stringify(this.props.tableFields)),
        };
    }
    componentDidMount() {
        let exportFields = this.state.exportFields
        if (this.props.type === 'Tasks') {
            if (exportFields && exportFields.length) {

                exportFields.push({
                    field: "description",
                    header: "Description"
                })
                this.setState({
                    exportFields: exportFields
                })
            }
        }
        if (this.props.type === 'Employees') {
            if (exportFields && exportFields.length) {

                exportFields.push({
                    field: "terminationDate",
                    header: "Termination Date"
                })
                this.setState({
                    exportFields: exportFields
                })
            }
        }
    }
    // Onchange checkbox
    onChange = (event) => {
        if (event && event.target) {
            this.setState({
                [event.target.name]: [event.target.value]
            })
        }
    }

    // On Confirm 
    submit = (values) => {
        this.props.closeCsvFieldSelectModal('confirm', values)
    }

    render() {
        const { t, handleSubmit } = this.props;
        return (
            <div>
                <Modal isOpen={this.props.isOpenCsvFieldSelectModal} className='deleteModalHeight'>
                    <ModalBody className='deleteModalBody'>
                        <div style={{ marginBottom: 10 }}>
                            Select Fields To Export
                        </div>
                        <form onSubmit={handleSubmit(this.submit)} >
                            <div className='row mx-0'>
                                {
                                    this.state.exportFields.map((item, index) => {
                                        if (item && item.field != 'Sno' && item.field != 'Actions') {
                                            return <span className='col-sm-3 mr-0'>
                                                <Field
                                                    key={index}
                                                    name={item.field ? item.field : null}
                                                    component={CheckBox}
                                                    checked={this.state[`${item.header}`]}
                                                    onChange={(e) => this.onChange(e)}
                                                    label={item.header ? t(item.header) : null}
                                                    defaultChecked={true}
                                                /></span>
                                        }
                                    })
                                }
                            </div>
                            <Button
                                color="primary"
                                outline
                                type="button"
                                onClick={() => this.props.closeCsvFieldSelectModal('close', null)}
                                className='deleteModalBtn marginRight'
                            >
                                CANCEL
                            </Button>
                            <Button
                                color='primary'
                                outline
                                type="submit"
                                className='deleteModalBtn'
                            >
                                CONFIRM
                            </Button>
                        </form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
CsvFieldSelectModal = reduxForm({
    form: "CsvFieldSelectModal Form", // a unique identifier for this form
    enableReinitialize: true,
})(CsvFieldSelectModal);

export default withTranslation('common')(CsvFieldSelectModal);

// ||CodeByTej