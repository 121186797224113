import React from 'react';
import TableRender from '../../CommonDataTable/TableRender';
// config file
import config from '../../../../config/config';
import { withTranslation } from 'react-i18next';
class LeaveHistoryTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getTableFields = () => {
        const { t } = this.props;
        let data = [
            { textAlign: 'center', width: 30, field: 'Sno', header: 'leave_his.table_fields.s_no', sortable: false, },
            { textAlign: 'left', width: 90, field: 'displayName', header: 'leave_his.table_fields.name', filter: true, sortable: true, placeholder: 'leave_his.table_fields.ph_search' },
            { textAlign: 'center', width: 80, field: 'created', header: 'leave_his.table_fields.date', filter: true, sortable: true, placeholder: config.dateDmyFormat },
            { textAlign: 'center', width: 80, field: 'fromDate', header: 'leave_his.table_fields.from_date', filter: true, sortable: true, placeholder: config.dateDmyFormat },
            { textAlign: 'center', width: 80, field: 'toDate', header: 'leave_his.table_fields.to_date', filter: true, sortable: true, placeholder: config.dateDmyFormat },
            { textAlign: 'left', width: 60, field: 'type', header: 'leave_his.table_fields.type', filter: true, sortable: true, placeholder: 'leave_his.table_fields.ph_search' },
            { textAlign: 'center', width: 40, field: 'numberOfDays', header: 'leave_his.table_fields.no_of_days', placeholder: 'leave_his.table_fields.ph_search' },
            { textAlign: 'center', width: 40, field: 'afterLeaves', header: 'leave_his.table_fields.after_leaves', placeholder: 'leave_his.table_fields.ph_search' },
        ];

        return data;
    };
    render() {
        const { t } = this.props;
        return (
            <TableRender type='Leave History'
                globalSearch={t('leave_his.globalsearch')}
                tableFields={this.getTableFields}
            />
        );
    }
}
export default withTranslation('common')(LeaveHistoryTable);