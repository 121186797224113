import React from 'react';
import { Col, Card, CardBody, Container, Row, ButtonToolbar } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LeavesTable from './components/LeavesTable';
import VerticalForm from '../../Form1/FormLayouts/components/VerticalForm';
import Modal from '../../../shared/components/Modal';


const BasicTables = (props) => (
    
    <Container>
        {/* <Row>
            <Col xs={12} md={12} lg={12} xl={6}>
                <Card>
                    <CardBody>
                        <div className="card__title">
                            <h5 className="bold-text">Labels are above fie</h5>
                            <h5 className="subhead">Labels are above fields</h5>
                        </div>
                        <VerticalForm  />
                    </CardBody>
                </Card>
            </Col>

            <Col md={6} lg={12} xl={4}>
                <Modal
                    color="primary"
                    title="Congratulations!"
                    header
                    btn="Default"
                    message="Extremely we promotion remainder eagerness enjoyment an. Ham her demands removal
                        brought minuter raising invited gay. Contented consisted continual curiosity contained get sex.
                        Forth child dried in in aware do. You had met they song how feel lain evil near. Small she
                        avoid six yet table china. And bed make say been then dine mrs. To household rapturous
                        fulfilled attempted on so. "
                />
                
            </Col>
        </Row> */}
        


        <LeavesTable {...props} />
    </Container>
);

BasicTables.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(BasicTables);

