import React from 'react';
import { Card, CardBody, Table, Button } from 'reactstrap';
import fetch from '../../../../config/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusCrudModal from './statusCrudModal';
// import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import { withTranslation } from 'react-i18next';

class IssueStatusArray extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            issueStatusArray: '',
            toggle: false,
            isFormModalOpen: false,
            formType: '',
            placeholder: '',
            data: {},
            type: '',
            rowData: '',
            value: '',
            subIndex: '',
            settingsId: '',
            isSaveDisable: true,
        };
    }

    //toggle to hide/display status options
    toggle = () => {
        this.setState({ isSaveDisable: false });
        if (this.state.toggle == false) {
            this.getIssueStatusArrayFromServer();
        }
        if (this.state.toggle == true) {
            this.setState({ issueStatusArray: '', toggle: false });
        }
    };

    //To Get All Issue status options from settings
    getIssueStatusArrayFromServer = async () => {
        return fetch('GET', 'settings')
            .then(async (response) => {
                if (response && response.settings[0].issueStatus) {
                    this.setState({
                        issueStatusArray: response.settings[0].issueStatus,
                        settingsId: response.settings[0]._id,
                        toggle: true
                    })
                }
            })
    }

    //To Open Form Modal to Add/Edit/Delete issue status options 
    openFormModal = async (item, type, formType, i) => {
        let data = {}
        if (type == 'issueStatus') {
            data.label = item.label
        }
        await this.setState({
            isFormModalOpen: true,
            formType: formType,
            type: type,
            placeholder: item.label,
            rowData: item,
            statusIndex: i,
            data: data,
        });
        if (this.state.isFormModalOpen) {
            await this.formModalRef.getRowData(this.state.data);
        }
    }

    // To Close Form Modal
    closeFormModal = async () => {
        await this.setState({ isFormModalOpen: false })
        this.getIssueStatusArrayFromServer();
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Card className='px-3'>
                    <CardBody className='cardHeader'>
                        <div className="row m-0 pb-3">
                            <div className='col-4 pl-0 pr-0 bold-text' style={{ fontSize: '14px' }} >
                                {t('settings.label.page_settings')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Table className="table--bordered" responsive hover>
                                    <thead className="kpiTable">
                                        <tr>
                                            <th style={{ width: 'auto', color: 'black', fontSize: '14px' }}>#</th>
                                            <th colSpan='2' style={{ width: 'auto', color: 'black', fontSize: '14px' }}>{t('settings.label.category')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ width: 'auto', color: 'black' }}>1</td>
                                            <td colSpan='2' style={{ width: 'auto', color: 'blue' }}
                                                onClick={() => this.toggle()}>
                                                {t('settings.label.issue_status')}
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div className="col-6">
                                <Table className="table--bordered" responsive hover>
                                    <thead className="kpiTable">
                                        <tr>
                                            <th style={{ width: 'auto', color: 'black', fontSize: '14px' }}>#</th>
                                            <th style={{ width: 'auto', color: 'black', fontSize: '14px' }}>{t('settings.label.status_options')}</th>
                                            <th><Button className='py-0' disabled={this.state.isSaveDisable} style={{ margin: 0, zIndex: 'revert' }} onClick={() => this.openFormModal('', 'issueStatus', 'add')}>{t('settings.label.add')}</Button></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.issueStatusArray && this.state.issueStatusArray.length > 0 ?
                                            this.state.issueStatusArray.map((item, i) => {
                                                return <tr key={i}>
                                                    <td style={{ width: 'auto', color: 'black' }}>{i + 1} </td>
                                                    <td style={{ width: 'auto', color: 'black' }}>{item.label}</td>
                                                    <td style={{ width: 'auto', color: 'black' }}>
                                                        <FontAwesomeIcon className='genderIconAlignment'
                                                            color='white' icon='edit' data-toggle="tool-tip" title={t('settings.label.edit')}
                                                            style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                                            onClick={() => this.openFormModal(item, 'issueStatus', 'edit', i)}
                                                        />
                                                        <FontAwesomeIcon className='genderIconAlignment'
                                                            color='white' icon='trash-alt' data-toggle="tool-tip" title={t('settings.label.issue_status')}
                                                            style={{ color: '#bf1725', width: '13', }}
                                                            onClick={() => this.openFormModal(item, 'issueStatus', 'delete', i)}
                                                        />
                                                    </td>
                                                </tr>
                                            }) : null}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </CardBody>
                </Card>

                <StatusCrudModal
                    onRef={(ref) => (this.formModalRef = ref)}
                    isFormModalOpen={this.state.isFormModalOpen}
                    closeFormModal={this.closeFormModal}
                    formType={this.state.formType}
                    type={this.state.type}
                    placeholder={this.state.placeholder}
                    rowData={this.state.rowData}
                    statusIndex={this.state.statusIndex}
                    data={this.state.data}
                    statusArray={this.state.issueStatusArray}
                    settingsId={this.state.settingsId}
                />
            </div >
        );
    }
}

export default withTranslation('common')(IssueStatusArray);