import React from 'react';
import {
    Badge, Button, Col, Row, ButtonToolbar,
} from 'reactstrap';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MultiSelect } from 'primereact/multiselect';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { CSVLink, CSVDownload } from "react-csv";
import { withTranslation } from 'react-i18next';
import moment from 'moment';

// Loader
import Loader from '../../App/Loader';

//modals
import FormModal from '../../Form/FormModal';
import LeaveBalanceModal from '../CommonDataTable/LeaveBalanceModal';
import DeleteRowModal from '../CommonDataTable/DeleteRowModal';
import ViewModal from '../CommonDataTable/viewModal';
import BulkUploadModal from '../CommonDataTable/BulkUploadModal';
import RejectModal from '../CommonDataTable/RejectModal';
import RolesModal from '../CommonDataTable/RolesModal';
import ConfirmationModal from '../CommonDataTable/ConfirmationModal';
import CsvFieldSelectModal from './CsvFieldSelectModal';

// fetch method for server call
import fetch from '../../../config/service';
import config from '../../../config/config';
import configImages from '../../../config/configImages';
import configMessages from '../../../config/configMessages';

// Role Permissions
import RolePermissions from '../../Tables/CommonDataTable/Permissions';

// toaster message
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// Format date
import dateFormats from '../../UI/FormatDate/formatDate';

//Render Date picker
import RenderIntervalDatePickerField from '../../../shared/components/form/IntervalDatePicker';

//tooltip styles
const LightTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f17038',
        color: '#fff',
        boxShadow: theme.shadows[10],
        fontSize: 13,
        fontWeight: '400',
        margin: 0
    },

}))(Tooltip);


class DataTables extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            allUsersData: [],
            deleteRowDataId: {},
            tablefields: [{}],
            first: 0,
            rows: 20,
            direction: 'asc',
            filterCriteria: {
                limit: 20,
                page: 1,
                criteria: [],
                sortfield: '',
                direction: 'desc'
            },
            selectedRows: '',
            selectedRowsId: '',
            totalRecords: [],
            exportData: [],
            isOpenFormModal: false,
            isOpenLeaveBalanceModal: false,
            openBulkUploadModal: false,
            openViewModal: false,
            isLoading: true,
            isOpenRolesModal: false,
            isOpenTasksModal: false,
            screenPermissions: '',
            showLeaveOptions: false,
            userId: '',
            userReportingTo: '',
            daily: '',
            issueStatus: '',
            loginRole: '',
            startDate: '',
            endDate: '',
            apiUrl: '',
            IssueStatusTypesForUpdate: [],
            mulValues: '',
            haveStatus: false,
            haveProject: false,
            onClick: true,
            getData: [],
            disablebutton: false,
            actionsTypes: [],
        }
    }


    componentDidMount = async () => {
        await this.getLeavebalanceFromLoginCall();
        await this.getRolePermissions();
        await this.props.onRef(this);
        await this.getIssueStatusFromServer();

        if (this.props.tableFields && this.props.type) {
            let tablefields = await this.props.tableFields();
            let user = JSON.parse(localStorage.getItem('loginCredentials'));

            await this.setState({
                tablefields: tablefields
            });
        }
        if (this.state.loginRole === 'Admin' || this.state.loginRole === 'Director' || this.state.loginRole === 'SuperAdmin' || this.state.loginRole === 'TeamLead') {
            let options = [
                { label: 'Approve', value: 'Approve' },
                { label: 'Reject', value: 'Reject' },
                { label: 'Completed', value: 'Completed' },
            ];
            this.setState({ actionsTypes: options })
        } else if (this.state.loginRole === 'QA') {
            let options = [
                { label: 'Completed', value: 'Completed' },
                { label: 'Moved to Prod', value: 'Moved to Prod' }
            ];
            this.setState({ actionsTypes: options })

        }
        //Event listeners for removing from local through reload option
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        await this.getTableListDataFromServer(this.state.filterCriteria);
    }


    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
        this.props.onRef(null);
    }

    // Clearing localStorage items
    handleBeforeUnload = () => {
        localStorage.removeItem('tablestatedemo-local');
    };

    //Get 'Status Types' from Settings ||CodeByTej
    getIssueStatusFromServer = async () => {
        fetch('GET', 'settings')
            .then(async (response) => {
                if (response) {
                    let array2 = response.settings[0].issueStatus;
                    await this.setState({ IssueStatusTypesForUpdate: array2 });
                }
            }).catch((err) => {
                return err;
            });
    }
    getDailyForm = (type) => {
        this.openEditModal(type);
        // this.getProjectData();
    }

    //get Leave balance from login call
    getLeavebalanceFromLoginCall = () => {
        let loginBody = localStorage.getItem('loginBody');
        if (loginBody) {
            let user = JSON.parse(loginBody);
            return fetch('POST', 'auth/login', user)
                .then(async (response) => {
                    if (response && response.details) {
                        let user = response.details;
                        if (user.role && ((user.role == "Admin")
                            || (user.role == "Director")
                            || (user.role == "SuperAdmin")
                            || (user.role == "HR")
                            || (user.role == "Manager")
                            || (user.role == "TeamLead")
                            || (user.role == "QA"))) {
                            await this.setState({
                                showLeaveOptions: true,
                                userId: user._id,
                                userReportingTo: user._id,
                                loginRole: user.role
                            });
                        } else {
                            await this.setState({
                                showLeaveOptions: false
                            });
                        }
                        if (user.role && ((user.role != "Admin") && (user.role != "Director") && (user.role != "SuperAdmin"))) {
                            this.setState({
                                leaveBalance: user.totalLeaves
                            });
                        }
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }

    //filtering table fields based on roles 
    filterTableFields = async () => {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        if (user.role === 'Hr' && this.props.type === 'Employees') {
            let newF = this.state.tablefields.filter(field => field.field !== 'Actions');
            // tablefields.splice(12, 1)

        }
        this.setState({ reportingForContractor: user.reportingToName })
        if (this.props.type === "Timesheet" && user.employmentType === "Permanent" && user.reportingToName !== "this.state.reportingForContractor" && user.role !== "Admin") {
            let exceptStatus = this.state.tablefields.filter(item => item.field !== "status");
            this.setState({ tablefields: exceptStatus })
        }

    }

    //roles restriction function using for multiTask approve/reject
    getLoginRole = () => {
        if (this.state.loginRole === 'Admin' || this.state.loginRole === 'Director' || this.state.loginRole === 'SuperAdmin' || this.state.loginRole === 'TeamLead' || this.state.loginRole === 'QA') {
            return true;
        }
        return false;
    }

    //get table list data from server with filters if any
    getTableListDataFromServer = async (filterCriteria, type) => {
        let currentCriteria = filterCriteria;

        let url, apiUrl;
        this.setState({ isLoading: true, selectedRows: '' });
        if (!currentCriteria || currentCriteria == {}) {
            currentCriteria = this.state.filterCriteria;
        }
        // detailed view and daily view buttons in timesheet
        if (this.state.daily != 'daily') {
            url = this.getApiUrl();
        } else {
            url = 'timeSheet/daily';
        }
        await this.setState({ apiUrl: url });
        // to filter employee Id leaves
        if (this.props.type == 'Leaves' || this.props.type == 'Tasks' || this.props.type == 'Issues') {
            if (this.props.location && this.props.location.pathname) {
                let path = this.props.location.pathname.split('/');
                if (path[2]) {
                    let id = path[2];
                    // if (!filterCriteria) {
                    //     filterCriteria = {};
                    // }
                    // if (!filterCriteria['criteria']) {
                    //     filterCriteria['criteria'] = [];
                    // }
                    if (this.props.type == 'Leaves') {
                        currentCriteria['criteria'].push({
                            key: 'employeeId',
                            value: id,
                            type: 'eq'
                        });
                    } else {
                        if (id !== "redirect") {
                            currentCriteria['criteria'].push({
                                key: 'projectId',
                                value: id,
                                type: 'eq'
                            });
                        }
                        if (window.location.href.includes("redirect") && this.props.type == 'Tasks') {
                            let details = JSON.parse(localStorage.getItem('loginCredentials'))
                            currentCriteria.criteria = [
                                { key: "status", value: ["Pending", "Active"], type: "in" },
                                { key: "assignedToName", value: details.displayName, type: "eq" }
                            ]
                            document.getElementsByClassName("p-column-filter")[5].value = details.displayName
                            window.history.pushState(null, null, `Tasks`);

                        }
                    }
                }
            }
        } else {
            if (!currentCriteria || !currentCriteria['criteria']) {
                currentCriteria = this.state.filterCriteria;
                filterCriteria['criteria'] = [];
                filterCriteria['direction'] = 'desc';
                filterCriteria['sortfield'] = this.props.type === "Inventory" ? 'updated' : 'created';
            }
            // if (currentCriteria && currentCriteria['criteria'] && currentCriteria['criteria'].length > 0) {
            //     delete currentCriteria['sortfield'];
            //     delete currentCriteria['direction'];
            // }

        }

        //applyng filter when a row item is deleted or when passed filer is undefined
        if (currentCriteria == undefined) {
            currentCriteria = {};
            currentCriteria['limit'] = 20;
            currentCriteria['page'] = 1;
            currentCriteria['criteria'] = [];
            currentCriteria['direction'] = 'desc';
        }

        //filter handling when clicked on refresh icon
        if (type && type === 'refresh') {
            localStorage.removeItem('tablestatedemo-local')

            // Paginator current page reset on refresh
            await this.setState({
                filters: {},
                first: 0,
                rows: 20,
            });

            let tableItems = this.state.tablefields;
            tableItems.forEach(async (element, i) => {
                if (document.getElementsByClassName("p-inputtext")[i] && document.getElementsByClassName("p-inputtext")[i].value) {
                    document.getElementsByClassName("p-inputtext")[i].value = '';
                }
                if (element && element.filterElement) {
                    await this.setState({ [element.field]: '' });
                }
            });
            if (document.getElementById("globalSearch") && document.getElementById('globalSearch').value) {
                document.getElementById('globalSearch').value = '';
            }
            if (this.datePickerRef) { this.datePickerRef.clearSelectedDates() }

            // Default filterCriteria When Refresh Clicked
            currentCriteria = {};
            currentCriteria['limit'] = 20;
            currentCriteria['page'] = 1;
            currentCriteria['criteria'] = [];
            currentCriteria['direction'] = 'desc';
            currentCriteria['sortfield'] = this.props.type === "Inventory" ? 'updated' : 'created';

            this.setState({
                filterCriteria: currentCriteria
            })
        }

        // To filter with defalut status values when rendered first time/ refreshed  || CodeByTej
        if (currentCriteria && currentCriteria['criteria'] && currentCriteria['criteria'].length == 0) {
            let arr = await this.props.tableFields();
            if (arr.length > 0) {
                let obj;
                if (this.props.type === 'Timesheet') {
                    obj = arr.find(el => el.field == 'projectName')
                } else {
                    obj = arr.find(el => el.field == 'status')
                }

                if (obj && obj.defaultValues) {
                    currentCriteria.criteria.push(
                        {
                            key: obj.field,
                            value: obj.defaultValues,
                            type: "in"
                        }
                    )
                    if (obj.field == 'status') {
                        this.setState({ haveStatus: true, haveProject: false });
                    }
                    if (obj.field == 'projectName') {
                        this.setState({ haveStatus: false, haveProject: true });
                    }
                }
            }
        }

        //Appending the defaullt sortfiled and direction to url if no sortfield in filterCriteria 
        if (currentCriteria) {
            if (currentCriteria.sortfield === '' || (!currentCriteria.sortfield)) {

                if (this.props.type == 'Leaves') {
                    currentCriteria.sortfield = 'fromDate';
                    currentCriteria['direction'] = 'desc';
                } else if (this.props.type === 'Timesheet' || this.props.type == 'Attendance' || this.props.type == 'Public Holidays') {
                    currentCriteria.sortfield = currentCriteria.sortfield ? currentCriteria.sortfield : 'date';
                    currentCriteria['direction'] = 'desc';
                } else {
                    currentCriteria.sortfield = this.props.type === "Inventory" ? 'updated' : 'created';
                    currentCriteria['direction'] = 'desc';
                }
            }

            // Escaping effect of special char '&' in filters
            if (currentCriteria) {
                let filterString = JSON.stringify(currentCriteria);
                filterString = filterString.replace('&', '%26');

                apiUrl = `${url}?filter=${filterString}`;
                //url only for Clients data list||CodeBYTej
                if (this.props.type == 'Clients') {
                    apiUrl = `${url}?filter=${filterString}&role=Client`;
                }
            }
        }
        if (this.props.type === "Issues") {
            localStorage.setItem("currentCriteria", JSON.stringify(currentCriteria))
        }

        await this.filterTableFields();

        return fetch('GET', apiUrl)
            .then(async (response) => {
                let AllEmployeesData = [];
                if (url == 'inventories/inventoryhistory') {
                    url = 'inventories';
                }
                if (url == 'timeSheet/daily') {
                    url = 'timeSheet'
                }
                if (url == 'employees/getClients') {
                    url = 'clients'
                }
                if (url == 'attendances/lateCommers') {
                    url = 'attendances'
                }
                if (response && response[url] && response[url].length && response[url].length > 0) {
                    if (response.pagination && response.pagination.totalCount) {
                        let totalRecordsLength = response.pagination.totalCount;
                        let totalRecords = response[url];
                        await this.setState({
                            totalRecordsLength: totalRecordsLength,
                            totalRecords: totalRecords
                        })

                        //disable Add button 
                        const totalRecordDate = new Date(totalRecords[0].date);
                        const todaysDate = new Date();

                        function formatDate(date) {
                            const day = String(date.getDate()).padStart(2, '0');
                            const month = String(date.getMonth() + 1).padStart(2, '0');
                            const year = date.getFullYear();
                            return `${day}-${month}-${year}`;
                        }
                        const formattedTotalRecordDate = formatDate(totalRecordDate);
                        const formattedTodaysDate = formatDate(todaysDate);

                        if (formattedTotalRecordDate === formattedTodaysDate && this.props.type == 'DailyStatus') {
                            this.setState({
                                disablebutton: true
                            })
                        }
                    }

                    let index = 0;
                    for (let employee of response[url]) {
                        if (this.state.first) {
                            employee.Sno = (index + 1) + this.state.first;
                        } else {
                            employee.Sno = index + 1;
                        }
                        index++;
                        AllEmployeesData.push(employee);
                    }
                }

                //calling export data method to set the epxort data to state
                // await this.getDataToExport();

                if (this.props.type === 'Tasks' && !this.getLoginRole()) {
                    this.state.tablefields.splice(0, 1,
                        { textAlign: 'center', width: 45, field: 'Sno', header: 'tasks.table_fields.s_no', filter: false, sortable: false, },
                    )
                }
                await this.setState({
                    allUsersData: AllEmployeesData,
                    currentFilterCriteria: currentCriteria,
                    isLoading: false
                })
                return AllEmployeesData;
            }).catch((err) => {
                return err;
            });
    }

    // Called When Clicked on Export To CSV Button and Opens CsvFieldSelectModal to enable field selection ||CodeByTej
    openCsvFieldSelectModal = () => {
        this.setState({
            isOpenCsvFieldSelectModal: true,
        })
    }

    // Called When Clicked on Cancel or Confirm in CsvFieldSelectModal ||CodeByTej
    closeCsvFieldSelectModal = async (type, fields) => {
        if (type && type === 'confirm' && fields) {
            await this.setState({
                isOpenCsvFieldSelectModal: false,
                exportFields: fields
            })
            await this.getDataToExport()
        } else {
            this.setState({
                isOpenCsvFieldSelectModal: false,
            })

        }
    }

    //Get all data with filters applied of current screen to export to CSV || CodeByTej
    getDataToExport = async () => {
        await this.setState({ isLoading: true })

        let filterCriteria = this.state.currentFilterCriteria;
        delete filterCriteria['limit'];
        delete filterCriteria['page'];
        let url = this.getApiUrl();
        let apiUrl;

        if (filterCriteria) {
            filterCriteria = JSON.stringify(filterCriteria);
            filterCriteria = filterCriteria.replace('&', '%26');

            apiUrl = `${this.state.apiUrl}?filter=${filterCriteria}&type=exportToCsv`;
            //url only for Clients dataExport ||CodeBYTej
            if (this.props.type == 'Clients') {
                apiUrl = `${this.state.apiUrl}?filter=${filterCriteria}&role=Client&type=exportToCsv`;
            }
        }

        return fetch('GET', apiUrl)
            .then(async (response) => {
                if (url == 'inventories/inventoryhistory') {
                    url = 'inventories'
                }
                if (url == 'employees/getClients') {
                    url = 'clients'
                } if (url == 'dailystatus') {
                    url == 'dailystatus'
                }
                if (response && response[url]) {
                    let exportHeaders = [];
                    let exportData = response[url];
                    exportData.forEach((item) => {
                        if (item.created) {
                            item.created = dateFormats.formatDate(item.created, config.dateDmyFormat);
                        }
                        if (item.updated) {
                            item.updated = dateFormats.formatDate(item.updated, config.dateDmyFormat);
                        }
                        if (item.fromDate) {
                            item.fromDate = dateFormats.formatDate(item.fromDate, config.dateDmyFormat);
                        }
                        if (item.toDate) {
                            item.toDate = dateFormats.formatDate(item.toDate, config.dateDmyFormat);
                        }
                        if (item.purchaseDate) {
                            item.purchaseDate = dateFormats.formatDate(item.purchaseDate, config.dateDmyFormat);
                        }
                        if (item.date) {
                            item.date = dateFormats.formatDate(item.date, config.dateDmyFormat);
                        }
                        if (item.startDate) {
                            item.startDate = dateFormats.formatDate(item.startDate, config.dateDmyFormat);
                        }
                        if (item.endDate) {
                            item.endDate = dateFormats.formatDate(item.endDate, config.dateDmyFormat);
                        }
                        if (item.joinDate) {
                            item.joinDate = dateFormats.formatDate(item.joinDate, config.dateDmyFormat);
                        }
                        if (item.terminationDate) {
                            item.terminationDate = dateFormats.formatDate(item.terminationDate, config.dateDmyFormat);
                        }
                        if (item.assignEmployees) {
                            let emps = ' ';
                            item.assignEmployees.forEach(element => {
                                emps = emps + " " + element.displayName + " ,";
                            });
                            item.assignEmployees = emps;
                        }

                        //codeByTej
                        if (item.createdBy && item.createdBy.name) {
                            item.createdBy = item.createdBy.name
                        }
                        if (item.updatedBy && item.updatedBy.name) {
                            item.updatedBy = item.updatedBy.name
                        }
                    })

                    //Handling Selected Fields recieved from CsvFieldSelectModal  ||CodeByTej
                    let exportFields = await this.state.exportFields

                    //Taking tablefields data to display to export field names as same as table fields
                    let tableData = this.state.tablefields;
                    tableData.forEach((item) => {
                        if (item.header && item.field) {
                            item.header = `${this.props.t(item.header)}`;
                            for (const field in exportFields) {
                                if (item.field === field && exportFields[field]) {
                                    exportHeaders.push({ 'label': item.header, 'key': field });
                                }
                            }
                        }
                    });
                    if (this.props.type === 'Tasks') {
                        exportHeaders.push(
                            { 'label': 'Description', 'key': 'descriptionWithoutTags' },
                            { 'label': 'Description', 'key': 'description' }
                        )
                    }
                    if (this.props.type === 'Employees') {
                        exportHeaders.push(
                            { 'label': 'Termination Date', 'key': 'terminationDate' },
                        )
                    }
                    //mapping function to remove double quotes and html tags globally
                    let exportDataWithoutQuotes = exportData.map(item => {
                        const newItem = { ...item };
                        Object.keys(newItem).forEach(key => {
                            newItem[key] = String(newItem[key]).replace(/"/g, '""');
                        });
                        //Removing '-' starting of the text it's not supproting for windows
                        if (item.description) {
                            newItem.description = item.description.replace(/-/, '')
                            newItem.descriptionWithoutTags = item.description.replace(/-/, '').replace(/<p>/gi, ' ').replace(/<\/p>/gi, ' ');
                        }
                        return newItem;
                    });

                    // Assigning data to Export and Triggering Download of CSV file ||CodeByTej
                    await this.setState({
                        exportHeaders: exportHeaders,
                        exportData: exportDataWithoutQuotes,
                        isLoading: false
                    }, () => {
                        // click the CSVLink component to trigger the CSV download ||CodeByTej
                        this.csvLink.link.click();
                    })
                }
            }).catch((err) => {
                return err;
            });
    }

    //get url based on type
    getApiUrl = () => {
        let apiUrl = '';
        if (this.props.type === 'Employees') {
            apiUrl = 'employees';
        } else if (this.props.type === 'Projects') {
            apiUrl = 'projects';
        } else if (this.props.type === 'Tasks') {
            apiUrl = 'tasks';
        } else if (this.props.type === 'Leaves') {
            apiUrl = 'leaves';
        } else if (this.props.type === 'Leave History') {
            apiUrl = 'leaveHistory';
        } else if (this.props.type === 'Roles') {
            apiUrl = 'roles';
        } else if (this.props.type === 'Attendance') {
            apiUrl = 'attendances';
        } else if (this.props.type === 'Issues') {
            apiUrl = 'issues';
        } else if (this.props.type === 'Timesheet') {
            apiUrl = 'timeSheet';
        } else if (this.props.type === 'Public Holidays') {
            apiUrl = 'publicholidays';
        } else if (this.props.type === 'Inventory') {
            apiUrl = 'inventories';
        } else if (this.props.type == 'Inventory History') {
            apiUrl = 'inventories/inventoryhistory';
        } else if (this.props.type === 'Activities') {
            apiUrl = 'activities';
        } else if (this.props.type == 'Screens') {
            apiUrl = 'menuLists';
        } else if (this.props.type == 'Email Templates') {
            apiUrl = 'templates';
        } else if (this.props.type == 'Presentations') {
            apiUrl = 'presentations';
        } else if (this.props.type == 'Clients') {
            apiUrl = 'employees/getClients';
        } else if (this.props.type == 'LateComers') {
            apiUrl = 'attendances/lateCommers';
        } else if (this.props.type == 'EmployeeIdMapping') {
            apiUrl = 'employeeMapping';
        } else if (this.props.type == 'DailyStatus') {
            apiUrl = 'dailystatus'
        }

        return apiUrl;
    }

    openEditModal = async (type) => {
        await fetch('GET', 'dailystatus/getEmployee')
            .then(async (response) => {
                if (response) {
                    // let data = response;
                    await this.setState({
                        getData: response
                    })
                }
            }).catch((error) => {
                return error;
            })
        this.setState({
            isOpenFormModal: true,
            formType: type,
            selectedRows: '',
        });

    }

    //open Form modal
    openFormModal = async (type, rowData) => {
        if (this.props.type == 'Roles') {
            this.setState({
                isOpenRolesModal: true,
                formType: type,
                selectedRows: ''
            })
            if (type == 'edit') {
                this.roleModalRef.getRowData(rowData, type);
            }
            if (type == 'add') {
                let rowData, allRoles = this.state.allUsersData;
                allRoles.forEach((item) => {
                    if (item.role == 'Admin' || item.role == 'Director') {
                        rowData = item;
                    }
                })
                this.roleModalRef.getRowData(rowData)
            }
        }
        else {
            await this.setState({
                isOpenFormModal: true,
                formType: type,
                selectedRows: '',
            });
            if (type == 'edit') {
                if (this.state.isOpenFormModal && this.formModalRef) {
                    this.formModalRef.getrowData(rowData);
                }
            }
        }
    }

    //closeRolesModal
    closeRolesModal = () => {
        this.setState({ isOpenRolesModal: false })
    }

    //closeTasksModal
    closeTasksModal = () => {
        this.setState({ isOpenTasksModal: false })
    }

    //close form modal
    closeFormModal = async () => {
        await this.setState({
            isOpenFormModal: false,
        })
    }

    // delete selected row
    deleteSelectedRow = () => {
        let apiUrl = this.getApiUrl();
        let method, url, body = {}

        if ((this.props.type == 'Employees' || this.props.type == 'Issues') &&
            this.state.selectedRowsId && this.state.selectedRowsId.length &&
            this.state.selectedRowsId.length > 0) {
            method = 'POST';
            if (this.props.type != 'Issues') {
                url = `${apiUrl}/cancelMulptipleEmployees`;
            }
            else {
                url = `${apiUrl}/updateMultipleIssues`
            }
            body['status'] = this.state.issueStatus
            body[apiUrl] = [...this.state.selectedRowsId]
        } else {
            url = `${apiUrl}/${this.state.deleteRowDataId}`;
            method = 'DELETE';
            body = ''
        }
        return fetch(method, url, body)
            .then(async (response) => {
                this.setState({
                    openDeleteModal: false
                });
                if (response && response.respCode && response.respCode === 204) {
                    showToasterMessage(response.respMessage, 'success');
                    this.getTableListDataFromServer();
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }

    //close delete modal
    closeDeleteModal = async () => {
        await this.setState({
            openDeleteModal: false
        })
    }

    // conformation for delete employee
    deleteConfirmAction = async (rowData, value) => {
        let selectedRowsId = [];
        let selectedRows = [...this.state.selectedRows]
        if (selectedRows && selectedRows.length && selectedRows.length > 0 && rowData == '') {
            selectedRows.forEach((item, index) => {
                selectedRowsId.push(item._id)
            })
            await this.setState({ selectedRowsId: selectedRowsId })
            if (this.props.type == 'Issues') {
                let issueStatusValue;
                if (value && value.value) {
                    issueStatusValue = value.value;
                } else {
                    issueStatusValue = '';
                }
                await this.setState({ issueStatus: issueStatusValue })
                this.deleteSelectedRow();
            }
        }
        if ((this.props.type != 'Issues' && selectedRows && selectedRows.length &&
            selectedRows.length > 0) || rowData) {
            this.setState({
                deleteRowDataId: rowData,
                openDeleteModal: true
            });
        }
    }
    /**
     * 
     * @param {object} event 
     * @returns onClick function for actions dropdown which is placed in task screen 
     */
    onActionsChange = async (event) => {

        let selectedRows = [...this.state.selectedRows]
        let value = event.target.value

        if (value == 'Approve') {
            this.openConfirmationModal(selectedRows, 'Approved', 'Approve')
        } if (value === 'Completed' || value === 'Moved to Prod') {
            this.openConfirmationModal(selectedRows, value, value)
        }
        //sending extra props, for multirecords rejection using textarea
        else if (value == 'Reject') {
            this.openRejectModal(selectedRows, 'isDropdownValue')
        }
    };

    //Adding filters for myTasks button
    myTaskFilters = async () => {
        let filterCriteria = {
            limit: 20,
            page: 1,
            criteria: [{ "key": "status", "value": ['Pending', 'Active'], "type": "in" }],
            sortfield: '',
            direction: 'desc'
        }
        let details = JSON.parse(localStorage.getItem('loginCredentials'))

        let filterValues = ["new", "in development", "development completed", "ready for testing", "reopen", "onhold", "review completed", "need clarification"]
        filterCriteria.criteria[1] = { "key": "assignedToName", "value": details.displayName, "type": "regexOr" };
        filterCriteria.criteria[2] = { "key": "taskStatus", "value": filterValues, "type": "in" };
        await this.setState({
            filterCriteria: filterCriteria
        })
        this.getTableListDataFromServer(this.state.filterCriteria)
    }
    openViewModal = async (rowData) => {
        await this.setState({
            idForProjectList: rowData._id
        })
        let keys = Object.keys(rowData);
        let formFields = this.props.formFields();
        if (this.props.type == 'Leaves') {
            formFields.push(
                {
                    required: true,
                    value: '',
                    type: 'date',
                    name: 'halfDayType',
                    label: 'Half Date Type',
                    id: 'fromDate',
                    placeholder: 'dd-mm-yyyy'
                },
            )
        }
        if (this.props.type == 'Attendance') {
            let value = ' ';
            rowData.checkouts.forEach((item, index) => {
                let time = dateFormats.formatDate(item.time, config.timeFormat), timeCheck = '';
                if (index % 2 == 0) {
                    timeCheck = ' IN => '
                }
                else {
                    timeCheck = ' OUT => '
                }
                value = value + timeCheck + time;
            });

            formFields.push(
                {
                    required: true,
                    value: value,
                    type: 'text',
                    name: 'checkin',
                    label: 'Check In',
                    placeholder: 'dd/mm/yyyy'
                },
            )
        }

        for (let item of formFields) {
            if (keys.indexOf(item.name) !== -1) {
                if (rowData[item.name]) {
                    if (item.name == 'created' || item.name == 'startDate' || item.name == 'endDate' || item.name == 'joinDate' || item.name == 'dateOfBirth') {
                        item.value = dateFormats.formatDate(rowData[item.name], config.dateDashFormat);
                    } else if (item.name == 'reportingTo') {
                        if (rowData[item.name].displayName) {
                            item.value = rowData[item.name].displayName;
                        }
                    }
                    // else if (item.name == 'laptopSNO') {
                    //     if (rowData['reportingTo'] && rowData['reportingTo'].laptopSNO) {
                    //         item.value = rowData['reportingTo'].laptopSNO[0];
                    //     }
                    // }
                    else {
                        item.value = rowData[item.name];
                    }

                }
            }
        }
        //displaying inventory data of respective employee
        if (rowData && rowData.laptopSNO && rowData.laptopSNO.length) {
            this.props.handleViewData(rowData, formFields)
        }
        await this.setState({
            openViewModal: true,
            rowData: rowData
        });
        this.viewModalRef.getRowData(formFields)
    }

    closeViewModal = async () => {
        await this.setState({
            openViewModal: false
        });
    }

    //on changing pagination
    onPageChange = async (event, filterCriteria) => {
        if (event && event.rows) {
            let currentPage = event.page + 1;
            filterCriteria['limit'] = event.rows;
            filterCriteria['page'] = currentPage;
            await this.setState({
                rows: event.rows,
                page: event.page,
                first: event.first,
                pageCount: event.pageCount
            })
        }
        this.getTableListDataFromServer(filterCriteria)
    }

    //sorting fields
    sortChange = (event) => {

        //Empty the search fields when sorted ||CodeByTej
        let tableItems = this.state.tablefields;
        tableItems.forEach(async (element, i) => {
            if (document.getElementsByClassName("p-inputtext")[i] && document.getElementsByClassName("p-inputtext")[i].value) {
                document.getElementsByClassName("p-inputtext")[i].value = '';
            }
            if (element && element.filterElement) {
                await this.setState({ [element.field]: '' });
            }
        });
        if (document.getElementById("globalSearch") && document.getElementById('globalSearch').value) {
            document.getElementById('globalSearch').value = '';
        }

        this.setState({ selectedRows: '' })
        let criteria = this.state.filterCriteria
        let sortCount = this.state.sortCount;
        if (event && event['sortField']) {
            sortCount = sortCount == 0 ? sortCount + 1 : 0;
            let sortField = event['sortField'];
            let filterCriteria = {
                criteria: criteria.criteria,

                direction: sortCount == 0 ? "desc" : 'asc',
                sortfield: sortField,
                limit: this.state.rows,
                page: this.state.page ? this.state.page : 1,
            }
            this.setState({
                sortCount: sortCount,
                filterCriteria: filterCriteria
                // isLoading: true
            });
            this.getTableListDataFromServer(filterCriteria);
        }
    }

    sortHours = () => {
        // if (this.props.type == 'Timesheet') {
        const onClick = this.state.onClick;

        this.setState({ onClick: !onClick }, () => {
            this.state.allUsersData.sort((a, b) => {
                if (this.state.onClick) {
                    return parseFloat(a.timesheetHours) - parseFloat(b.timesheetHours);
                } else {
                    return parseFloat(b.timesheetHours) - parseFloat(a.timesheetHours);
                }
            });

            // Update the state with the sorted array
            this.setState({
                allUsersData: this.state.allUsersData
            });
        });
        // }
    }


    // table global search
    globalSearch = (value) => {
        let filterCriteria = this.state.filterCriteria;

        // Check if the input value is empty or contains only spaces
        if (value.trim().length === 0) {
            // If the input is empty, remove the globalSearch filter and return
            if (filterCriteria["globalSearch"]) {
                delete filterCriteria["globalSearch"];
                this.getTableListDataFromServer(filterCriteria);
            }
            return;
        }
        // To check whether filter value has any '&' and replace with 'ampd' to escape '&' char in server call
        let filterValue = value.replace('&', 'ampd');
        filterCriteria["globalSearch"] = { "value": filterValue, "type": "user" };
        this.getTableListDataFromServer(filterCriteria);
    }
    // dateFilters = () => {
    // if (this.state.startDate && this.state.endDate && !isDateFilterEnabled) {
    //     let startDate = (moment(this.state.startDate).format('YYYY-MM-DD'));
    //     let endDate = (moment(this.state.endDate).format('YYYY-MM-DD'));
    //     let findstartDate = filterCriteria["criteria"].findIndex(obj => obj.key === "date" && obj.type === "gte")
    //     let findendDate = filterCriteria["criteria"].findIndex(obj => obj.key === "date" && obj.type === "lte")
    //     if (findstartDate === -1 && findendDate === -1) {

    //         let startrangeObj = { 'key': 'date', 'value': startDate, 'type': 'gte' }
    //         let endrangeObj = { 'key': 'date', 'value': endDate, 'type': 'lte' }
    //         filterCriteria.criteria.push(startrangeObj);
    //         filterCriteria.criteria.push(endrangeObj);
    //     }
    //     // this.getTableListDataFromServer(filterCriteria);
    // }
    // }

    // on search get data from server
    onFilterChange = async (event, type) => {
        if (event && event.target && event.target.name) {
            setTimeout(() => {
                let existingFilters = JSON.parse(localStorage.getItem('tablestatedemo-local'))

                const updatedFilters = {
                    ...existingFilters,
                    filters: {
                        ...existingFilters.filters,
                        [event.target.name]: {
                            'value': event.value,
                            'matchMode': "startsWith"
                        }
                    }
                };

                localStorage.setItem('tablestatedemo-local', JSON.stringify(updatedFilters));
            }, 2000);
        }

        let isDateFilterEnabled = false;
        this.setState({ selectedRows: '' });
        let pageEvent = { first: 0, page: 0, rows: this.state.rows, pageCount: this.state.pageCount };
        if (type == 'dropdownFilter') {
            await this.setState({ [event.target.name]: event.target.value });
        }
        let filterCriteria = this.state.filterCriteria;
        if (filterCriteria.criteria === undefined) {
            filterCriteria.criteria = [];
        }
        if (type == 'dropdownFilter' && event.value == null) {
            // in  All case if the value is null then find & remove that obj from filter criteria
            if (filterCriteria && filterCriteria.criteria && filterCriteria.criteria.length > 0) {
                let obj = filterCriteria.criteria.find(x => x.key == event.target.name);
                let indx = filterCriteria.criteria.indexOf(obj);
                filterCriteria.criteria.splice(indx, 1);
                this.onPageChange(pageEvent, filterCriteria);
                return;
            }
        } else {
            let fieldName = '', selectedFilterValue, selectedFilterType, date, formattedDate, invalidDate = false, invalidLength = false;
            if (event) {
                if (event && event.filters) {
                    this.setState({ filters: event.filters });
                    if (Object.keys(event.filters) && Object.keys(event.filters).length > 0) {
                        let filterObj = event.filters;
                        // let otherFilterCase = false;
                        Object.keys(filterObj).forEach(async (key) => {
                            fieldName = key;
                            if (filterCriteria && filterCriteria.criteria) {
                                if (fieldName == 'createdBy') {
                                    fieldName = 'createdBy.name';
                                }
                                //  date fields
                                let dateFields = ['dateOfBirth', 'fromDate', 'toDate', 'date', 'created', 'updated', 'purchaseDate', 'dateOfBirth', 'startDate', 'endDate', 'joinDate'];
                                if (dateFields.includes(fieldName)) {
                                    // to search
                                    if ((filterObj[key].value && filterObj[key].value.length < 10)) {
                                        invalidLength = true;
                                    } else {
                                        if (!dateFormats.dateIsValid(filterObj[key].value)) {
                                            invalidDate = true;
                                        }
                                        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                                        // to search
                                        selectedFilterValue = filterObj[key] && filterObj[key].value && filterObj[key].value.length >= 10 ? dateFormats.formatDate(filterObj[key].value, config.serverDateFormat, config.dateDmyFormat, 'dateFilter', timeZone) : null;

                                        selectedFilterType = 'gte';
                                        // formatted date is given date plus one to give value for lte type
                                        date = dateFormats.addDaysToDate(selectedFilterValue, 1, timeZone);
                                        formattedDate = dateFormats.formatDate(date, config.dateDashFormat);
                                        // removing  lte prev  obj of same field in filter criteria (remove duplicates)
                                        let obj = function getIndex(fieldName) {
                                            for (var i = 0; i < filterCriteria.criteria.length; i++) {
                                                if (filterCriteria.criteria[i].key == fieldName && filterCriteria.criteria[i].type == 'lt') return i;
                                            }

                                        }
                                        let index = filterCriteria.criteria.indexOf(obj);
                                        filterCriteria['criteria'].splice(index, 1);
                                    }
                                }
                                //To check whether filter value has any '&' and replace with 'ampd' to escape '&' char in server call 
                                else {
                                    selectedFilterValue = filterObj[key] && filterObj[key].value ? filterObj[key].value : null;
                                    if (Array.isArray(selectedFilterValue)) {
                                        selectedFilterValue = filterObj[key] && filterObj[key].value ? filterObj[key].value : null;
                                        selectedFilterType = 'in';
                                    } else {
                                        selectedFilterValue = selectedFilterValue.replace('&', 'ampd');
                                        selectedFilterValue = selectedFilterValue.trim()
                                        selectedFilterType = 'regexOr';
                                    }
                                }
                                // to check whether  key already exits in filter criteria or not 
                                let obj = filterCriteria.criteria.find(x => x.key == fieldName);
                                let index = filterCriteria.criteria.indexOf(obj);
                                if (index === -1) {
                                    await filterCriteria['criteria'].push({
                                        key: fieldName,
                                        value: selectedFilterValue,
                                        type: selectedFilterType
                                    });

                                } else {
                                    filterCriteria.criteria[index].value = selectedFilterValue;
                                    filterCriteria.criteria[index].type = selectedFilterType;

                                }

                                // if  key is date field push lte also
                                if (selectedFilterType === 'gte' && date !== 'Invalid date' && !invalidLength) {
                                    isDateFilterEnabled = true;
                                    await filterCriteria['criteria'].push({ key: fieldName, value: date, type: 'lt' });
                                }
                                if (invalidLength || invalidDate) {
                                    delete filterCriteria['criteria'][key];
                                }
                            }
                            if (filterObj[key].value && filterObj[key].value.length < 3) {
                                // to check whether  key already exits in filter criteria or not
                                let obj2 = filterCriteria.criteria.find(x => x.key == fieldName);
                                let index2 = filterCriteria.criteria.indexOf(obj2);
                                await filterCriteria.criteria.splice(index2, 1);
                            }
                        });
                        //Commenting this code, it's pushing unneccesay date filters to criteria
                        // this.dateFilters();

                        if (invalidDate) {
                            showToasterMessage('Invalid Date Format', 'error');
                            return;
                        } if (invalidLength) {
                            return;
                        }

                    }
                    else if (event.filters == {} && filterCriteria && filterCriteria.criteria && filterCriteria.criteria.length == 0) {
                        filterCriteria['criteria'] = [];
                    }
                } else if (type == 'dropdownFilter') {
                    let fieldName = event.target.name;
                    let selectedFilterValue = event.target.value;
                    if (event && event.target && (event.target.name == 'status' || event.target.name == 'taskStatus' || event.target.name == 'priority')) {
                        selectedFilterType = 'in';
                    } else if (event && event.target && event.target.name == 'type') {
                        selectedFilterType = 'regexOr';
                    } else if (event && event.target && event.target.name == 'projectName') {
                        selectedFilterType = 'in';
                    } else {
                        selectedFilterType = 'eq';
                    }
                    if (filterCriteria && filterCriteria.criteria) {
                        let obj = filterCriteria.criteria.find(x => x.key == fieldName);
                        let index = filterCriteria.criteria.indexOf(obj);
                        if (index == -1) {

                            await filterCriteria['criteria'].push({
                                key: fieldName, value: selectedFilterValue,
                                type: selectedFilterType
                            });
                            await this.onPageChange(pageEvent, filterCriteria);
                        } else {
                            filterCriteria['criteria'].splice(index, 1, {
                                key: fieldName, value: selectedFilterValue,
                                type: selectedFilterType
                            });
                            await this.onPageChange(pageEvent, filterCriteria);
                        }


                    }
                }


                if (filterCriteria && filterCriteria.criteria && filterCriteria.criteria.length > 0) {
                    if (filterCriteria.criteria && filterCriteria.criteria[filterCriteria.criteria.length - 1] && filterCriteria.criteria[filterCriteria.criteria.length - 1].value && filterCriteria.criteria[filterCriteria.criteria.length - 1].value.length < 2) {
                        if (this.props.type == 'Timesheet') {
                            if (!this.state.haveProject) {
                                return;
                            }
                            if (this.state.haveProject) {
                                if (filterCriteria.criteria.length > 1) {
                                    return;
                                } else {
                                    await this.onPageChange(pageEvent, filterCriteria);
                                }
                            }
                        } else {
                            if (!this.state.haveStatus) {
                                return;
                            }
                            if (this.state.haveStatus) {
                                if (filterCriteria.criteria.length > 1) {
                                    return;
                                } else {
                                    await this.onPageChange(pageEvent, filterCriteria);
                                }
                            }
                        }
                    } else {

                        await this.onPageChange(pageEvent, filterCriteria)
                    }

                } else {
                    filterCriteria.criteria = [];
                    await this.onPageChange(pageEvent, filterCriteria);
                }

            } else {
                this.setState({
                    isLoading: false
                })
            }
        }
    }

    //open Bulk Modal
    bulkUploadMoadal = () => {
        this.setState({ openBulkUploadModal: true })
    }

    //close Bulk Modal
    closeBulkModal = () => {
        this.setState({ openBulkUploadModal: false });
        this.getTableListDataFromServer(this.state.filterCriteria, 'refresh')
    }

    //select multiple rows to delete
    selectRowsToDelete = async (event) => {
        await this.setState({ selectedRows: event.value })
    }

    changeLeaveStatus = async (rowData, type, status) => {
        await this.setState({ formType: 'edit', setFormRef: true })
        if (type == 'reason') {
            if (this.formModalRef) {
                //mapping the data for multiselect approval for task screen 
                if (Array.isArray(rowData) && this.props.type === 'Tasks') {
                    let taskIds = rowData.map(item => item._id)
                    const response = {
                        taskIds: taskIds,
                        status: rowData.status,
                    };
                    this.formModalRef.handleData(response, 'reason', 'changeStatus')
                } else {
                    this.formModalRef.handleData(rowData, 'reason', 'changeStatus')
                }
            }

        } else {
            let obj = {};
            if (this.props.type == 'Attendance') {
                obj.wfhApproved = status;
            } else if (status === 'Moved to Prod') {
                obj.taskStatus = status;
            } else {
                obj.status = status;
            }
            if (this.formModalRef) {
                //mapping the data for multiselect actions
                if (Array.isArray(rowData) && this.props.type === 'Tasks') {
                    let taskIds = rowData.map(item => item._id)
                    await this.formModalRef.getrowData(taskIds);
                } else {
                    await this.formModalRef.getrowData(rowData);
                }
                if (this.props.type == 'Attendance') {
                    this.formModalRef.handleData(obj, 'wfhApproved', 'changeStatus')
                }
                else {
                    this.formModalRef.handleData(obj, 'status', 'changeStatus')
                }
            }
        }
    }

    //open leave balance update modal
    openLeaveUpdateModal = async (type, data) => {
        await this.setState({ isOpenLeaveBalanceModal: true, leavedata: data })

    }
    //close leave update modal
    closeLeaveUpdateModal = () => {
        this.setState({ isOpenLeaveBalanceModal: false });
    }

    //openRejectModal
    openRejectModal = async (rowData, type) => {
        await this.setState({ setFormRef: true })

        await this.formModalRef.getrowData(rowData);
        this.setState({
            openRejectModal: true,
            isDropdownValue: type
        });
    }
    closeRejectModal = () => {
        this.setState({ openRejectModal: false });
    }


    //openConfirmationModal
    openConfirmationModal = async (rowData, status, type) => {
        // Disable leave approval until action completes based on row Id
        localStorage.setItem('leaveApprovalId', JSON.stringify(rowData._id))
        await this.setState({
            item: rowData,
            confirmText: type,
            [`${rowData._id}`]: true,
        })
        if (this.props.type == 'Tasks' && status === 'Approved') {
            status = 'Active'
        }
        this.setState({ openConfirmationModal: true, leaveStatus: status })
    }

    closeConfirmationModal = (type) => {
        if (type && type === 'yes') {
            this.setState({
                openConfirmationModal: false,
            })
        }
        else {
            //  Enable Leave approval if action not confirmed 
            let leaveApprovalId;
            if (localStorage.getItem('leaveApprovalId') && localStorage.getItem('leaveApprovalId') !== 'undefined') {
                leaveApprovalId = JSON.parse(localStorage.getItem('leaveApprovalId'));
                this.setState({
                    openConfirmationModal: false,
                    [`${leaveApprovalId}`]: false,
                })
            } else {
                this.setState({
                    openConfirmationModal: false,
                })
            }
        }
    }
    //Formatting function for array of strings and dispalying data in the ',' seperated values
    flattenArray = (arrayVal) => {
        let val = '';
        if (arrayVal) {
            val = JSON.stringify(arrayVal);
            val = val.replace(/"/g, '')
                .replace(/\[/g, '')
                .replace(/]/g, '')
                .replace(/{/g, '')
                .replace(/}/g, '')
                .replace(/,/g, ' , ')
                .replace(/:/g, ' : ');
        }
        return val;
    }

    //change table body values
    changeFieldValues = (item, column) => {
        if (item && ((this.props.type == 'Tasks' && column.field == 'name') || (column.field == 'description' || column.field == 'reason' || column.field == 'punchRecords' || column.field === 'taskName')) && item[column.field]) {
            return (
                <LightTooltip title={item[column.field]} >
                    <div>{item[column.field]}</div>
                </LightTooltip>
            )
        }

        if (column.field == 'projectId') {
            return <span className='text-uppercase'>{item[column.field].name}</span>
        }
        if (column.field == 'name') {
            if (this.props.type == 'Projects') {
                return <Link to={`/projectDetails/${item._id}`}> <span className='text-uppercase'>{item.name}</span></Link>
            } else if (this.props.type == 'Inventory') {
                return <Link to={`/inventoryDetails/${item._id}`} className='text-capitalize'>{item.name}</Link>;
            }
        }
        if (column.field == 'taskID') {
            if (this.props.type == 'Tasks') {
                return <Link to={`/taskDetails/${item._id}`}><span className='text-capitalize'>{item.taskID}</span></Link>
            }
        }
        if (column.field == 'totalLeaves') {
            if (item && item[column.field] && isNaN(item[column.field])) {
                return 0;
            } else {
                return item[column.field];
            }
        }
        if (column.field == 'displayName' && this.props.type == 'Employees') {

            return <div className='textElipses'>
                {item.photo ?
                    <img src={`${config.imgUrl}employee/${item.photo}`} className='imgStyles' />
                    :
                    <img src={configImages.defaultImg} className='imgStyles' />
                }
                <span style={{ color: 'blue' }} className='text-capitalize'
                    onClick={() => this.openViewModal(item)}>
                    {item.displayName}</span>
            </div >
        }
        // if (column.field == 'priority') {
        //     return <div className='textElipses1'>
        //         <span className='text-capitalize'>
        //             {item.priority}
        //         </span>
        //     </div>
        // }

        if (column.field == 'employeeName') {
            return <div className='textElipses'>
                <span style={{ color: 'blue' }} className='text-capitalize'
                    onClick={() => this.openViewModal(item)}>{item.employeeName}</span>
            </div>
        } else if (column.field == 'status') {
            if (item.status == 'Active' || item.status == 'Fixed' || item.status == 'In Stock' || item.status == 'Approved' || item.status == 'New' || item.status == 'in Stock') {
                return <Badge color='success' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.status}</Badge>
            } else if (item.status == 'Pending' || item.status == 'UAT' || item.status == 'rejected') {
                return <Badge color='danger' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.status}</Badge>
            } else if (item.status == 'Inactive' || item.status == 'inProgress' || item.status == 'Cancelled' ||
                item.status == 'Assigned' || item.status == 'assigned' || item.status == 'In Progress' || item.status == 'Closed') {
                return <Badge color='warning' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.status}</Badge>
            } else if (item.status == 'New' || item.status == 'Completed' || item.status == 'Resolved' || item.status == 'Reopen') {
                return <Badge color='primary' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.status}</Badge>
            } else if (item.status == 'Reject' || item.status == 'Repair' || item.status == 'repair') {
                return <Badge color='danger' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.status}</Badge>
            } else {
                return <Badge color='secondary' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.status}</Badge>
            }
        } else if (column.field == 'role') {
            if (item.role == 'Admin' || item.role == 'Director' || item.role == 'director' || item.role == 'admin') {
                return <Badge color='success' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.role}</Badge>
            } else if (item.role == 'SuperAdmin') {
                return <Badge color='danger' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.role}</Badge>
            } else if (item.role == 'TeamLead' || item.role == 'teamLead') {
                return <Badge color='warning' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.role}</Badge>
            } else if (item.role == 'Developer' || item.role == 'developer') {
                return <Badge color='primary' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.role}</Badge>
            } else if (item.role == 'Trainee' || item.role == 'trainee') {
                return <Badge color='info' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.role}</Badge>
            } else if (item.role == 'QA' || item.role == 'qa') {
                return <Badge color='secondary' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.role}</Badge>
            } else if (item.role == 'Support' || item.role == 'support') {
                return <Badge color='secondary' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.role}</Badge>
            } else {
                return <Badge color='info' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.role}</Badge>
            }
        } else if (column.field === 'taskStatus' && this.props.type === 'Tasks') {
            if (item.taskStatus == 'development completed' || item.taskStatus == 'duplicate') {
                return <Badge color='success' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.taskStatus}</Badge>
            } else if (item.taskStatus == 'in development' || item.taskStatus == 'analysis/design') {
                return <Badge color='primary' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.taskStatus}</Badge>
            } else if (item.taskStatus == 'resolved' || item.taskStatus == 'moved to prod' || item.taskStatus == 'not an issue') {
                return <Badge color='secondary' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.taskStatus}</Badge>
            } else if (item.taskStatus == 'need clarification' || item.taskStatus == 'review completed' || item.taskStatus == 'ready for testing') {
                return <Badge color='warning' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.taskStatus}</Badge>
            } else if (item.taskStatus == 'reopen' || item.taskStatus == 'unable to replicate') {
                return <Badge color='danger' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.taskStatus}</Badge>
            } else {
                return <Badge color='info' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.taskStatus}</Badge>
            }
        } else if (column.field == 'type' && (this.props.type === 'Tasks' || this.props.type === 'Timesheet')) {
            if (item.type == 'Regular' || item.type == 'New' || item.type == 'new') {
                return <Badge color='success' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.type}</Badge>
            } else if (item.type == 'Client Location') {
                return <Badge color='primary' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.type}</Badge>
            } else if (item.type == 'Work From Home') {
                return <Badge color='secondary' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.type}</Badge>
            } else if (item.type == 'defect' || item.type == 'Defect') {
                return <Badge color='danger' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.type}</Badge>
            } else {
                return <Badge color='info' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.type}</Badge>
            }
        } else if (column.field == 'priority') {
            if (item.priority == 'minor') {
                return <Badge color='success' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.priority}</Badge>
            } else if (item.priority == 'major') {
                return <Badge color='primary' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.priority}</Badge>
            } else if (item.priority == 'blocker') {
                return <Badge color='secondary' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.priority}</Badge>
            } else if (item.priority == 'critical') {
                return <Badge color='danger' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.priority}</Badge>
            } else {
                return <Badge color='info' pill style={dropdownOptionsStyle} className='text-capitalize'>{item.priority}</Badge>
            }
        }
        else if (column.field == 'Actions') {
            let details = JSON.parse(localStorage.getItem('loginCredentials'))
            return (
                <div className='row' style={{ justifyContent: 'center' }}>
                    {this.props.type == 'Employees' && details.role == "Admin" && this.state.loginRole == 'Admin' || (this.props.type == 'Employees' && details.role == 'HR') ?
                        <div>
                            <FontAwesomeIcon
                                className='genderIconAlignment'
                                color='white'
                                icon='check-circle'
                                data-toggle="tool-tip"
                                title="Leaves"
                                style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                onClick={() => this.openLeaveUpdateModal('leaveUpdate', item)} />
                        </div>
                        : null}
                    {(this.props.type == 'Leaves' && (item.status === '' || item.status === 'Rejected')) ||
                        (this.props.type == 'Tasks') ? null
                        :
                        <div>
                            {this.props.type == 'Issues' ?
                                <Link to={`/issueDetails/${item._id}`} >
                                    <FontAwesomeIcon
                                        className='genderIconAlignment'
                                        tooltip="issues"
                                        data-toggle="tool-tip"
                                        title="Edit"
                                        color='white'
                                        icon='edit'
                                        style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                    />
                                </Link> : this.props.type == 'Leaves' && item.status == "Approved" ? null :
                                    <FontAwesomeIcon
                                        className='genderIconAlignment'
                                        color='white'
                                        icon='edit'
                                        data-toggle="tool-tip"
                                        title="Edit"
                                        style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                        onClick={() => this.openFormModal('edit', item)} />
                            }
                            {(
                                (this.props.type === 'Attendance' &&
                                    !(this.state.loginRole === 'Admin' || this.state.loginRole === 'Director' ||
                                        this.state.loginRole === 'SuperAdmin' || this.state.loginRole === 'TeamLead')) ||
                                (this.props.type === 'Employees' && (details.role == 'Hr' || this.state.loginRole !== 'Admin')) ||
                                (this.props.type == 'Leaves' && item.status == "Approved" && this.state.loginRole !== 'Admin')
                            ) ? null : (
                                <FontAwesomeIcon
                                    className='genderIconAlignment'
                                    color='white'
                                    icon='trash-alt'
                                    data-toggle='tooltip'
                                    title='Delete'
                                    style={{ color: '#bf1725', width: '13', marginRight: '10px' }}
                                    onClick={() => this.deleteConfirmAction(item._id)}
                                />
                            )}

                            {(this.props.type == 'Attendance'
                                && (this.state.loginRole == 'Admin' || this.state.loginRole == 'Director' || this.state.loginRole == 'SuperAdmin')
                                && (item.type == 'Work From Home' || item.WFH == true)
                                && (item.wfhApproved == false)) ?
                                <FontAwesomeIcon className='genderIconAlignment'
                                    color='white'
                                    icon='check-circle'
                                    data-toggle="tool-tip"
                                    title="Approve"
                                    style={{ color: 'green', width: '15', }}
                                    onClick={() => this.openConfirmationModal(item, true, 'Approve')} />
                                : null
                            }
                        </div>}
                    {(this.props.type == 'Leaves' || this.props.type == 'Timesheet') && item.status == 'Pending' && this.state.showLeaveOptions ?
                        (this.state.loginRole == 'Admin' || this.state.loginRole == 'SuperAdmin' || (item.reportingTo == this.state.userReportingTo)) ?
                            <div style={{ marginLeft: '10px' }}>
                                <FontAwesomeIcon className='genderIconAlignment'
                                    color='white'
                                    icon='ban'
                                    data-toggle="tool-tip"
                                    title="Reject"
                                    style={{ color: '#024a88', width: '15', marginRight: '5px' }}
                                    onClick={() => this.openRejectModal(item)} />
                                <FontAwesomeIcon className='genderIconAlignment'
                                    color='white'
                                    icon='check-circle'
                                    data-toggle="tool-tip"
                                    title="Approve"
                                    style={this.state[`${item._id}`] ? { color: 'gray', width: '15', } : { color: 'green', width: '15', }}
                                    onClick={this.state[`${item._id}`] ? null : () => this.openConfirmationModal(item, 'Approved', 'Approve')} />
                            </div>
                            : null
                        : null}

                    {this.props.type == 'Projects' ?
                        <div style={{ marginLeft: '10px' }}>
                            <Link to={`/tasks/${item._id}`}>
                                <FontAwesomeIcon className='genderIconAlignment'
                                    color='white'
                                    icon='list-alt'
                                    data-toggle="tool-tip"
                                    title="Tasks"
                                    style={{ backgroundColor: '#024a88', width: '15', marginRight: '5' }}
                                />
                            </Link>

                            <Link to={`/issues/${item._id}`} >
                                <FontAwesomeIcon className='genderIconAlignment'
                                    tooltip="issues"
                                    color='white'
                                    icon='list'
                                    data-toggle="tool-tip"
                                    title="Issues"
                                    style={{ color: 'orange', width: '15', }}
                                />
                            </Link>
                        </div>
                        : null}

                    {/* Tasks Approval */}
                    {this.props.type == 'Tasks' ?
                        (this.state.loginRole == 'Admin' || this.state.loginRole == 'SuperAdmin' ||
                            this.state.loginRole == 'TeamLead') ?
                            <div>
                                <FontAwesomeIcon className='genderIconAlignment'
                                    color='white'
                                    icon='edit'
                                    data-toggle="tool-tip"
                                    title="Edit"
                                    style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                    onClick={() => this.openFormModal('edit', item)}
                                />
                                <FontAwesomeIcon
                                    className='genderIconAlignment'
                                    color='white'
                                    icon='trash-alt'
                                    data-toggle="tool-tip"
                                    title="Delete"
                                    style={{ color: '#bf1725', width: '13', }}
                                    onClick={() => this.deleteConfirmAction(item._id)}
                                />
                                {item.status == 'Pending' && (this.state.loginRole == 'Admin' || (item.projectId.teamLeadName === details.displayName)) ?
                                    <span style={{ marginLeft: '10px' }}>
                                        <FontAwesomeIcon className='genderIconAlignment'
                                            color='white'
                                            icon='ban'
                                            data-toggle="tool-tip"
                                            title="Reject"
                                            style={{ color: '#024a88', width: '15', marginRight: '5px' }}
                                            onClick={() => this.openRejectModal(item)}
                                        />
                                        <FontAwesomeIcon className='genderIconAlignment'
                                            color='white'
                                            icon='check-circle'
                                            data-toggle="tool-tip"
                                            title="Approve"
                                            style={{ color: 'green', width: '15', }}
                                            onClick={() => this.openConfirmationModal(item, 'Approved', 'Approve')}
                                        />
                                    </span>
                                    : null}
                            </div>
                            :
                            <div>
                                {/* {item.status == 'Pending' ? */}
                                <div>
                                    <FontAwesomeIcon className='genderIconAlignment'
                                        color='white' icon='edit'
                                        data-toggle="tool-tip"
                                        title="Edit"
                                        style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                        onClick={() => this.openFormModal('edit', item)} />
                                    <FontAwesomeIcon className='genderIconAlignment'
                                        color='white'
                                        icon='trash-alt'
                                        data-toggle="tool-tip"
                                        title="Delete"
                                        style={{ color: '#bf1725', width: '13', }}
                                        onClick={() => this.deleteConfirmAction(item._id)} />
                                </div>
                                {/* : null} */}
                            </div>
                        : null
                    }
                </div >
            )
        } else if (column.field === "sprintName") {
            let val = this.flattenArray(item[column.field]);
            return val
        } else if (column.field == 'photo') {
            return <div style={{ textAlign: 'center' }}>
                {item.photo ?
                    <img src={`${config.imgUrl}employee/${item.photo}`} className='imgStyles' /> :
                    <img src={configImages.defaultImg} className='imgStyles' />}
            </div>
        } else if (column.field == 'gender') {
            if (item.gender == 'Female') {
                return <div style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon className='genderIconAlignment' icon='female'
                        color='white' data-toggle="tool-tip" title="Female"
                        style={{ backgroundColor: 'red', width: '15', }} /></div>
            } else if (item.gender == 'Male') {
                return <div style={{ textAlign: 'center' }}>
                    <FontAwesomeIcon className='genderIconAlignment' icon='male'
                        color='white' data-toggle="tool-tip" title="Male"
                        style={{ backgroundColor: 'green', width: '15', }} /></div>
            }
        } else if (column.field == 'teamLead') {
            if (item && item[column.field] && item[column.field].displayName) {
                return item[column.field].displayName;
            }

        }
        else if (column.field == 'createdBy' && this.props.type != 'Employees' && this.props.type != 'Leaves' && this.props.type != 'Attendance') {
            column.field == 'createdBy.name';
            if (item[column.field] && item[column.field].name) {
                return <span className='text-capitalize'>{item[column.field].name}</span>
            }

        }
        else if (column.field == 'beforeLeaves' && this.props.type == 'Leave History') {
            if (item && item.leaveBalance && item.leaveBalance.beforeLeaves) {
                return item.leaveBalance.beforeLeaves;
            }
        } else if (column.field == 'afterLeaves' && this.props.type == 'Leave History') {
            if (item && item.leaveBalance && item.leaveBalance.afterLeaves) {
                return item.leaveBalance.afterLeaves;
            }
        } else if (column.field == 'updatedBy' && (this.props.type == 'Roles')) {
            if (item[column.field] && item[column.field].name) {
                return <span className='text-capitalize'>{item[column.field].name}</span>
            }
        } else if (column.field == 'startDate' || column.field == 'endDate' || column.field == 'dateOfBirth' || column.field == 'joinDate'
            || column.field == 'fromDate' || column.field == 'toDate' || column.field == 'date'
            || column.field == 'updated' || column.field == 'purchaseDate' || column.field == 'created') {
            if (this.props.type == 'Activities') {
                let date = dateFormats.formatDate(item[column.field], config.dateDmyFormat);
                // let date = (moment(item[column.field]).format('DD-MM-YYYY'));
                return date;
            } else {
                let date = dateFormats.formatDate(item[column.field], config.dateDmyFormat);
                // let date = (moment(item[column.field]).format('DD-MM-YYYY'));
                return date;
            }

        }
        // Attendance in and out time format || CodeByTej
        else if (column.field == 'inTime' || column.field == 'outTime') {
            let time = dateFormats.formatDate(item[column.field], config.timeFormat);
            return time;
        } else if (column.field == 'WFH') {
            if (item.WFH == true) {
                return 'WFH';
            }
            else {
                return '';
            }

        }
        // else if (column.field == 'workingHours') {
        //     if (this.props.type == 'Attendance') {
        //         if (item.isLessThan6Hours && (parseInt(item.workingHours) != 0)) {
        //             return (
        //                 <div style={{ backgroundColor: '#fdad8a' }}>
        //                     <span>{item.workingHours}</span>
        //                 </div>
        //             )
        //         }
        //         else if (item.isLessThan6Hours && (parseInt(item.workingHours) == 0)) {
        //             return (
        //                 <div style={{ backgroundColor: '#7dfcf5' }}>
        //                     <span>{item.workingHours}</span>
        //                 </div>
        //             )
        //         }
        //         else {
        //             return (
        //                 <div style={{ backgroundColor: null }}>
        //                     <span>{item.workingHours}</span>
        //                 </div>
        //             )
        //         }
        //     }
        // }
        else if (column.field == 'checkouts') {
            if (this.props.type == 'Attendance') {
                if (item && item.checkouts) {
                    return (item.checkouts.length);
                }
            }
        } else if (column.field == 'hours') {
            if (this.props.type == 'Timesheet') {
                if (this.state.daily == 'daily') {
                    return item.timesheetHours;
                }
                else {
                    return item[column.field];
                }
            }
        } else if (column.field == 'email' || column.field == 'companyEmail') {
            return item[column.field];
        } else {
            if (item[column.field]) {
                return <span className='text-capitalize'>{item[column.field]}</span>
            }
        }
    }

    getRolePermissions = () => {
        let screenPermissions = RolePermissions.screenPermissions(this.props.type);
        this.setState({ screenPermissions: screenPermissions })
    }

    // Handler triggers on confirming in Confirmation Modal 
    clickConfirm = () => {
        this.changeLeaveStatus(this.state.item, '', this.state.leaveStatus)
        this.closeConfirmationModal('yes');
    }

    rowClassName = (item) => {
        if (this.props.type == 'Public Holidays') {
            if (item.day == 'Sunday' || item.day == 'Saturday') {
                return (
                    { 'p-highlightHoliday': true }
                )
            }
        }
        if (this.props.type == 'Attendance' || this.props.type == 'Timesheet') {
            if (item.isHoliday === true || item.isWeekEnd === true) {
                return (
                    { 'p-highlightHoliday': (item.isHoliday === true || item.isWeekEnd === true) }
                )
            }
        }
        if (this.props.type == 'Timesheet' && this.state.daily != 'daily') {
            if (item && (item.createdBy && item.employeeName)) {
                if (item.employeeName !== item.createdBy.name) {
                    return ({ 'p-highlightOthersCreated': true })
                }
            }
        }
        if (this.props.type == 'Attendance') {
            if (item && item.workingHours < 6) {
                return ({ 'p-highlightForLessHours': true })
            }
            if (item && item.workingHours == 0) {
                return ({ 'p-highlightForZeroHours': true })
            }
        }
        return null;
    }

    // detailed view and daily view buttons in timesheet
    detailedview = async () => {
        let daily = ''
        let tablefields = await this.props.tableFields();
        // let filterCriteria = this.state.filterCriteria;
        // filterCriteria.criteria = [];
        await this.setState({ daily: daily, tablefields: tablefields })
        this.getTableListDataFromServer();
    }

    dailyview = async () => {
        let daily = 'daily'
        await this.setState({ daily: daily })
        let dailytablefields = this.state.tablefields;
        const result = dailytablefields.filter(item => item.field == 'Sno' || item.field == 'date' || item.field == 'hours');
        result.splice(1, 0, { textAlign: 'center', width: 100, field: 'employeeName', header: 'Employee', filter: true, sortable: false, });

        // let filterCriteria = this.state.filterCriteria;
        // filterCriteria.criteria = [];
        this.setState({ tablefields: result })
        this.getTableListDataFromServer();
    }

    // Data Table Date Interval Handler 
    handleDateInterval = (startDate, endDate) => {
        if (startDate) {
            this.setState({ startDate: startDate })
        }
        if (endDate) {
            this.setState({ endDate: endDate })
        }
    }

    getTimesheetReport = async () => {
        if (this.state.startDate && this.state.endDate) {
            await this.props.getTimesheetReports(this.state.startDate, this.state.endDate);
        }
    }

    searchInDateRange = (type) => {
        if (type === "clear") {
            let filtercriteria = this.state.filterCriteria
            let startDate = filtercriteria["criteria"].findIndex(obj => obj.key === "date" && obj.type === "gte" || obj.key === "fromDate" && obj.type === "gte")
            if (startDate !== -1) {
                filtercriteria["criteria"].splice(startDate, 1)
            }
            let endDate = filtercriteria["criteria"].findIndex(obj => obj.key === "date" && obj.type === "lte" || obj.key === "fromDate" && obj.type === "lte")
            if (endDate !== -1) {
                filtercriteria["criteria"].splice(endDate, 1)
            }
            this.setState({ startDate: startDate, endDate: endDate })
            if (this.datePickerRef) { this.datePickerRef.clearSelectedDates() }
            this.getTableListDataFromServer(filtercriteria);
        } else {
            if (this.state.startDate && this.state.endDate) {

                let filterCriteria = this.state.filterCriteria;
                let startDate = (moment(this.state.startDate).format('YYYY-MM-DD'));
                let endDate = (moment(this.state.endDate).format('YYYY-MM-DD'));
                filterCriteria['limit'] = 20;
                filterCriteria['page'] = 1;

                // Remove if already date filters are existed
                const defaultFilters = filterCriteria['criteria'].filter(
                    (criteria) => criteria.key !== 'date' && criteria.key !== 'fromDate'
                );
                // Clear existing date range criteria
                filterCriteria['criteria'] = defaultFilters;

                if (this.props.type === 'Leaves') {
                    filterCriteria['criteria'].push(
                        { 'key': 'fromDate', 'value': startDate, 'type': 'gte' },
                        { 'key': 'fromDate', 'value': endDate, 'type': 'lte' });
                } else {
                    filterCriteria['criteria'].push(
                        { 'key': 'date', 'value': startDate, 'type': 'gte' },
                        { 'key': 'date', 'value': endDate, 'type': 'lte' });
                }
                // this.setState({ filterCriteria: filterCriteria });
                this.getTableListDataFromServer(filterCriteria);
            } else {
                alert('no dates selected');
            }
        }
    }
    render() {
        const { t } = this.props;
        //main header(table && exporttocsv search buttons)
        let header =
            <div className="row">
                {/* global Search */}
                <div className={(this.props.type == 'Attendance' || this.props.type == 'Timesheet' || this.props.type == 'LateComers') ? "col-9" : this.props.type == 'Leaves' ? 'col-9' : 'col-6'}>
                    {(this.props.type == 'Attendance' || this.props.type == 'Timesheet' || this.props.type == 'Leaves' || this.props.type == 'LateComers') ?
                        <div className='row'>
                            <RenderIntervalDatePickerField
                                onRef={(ref) => (this.datePickerRef = ref)}
                                handleDateValueInParent={this.handleDateInterval}
                            />
                            <Button className="col-sm-1 mb-0" color="primary" onClick={this.searchInDateRange}>{t('dataTable.table_head.search')}</Button>
                            <Button className="col-sm-1 mb-0" color="primary" onClick={() => this.searchInDateRange("clear")}>{t('dataTable.table_head.clear')}</Button>
                            {
                                this.props.type === 'Timesheet' && (this.state.loginRole === 'Admin' || this.state.loginRole === 'TeamLead') ?
                                    <>
                                        <Button className="col-sm-2 mb-0 ml-0" color="primary" onClick={() => this.getTimesheetReport()}>
                                            <FontAwesomeIcon icon='file' className='ml-1' />{t('dataTable.table_head.reports')}</Button>
                                    </>
                                    : null
                            }
                            {
                                this.props.type == 'Leaves' && (this.state.leaveBalance || this.state.leaveBalance === 0) ?
                                    <>
                                        <div className="col-3 pt-1" > <p><b>{t('dataTable.table_head.leave_bal')}</b>:  {this.state.leaveBalance}</p></div>
                                    </>
                                    : null
                            }
                        </div>
                        : null}
                </div>
                <div className={(this.props.type == 'Attendance' || this.props.type == 'Timesheet' || this.props.type == 'LateComers') ? "col-3" : this.props.type == 'Leaves' ? ((this.state.leaveBalance >= 0 || this.state.leaveBalance <= 0) ? "col-3" : !this.state.leaveBalance ? 'col-3' : null) : 'col-6'}>
                    <div className='serachAlignment float-right' >
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                < i className="pi pi-search" ></i >
                            </span>
                            <InputText
                                type="search"
                                name='globalSearch'
                                id='globalSearch'
                                onInput={(e) => this.globalSearch(e.target.value)}
                                placeholder={this.props.globalSearch ? this.props.globalSearch : t('dataTable.table_head.ph_globalSearch')} size="25" />
                        </div>
                    </div>
                </div>
            </div >
        return (
            <Row className="mx-0" >
                <Col md={3}>
                    <h3 className="page-title">
                        {this.props.type}
                    </h3>
                </Col>
                <Col md={9} className='text-right'>
                    <ButtonToolbar className="form__button-toolbar" style={{ justifyContent: 'flex-end' }} >
                        {/* Buttons for daily view */}
                        {this.props.type == 'Timesheet' ? <Button color="primary" onClick={this.dailyview}>{t('dataTable.title_bar.daily_view')}</Button> : null}
                        {this.props.type == 'Timesheet' ? <Button color="primary" onClick={this.detailedview} >{t('dataTable.title_bar.detailed_view')}</Button> : null}

                        {/* Button for task screen, displaying filtered data of that employee */}
                        {this.props.type === 'Tasks' ?
                            <Button color="primary" onClick={this.myTaskFilters}>{t('dataTable.title_bar.my_tasks')}</Button>
                            : null}
                        {/* button for downloading formatted monthly report data */}
                        {this.props.type === 'Employees' && (this.state.loginRole === 'HR' || this.state.loginRole === 'Admin') ?
                            <Button color="primary" className='ml-auto' onClick={this.props.getMonthlyReport}>
                                <FontAwesomeIcon icon='file' className='ml-1' />{t('dataTable.title_bar.monthly_report')}
                            </Button>
                            : null
                        }
                        {/* Export to csv */}
                        {this.props.type != 'Presentations' ?
                            <Button color="primary" className={this.props.type !== 'Tasks' && this.props.type !== 'Employees' ? 'ml-auto' : ''} onClick={this.openCsvFieldSelectModal}>
                                <FontAwesomeIcon icon='file' className='ml-1' />{t('dataTable.title_bar.export_csv')}
                            </Button>
                            : null
                        }

                        {this.state.exportData ? <CSVLink
                            headers={this.state.exportHeaders ? this.state.exportHeaders : null}
                            data={this.state.exportData}
                            filename={`${this.props.type}.csv`}
                            className="hidden text-white"
                            // onClick={!this.state.exportData ? '' : null}
                            ref={(r) => this.csvLink = r}
                            target="_blank" >  </CSVLink> : null}


                        {this.props.sample ? (
                            (this.props.type === 'Public Holidays' && this.state.loginRole !== 'Admin' && this.state.loginRole !== 'Director') || this.props.type === 'Clients' ||
                            this.props.type === 'DailyStatus' || (this.props.type == 'Employees' && this.state.loginRole == 'TeamLead')
                        ) ? null : (
                            <Button color="primary" onClick={this.bulkUploadMoadal}>
                                <FontAwesomeIcon icon='upload' onClick={this.bulkUploadMoadal} />
                                {t('dataTable.title_bar.bulk_upload')}
                            </Button>
                        ) : null}

                        {/* Add button */}
                        {this.state.screenPermissions == 'edit' && this.props.type != 'Attendance' && this.props.type != 'Activities' ?
                            <Button color="primary" disabled={this.state.disablebutton} onClick={() => this.openFormModal('add')}>{t('dataTable.title_bar.add')}</Button>
                            : null}
                        {/* {this.state.screenPermissions === 'edit' &&
                            this.props.type !== 'Attendance' &&
                            this.props.type !== 'Activities' &&
                            this.props.formType === 'DailyStatus' &&
                            this.props.type === 'add' ?
                            <Button color="primary" disabled={isDisable} onClick={() => this.openFormModal('add')}>{t('dataTable.title_bar.add')}</Button>
                            : null} */}


                        {/* Add Client button */}
                        {/* {this.state.screenPermissions == 'edit'
                            && this.props.type == 'Employees' ?
                            <Button color="primary" onClick={() => this.openForm
                                Modal('addClient')}>Add Client</Button>
                            : null
                        } */}

                        {/* Delete button */}
                        {this.props.type == 'Employees' && this.state.loginRole !== 'TeamLead' && this.state.loginRole !== 'HR' && this.state.loginRole !== 'Director' ?
                            <Button color="primary" onClick={() => this.deleteConfirmAction('', 'delete')}>{t('dataTable.title_bar.delete')}</Button>
                            : null}

                        {/* Actions Dropdown for task screen for multi accept & reject tasks */}
                        {this.props.type === 'Tasks' && this.getLoginRole() ?
                            <Dropdown
                                style={{ minWidth: "100px", height: "100%", lineHeight: 1.25, textAlign: 'center' }}
                                name="actions"
                                value={this.state.actions}
                                disabled={
                                    this.state.selectedRows &&
                                        this.state.selectedRows.length > 0
                                        ? false
                                        : true
                                }
                                options={this.state.actionsTypes}
                                placeholder={t("Actions")}
                                onChange={(e) => this.onActionsChange(e)}
                            />
                            : null}

                        {this.props.type == 'DailyStatus' ?
                            <Button color="primary" onClick={() => this.getDailyForm('edit')}>{t('dataTable.title_bar.edit')}</Button>
                            : null}

                        <Button
                            type="button"
                            onClick={() => this.getTableListDataFromServer(this.state.filterCriteria, 'refresh')} >
                            <FontAwesomeIcon icon='sync-alt' className='refreshIcon ml-1' />
                        </Button>
                    </ButtonToolbar>
                </Col>

                {
                    (this.props.type == 'Attendance') ?
                        <Col md={12} className='text-left' style={{ fontSize: '13px', marginBottom: '13px' }}>
                            <span className='colorLegend' style={{ backgroundColor: '#fbff00' }}></span>{t('dataTable.color_legend.h_w')}
                            <span className='colorLegend' style={{ backgroundColor: '#fdad8a', marginLeft: '40px' }}></span>{t('dataTable.color_legend.wh')} &lt; 6
                            <span className='colorLegend' style={{ backgroundColor: '#7dfcf5', marginLeft: '40px' }}></span>{t('dataTable.color_legend.wh')} = 0
                        </Col>
                        : (this.props.type == 'Timesheet') ?
                            <Col md={12} className='text-left' style={{ fontSize: '13px', marginBottom: '13px' }}>
                                <span className='colorLegend' style={{ backgroundColor: '#fbff00' }}></span>{t('dataTable.color_legend.h_w')}
                                <span className='colorLegend' style={{ backgroundColor: '#6db6be', marginLeft: '40px' }}></span>{t('dataTable.color_legend.reported_by')}
                            </Col>
                            : null
                }

                <Col className="mb-4">
                    <Loader loader={this.state.isLoading} />

                    <DataTable
                        rowClassName={this.rowClassName}
                        ref={(el) => this.dataTableRef = el}
                        value={this.state.allUsersData}
                        header={header}
                        totalRecords={this.state.totalRecordsLength}
                        paginator={false}
                        lazy={true}
                        resizableColumns={true}
                        columnResizeMode="expand"
                        onSort={this.state.daily == 'daily' ? this.sortHours : this.sortChange}
                        globalFilter={this.state.globalFilter}
                        filters={this.state.filters}
                        onFilter={this.onFilterChange}
                        scrollable={true}
                        selection={this.props.type == 'Employees' || this.props.type == 'Issues' || this.props.type == 'Tasks' ? this.state.selectedRows : false}
                        onSelectionChange={e => this.selectRowsToDelete(e)}
                        scrollHeight="1000px" style={{ marginTop: '0px', }}
                        emptyMessage={this.state.isLoading ? configMessages.loading : configMessages.noRecords}
                        sortMode="single"

                        metaKeySelection={false}
                        stateStorage="local"
                        stateKey="tablestatedemo-local"
                    // loading={this.state.isLoading}
                    >
                        {this.state.tablefields && this.state.tablefields.length > 0 ?
                            this.state.tablefields.map((item, i) => {
                                return <Column key={item.field + i}
                                    style={{
                                        maxwidth: item.width, padding: '4px',
                                    }}
                                    bodyStyle={{
                                        // backgroundColor: (item.field == 'workingHours') ? this.state.whcolor : null,
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap', textAlign: item.textAlign ? item.textAlign : 'left'
                                    }}
                                    selectionMode={item.selectionMode}
                                    field={item.field}
                                    header={t(item.header)}
                                    body={this.changeFieldValues}
                                    headerStyle={{ padding: '4px', fontWeight: '500', width: item.width, fontSize: 13, color: 'black', }}
                                    filter={item.filter ? true : false}
                                    sortable={item.sortable ? true : false}
                                    filterPlaceholder={item.placeholder ? t(item.placeholder) : t('tableFields.ph_search')}
                                    filterElement={item.filterElement ? item.field && (item.field == 'status' || item.field === 'taskStatus' || item.field == 'projectName' || item.field === 'priority') ?
                                        <MultiSelect style={{ width: '60px', minWidth: '100%', lineHeight: 1.15, height: '30px' }}
                                            appendTo={document.body}
                                            name={item.field}
                                            placeholder={t('tableFields.ph_empty')}
                                            maxSelectedLabels={2}
                                            selectedItemsLabel='All'
                                            value={this.state[item.field] ? this.state[item.field] : item.defaultValues ? item.defaultValues : null}
                                            options={item.filterElement}
                                            onChange={(e) => this.onFilterChange(e, 'dropdownFilter')} /> :
                                        <Dropdown style={{ width: '60px', minWidth: '100%', lineHeight: 1.15 }}
                                            appendTo={document.body}
                                            key={item.filterElement}
                                            name={item.field}
                                            value={this.state[item.field] ? this.state[item.field] : null}
                                            options={item.filterElement}
                                            onChange={(e) => this.onFilterChange(e, 'dropdownFilter')} />
                                        : null}
                                />
                            })
                            : null}
                    </DataTable>

                    <Row className="paginationcss">
                        <Col className="pr-0  col-9">
                            <Paginator
                                totalRecords={this.state.totalRecordsLength}
                                first={this.state.first}
                                rows={this.state.rows}
                                rowsPerPageOptions={[5, 10, 15, 20, 30]}
                                onPageChange={(e) => this.onPageChange(e, this.state.filterCriteria)}
                                // pageLinkSize={5}
                                style={{ padding: 10, backgroundColor: 'rgba(0,0,0,.03)', border: 'none' }}
                            >
                            </Paginator>
                        </Col>
                        <Col className="col-3 pl-0 pt-3 text-center"
                            style={{ backgroundColor: 'White', border: 'none' }}
                        >
                            <span style={{ fontSize: "15px", fontFamily: "Open Sans,Helvetica Neue,sans-serif" }}>{t('dataTable.total_records')}{this.state.totalRecordsLength}</span>
                        </Col>
                    </Row>
                </Col>


                {/* displaying add edit form */}
                {
                    this.state.isOpenFormModal || this.state.setFormRef ?
                        <FormModal
                            onRef={(ref) => (this.formModalRef = ref)}
                            openFormModal={this.state.isOpenFormModal}
                            closeFormModal={this.closeFormModal}
                            closeRejectModal={this.closeRejectModal}
                            type={this.props.type}
                            formName={this.props.formName}
                            formType={this.state.formType}
                            formFields={this.props.formFields}
                            getTableListDataFromServer={this.getTableListDataFromServer}
                            apiUrl={this.props.type == 'Clients' ? 'employees' : this.state.daily == 'daily' ? "timesheet" : this.state.apiUrl}
                            role={this.state.loginRole}
                            data={this.state.getData}

                        /> : null
                }
                <RolesModal
                    onRef={(ref) => (this.roleModalRef = ref)}
                    openRolesModal={this.state.isOpenRolesModal}
                    closeRolesModal={this.closeRolesModal}
                    type={this.props.type}
                    formType={this.state.formType}
                    getDataFromServer={this.getTableListDataFromServer}
                    apiUrl={this.state.apiUrl}
                />

                {/* Leave update balance model */}
                <LeaveBalanceModal
                    OpenLeaveBalanceModal={this.state.isOpenLeaveBalanceModal}
                    closeLeaveUpdateModal={this.closeLeaveUpdateModal}
                    leavedata={this.state.leavedata}
                    getTableListDataFromServer={this.getTableListDataFromServer}
                />

                {/* displaying row COntent */}
                {this.state.openViewModal ?
                    < ViewModal
                        type={this.props.type}
                        openViewModal={this.state.openViewModal}
                        rowData={this.state.rowData}
                        formFields={this.props.formFields}
                        onRef={(ref) => (this.viewModalRef = ref)}
                        closeViewModal={this.closeViewModal}
                        idForProjectList={this.state.idForProjectList}
                    /> : null}

                {/* delete Selected row modal */}
                <DeleteRowModal
                    openDeleteModal={this.state.openDeleteModal}
                    closeDeleteModal={this.closeDeleteModal}
                    deleteSelectedRow={this.deleteSelectedRow} />

                {
                    this.props.sample ? <BulkUploadModal
                        type={this.props.bulkapi}
                        sample={this.props.sample}
                        openBulkUploadModal={this.state.openBulkUploadModal}
                        closeBulkModal={this.closeBulkModal} /> : null
                }

                <RejectModal
                    formType={this.state.formType}
                    onRef={(ref) => (this.rejectRef = ref)}
                    openRejectModal={this.state.openRejectModal}
                    closeRejectModal={this.closeRejectModal}
                    changeLeaveStatus={this.changeLeaveStatus}
                    isDropdownValue={this.state.isDropdownValue} />

                {
                    this.state.openConfirmationModal ?
                        <ConfirmationModal
                            formType={this.state.formType}
                            onRef={(ref) => (this.confirmRef = ref)}
                            openConfirmationModal={this.state.openConfirmationModal}
                            closeConfirmationModal={this.closeConfirmationModal}
                            confirm={this.clickConfirm}
                            text={this.state.confirmText}
                        // confirm={this.changeLeaveStatus(this.state.item,'',this.state.leaveStatus)}
                        /> : null
                }

                {/* Modal to select fields to export in CSV file ||CodeByTej */}
                {
                    this.state.isOpenCsvFieldSelectModal ?
                        <CsvFieldSelectModal
                            isOpenCsvFieldSelectModal={this.state.isOpenCsvFieldSelectModal}
                            closeCsvFieldSelectModal={this.closeCsvFieldSelectModal}
                            tableFields={this.state.tablefields}
                            type={this.props.type}
                        />
                        : null
                }
            </Row >
        );
    }
}

const dropdownOptionsStyle = {
    padding: 5, fontSize: 11, fontWeight: '500', color: 'white'
}

export default withTranslation('common')(DataTables);
