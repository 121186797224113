import React, { PureComponent } from 'react';
import {
  Card, CardBody, Col, Button, ButtonToolbar,
} from 'reactstrap';
import PropTypes from 'prop-types';
import renderDatePickerField from '../../../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import validate from '../../Profile/components/validate';

import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { load as loadAccount } from './../../../../redux/reducers/commonReducer';


import renderAutoComplete from '../../../../containers/Form/components/AutoComplete';
import fetch from '../../../../config/service';
const renderField = ({
  input, placeholder, type, isDisable,meta: { touched, error },
  
}) => (
  
    <div className="form__form-group-input-wrap">
    <input {...input} disabled={isDisable} placeholder={placeholder} type={type} />
      {touched && error && <span className="form__form-group-error">{error}</span>}
    </div>
  );



renderField.propTypes = {
  input: PropTypes.shape().isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
};

renderField.defaultProps = {
  placeholder: '',
  meta: null,
  type: 'text',
  isDisable:false
};

class Project extends PureComponent {
  static propTypes = {
    t: PropTypes.func,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    pristine: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
  }
 
  componentDidMount(){
    this.getProjectData();
  }


   // get data from server based on Id
   getProjectData = async () => {
    if (localStorage.getItem('loginCredentials')) {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        let Url = 'projects/' + '5d4054d32550f77e460ab29e';
        fetch('GET', Url, '')
            .then(async (response) => {
                if (response && response.details) {
                  this.props.load(response.details,'project');
                } else if (response && response.errorMessage) {
                }
            }).catch((err) => {
                return err;
            });

    } else {
        return;
    }
}

  getFileName = (file) => {
    this.setState({ file: file });
  }

  render() {
    const { handleSubmit, reset, t } = this.props;
   return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Project</h5>
            </div>
            <form className="form" onSubmit={handleSubmit}>
              <div className="form__half">
                <div className="form__form-group">
                  <span className="form__form-group-label">Project</span>
                  <div className="form__form-group-field">
                    <Field
                      name="name"
                      component={renderField}
                      type="text"
                      disabled />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Start Date</span>
                  <div className="form__form-group-field">
                    <Field
                      name="startDate"
                      component={renderDatePickerField}
                      // type="text"
                      // placeholder="First Name"
                    />
                    <div className="form__form-group-icon">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Status</span>
                  <div className="form__form-group-field">
                    <Field
                      name="status"
                      component={renderField}
                      type="text"
                      placeholder="status"
                      disabled
                    />
                  </div>
                </div>

               

                <div className="form__form-group">
                  <span className="form__form-group-label">Description</span>
                  <div className="form__form-group-field">
                    <Field
                      name="description"
                      component="textarea"
                      type="text"
                      placeholder="write you description"
                    />
                  </div>
                </div>
              </div>
              <div className="form__half">
              <div className="form__form-group">
                  <span className="form__form-group-label">Team Size</span>
                  <div className="form__form-group-field">
                    <Field
                      name="teamSize"
                      component={renderField}
                      type="text"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">End Date</span>
                  <div className="form__form-group-field">
                    <Field
                      name="endDate"
                      component={renderDatePickerField}
                    />
                    <div className="form__form-group-icon">
                      <CalendarBlankIcon />
                    </div>
                  </div>
                </div>
              

                <div className="form__form-group">
                  <span className="form__form-group-label">Team Lead</span>
                  <div className="form__form-group-field">
                    <Field
                      name="teamLead"
                      component={renderAutoComplete}
                      type="text"
                      placeholder="Team Lead"
                      searchField='displayName'
                      searchApi='employees'
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">Team Members</span>
                  <div className="form__form-group-field">
                    <Field
                      name="assignEmployees"
                      component={renderAutoComplete}
                      type="text"
                      placeholder="Team Members"
                      searchField='displayName'
                      searchApi='employees'
                      multiple={true}
                    />
                  </div>
                </div>
               

                <ButtonToolbar className="form__button-toolbar">
                  <Button type="button" >
                    Cancel
              </Button>
                  <Button color="primary" type="submit">Submit</Button>
                </ButtonToolbar>
              </div>
            </form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
Project = reduxForm({
  form: "ProjectForm", // a unique identifier for this form
  enableReinitialize: true,
  validate
})(Project);

// You have to connect() to any reducers that you wish to connect to yourself
Project = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from commonData reducer
  }),
  { load: loadAccount } // bind commonData loading action creator
)(Project);

export default Project;