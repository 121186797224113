import React from 'react';
import TableRender from '../../CommonDataTable/TableRender';
import config from '../../../../config/config';
import RolePermissions from '../../CommonDataTable/Permissions';
import fetch from '../../../../config/service';

// config file
export default class EmployeesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getTableFields = async () => {
        const { t } = this.props;

        let statusTypes = [
            { label: 'Pending', value: 'Pending' },
            { label: 'Approved', value: 'Approved' },
            { label: 'Rejected', value: 'Rejected' },
        ];
        let statusValue = ['Pending', 'Approved', 'Rejected'];

        let data = [
            { textAlign: 'center', width: 50, field: 'Sno', header: 'leaves.table_fields.s_no', filter: false, sortable: false, },
            { textAlign: 'center', width: 90, field: 'companyEmpId', header: 'leaves.table_fields.emp_id', filter: true, sortable: true, placeholder: 'leaves.table_fields.ph_search' },
            { textAlign: 'left', width: 150, field: 'employeeName', header: 'leaves.table_fields.employee', filter: true, sortable: true, placeholder: 'leaves.table_fields.ph_search' },
            { textAlign: 'center', width: 95, field: 'leaveType', header: 'leaves.table_fields.leave_type', filter: true, sortable: true, placeholder: 'leaves.table_fields.ph_search' },
            { textAlign: 'center', width: 105, field: 'reportingToName', header: 'leaves.table_fields.reporting_to', filter: true, sortable: true, placeholder: 'leaves.table_fields.reporting_to' },
            { textAlign: 'left', width: 130, field: 'reason', header: 'leaves.table_fields.reason', placeholder: 'leaves.table_fields.ph_search' },
            { textAlign: 'center', width: 50, field: 'numberOfDays', header: 'leaves.table_fields.no_of_days', sortable: false, },
            { textAlign: 'center', width: 90, field: 'created', header: 'leaves.table_fields.applied_date', filter: true, sortable: true, placeholder: config.dateDmyFormat },
            { textAlign: 'center', width: 90, field: 'fromDate', header: 'leaves.table_fields.from_date', filter: true, sortable: true, placeholder: config.dateDmyFormat },
            { textAlign: 'center', width: 90, field: 'toDate', header: 'leaves.table_fields.to_date', filter: true, sortable: false, placeholder: config.dateDmyFormat },
            { textAlign: 'center', width: 75, field: 'status', header: 'leaves.table_fields.status', filterElement: statusTypes, filter: true, sortable: false, defaultValues: statusValue },
            { textAlign: 'center', width: 90, field: 'Actions', header: 'leaves.table_fields.actions', filter: false, sortable: false },
        ];

        let screenPermissions = RolePermissions.screenPermissions('Leaves');
        if (screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        const { t } = this.props;
        let LeaveType = [
            { label: 'Casual Leave', value: 'Casual Leave' },
            { label: 'Sick Leave', value: 'Sick leave' },
        ];
        let leaveDay = [
            { label: 'Half Day', value: 'Half Day' },
            { label: 'Full Day', value: 'Full Day' },
        ];
        return ([
            { required: true, value: '', type: 'autoComplete', name: 'employeeName', label: t('leaves.form_fields.name'), id: 'name', searchApi: 'employees', searchField: 'displayName', placeholder: t('leaves.form_fields.ph_name'), otherField: 'employeeName', isShowItemTemplate: true },
            { required: true, value: '', type: 'dropDown', name: 'leaveType', label: t('leaves.form_fields.leave_type'), id: 'leaveType', options: LeaveType, placeholder: 'Select' },
            { required: true, value: '', type: 'dropDown', name: 'leaveDay', label: t('leaves.form_fields.leave_day'), id: 'leaveDay', options: leaveDay, placeholder: 'Select' },
            { required: true, value: '', type: 'date', name: 'fromDate', label: t('leaves.form_fields.from_date'), id: 'fromDate', placeholder: 'dd/mm/yyyy' },
            { required: true, value: '', type: 'date', name: 'toDate', label: t('leaves.form_fields.to_date'), id: 'toDate', placeholder: 'dd/mm/yyyy' },
            { required: true, value: '', type: 'textarea', name: 'reason', label: t('leaves.form_fields.reason'), id: 'reason' },
        ]);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <TableRender type='Leaves'
                    globalSearch={t('leaves.table.globalsearch')}
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                />
            </div>
        );
    }
}