import React from 'react';
import TableRender from '../../CommonDataTable/TableRender';
import config from '../../../../config/config';
import RolePermissions from '../../CommonDataTable/Permissions'
import { withTranslation } from 'react-i18next';

// config file
class ProjectsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount = () => {
        this.getRolePermissions();
    }

    getRolePermissions = () => {
        let screenPermissions = RolePermissions.screenPermissions('Projects');
        if (screenPermissions == 'edit') {
        }
    }

    getTableFields = () => {
        const { t } = this.props;
        let statusTypes = [
            { label: 'Active', value: 'Active' },
            { label: 'Pending', value: 'Pending' },
            { label: 'Closed', value: 'Closed' },
        ];
        let statusValue = ['Active', 'Pending'];
        let data = [
            { textAlign: 'center', width: 59, field: 'Sno', header: 'projects.table_fields.s_no', filter: false, sortable: false, },
            { textAlign: 'left', width: 130, field: 'name', header: 'projects.table_fields.name', filter: true, sortable: true, placeholder: 'projects.table_fields.ph_search' },
            { textAlign: 'center', width: 100, field: 'startDate', type: 'date', header: 'projects.table_fields.start_date', filter: true, sortable: true, placeholder: config.dateDmyFormat, },
            { textAlign: 'center', width: 60, field: 'teamSize', header: 'projects.table_fields.team_size', filter: false, sortable: true, },
            { textAlign: 'left', width: 100, field: 'teamLeadName', header: 'projects.table_fields.team_lead', filter: true, sortable: false, placeholder: 'projects.table_fields.ph_search' },
            { textAlign: 'left', width: 100, field: 'createdBy', header: 'projects.table_fields.created_by', filter: true, sortable: false, placeholder: 'projects.table_fields.ph_search' },
            { textAlign: 'center', width: 55, field: 'status', header: 'projects.table_fields.status', filterElement: statusTypes, filter: true, sortable: false, defaultValues: statusValue },
            { textAlign: 'center', width: 90, field: 'Actions', header: 'projects.table_fields.actions', filter: false, sortable: false },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Projects');
        if (screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        const { t } = this.props;
        let statusTypes = [
            { label: 'Active', value: 'Active' },
            { label: 'Pending', value: 'Pending', disabled: true },
            { label: 'Closed', value: 'Closed', disabled: true },
        ];
        let data = [
            { required: true, value: '', type: 'text', name: 'name', label: t('projects.form_fields.project_name'), id: 'projectName' },
            { required: true, value: '', type: 'text', name: 'prefix', label: t('projects.form_fields.project_key'), id: 'projectprefix' },
            { required: true, value: '', type: 'date', name: 'startDate', label: t('projects.form_fields.start_date'), id: 'startDate', placeholder: config.dateDashFormat },
            // { required: false, value: '', type: 'date', name: 'endDate', label: t('projects.form_fields.end_date'), id: 'lastName', minDate: new Date(), placeholder: config.dateDashFormat },
            { required: true, value: '', type: 'autoComplete', name: 'teamLead', label: t('projects.form_fields.team_lead'), id: 'teamLead', searchApi: 'employees', searchField: 'displayName', placeholder: t('projects.form_fields.ph_team_lead'), isMultiple: false, otherField: 'teamLeadName', filterField: 'role', filterValue: ['TeamLead', 'Director'] },
            { required: false, value: '', type: 'dropDown', name: 'status', label: t('projects.form_fields.status'), id: 'status', options: statusTypes, defaultValue: true },
            { required: true, value: '', type: 'autoComplete', name: 'assignEmployees', label: t('projects.form_fields.team_members'), id: 'teamMembers', searchApi: 'employees', searchField: 'displayName', isMultiple: true, },
            { required: false, value: '', type: 'text', name: 'clientName', label: t('projects.form_fields.client_name'), id: 'clientName' },
            { required: false, value: '', type: 'text', name: 'clientPhoneNumber', label: t('projects.form_fields.client_phone'), id: 'clientPhoneNumber' },
            { required: false, value: '', type: 'textarea', name: 'clientAddress', label: t('projects.form_fields.client_address'), id: 'clientAddress' },
            { required: false, value: '', type: 'textarea', name: 'description', label: t('projects.form_fields.desc'), id: 'description' },
            { required: false, value: '', type: 'file', name: 'file', label: t('projects.form_fields.attatch_file'), id: 'file', chooseLabel: t('projects.form_fields.choose_file'), isMultiple: true },
            {
                required: false, value: '', type: 'radio', name: 'showsTaskId', label: t('projects.form_fields.showsTaskId'), id: 'showsTaskId',
                options: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }],
            },
            // {
            //     name: 'showsTaskId',
            //     label: t('projects.form_fields.showsTaskId'),
            //     id: 'showsTaskId',
            //     type: 'ToggleButton',
            //     required: false,
            //     value: false
            // }
        ];
        return data;
    }

    render() {
        const { t } = this.props;
        return (
            <TableRender type='Projects'
                globalSearch={t('projects.globalsearch')}
                tableFields={this.getTableFields}
                formFields={this.getFormFields}
                formName='Project'
            />
        );
    }
}
export default withTranslation('common')(ProjectsTable);