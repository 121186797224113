import React from 'react';
import PropTypes from 'prop-types';

import { Input, FormFeedback, Button, Card, Col, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
// login styles
// import '../../../../scss/loginStyles.css';

// fecth method from service.js file
import fetch from '../../../../config/service';
import config from '../../../../config/config';

// show message 
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

import Loader from '../../../App/Loader';
import { withTranslation } from 'react-i18next';
// config file
class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.buttonActionType = null;
        this.state = {
            email: '',
            validate: {
                currentPassword: false,
                newPassword: false,
                confirmPassword: false
            },
            isLoginSuccess: false,
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            isLoading: true
        };
    }
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    // on value change in input
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    // handle login user data
    handleUserPassword = () => {
        this.setState({ isLoading: true });
        let userData = localStorage.getItem('loginCredentials');
        userData = JSON.parse(userData);
        let userBody = {
            currentPassword: this.state.currentPassword,
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword
        }
        return fetch('POST', `employees/changePassword`, userBody)
            .then((response) => {
                if (response && response.respCode && response.respCode === 200) {
                    // display message
                    this.setState({ isLoading: false });
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    this.setState({ isLoading: false });
                    // display message
                    showToasterMessage(response.errorMessage, 'error');
                } else {
                    this.setState({ isLoading: false });
                }
                this.clearInputFields();
            }).catch((err) => {
                return err;
            });
    }

    // check input fields empty or not
    checkCredentials = () => {
        const { validate } = this.state
        if (!this.state.currentPassword) {
            validate.currentPassword = 'has-danger';
        }
        if (!this.state.newPassword) {
            validate.newPassword = 'has-danger';
        }
        if (!this.state.confirmPassword) {
            validate.confirmPassword = 'has-danger'
        }
        this.setState({ validate })
    }

    // validate password
    validatePassword = (e) => {
        let validate = this.state.validate;
        if (e.target.value.length > 6) {
            var test = config.passwordRegex.test(e.target.value);
            if (test) {
                validate[e.target.name] = 'has-success';
            } else {
                validate[e.target.name] = 'has-danger';
            }
            this.setState({ validate: validate })
        }
    }

    // clear input data after submit password changes
    clearInputFields = () => {
        let validate = {
            currentPassword: false,
            newPassword: false,
            confirmPassword: false
        }
        this.setState({
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            validate: validate
        })
    }

    render() {
        const { t } = this.props;
        return (
            <div className="changePasswordCardStyle " >

                <Card className='changePasswordCard'>
                    <CardHeader style={{ fontSize: 20 }}>{t('change_pw.title')}</CardHeader>

                    <CardBody className='changePasswordCardBody'>
                        <div className='form form--horizontal'>
                            <Loader loader={this.state.isLoading} />
                            <div className="form__form-group">
                                <span className="form__form-group-label col-sm-3">{t('change_pw.current_pw')}</span>

                                <div className='form__form-group-field col-sm-6'>

                                    <Input className='inputLogin'
                                        type="password"
                                        name="currentPassword"
                                        valid={this.state.validate.currentPassword === 'has-success'}
                                        invalid={this.state.validate.currentPassword === 'has-danger'}
                                        value={this.state.currentPassword}
                                        onChange={(e) => {
                                            this.validatePassword(e)
                                            this.handleChange(e)
                                        }}
                                    />
                                    {!this.state.currentPassword ?
                                        <FormFeedback className='formErrorText' >{t('change_pw.curpw_error')}</FormFeedback> :
                                        null}
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className="form__form-group-label col-sm-3">{t('change_pw.new_pw')}</span>
                                <div className=' form__form-group-field col-sm-6'>
                                    <Input className='inputLogin'
                                        type="password"
                                        name="newPassword"
                                        valid={this.state.validate.newPassword === 'has-success'}
                                        invalid={this.state.validate.newPassword === 'has-danger'}
                                        value={this.state.newPassword}
                                        onChange={(e) => {
                                            this.validatePassword(e)
                                            this.handleChange(e)
                                        }}
                                    />
                                    {!this.state.newPassword ?
                                        <FormFeedback className='formErrorText'>{t('change_pw.newpw_error')}</FormFeedback> :
                                        null}
                                </div>
                            </div>
                            <div className="form__form-group">
                                <span className=" form__form-group-label col-sm-3">{t('change_pw.conf_pw')}</span>
                                <div className=' form-group-field col-sm-6'>
                                    <Input className='inputLogin'
                                        type="password"
                                        name="confirmPassword"
                                        valid={this.state.validate.confirmPassword === 'has-success'}
                                        invalid={this.state.validate.confirmPassword === 'has-danger'}
                                        value={this.state.confirmPassword}
                                        onChange={(e) => {
                                            this.validatePassword(e)
                                            this.handleChange(e)
                                        }}
                                    />
                                    {!this.state.confirmPassword && this.state.newPassword ?
                                        <FormFeedback className='formErrorText' >{t('change_pw.reEnter_error')}</FormFeedback> :
                                        null}
                                </div>
                            </div>
                        </div>
                        <Button className="loginBtn col-sm-2 mt-4 " style={{ marginLeft: '200px' }} color='primary'
                            onClick={this.state.currentPassword && this.state.newPassword && this.state.confirmPassword
                                ? this.handleUserPassword : this.checkCredentials}>{t('change_pw.submit')}
                        </Button>
                        <Button className="loginBtn col-sm-2 mt-4 " style={{ marginLeft: '50px' }} color='primary' outline
                            onClick={() => this.clearInputFields()}>{t('change_pw.reset')}
                        </Button>
                        <Link to={'/Dashboard'}>
                            <Button className="loginBtn col-sm-2 mt-4 " style={{ marginLeft: '50px' }} color='primary'
                            >{t('change_pw.back')}</Button>
                        </Link>


                    </CardBody>

                </Card>
            </div >

        );
    }
}
export default withTranslation('common')(ChangePassword);