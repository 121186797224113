/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  PieChart, Pie, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Panel from '../../../shared/components/Panel';



const style = {
  left: 0,
  width: '100%',
  lineHeight: '24px',
  position: 'relative',
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend row">
    {
      payload.map((entry, index) => (
        <li className="col-6" key={`item-${index}`}><span style={{ backgroundColor: entry.color }} />{entry.value}</li>
      ))
    }
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    vslue: PropTypes.string,
  })).isRequired,
};

const Issues = ({ projectName, isssues, title }) => (
  <Panel
    lg={6}
    xl={4}
    md={12}
    title={title}
    subhead={projectName}
  >
    <div className="dashboard__visitors-chart">

      <ResponsiveContainer className="dashboard__chart-pie" width="100%" height={220}>
        <PieChart className="dashboard__chart-pie-container">
          <Tooltip />
          <Pie data={isssues} dataKey="value" cy={110} innerRadius={70} outerRadius={100} />
          <Legend layout="vertical" verticalAlign="bottom" wrapperStyle={style} content={renderLegend} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  </Panel>
);

Issues.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(Issues);
