import React from "react";
import TableRender from "../../CommonDataTable/TableRender";
import configImages from "../../../../config/configImages";
import RolePermissions from "../../CommonDataTable/Permissions";
import { withTranslation } from "react-i18next";

class ClientsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roleType: []
    };
  }

  getTableFields = async () => {
    const { t } = this.props;
    let statusTypes = [
      { label: t('clients.table_fields.labels.active'), value: 'Active' },
      { label: t('clients.table_fields.labels.pending'), value: 'Pending' },
      { label: t('clients.table_fields.labels.inactive'), value: 'Inactive' }
    ];
    let statusValue = ['Active', 'Pending', 'Inactive'];

    let data = [
      { textAlign: 'center', width: 40, selectionMode: 'multiple', field: 'Sno', filter: false, sortable: false, placeholder: 'clients.table_fields.ph_search' },
      { textAlign: 'left', width: 200, field: 'displayName', header: 'clients.table_fields.name', filter: true, sortable: true, placeholder: 'clients.table_fields.ph_search' },
      { textAlign: 'center', width: 60, field: 'employeeId', header: 'clients.table_fields.clientId', sortable: true },
      { textAlign: 'left', width: 115, field: 'phoneNumber', header: 'clients.table_fields.phone', filter: true, sortable: true, placeholder: 'clients.table_fields.ph_search' },
      { textAlign: 'left', width: 180, field: 'email', header: 'clients.table_fields.email', filter: true, sortable: true, placeholder: 'clients.table_fields.ph_search' },
      { textAlign: 'center', width: 80, field: 'status', header: 'clients.table_fields.status', filterElement: statusTypes, filter: true, sortable: false, defaultValues: statusValue },
      { textAlign: 'center', width: 90, field: 'Actions', header: 'clients.table_fields.actions', filter: false, sortable: false },
    ];
    let screenPermissions = RolePermissions.screenPermissions('Employees');
    if (screenPermissions != 'edit') {
      data.pop();
    }
    return [...data];
  };

  getFormFields = () => {
    const { t } = this.props;
    let statusTypes = [
      { label: t('clients.form_fields.labels.active'), value: 'Active' },
      { label: t('clients.form_fields.labels.pending'), value: 'Pending' },
      { label: t('clients.form_fields.labels.inactive'), value: 'Inactive' }
    ];
    let statusValue = ['Active', 'Pending', 'Inactive'];

    return ([
      { required: true, value: '', type: 'text', name: 'firstName', label: t('clients.form_fields.firstname'), id: 'firstName' },
      { required: true, value: '', type: 'text', name: 'lastName', label: t('clients.form_fields.lastname'), id: 'lastName' },
      { required: true, value: '', type: 'email', name: 'email', label: t('clients.form_fields.email'), id: 'companyemail', placeholder: 'name@domain.com' },
      { required: true, value: '', type: 'text', name: 'employeeId', label: t('clients.form_fields.clientId'), id: 'employeeId', },
      { required: true, value: '', type: 'text', name: 'phoneNumber', label: t('clients.form_fields.phone'), id: 'phoneNumber', maxLength: 10 },
      { required: false, value: '', type: 'text', name: 'skypeId', label: t('clients.form_fields.skypeId'), id: 'skypeId' },
      { required: false, value: '', type: 'textarea', name: 'address', label: t('clients.form_fields.address'), id: 'address' },
      { required: false, value: '', type: 'dropDown', name: 'status', label: t('clients.form_fields.status'), id: 'status', options: statusTypes, defaultValue: true },

    ]);
  }


  render() {
    const { t } = this.props;
    return (
      <div>
        <TableRender
          type='Clients'
          globalSearch='Name'
          tableFields={() => this.getTableFields()}
          formFields={this.getFormFields}
          sample={configImages.employeesSampleXL}
          bulkapi='clients'
        />
      </div>
    );
  }
}
export default withTranslation('common')(ClientsTable);