import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import InventoryPage from './components/Inventory';

const Inventory = ({ t }) => (
    <Container>
        <InventoryPage />
    </Container>
);

Inventory.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(Inventory);
