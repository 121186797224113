import config from './config';
import showToasterMessage from '../containers/UI/ToasterMessage/toasterMessage';

let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
};

// To get logged in user token
const getLoggedInuserToken = () => {
    let userData = localStorage.getItem('loginCredentials');
    userData = JSON.parse(userData);
    if (userData) {
        headers.Authorization = `Bearer ${userData.accessToken}`;
        headers.logggedInUserData = {
            email: userData.email,
            password: userData.password,
        }
    }
    return headers;
}

const fetchMethodRequest = (method, url, body, type, mul) => {
    if (url === 'auth/login') {
        return sendRequestToServer(method, url, body, headers);
    } else {
        let headers = getLoggedInuserToken();
        // upload method conditions, headers
        if (type && type == 'upload') {
            let formData = new FormData();
            if (body && !mul) {
                formData.append('file', body);
            }
            if (body && mul) { //for multiple file upload
                body.forEach(ele => {
                    formData.append('file', ele);
                })
            }
            if (headers.logggedInUserData) {
                delete headers.logggedInUserData;
            }
            body = {
                isUploadingImage: true,
                imageInfo: formData,
            }
        }
        return sendRequestToServer(method, url, body, headers)
            .then(response => {
                if (response) {
                    if (response.errorCode && response.errorCode === 9001) { // token expiry
                        return response;
                    } else {
                        return response;
                    }
                }
            })
            .catch((err) => {
                console.log('error ' + err)
            });
    }
}


const sendRequestToServer = (method, url, body, headers) => {
    let reqHeaders = { ...headers };

    if (reqHeaders && reqHeaders.logggedInUserData) {
        delete reqHeaders.logggedInUserData;
    }
    let isImageReqSent = false;
    let request;

    if (body && body.isUploadingImage) { // only for image upload
        isImageReqSent = true;
        request = fetch(`${config.apiUrl}${url}`, {
            method: method,
            headers: {
                'Authorization': `${reqHeaders.Authorization}`
            },
            body: body.imageInfo
        })

    }

    if (!isImageReqSent) { // send request for call except image upload 
        if (method === 'GET' || method === 'DELETE') {
            request = fetch(`${config.apiUrl}${url}`, { method: method, headers: reqHeaders })
        } else if (method === 'POST' || method === 'PUT') {
            request = fetch(`${config.apiUrl}${url}`, { method: method, headers: reqHeaders, body: JSON.stringify(body) })
        }
    }
    // console.log(request)

    return request.then(res => res.json())
        .then(responseJson => {
            // console.log(responseJson)
            return responseJson;
        }).catch(err => {
            console.log('err --- ', err);
            showToasterMessage(config.serverErrMessage, 'error');
        });
}
export default fetchMethodRequest;
