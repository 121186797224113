import React from 'react';
import { Card, CardBody, CardHeader, Table, Button, Modal, ModalBody, ButtonToolbar } from 'reactstrap';
import fetch from '../../../../config/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KpiEditModal from './editModal';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import { withTranslation } from 'react-i18next';

class KPI extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            kpiCategoryArray: '',
            kpiSubCategoryArray: '',
            subCategoryHeadng: "Sub Category",
            isFormModalOpen: false,
            rowData: '',
            type: '',
            value: '',
            formType: '',
            subIndex: '',
            categoryId: '',
            data: {},
            isSaveDisable: true
        };
    }
    componentDidMount = async () => {
        this.getKpiListFromServer();
    }

    //To Get All KPI Cattegories list from server
    getKpiListFromServer = async () => {
        return fetch('GET', 'kpi')
            .then(async (response) => {
                if (response && response.kpis) {
                    this.setState({ kpiCategoryArray: response.kpis })
                }
            })
    }

    //To Get sub category list when clicked on a category
    getSubCategory = async (index, item) => {
        let categoryId = item._id;
        this.setState({
            isSaveDisable: false,
            kpiSubCategoryArray: this.state.kpiCategoryArray[index].subCategory,
            subCategoryHeadng: this.state.kpiCategoryArray[index].category,
            categoryId: categoryId
        });
    }

    //To Open Form Modal to Add or Edit Category/Sub-category 
    openFormModal = async (item, type, formType, i) => {
        let data = {};
        if (type == 'Category') {
            data.category = item.category;
        }
        else {
            data.subCategory = item;
        }
        await this.setState({
            isFormModalOpen: true,
            rowData: item,
            type: type,
            formType: formType,
            subCategoryIndex: i,
            data: data
        });
        if (this.state.isFormModalOpen) {
            await this.formModalRef.getRowData(this.state.data);
        }
    }

    //To Close Form Modal
    closeFormModal = async () => {
        await this.setState({ isFormModalOpen: false })
        this.getKpiListFromServer();
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Card className='px-3'>
                    <CardHeader className='cardHeader'>
                        <div className="row m-0">
                            <div className='col-4 pl-0 pr-0' >
                                <h4 className='bold-text'>{t('kpi.title')}</h4>
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody className='cardHeader'>
                        <div className="row m-0 pb-3">
                            <div className='col-4 pl-0 pr-0 bold-text' style={{ fontSize: '14px' }} >
                                {t('kpi.full_title')}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <Table className="table--bordered" responsive hover>
                                    <thead className="kpiTable">
                                        <tr>
                                            <th style={{ width: 'auto', color: 'black', fontSize: '14px' }}>#</th>
                                            <th style={{ width: 'auto', color: 'black', fontSize: '14px' }}>{t('kpi.category')}</th>
                                            <th><Button style={{ margin: 0, zIndex: 'revert' }} onClick={() => this.openFormModal('', t('kpi.category'), t('kpi.add'))}>{t('kpi.add')}</Button></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.kpiCategoryArray && this.state.kpiCategoryArray.length > 0 ?
                                            this.state.kpiCategoryArray.map((item, index) => {
                                                return <tr key={index} >
                                                    <td style={{ width: 'auto', color: 'black' }}>{index + 1} </td>
                                                    <td style={{ width: 'auto', color: 'blue' }}
                                                        onClick={() => this.getSubCategory(index, item)}>
                                                        {item.category}
                                                    </td>
                                                    <td style={{ width: 'auto', color: 'black' }}>
                                                        <FontAwesomeIcon className='genderIconAlignment'
                                                            color='white' icon='edit' data-toggle="tool-tip" title={t('kpi.edit')}
                                                            style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                                            onClick={() => this.openFormModal(item, t('kpi.category'), t('kpi.edit'))}
                                                        />
                                                        <FontAwesomeIcon className='genderIconAlignment'
                                                            color='white' icon='trash-alt' data-toggle="tool-tip" title={t('kpi.delete')}
                                                            style={{ color: '#bf1725', width: '13', }}
                                                            onClick={() => this.openFormModal(item, t('kpi.category'), t('kpi.delete'))}
                                                        />
                                                    </td>
                                                </tr>
                                            }) : null}
                                    </tbody>
                                </Table>
                            </div>
                            <div className="col-5">
                                <Table className="table--bordered" responsive hover>
                                    <thead className="kpiTable">
                                        <tr>
                                            <th style={{ width: 'auto', color: 'black', fontSize: '14px' }}>#</th>
                                            <th style={{ width: 'auto', color: 'black', fontSize: '14px' }}>{this.state.subCategoryHeadng}-{t('kpi.traits')}</th>
                                            <th><Button style={{ margin: 0, zIndex: 'revert' }} disabled={this.state.isSaveDisable} onClick={() => this.openFormModal('', t('kpi.subcategory'), t('kpi.add'))}>{t('kpi.add')}</Button></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.kpiSubCategoryArray && this.state.kpiSubCategoryArray.length > 0 ?
                                            this.state.kpiSubCategoryArray.map((item, i) => {
                                                return <tr key={i}>
                                                    <td style={{ width: 'auto', color: 'black' }}>{i + 1} </td>
                                                    <td style={{ width: 'auto', color: 'black' }}>{item}</td>
                                                    <td style={{ width: 'auto', color: 'black' }}>
                                                        <FontAwesomeIcon className='genderIconAlignment'
                                                            color='white' icon='edit' data-toggle="tool-tip" title={t('kpi.edit')}
                                                            style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                                                            onClick={() => this.openFormModal(item, t('kpi.subcategory'), t('kpi.edit'), i)}
                                                        />
                                                        <FontAwesomeIcon className='genderIconAlignment'
                                                            color='white' icon='trash-alt' data-toggle="tool-tip" title={t('kpi.delete')}
                                                            style={{ color: '#bf1725', width: '13', }}
                                                            onClick={() => this.openFormModal(item, t('kpi.subcategory'), t('kpi.delete'), i)}
                                                        />
                                                    </td>
                                                </tr>
                                            }) : null}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </CardBody>
                </Card>

                <KpiEditModal
                    onRef={(ref) => (this.formModalRef = ref)}
                    isFormModalOpen={this.state.isFormModalOpen}
                    rowData={this.state.rowData}
                    subCategories={this.state.kpiSubCategoryArray}
                    closeFormModal={this.closeFormModal}
                    formType={this.state.formType}
                    type={this.state.type}
                    placeholder={this.state.type}
                    subCategoryIndex={this.state.subCategoryIndex}
                    categoryId={this.state.categoryId}
                    data={this.state.data}
                />
            </div >
        );
    }
}

export default withTranslation('common')(KPI);