import React from 'react';
import TableRender from '../../CommonDataTable/TableRender';
import config from '../../../../config/config';
import configImages from '../../../../config/configImages';
import RolePermissions from '../../CommonDataTable/Permissions';
import { withTranslation } from 'react-i18next';



// config file
class TasksTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }



    getTableFields = () => {
        const { t } = this.props;

        let priorityTypes = [
            // { label: t('tasks.table_fields.labels.all'), value: null },
            { label: t('tasks.table_fields.labels.blocker'), value: 'blocker' },
            { label: t('tasks.table_fields.labels.minor'), value: 'minor' },
            { label: t('tasks.table_fields.labels.major'), value: 'major' },
            { label: t('tasks.table_fields.labels.critical'), value: 'critical' }
        ];
        let priorityTypeValues = ['blocker', 'minor', 'major', 'critical']

        let issueType = [
            { label: t('tasks.table_fields.labels.all'), value: null },
            { label: t('tasks.table_fields.labels.new'), value: 'new' },
            { label: t('tasks.table_fields.labels.defect'), value: 'defect' },
        ]

        let statusTypes = [
            { label: t('tasks.table_fields.labels.pending'), value: 'Pending' },
            { label: t('tasks.table_fields.labels.active'), value: 'Active' },
            { label: t('tasks.table_fields.labels.inactive'), value: 'Inactive' },
            { label: t('tasks.table_fields.labels.completed'), value: 'Completed' }
        ]
        let statusValue = ['Pending', 'Active'];

        let taskStatusTypes = [
            { label: t('tasks.form_fields.labels.new'), value: 'new' },
            { label: t('tasks.form_fields.labels.in_development'), value: 'in development' },
            { label: t('tasks.form_fields.labels.development_completed'), value: 'development completed' },
            { label: t('tasks.form_fields.labels.ready_for_testing'), value: 'ready for testing' },
            { label: t('tasks.form_fields.labels.analysis_design'), value: 'analysis/design' },
            { label: t('tasks.form_fields.labels.resolved'), value: 'resolved' },
            { label: t('tasks.form_fields.labels.reopen'), value: 'reopen' },
            { label: t('tasks.form_fields.labels.review_completed'), value: 'review completed' },
            { label: t('tasks.form_fields.labels.need_clarification'), value: 'need clarification' },
            { label: t('tasks.form_fields.labels.onhold'), value: 'onhold' },
            { label: t('tasks.form_fields.labels.future_implementation'), value: 'future implementation' },
            { label: t('tasks.form_fields.labels.duplicate'), value: 'duplicate' },
            { label: t('tasks.form_fields.labels.moved_to_prod'), value: 'moved to prod' },
            { label: t('tasks.form_fields.labels.unable_to_replicate'), value: 'unable to replicate' },
            { label: t('tasks.form_fields.labels.not_an_issue'), value: 'not an issue' }
        ];
        let taskStatusValue = ['new', 'in development', 'development completed', 'ready for testing', 'analysis/design', 'resolved', 'reopen', 'onhold', 'future implementation', 'moved to prod', 'duplicate', 'unable to replicate', 'review completed', 'need clarification', 'not an issue'];


        let data = [
            { textAlign: 'center', width: 40, selectionMode: 'multiple', field: 'Sno', filter: false, sortable: false, placeholder: 'Search' },
            { textAlign: 'left', width: 80, field: 'taskID', header: 'tasks.table_fields.taskId', filter: true, sortable: false, },
            { textAlign: 'left', width: 120, field: 'name', header: 'tasks.table_fields.task_summary', filter: true, sortable: true, placeholder: 'tasks.table_fields.ph_search' },
            { textAlign: 'left', width: 100, field: 'sprintName', type: 'array', header: 'tasks.table_fields.sprint_name', filter: true, sortable: true, placeholder: 'tasks.table_fields.ph_search' },
            { textAlign: 'left', width: 100, field: 'projectName', header: 'tasks.table_fields.project', filter: true, sortable: true, placeholder: 'tasks.table_fields.ph_search' },
            { textAlign: 'left', width: 90, field: 'assignedToName', header: 'tasks.table_fields.assigned_to', filter: true, sortable: true, placeholder: 'tasks.table_fields.ph_search' },
            { textAlign: 'center', width: 52, field: 'estimatedHours', header: 'tasks.table_fields.estimated_hours', sortable: true, filter: false },
            { textAlign: 'center', width: 50, field: 'actualWorkingHours', header: 'tasks.table_fields.worked_hours', sortable: true, filter: false },
            { textAlign: 'center', width: 70, field: 'startDate', type: 'date', header: 'tasks.table_fields.start_date', filter: true, sortable: true, placeholder: config.dateDmyFormat, },
            { textAlign: 'center', width: 70, field: 'endDate', type: 'date', header: 'tasks.table_fields.end_date', filter: true, sortable: true, placeholder: config.dateDmyFormat, },
            { textAlign: 'left', width: 80, field: 'createdBy', header: 'tasks.table_fields.created_by', filter: true, sortable: false, placeholder: 'tasks.table_fields.ph_search' },
            { textAlign: 'center', width: 70, field: 'created', type: 'date', header: 'tasks.table_fields.created', filter: true, sortable: true, placeholder: config.dateDmyFormat, },
            { textAlign: 'center', width: 90, field: 'taskStatus', header: 'tasks.table_fields.task_status', filterElement: taskStatusTypes, filter: true, sortable: false, defaultValues: taskStatusValue },
            { textAlign: 'left', width: 55, field: 'priority', header: 'tasks.table_fields.priority', filterElement: priorityTypes, filter: true, sortable: false, defaultValues: priorityTypeValues },
            { textAlign: 'center', width: 55, field: 'type', header: 'tasks.table_fields.type', filterElement: issueType, filter: true, sortable: false },
            { textAlign: 'center', width: 60, field: 'status', header: 'tasks.table_fields.status', filterElement: statusTypes, filter: true, sortable: false, defaultValues: statusValue },
            { textAlign: 'center', width: 70, field: 'updated', type: 'date', header: 'tasks.table_fields.updated', filter: true, sortable: true, placeholder: config.dateDmyFormat, },
            { textAlign: 'center', width: 75, field: 'Actions', header: 'tasks.table_fields.actions', filter: false, sortable: false },


        ];

        let screenPermissions = RolePermissions.screenPermissions('Tasks');
        if (screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        const { t } = this.props;

        let taskStatusTypes = [
            { label: t('tasks.form_fields.labels.new'), value: 'new' },
            { label: t('tasks.form_fields.labels.in_development'), value: 'in development' },
            { label: t('tasks.form_fields.labels.development_completed'), value: 'development completed' },
            { label: t('tasks.form_fields.labels.ready_for_testing'), value: 'ready for testing' },
            { label: t('tasks.form_fields.labels.analysis_design'), value: 'analysis/design' },
            { label: t('tasks.form_fields.labels.resolved'), value: 'resolved' },
            { label: t('tasks.form_fields.labels.reopen'), value: 'reopen' },
            { label: t('tasks.form_fields.labels.review_completed'), value: 'review completed' },
            { label: t('tasks.form_fields.labels.need_clarification'), value: 'need clarification' },
            { label: t('tasks.form_fields.labels.onhold'), value: 'onhold' },
            { label: t('tasks.form_fields.labels.future_implementation'), value: 'future implementation' },
            { label: t('tasks.form_fields.labels.duplicate'), value: 'duplicate' },
            { label: t('tasks.form_fields.labels.moved_to_prod'), value: 'moved to prod' },
            { label: t('tasks.form_fields.labels.unable_to_replicate'), value: 'unable to replicate' },
            { label: t('tasks.form_fields.labels.not_an_issue'), value: 'not an issue' }
        ];
        let statusTypes = [
            { label: t('tasks.table_fields.labels.pending'), value: 'Pending' },
            { label: t('tasks.table_fields.labels.active'), value: 'Active' },
            { label: t('tasks.table_fields.labels.inactive'), value: 'Inactive' },
            { label: t('tasks.table_fields.labels.completed'), value: 'Completed' }
        ]
        let issueType = [
            { label: t('tasks.table_fields.labels.new'), value: 'new' },
            { label: t('tasks.table_fields.labels.defect'), value: 'defect' },
        ]

        let priorityTypes = [
            { label: t('tasks.table_fields.labels.blocker'), value: 'blocker' },
            { label: t('tasks.table_fields.labels.minor'), value: 'minor' },
            { label: t('tasks.table_fields.labels.major'), value: 'major' },
            { label: t('tasks.table_fields.labels.critical'), value: 'critical' }
        ]

        return ([
            { required: false, value: '', type: 'text', name: 'taskID', label: t('tasks.form_fields.taskId'), id: 'taskID', disable: true },
            { required: true, value: '', type: 'autoComplete', name: 'projectId', label: t('tasks.form_fields.project'), id: 'Project', searchApi: 'projects', searchField: 'name', placeholder: t('tasks.form_fields.ph_project'), otherField: 'projectName' },
            { required: false, value: '', type: 'autoComplete', name: 'sprint', label: t('tasks.form_fields.sprint_name'), id: 'sprint', searchApi: 'sprints', searchField: 'name', filterField: 'projectName', isMultiple: true, filterValue: ['In Progress', 'Not Started'] },
            { required: true, value: '', type: 'text', name: 'name', label: t('tasks.form_fields.task_summary'), id: 'taskName' },
            { required: false, value: '', type: 'text', name: 'screen', label: t('tasks.form_fields.screen/module_name'), id: 'screenName' },
            { required: false, value: '', type: 'dropDown', name: 'priority', label: t('tasks.form_fields.priority'), id: 'priority', options: priorityTypes },
            { required: false, value: '', type: 'dropDown', name: 'taskStatus', label: t('tasks.form_fields.task_status'), id: 'status', options: taskStatusTypes },
            { required: false, value: '', type: 'date', name: 'startDate', label: t('tasks.form_fields.start_date'), id: 'startDate', placeholder: config.dateDashFormat },
            { required: false, value: '', type: 'date', name: 'endDate', label: t('tasks.form_fields.end_date'), id: 'lastDate', placeholder: config.dateDashFormat },
            { required: false, value: '', type: 'number', name: 'estimatedHours', label: t('tasks.form_fields.est_hrs'), id: 'estimatedHours' },
            { required: false, value: '', type: 'dropDown', name: 'status', label: t('tasks.form_fields.status'), id: 'status', options: statusTypes },
            { required: false, value: '', type: 'autoComplete', name: 'assignedTo', label: t('tasks.form_fields.assigned_to'), id: 'AssignedTo', searchApi: 'employees', searchField: 'displayName', placeholder: t('tasks.form_fields.ph_assigned_to'), otherField: 'assignedToName' },
            { required: true, value: '', type: 'dropDown', name: 'type', label: t('tasks.form_fields.type'), id: 'type', options: issueType, defaultValue: true },
            { required: false, value: '', type: 'file', name: 'file', label: t('tasks.form_fields.attatch_file'), id: 'file', chooseLabel: t('tasks.form_fields.choose_file'), isMultiple: true },
            { required: false, value: '', type: 'editor', name: 'description', label: t('tasks.form_fields.desc'), id: 'description' },


        ]);


    }
    render() {
        const { t } = this.props;
        return (
            <div>
                <TableRender type='Tasks'
                    globalSearch={t('tasks.globalsearch')}
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                    sample={configImages.tasksSampleXL}
                    bulkapi='task'
                />
            </div>
        );
    }
}
export default withTranslation('common')(TasksTable);