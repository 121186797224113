import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import SettingsForm from './components/settings';

const Settings = ({ t }) => (
    <Container>
        <SettingsForm onSubmit />
    </Container>
);

Settings.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(Settings);
