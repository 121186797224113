import React, { PureComponent } from 'react';
import { load as loadAccount } from '../../../redux/reducers/commonReducer'

import { Modal, ModalBody, ButtonToolbar, Button } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import DefaultInput from '../../../shared/components/form/DefaultInput';

import { connect } from 'react-redux';

class commentsModal extends React.Component {

    // For reseting the form 
    resetForm = async () => {
        await this.props.reset();
    }
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount = async () => {
        this.props.onRef(this);
    }
    // Load comments when clicked on edit
    loadComments = (rowData) => {
        this.setState({ rowData: rowData })
        this.props.load(rowData);
    }
    // For rating accepting only no's between 1-10 
    ratingValidate = value => {
        if (value <= 0 || value > 10) {
            return null
        } else {
            return value
        }
    }
    render() {
        const { handleSubmit } = this.props;
        return (
            < Modal isOpen={this.props.isOpen}
                style={{ width: "579px" }} >
                <ModalBody className='deleteModalBody'>
                    <form className="form" onSubmit={handleSubmit(this.props.sendCommentsToServer)}>
                        <div className="col-sm-12 text-left mb-3 pl-0">
                            <span>
                                <h4 className="mb-2 fontbold">
                                    {this.state.rowData && this.state.rowData.category && this.state.rowData.subCategory ?
                                        this.state.rowData.category + "-" + this.state.rowData.subCategory
                                        : null
                                    }
                                </h4>
                            </span>
                        </div>
                        <div className="form__form-group">
                            {/* <div className="form__form-group" style={{ fontSize: '13px', textTransform: 'uppercase', textAlign: 'left' }}>Are you sure want to save ?</div> */}
                            {(this.props.role == 'TeamLead' && this.props.employeeStatus != 'Submitted')
                                || (this.props.dropdownSelected == false && (this.props.loginStatus == 'Initiated'
                                    || this.props.loginStatus == 'Saved')) ?
                                <div>

                                    <div className="row">
                                        <div className="col-sm-12 " style={{ display: "flex" }}>
                                            <span className="form__form-group-label ">Employee Rating (1-10)</span>
                                        </div>

                                        <div className="col-sm-8">
                                            <div className="form__form-group-field" style={{ marginBottom: "45px" }}>
                                                <Field
                                                    name="employeeRating"
                                                    component={DefaultInput}
                                                    type='number'
                                                    placeholder="Rating"
                                                    normalize={this.ratingValidate}
                                                    style={{ height: '258px', width: '150px' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 " style={{ display: "flex" }}>
                                            <span className="form__form-group-label ">Employee Comments</span>

                                        </div>
                                        <div className="col-sm-8">
                                            <div className="form__form-group-field" style={{ marginBottom: "45px" }}>
                                                <Field
                                                    name="employeeComment"
                                                    component="textarea"
                                                    type="textarea"
                                                    placeholder="Enter your comments"
                                                    style={{ height: '258px', width: '426px' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            {this.props.role == 'TeamLead' && this.props.employeeStatus == 'Submitted' ?
                                <div>
                                    <div className="row">
                                        <div className="col-sm-12 text-left mb-3"><span><h6 className="mb-2 fontbold">Employee Rating :</h6></span>
                                            <h5 className="employeeCommentColor"> {this.state.rowData && this.state.rowData.employeeRating ?
                                                this.state.rowData.employeeRating : null}</h5></div>
                                        <div className="col-sm-12 text-left mb-5"><h6 className="mb-2 fontbold">Employee Comment :
                                            </h6><h5 className="employeeCommentColor"> {this.state.rowData && this.state.rowData.employeeComment ?
                                                this.state.rowData.employeeComment : null}</h5></div>
                                        <div className="col-sm-12 " style={{ display: "flex" }}>
                                            <span className="form__form-group-label fontbold ">Manager Rating (1-10)</span>

                                        </div>

                                        <div className="col-sm-8">
                                            <div className="form__form-group-field" style={{ marginBottom: "45px" }}>
                                                <Field
                                                    name="managerRating"
                                                    component={DefaultInput}
                                                    type='number'
                                                    placeholder="Rating"
                                                    style={{ height: '258px', width: '150px' }}
                                                    normalize={this.ratingValidate}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 " style={{ display: "flex" }}>
                                            <span className="form__form-group-label fontbold ">Manager Comments</span>

                                        </div>
                                        <div className="col-sm-8">
                                            <div className="form__form-group-field" style={{ marginBottom: "45px" }}>
                                                <Field
                                                    name="managerComment"
                                                    component="textarea"
                                                    type="textarea"
                                                    placeholder="Enter your comments"
                                                    style={{ height: '258px', width: '426px' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                        </div>

                        <div className="form__form-group" style={{ textAlign: "left" }}>

                            <ButtonToolbar className="form__button-toolbar m-0">
                                <Button color="primary" type="submit" >save</Button>
                                <Button type="button" onClick={this.props.closeModal}>Cancel</Button>
                            </ButtonToolbar>
                        </div>
                    </form>
                </ModalBody>
            </Modal >
        )
    }
}
commentsModal = reduxForm({
    form: "commentsmodal Form", // a unique identifier for this form

    enableReinitialize: true,
})(commentsModal);

// You have to connect() to any reducers that you wish to connect to yourself
commentsModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(commentsModal);

export default commentsModal;
