import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import EmailTemplatesTable from './components/EmailTemplatesTable';

const TemplatesPage = (props) => (
    <Container>
        <EmailTemplatesTable {...props} />
    </Container>
);

TemplatesPage.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(TemplatesPage);
