import React from 'react';
import { Card, CardBody, Col, Nav, NavItem, NavLink, TabContent, TabPane, CardHeader, Button } from 'reactstrap';
import classnames from 'classnames';
import { Editor } from 'primereact/editor';

import fetch from '../../../../config/service';
import configImages from '../../../../config/configImages';
import config from '../../../../config/config';
import BulkUploadModal from '../../CommonDataTable/BulkUploadModal';
import FileUpload from '../../../Form/components/FileUpload';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage'
import EditorModal from '../../CommonDataTable/EditorModal';
import dateFormats from '../../../UI/FormatDate/formatDate';
import Loader from '../../../App/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';


// config file
class ProjectDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            projectDetailsArray: [
                { name: 'name', label: 'project_details.labels.name', value: '', },
                { name: 'startDate', label: 'project_details.labels.start_date', value: '' },
                { name: 'teamSize', label: 'project_details.labels.team_size', value: '' },
                { name: 'teamLeadName', label: 'project_details.labels.team_lead', value: '' },
                { name: 'endDate', label: 'project_details.labels.end_date', value: '' },
            ],
            projectDetails: {},
            projectHistory: [],
            projectComments: [],
            projectAttachments: [],
            activeTab: '1',
            teamMembers: [],
            openEditorModal: false,
            isLoading: false
        };
    }
    componentDidMount = () => {
        this.getRowDataBasedOnId();
    }
    toggle = (tab) => {
        const { activeTab } = this.state;
        if (activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    getFileName = (fileName) => {
        let data = {}
        data.contextId = { 'project': this.props.projectId };
        data.contextType = "project";
        data.eventType = "attachments";
        data.attachment = fileName;
        fetchMethodRequest('POST', 'events', data)
            .then(async (response) => {
                if (response && response.respCode) {
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });
    }

    sendCommentsAttchementsDataToServer = (body, type) => {
        this.setState({ isLoading: true })
        let data = {};
        data.contextId = { 'project': this.props.match.params.id };
        data.contextType = "project";
        if (type === 'comments') {
            data.eventType = "COMMENTS";
            data.comments = body;
        } else {
            data.eventType = "attachments";
            data.attachment = body;
        }
        return fetch('POST', 'events', data)
            .then(async (response) => {
                if (response && response.respCode) {
                    this.getRowDataBasedOnId();
                    this.closeEditorModal();
                    showToasterMessage(response.respMessage, 'success');
                    this.setState({ isLoading: false })
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                    this.setState({ isLoading: false })
                }
                this.closeEditorModal();
            }).catch((err) => {
                return err;
            });
    }

    getRowDataBasedOnId = () => {
        this.setState({ isLoading: true })
        return fetch('GET', `projects/${this.props.match.params.id}?types=[COMMENTS,ATTACHMENTS]`)
            .then(async (response) => {
                if (response && (response.details || response.history || response.comments)) {
                    let projectDetails = response.details;
                    let projectHistory = response.history;
                    let projectComments = response.comments;
                    let projectAttachments = response.attachments
                    let keys = Object.keys(projectDetails)
                    let teamMembers = [];
                    let formData = [];
                    for (let item of this.state.projectDetailsArray) {
                        if (keys.indexOf(item.name) !== -1) {
                            if (item.name == 'startDate' || item.name == 'endDate') {
                                item.value = dateFormats.formatDate(projectDetails[item.name], config.dateDashFormat);
                            } else {
                                item.value = projectDetails[item.name];
                            }
                        }
                        formData.push(item);
                    }
                    if (projectDetails.assignEmployees) {
                        let assignEmployees = projectDetails.assignEmployees;
                        assignEmployees.forEach((item, index) => {
                            teamMembers.push(item.displayName);
                        })
                    }
                    this.setState({
                        projectDetails: projectDetails, projectDetailsArray: formData,
                        projectHistory: projectHistory, projectComments: projectComments,
                        projectAttachments: projectAttachments, teamMembers: teamMembers,
                        isLoading: false
                    })
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                    this.setState({ isLoading: false })
                }
            }).catch((err) => {
                return err;
            });
    }

    openEditorModal = () => {
        this.setState({ openEditorModal: true })
    }
    closeEditorModal = () => {
        this.setState({ openEditorModal: false })
    }
    goToPreviousPage = () => {
        window.history.back();
    }

    //Delete Attachments ||CodeByTej
    deleteAttachment = async (item) => {
        this.setState({ isLoading: true });
        let id = item._id;
        let url = `${id}?contextType=${item.contextType}&eventType=${item.eventType}`
        return fetch('DELETE', `events/${url}`)
            .then(async (response) => {
                if (response && response.respMessage) {
                    showToasterMessage(response.respMessage, 'success');
                    this.getRowDataBasedOnId();
                }
                else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                    this.setState({ isLoading: false });
                }
            }).catch((err) => {
                return err;
            });
    }
    render() {
        const { activeTab } = this.state;
        const { t } = this.props;
        return (
            <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                <Card>
                    <CardHeader className='cardHeader'>
                        <div className="row m-0">
                            <div className='col-4 pl-0 pr-0' >{t('project_details.title')}
                            </div>
                        </div>
                    </CardHeader>
                    <CardBody className='cardHeader'>
                        <div>
                            <Button color='primary' outline className='mt-0 mb-2 pt-0 pb-0 pr-2 pl-2'
                                onClick={this.openEditorModal}>{t('project_details.add_comments')}
                            </Button>
                            <Button color='primary' outline className='mt-0 mb-2 pt-0 pb-0 pr-2 pl-2' onClick={this.goToPreviousPage}>
                                {t('project_details.back')}
                            </Button>
                        </div>
                        <div className='row'>
                            {this.state.projectDetailsArray.map((item, i) => {
                                return (item.value ?
                                    <div key={i} className='col-sm-auto'>
                                        <div className='row'>
                                            <span className='col-sm-auto labelFont mb-1'
                                                style={{ fontWeight: 'bold' }}>
                                                {t(item.label) + " :"}
                                            </span>
                                            <span className='col-sm-auto LabelValueFont text-capitalize'>
                                                {item.value}
                                            </span>
                                        </div>
                                    </div>
                                    : null
                                )
                            })}
                        </div>
                        <div className='row mt-3'>
                            <span className='col-sm-auto labelFontt' style={{ fontWeight: 'bold' }}>{t('project_details.labels.team_members')} : </span>
                            <span className='col-sm-auto LabelValueFont text-capitalize'>
                                {this.state.teamMembers ? this.state.teamMembers.join('  ,  ') : ''}
                            </span>
                        </div>

                        {this.state.projectDetails['description'] ?
                            <div className='row mt-3'>
                                <span className='col-sm-auto labelFont' style={{ fontWeight: 'bold' }}>{t('project_details.labels.desc')} :</span>
                                <span className='col-sm-auto LabelValueFont'
                                    dangerouslySetInnerHTML={{ __html: this.state.projectDetails['description'] }}>
                                </span>
                            </div>
                            : null
                        }
                        <div className="tabs mt-4" style={{ backgroundColor: '#EAECEF', borderRadius: '5px' }} >
                            <div className="tabs__wrap">
                                <Nav tabs style={{ backgroundColor: 'white', borderColor: 'orange' }}>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { this.toggle('1') }}>{t('project_details.nav_tabs.history')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { this.toggle('2') }}>{t('project_details.nav_tabs.comments')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => { this.toggle('3') }}>{t('project_details.nav_tabs.attachments')}</NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTab} style={{ overflow: 'auto' }}>
                                    <TabPane tabId="1" >
                                        <div style={{ maxHeight: 600 }}>
                                            {this.state.projectHistory && this.state.projectHistory.length > 0 ?
                                                this.state.projectHistory.map((item, i) => {
                                                    return (
                                                        <div key={i} className='row  p-2 m-0'>
                                                            <div className="timeline__content col-sm-10">
                                                                <div className='row justify-content-between' style={{ padding: '0px 10px 0px 10px' }}>
                                                                    <h5 className='col-sm-auto text-capitalize'>{item.createdBy.employee ? item.createdBy.employee.displayName : null}</h5>
                                                                    <h6 className='col-sm-auto'> {dateFormats.timeConversion(item.created)}</h6>
                                                                    <p className='col-12'
                                                                        dangerouslySetInnerHTML={{ __html: item.description }}>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="timeline__icon ml-3">
                                                                {item.createdBy.employee ?
                                                                    <img src={`${config.imgUrl}employee/${item.createdBy.employee.photo ? item.createdBy.employee.photo : null}`} className='detailsImgStyle rounded-circle'
                                                                    />
                                                                    : <img src={configImages.defaultImg} style={{ borderRadius: '100px' }} alt='img' />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : null
                                            }
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <div style={{ maxHeight: 600 }}>
                                            {this.state.projectComments && this.state.projectComments.length > 0 ?
                                                this.state.projectComments.map((item, i) => {
                                                    return (
                                                        <div key={i} className='row  p-2 m-0'>
                                                            <div className="timeline__content col-sm-10">
                                                                <div className='row justify-content-between' style={{ padding: '0px 10px 0px 10px' }}>
                                                                    <h5 className='col-sm-auto text-capitalize'>{item.createdBy.name}</h5>
                                                                    <h6 className='col-sm-auto'>{dateFormats.formatDate(item.created, config.dateDmyFormat)}</h6>
                                                                    <p className='col-12' dangerouslySetInnerHTML={{ __html: item.comments }}></p>
                                                                </div>
                                                            </div>
                                                            <div className="timeline__icon ml-3">
                                                                {item.createdBy.employee.photo ?
                                                                    <img src={`${config.imgUrl}employee/${item.createdBy.employee.photo}`} className='detailsImgStyle rounded-circle'
                                                                    />
                                                                    : <img src={configImages.defaultImg} style={{ borderRadius: '100px' }} alt='img' />
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : null
                                            }
                                        </div>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <Loader loader={this.state.isLoading} />
                                        <div className='row p-2 m-0' >
                                            <div className="col-12 text-right">
                                                <div className='dragdrop mt-1'>
                                                    <FileUpload
                                                        onRef={(ref) => (this.fileUploadRef = ref)}
                                                        url={`events/uploadAttachment`}
                                                        chooseLabel={t('project_details.choose_file')}
                                                        projectId={this.props.match.params.id}
                                                        projectType='attach'
                                                        getFileName={this.sendCommentsAttchementsDataToServer}
                                                    />
                                                </div>
                                            </div>
                                            {this.state.projectAttachments && this.state.projectAttachments.length > 0 ?
                                                this.state.projectAttachments.map((item, i) => {
                                                    return (
                                                        <div key={i} className='col-sm-2' style={{ padding: '20px' }}>
                                                            <div style={{ justifyContent: 'center' }}>
                                                                {item && item.attachment && item.attachment.map((attachment, j) => (
                                                                    <div key={j}>
                                                                        <FontAwesomeIcon
                                                                            className='genderIconAlignment'
                                                                            color='white'
                                                                            icon='trash-alt'
                                                                            data-toggle="tool-tip"
                                                                            title="Delete"
                                                                            style={{ color: '#bf1725', width: '13', marginRight: '10px', cursor: 'pointer' }}
                                                                            onClick={() => this.deleteAttachment(item)}
                                                                        />
                                                                        {attachment && attachment.path ? (
                                                                            <img
                                                                                src={`${config.imgUrl}attachment/${attachment.name}`}
                                                                                className='detailsImgStyle'
                                                                            />
                                                                        ) : (
                                                                            <img src={configImages.defaultImg} className='detailsImgStyle' />
                                                                        )}
                                                                        <a target="_blank" href={`${config.imgUrl}attachment/${attachment.name}`}>{attachment.name}</a>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                : null
                                            }
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </CardBody>
                </Card >
                <BulkUploadModal
                    type='Attachments'
                    url={this.getApiUrl}
                    openBulkUploadModal={this.state.openBulkUploadModal}
                    closeBulkModal={this.closeBulkModal} />
                <EditorModal
                    commentsData={this.sendCommentsAttchementsDataToServer}
                    openEditorModal={this.state.openEditorModal}
                    closeEditorModal={this.closeEditorModal} />
            </div >
        );
    }
}
export default withTranslation('common')(ProjectDetails);