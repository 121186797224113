import React from "react";
import PropTypes, { element } from 'prop-types';
import classNames from 'classnames';
// import { Button, Modal, ModalHeader, ModalBody, ButtonToolbar, Card, CardBody } from 'reactstrap';
import DefaultTextArea from '../../../shared/components/form/DefaultTextArea';
import { Field, reduxForm } from 'redux-form';
import { Button, Modal, ModalHeader, ModalBody, ButtonToolbar, Card, CardBody } from 'reactstrap';
import DefaultInput from '../../../shared/components/form/DefaultInput';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// config file
import fetch from '../../../config/service'
import { withTranslation } from "react-i18next";
import Select from '../../../shared/components/form/Select';

class LeaveBalanceModal extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            colored: false,
            header: true,
        };
    }


    submit = (value) => {
        this.sendDataToServer(value);
    }
    sendDataToServer = async (value) => {
        let url = `employees/${this.props.leavedata._id}?type=LeaveUpdate`;
        let body = {
            numberOfDays: parseFloat(value.noOfDays),
            reason: value.rejectReason,
            operation: value.leaveOptions
        }
        return fetch('PUT', url, body)
            .then(async (response) => {
                if (response && response.respCode && response.respCode === 204) {
                    showToasterMessage(response.respMessage, 'success');

                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
                this.props.getTableListDataFromServer();
                this.props.reset();


            }).catch((err) => {
                return err;
            });

    }


    options = [
        { label: 'Add', value: 'Add' },
        { label: 'Subtract', value: 'Subtract' },
    ];

    handleSelectChange = (selectedValue) => {
        // console.log('Selected value:', selectedValue);

    };

    render() {
        const { handleSubmit, t } = this.props;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        return (
            <Modal
                isOpen={this.props.OpenLeaveBalanceModal}
                className={`modal-dialog-centered modal-dialog--primary ${modalClass}`}
            >
                <ModalHeader className="modal__header">
                    <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.props.closeLeaveUpdateModal} />
                    <h4 className="bold-text  modal__title">{t('forms.update_leaveBal')}</h4>
                </ModalHeader>
                <Card>
                    <CardBody className='cardHeader'>
                        <form className="form form--horizontal" onSubmit={handleSubmit(this.submit)}>
                            <div className="form__form-group col-sm-8">
                                <label className="form__form-group-label pt-3">{t('forms.reason')}</label>
                                <div className="form__form-group-field">
                                    <Field
                                        name="rejectReason"
                                        component={DefaultTextArea}
                                        id="reason"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form__form-group col-sm-8">
                                <label className="form__form-group-label pt-3">{t('forms.no_of_days')}</label>
                                <Field
                                    name="noOfDays"
                                    component={DefaultInput}
                                    id="noOfDays"
                                    className="form-control"
                                />
                            </div>
                            <div className="form__form-group col-sm-8">
                                <label className="form__form-group-label pt-3">{t('forms.Leave Options')}</label>
                                <div className="form__form-group-field">
                                    <Field
                                        name="leaveOptions"
                                        component={Select}
                                        type={"Leave Update Balance"}
                                        options={this.options}
                                        onChange={this.handleSelectChange}
                                        placeholder="Select an option"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <ButtonToolbar className="modal__footer footerData mb-2">
                                    <Button color="primary" outline onClick={this.props.closeLeaveUpdateModal}>
                                        {t('forms.cancel')}
                                    </Button>
                                    <Button color="primary" type="submit" outline onClick={this.props.closeLeaveUpdateModal}>
                                        {t('forms.update')}
                                    </Button>
                                </ButtonToolbar>
                            </div>
                        </form>


                    </CardBody>
                </Card >

            </Modal >
        );
    }
}
LeaveBalanceModal = reduxForm({
    form: "Leave Update Balance", // a unique identifier for this form
    enableReinitialize: true,
})(LeaveBalanceModal);

export default withTranslation('common')(LeaveBalanceModal);