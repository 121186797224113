/* eslint-disable react/no-unused-state,react/no-unescaped-entities */
import React, { PureComponent } from 'react';
import config from '../../../../config/config';
import RolePermissions from '../../CommonDataTable/Permissions';
import TableRender from '../../CommonDataTable/TableRender';
import { withTranslation } from 'react-i18next';

class Templates extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    getTableFields = () => {
        const { t } = this.props;
        let data = [
            { textAlign: 'center', width: 5, field: 'Sno', header: 'templates.table_fields.s_no' },
            { textAlign: 'left', width: 19, field: 'name', header: 'templates.table_fields.name', sortable: true, },
            { textAlign: 'left', width: 19, field: 'subject', header: 'templates.table_fields.subject', sortable: true, },
            { textAlign: 'center', width: 19, field: 'updated', header: 'templates.table_fields.updated', sortable: true, },
            { textAlign: 'center', width: 19, field: 'created', header: 'templates.table_fields.created', sortable: true, },
            { textAlign: 'center', width: 19, field: 'Actions', header: 'templates.table_fields.actions', },

        ];
        let screenPermissions = RolePermissions.screenPermissions('Email Templates');
        if (screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        const { t } = this.props;
        return ([
            { required: false, value: '', type: 'text', name: 'name', label: t('templates.form_fields.name'), id: 'name' },
            { required: false, value: '', type: 'text', name: 'subject', label: t('templates.form_fields.subject'), id: 'subject' },
            { required: false, value: '', type: 'editor', name: 'templateText', label: t('templates.form_fields.desc'), id: 'templateText' },

        ]);
    }


    render() {
        const { t } = this.props;
        return (
            <div >
                <TableRender type='Email Templates'
                    globalSearch={t('templates.table.globalsearch')}
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields} {...this.props}
                />
            </div>
        );
    }
}
export default withTranslation('common')(Templates);