import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import LateComersList from './components/LateComers';

const LateComersPage = ({ t }) => (
    <Container>
        <LateComersList />
    </Container>
);

LateComersPage.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(LateComersPage);
