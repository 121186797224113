import Moment from 'moment';
import config from '../../../config/config'
import moment from 'moment-timezone'

const removeZFromDate = (specificDate) => {
    let newDate = JSON.stringify(specificDate);
    return JSON.parse(newDate.replace('Z', ''));
}
export default {
    formatDate: (specificDate, expectedformat = config.dateDmyFormat, dmy, type, timeZone) => {
        if (specificDate === 'todayDate') {
            return Moment().tz(config.ESTTimezone).format(expectedformat); // est time
        } else if (specificDate === 'UTCTimeNow') {
            return Moment.utc().format(expectedformat); // utc time
        } else {
            if (specificDate) {
                let newDate = removeZFromDate(specificDate);
                if (dmy) {
                    if (type == 'dateFilter' && timeZone == 'Asia/Calcutta') {
                        return Moment(specificDate, dmy).utc().endOf('day').format(expectedformat) + 'Z';
                    } else if (type == 'dateFilter' && timeZone !== 'Asia/Calcutta') {
                        return moment.tz(specificDate, "DD-MM-YYYY", timeZone).utc().subtract(1, "days").endOf('day').format(expectedformat) + 'Z';
                    } else {
                        return Moment(newDate, dmy).utc().format(expectedformat); // selected date time
                    }
                } else {
                    return Moment(newDate).format(expectedformat);
                }

            } else {
                return null;
            }
        }
    },
    datesComparisionSame: (newDate, currentDate) => {
        if (newDate && currentDate) {
            let newDateModified = removeZFromDate(newDate);
            let currentDateModified = removeZFromDate(currentDate);
            return Moment(newDateModified).isSame(currentDateModified);
        } else {
            return null;
        }
    },
    addDaysToDate: (date, days, timeZone) => {
        if (date) {
            // let newDate = removeZFromDate(date);
            // let dateAfterDays = Moment(newDate).add(days, 'days').format('YYYY-MM-DD HH:mm:ss');
            if (timeZone == 'Asia/Calcutta') {
                let dateAfterDays = Moment(date).endOf('day').format(config.serverDateFormat) + 'Z';
                return dateAfterDays;
            } else {
                let dateAfterDays = Moment(date).add(days, 'days').endOf('day').format(config.serverDateFormat) + 'Z';
                return dateAfterDays;
            }
        } else {
            return
        }
    },
    datesComparisionSameOrBefore: (newDate, currentDate) => {

        if (newDate && currentDate) {
            let newDateModified = removeZFromDate(newDate);
            let currentDateModified = removeZFromDate(currentDate);
            return Moment(newDateModified).isSameOrBefore(currentDateModified);
        } else {
            return null;
        }
    },
    datesComparisionBefore: (newDate, currentDate) => {

        if (newDate && currentDate) {
            let newDateModified = removeZFromDate(newDate);
            let currentDateModified = removeZFromDate(currentDate);
            return Moment(newDateModified).isBefore(currentDateModified);
        } else {
            return null;
        }
    },
    getYearsFromDate: (date) => {
        let age = moment().diff(date, 'years')
        return age.toString();
    },
    differenceBetweenDatesInYears: (date1, date2) => {
        let age = Moment(date1).diff(Moment(date2), 'years');
        return age;
    },
    dateIsValid: (date) => {
        if (Moment(date, config.dateDmyFormat).isValid()) {
            return true;
        } else {
            return false;
        }
    },
    timeConversion: (date) => {
        let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let istDate = new Date(date).toLocaleString('en-US', { timeZone: timeZone });

        let istTime = Moment(istDate).format(config.fullDateDmyFormat)
        return istTime;
    }
};