// CodeByTej
import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Presentations from './components/presentations';

const PresentationsPage = ({ t }) => (
    <Container>
        <Presentations />
    </Container>
);

PresentationsPage.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(PresentationsPage);