/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Container, Row } from 'reactstrap';

import Profile from './components/Profile';
import fetch from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

class ProfilePage extends PureComponent {
    constructor(props) {
        super(props);
    }


    // submit form data

    handleSubmit = (formValues) => {
        if (formValues) {
            let Url;
            if (localStorage.getItem('loginCredentials')) {
                let user = JSON.parse(localStorage.getItem('loginCredentials'));
                Url = 'employees/' + user._id;
            }
            formValues.profileType = 'true'
            return fetch('PUT', Url, formValues)
                .then(async (response) => {
                    if (response && response.respCode && response.respCode === 204) {
                        showToasterMessage(response.respMessage, 'success');
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }).catch((err) => {
                    return err;
                });

        } else {
            return;
        }
    };

    render() {
        return (
            <Container>
                <Row>
                    <Profile onSubmit={this.handleSubmit} />
                </Row>
            </Container>
        );
    }
}

export default ProfilePage;
