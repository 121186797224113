import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button, Modal, ModalBody } from "reactstrap";
import { withTranslation } from "react-i18next";

class WorkHoursModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                { textAlign: 'center', width: 45, field: 'Sno', header: 'S.No.' },
                { textAlign: 'left', width: 100, field: 'employeeName', header: 'Employee Name' },
                { textAlign: 'left', width: 100, field: 'role', header: 'Role' },
                { textAlign: 'center', width: 80, field: 'totalHours', header: 'Working Hours' },
            ]
        };
    }

    componentDidMount = () => {
        this.props.onRef(this);
    };

    componentWillUnmount() {
        this.props.onRef(null);
    }

    render() {
        const { t } = this.props;
        const { data } = this.state;

        return (
            <div>
                <Modal isOpen={this.props.openViewModal} className="ViewModalHeight">

                    <ModalBody>
                        <div style={{ marginBottom: 15, fontWeight: "bold", fontSize: 15 }}>
                            Employee Working Hours
                        </div>
                        <DataTable
                            value={this.props.tablefields}
                            tableStyle={{ minWidth: '30rem', marginTop: '20px' }}
                        >
                            {data && data.length > 0 ?
                                data.map((item, i) => {
                                    return <Column key={item.field + i}
                                        style={{
                                            maxwidth: item.width, padding: '4px',
                                        }}
                                        bodyStyle={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap', textAlign: item.textAlign ? item.textAlign : 'left'
                                        }}
                                        field={item.field}
                                        header={item.header}
                                        headerStyle={{ padding: '4px', fontWeight: '500', width: item.width, fontSize: 14, color: 'black', }}
                                    />
                                })
                                : null}
                        </DataTable>
                        <Button color="primary" onClick={this.props.closeViewModal} style={{ marginTop: '35px' }}>
                            {t('forms.close')}
                        </Button>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default withTranslation('common')(WorkHoursModal);
