import React from 'react';
import TableRender from '../../CommonDataTable/TableRender';
import RolePermissions from '../../CommonDataTable/Permissions';
import { withTranslation } from 'react-i18next';

// config file
class Screens extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getTableFields = () => {
        const { t } = this.props;
        let data = [
            { textAlign: 'center', width: 25, field: 'Sno', header: 'screens.table_fields.s_no' },
            { textAlign: 'center', width: 25, field: 'icon', header: 'screens.table_fields.icon' },
            { textAlign: 'center', width: 25, field: 'displayTitle', header: 'screens.table_fields.display_title' },
            { textAlign: 'center', width: 25, field: 'route', header: 'screens.table_fields.route' },
            { textAlign: 'center', width: 25, field: 'Actions', header: 'screens.table_fields.actions' },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Screens');
        if (screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        const { t } = this.props;
        return ([
            { value: '', type: 'text', name: 'icon', label: t('screens.form_fields.icon'), id: 'icon' },
            { value: '', type: 'text', name: 'displayTitle', label: t('screens.form_fields.display_title'), id: 'displayTitle' },
            { required: true, value: '', type: 'text', name: 'label', label: 'Label', id: 'label' },
            { value: '', type: 'text', name: 'title', label: t('screens.form_fields.title'), id: 'title' },
            { value: '', type: 'text', name: 'route', label: t('screens.form_fields.route'), id: 'route' },

        ]);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <TableRender type='Screens'
                    globalSearch={t('screens.globalsearch')}
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields}
                />
            </div>
        );
    }
}
export default withTranslation('common')(Screens);