import React from 'react';
import { Button, Modal, ModalBody, ButtonToolbar } from 'reactstrap';
import fetch from '../../../../config/service';
import { Field, reduxForm, change, reset } from 'redux-form';
import PropTypes from 'prop-types';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
import validate from '../../../Validations/validate';
import configMessages from '../../../../config/configMessages';
import DefaultInput from '../../../../shared/components/form/DefaultInput';
import { withTranslation } from 'react-i18next';
const required = value => (value && value.length > 0 && typeof value === 'string' ? undefined : configMessages.fillField)

class StatusCrudModal extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = async () => {
        this.props.onRef(this);
    }

    getRowData = async (rowData) => {
        this.props.dispatch(reset('StatusCrudModal'))

        this.props.dispatch(
            change('StatusCrudModal', 'label',
                rowData && rowData.label ?
                    rowData.label : null));
    }

    submitValues = async (values) => {
        let array = this.props.statusArray;
        let index = this.props.statusIndex;
        let id = this.props.settingsId;
        if (!Array.isArray(array)) {
            // Handle the case where `array` is not an array
            // You might want to initialize it as an empty array or handle it differently
            array = [];
        }

        let method = 'PUT', url = `settings/${id}`, body;

        if (this.props.formType == 'add') {
            array.push({ label: values.label, value: values.label });
        }
        if (this.props.formType == 'edit') {
            let value;
            if (values.label == 'All') {
                value = null
            }
            else {
                value = values.label
            }
            array.splice(index, 1, { label: values.label, value: value });
        }
        else if (this.props.formType == 'delete') {
            array.splice(index, 1);
        }
        body = { 'issueStatus': array };

        return fetch(method, url, body)
            .then(async (response) => {
                if (response && response.respCode == 204) {
                    showToasterMessage(this.props.type + " " + this.props.formType + "ed Successfully", 'success');
                    this.props.reset();
                    this.props.closeFormModal();
                }
            }).catch((err) => {
                return err;
            });
    }

    render() {
        const { handleSubmit, t } = this.props;

        return (
            <Modal isOpen={this.props.isFormModalOpen} className='deleteModalHeight'>
                <ModalBody className='deleteModalBody'>
                    <form className="form form--horizontal" onSubmit={handleSubmit(this.submitValues)}>
                        <div className="form__form-group">
                            <label className="form__form-group-label" style={{ fontSize: '13px', textTransform: 'uppercase', textAlign: 'left' }}>
                                <>{this.props.formType} {t('settings.label.status_value')}</>
                            </label>
                            <div className="form__form-group-field">
                                <Field
                                    name={'label'}
                                    component={DefaultInput}
                                    type="text"
                                    placeholder={this.props.formType == 'edit' ? this.props.placeholder : ''}
                                    validate={required}
                                    isDisable={this.props.formType == 'delete' ? true : null}
                                />
                            </div>
                        </div>
                        <div className="form__form-group" style={{ textAlign: "left" }}>
                            {this.props.formType == 'delete' ?
                                <div className="form__form-group" style={{ fontSize: '13px', textTransform: 'uppercase', textAlign: 'left' }}>{t('settings.label.delete_conf')} </div> : null}
                            <ButtonToolbar className="form__button-toolbar m-0">
                                <Button color="primary" type="submit">{this.props.formType == 'edit' ? t('settings.label.update') : this.props.formType == 'add' ? t('settings.label.add') : t('settings.label.delete')}</Button>
                                <Button type="button" style={{ zIndex: 'revert' }} onClick={this.props.closeFormModal}>{t('settings.label.cancel')}</Button>
                            </ButtonToolbar>
                        </div>
                    </form>
                </ModalBody>
            </Modal >
        )
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
StatusCrudModal = reduxForm({
    form: "StatusCrudModal", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(StatusCrudModal);

// You have to connect() to any reducers that you wish to connect to yourself
StatusCrudModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(StatusCrudModal);

export default withTranslation('common')(StatusCrudModal);