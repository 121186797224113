import React, { useDebugValue } from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Table,
    Card, CardBody, ButtonToolbar, Input, FormGroup, Label, Col, Nav, NavItem, NavLink, TabContent, TabPane,
    FormFeedback, FormText
} from 'reactstrap';
import fetch from '../../config/service';
import classNames from 'classnames';

//components
import AutoComplete from './components/AutoComplete';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import SprintModal from './SprintModal';
import MultiSelect from '../Form/components/MultiSelect';
import Select from '../../shared/components/form/Select';
import RadioButton from '../../shared/components/form/RadioButton';
import DefaultInput from '../../shared/components/form/DefaultInput';
import FileUpload from '../Form/components/FileUpload';
import TimePicker from '../../shared/components/form/TimePicker';
import DefaultTextArea from '../../shared/components/form/DefaultTextArea';
import Editor from '../../containers/Form/components/Editor';
import config from '../../config/config';
import configMessages from '../../config/configMessages';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';
import dateFormats from '../UI/FormatDate/formatDate';
import validate from '../Validations/validate';
import DatePicker from '../../shared/components/form/DatePicker';
import CheckBox from '../../shared/components/form/CheckBox';
import { load as loadAccount } from './../../redux/reducers/commonReducer';
import EditorModal from '../../containers/Tables/CommonDataTable/EditorModal';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';

import { Field, reduxForm, change, untouch } from 'redux-form';
import { connect } from 'react-redux';
import moment from 'moment';

import PropTypes from 'prop-types';
import Loader from '../App/Loader';
import configImages from '../../config/configImages';
import { withTranslation } from 'react-i18next';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField)
const radioRequired = value => (value || typeof value === 'string' ? undefined : configMessages.checkRadio)
// config file
class FormModal extends React.Component {
    static propTypes = {
        t: PropTypes.func,
        handleSubmit: PropTypes.func.isRequired,
        reset: PropTypes.func.isRequired,
        pristine: PropTypes.bool.isRequired,
        submitting: PropTypes.bool.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            formFields: [],
            formValues: {},
            selectedValue: '',
            DOB: '',
            checkedValues: [],
            colored: false,
            header: true,
            assignedEmps: [],
            isLoading: false,
            checked: false,
            currentDate: '',
            formDetails: {},
            formHistory: [],
            formComments: [],
            formAttachments: [],
            rowId: '',
            activeTab: '2',
            isClientAdded: false,
            taskError: false,
            projectError: false,
            accountNoError: false,
            ifscCodeError: false,
            biometricIdError: false,
            bankNameError: false,
            branchNameError: false,
            qualificationError: false,
            previousExperienceError: false,
            previousCompaneyError: false,
            consultancyError: false,
            uanNumberError: false,
            skypeIdError: false,
            biometricId: false,
            bitbuketIdError: false,
            pancardNumberError: false,
            clientPhoneNumberError: false,
            isSaveAndAdd: false,
            isSaveDisable: false,
            file: '',
            openEditorModal: false,
            text: '',
            getProjectName: [],
            inputValues: [],
            showDependencies: false,
            showPlannedWork: false,
            assignedDate: false,
            date: '',
            workingHours: '',
            isHolidays: false,
            // minDate: new Date().getDay(0)
        };
        this.props.load({});
    }
    toggle = (tab) => {
        const { activeTab } = this.state;
        if (activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };
    componentDidMount = async () => {
        this.props.onRef(this);
        this.getFormFields();
        let userDetails = localStorage.getItem("loginCredentials")
        userDetails = JSON.parse(userDetails)
        if (this.props.data[0] &&
            this.props.data[0].details &&
            this.props.formType == 'edit') {
            const projectNames = this.props.data[0].details.map(item => item);
            for (var obj of this.props.data[0].details) {
                obj.name = obj.projectName

            }

            await this.props.change(
                "projectName", this.props.data[0].details || [],
            );

        }
        if (
            this.props.type === 'DailyStatus' &&
            this.props.formType === 'edit' &&
            (this.props.data &&
                this.props.data[0] &&
                this.props.data[0].dependencies &&
                this.props.data[0].dependencies.length)
        ) {
            await this.setState({
                showDependencies: true,
                nameDependency: "Do you have dependencies",
            });


            await this.props.change("Do you have dependencies", 'Yes');
            await this.props.change("dependencies", this.props.data[0].dependencies || []);
        }

        if (
            this.props.type === 'DailyStatus' &&
            this.props.formType === 'edit' &&
            (
                (this.props.data &&
                    this.props.data[0] &&
                    this.props.data[0].planedWorks &&
                    this.props.data[0].planedWorks.length))
        ) {
            await this.setState({
                showPlannedWork: true,
                fieldDepency: "Do you have planned work for tomorrow",
            });
            await this.props.change("Do you have planned work for tomorrow", 'Yes');
            await this.props.change("planedWorks", this.props.data[0].planedWorks || []);

        }

        if (this.props.formType === 'add') {
            // const today = new Date();
            this.setState({
                employeeName: userDetails.displayName,
                // date: today, // Assuming you have a state property named 'date' to store the date
            });
        }

        if (this.props.formType == 'edit') {
            this.setState({
                employeeName: userDetails.displayName,
                getProjectName: this.props.data[0] && this.props.data[0].details,
                // projectName: this.props.data[0].details.nameplanedWorkplanedWorkss
            })
        }

        let d = new Date();
        if (this.props.type == 'Timesheet') {
            this.state.maxDate = d;
            if (this.props.role != 'Admin' && this.props.role != 'Director' && this.props.role != 'TeamLead' && this.props.role != 'SuperAdmin') {
                await this.getLeavesHolidays();
                //sending confirmation if any holidays are there
                if (this.state.isHolidays) {
                    await this.setState({
                        isHolidays: true
                    })
                    await this.setState({
                        minDate: await this.getMonday()
                    })
                } else {
                    this.setState({
                        minDate: await this.getMonday()
                    })
                }
            }
        }
        if (this.props.type == 'DailyStatus' && this.props.formType == 'add') {
            await this.props.load({})
        }
    }

    getMonday = async () => {
        var d = new Date();
        var day = d.getDay(),
            diff = d.getDate() - day + ((day == 1 || day == 0) ? -6 : this.state.isHolidays ? -6 : 1);     // Adjust when day is sunday
        return new Date(d.setDate(diff));
    }
    /**
    * List call for Employees leaves and public holidays
    * @returns current week holidays
    */
    getHolidays = async () => {
        try {
            const response = await fetch('GET', `leaves/weeklyDates`);
            if (response && response.length > 0) {

                const employeeLeaves = await response.flatMap((item) => {
                    if (item.fromDate !== item.toDate) {
                        const startDate = new Date(item.fromDate);
                        const endDate = new Date(item.toDate);

                        // Calculate the number of days between startDate and endDate
                        const dateDifference = (endDate - startDate) / (24 * 60 * 60 * 1000);

                        // Generate an array of dates in the range
                        const dateArray = Array.from({ length: dateDifference + 1 }, (_, index) => {
                            const currentDate = new Date(startDate);
                            currentDate.setDate(startDate.getDate() + index);
                            return currentDate.toISOString();
                        });
                        return dateArray;

                    } else if (item.fromDate) {
                        return [new Date(item.fromDate).toISOString()];
                    } else if (item.date) {
                        return [new Date(item.date).toISOString()];
                    } else {
                        return [];
                    }
                });
                return employeeLeaves;
            }
        } catch (error) {
            throw error;
        }
    }

    //Function for extending dates in the timesheet
    getLeavesHolidays = async () => {
        const monday = new Date();
        const currentDay = monday.getDay();
        const daysUntilMonday = 1 - currentDay;                // Calculate days until Monday
        monday.setDate(monday.getDate() + daysUntilMonday);    // Set the date to this week's Monday

        //call back function for list call
        const publicHolidays = await this.getHolidays();

        if (publicHolidays) {
            const firstDate = new Date(publicHolidays[0]);     //Taking first date from the array after sorting

            //This week's monday holiday or not
            const isMondayHoliday =
                firstDate.getDate() === monday.getDate() &&
                firstDate.getMonth() === monday.getMonth() &&
                firstDate.getFullYear() === monday.getFullYear();

            this.setState({
                isMondayHoliday: isMondayHoliday
            })
        }

        //If monday any holiday is there then only will extend dates for next
        if (this.state.isMondayHoliday) {
            this.sendHolidaysData(publicHolidays)
        }
    }
    /**
     * calculating the leaves days and sending confirmation when after leaves finished
     * @param {Array} publicHolidays 
     */
    sendHolidaysData = (publicHolidays) => {
        const currentDate = new Date();
        const currentDay = currentDate.getDay();
        let holidaysCount = 1;

        for (let i = 0; i < publicHolidays.length; i++) {
            currentDate.setDate(currentDate.getDate() - 1);  //previous day of current day

            //last day is included or not in the holidays list
            const isHolidayFinished = publicHolidays.includes(moment(currentDate).format('YYYY-MM-DD[T]00:00:00.000[Z]'))
            //including from monday holidays any of last day getting false breaking the loop
            if (isHolidayFinished) {
                holidaysCount++;

                if (holidaysCount === currentDay) {
                    this.setState({
                        isHolidays: true,
                    });
                    break;
                }
            } else {
                // Break the loop if it's not a holiday
                break;
            }
        }
    }

    getFormFields = async (type) => {
        if (this.props.formFields) {
            let formFields = this.props.formFields();

            if (this.props.type == 'Leaves') {
                if (localStorage.getItem('loginCredentials')) {
                    let user = JSON.parse(localStorage.getItem('loginCredentials'));
                    if (user.role && ((user.role != "Admin") && (user.role != "Director") && (user.role != "superAdmin") && user.role != "HR")) {
                        formFields.splice(0, 1);
                    }
                }
            }

            // to remove status field in add employee form
            if (this.props.type == 'Employees' && this.props.formType && this.props.formType == 'add') {
                formFields = formFields.filter((field) => {
                    return !(field.name == 'status' || field.name == 'terminationDate');
                })
            }

            if (this.props.type == 'DailyStatus' && this.props.formType && this.props.formType == 'add') {
                formFields = formFields.filter((field) => {
                    return field.name != 'Do you have dependencies' && field.name != 'Do you have planned work for tomorrow';

                })
            }

            // to remove fields in add task form
            if (this.props.type == 'Tasks' && this.props.formType && this.props.formType == 'add') {
                formFields = formFields.filter((field) => {
                    return !(field.name == 'taskStatus' || field.name == 'status' || field.name == 'taskID');
                })
            }

            if (this.props.type === 'Tasks' && this.props.formType === 'edit') {
                if (this.state.rowData && this.state.rowData.actualWorkingHours) {
                    formFields.splice(13, 0,
                        { required: false, value: this.state.rowData.workingHours, type: 'text', name: 'actualWorkingHours', label: 'Worked Hours', disable: true },
                    )
                }
            }

            // to remove comments field in add Issue form and inventory
            if (this.props.type == 'Issues' || this.props.type == 'Inventory' && this.props.formType && this.props.formType == 'add') {
                formFields = formFields.filter((field) => {
                    return field.name != 'comments';
                })
            }

            if (this.props.type == 'Timesheet' && (this.props.role != 'Admin' && this.props.role != 'Director' && this.props.role != 'TeamLead' && this.props.role != 'SuperAdmin')) {
                formFields = formFields.filter((field) => {
                    return field.name != 'employee';
                })
            }

            //To remove/add fields for Issues 
            if (this.props.type == 'Issues') {
                let user = JSON.parse(localStorage.getItem('loginCredentials'));
                //If user is client remove few fields
                if (user.role == 'Client') {
                    formFields.splice(3, 1);
                    formFields.pop();
                }

                //If user other than client add client added checkbox
                if (user.role != 'Client') {
                    formFields.push(
                        { required: false, value: '', type: 'checkBox', name: 'isClientAdded', id: 'isClientAdded' }
                    )
                }
            }
            if (this.props.type === 'Employees') {
                let disableField = formFields;
                disableField.forEach((field) => {
                    if (this.props.role != 'Admin' && this.props.role != 'Director' && this.props.role != 'TeamLead' && this.props.role != 'SuperAdmin') {
                        if (field.name === 'joinDate') {
                            field.disable = true;
                        }
                    }
                    if (this.props.formType === 'edit' && this.props.role !== 'Admin' && this.props.role !== 'SuperAdmin') {
                        if (field.name === 'reportingTo') {
                            field.disable = true;
                        }
                    }
                })
            }
            await this.setState({ formFields: formFields });
        }

        // if (this.props.type === 'DailyStatus' && this.props.formType === 'add') {
        //     // const todaysDate = new Date() || moment();
        //     // respData.find(ele.created === todaysDate)
        //     const todaysDate = new Date()
        //     let formdata = formFields;
        //     formdata.forEach((field) => {
        //         if (field.name === 'date') {
        //         }
        //     })
        // }
        // await this.setState({ formFields: formFields })
    }
    save = (type) => {
        if (type == 'save') {
            this.setState({ isSaveAndAdd: false });
        } else {
            this.setState({ isSaveAndAdd: true });
        }
    }

    //get leaves form data
    formattedLeavesFormData = () => {
        let formFields = [...this.state.formFields]
        let halfDayType = [
            { label: 'First Half', value: 'First half' },
            { label: 'Second Half', value: 'Second half' },
        ];
        let index = formFields.findIndex(field => field.name === 'toDate');
        formFields.splice(index, 1);
        formFields.splice(3, 0,
            { required: true, value: '', type: 'dropDown', name: 'halfDayType', label: 'Half Day Type', options: halfDayType, id: 'halfDayType', placeholder: 'Select' },
        );
        this.setState({ formFields: formFields })
    }

    inventoryStatusChange = async () => {
        let newFields = [...this.state.formFields];
        if (newFields.length) {
            let statusField = newFields.find(element => element.name === 'status');
            if (statusField) {
                this.props.change("assignedTo", '')
            }
        }
    }

    //getSingleRowData
    getrowData = async (rowData) => {
        //displaying comments in inventory edit && task edit modal
        if (this.props.type === 'Tasks' || this.props.type === 'Inventory') {
            const endPoint = (this.props.type === 'Inventory' ? 'inventories' : 'tasks');
            this.setState({ rowId: rowData._id });
            if (this.state.rowId) {
                fetch('GET', `${endPoint}/${rowData._id}?types=[COMMENTS,ATTACHMENTS]`)
                    .then(async (response) => {
                        if (response && (response.details || response.history || response.comments || response.attachments)) {
                            let details = response.details;
                            let history = response.history;
                            let comments = response.comments;
                            let attachments = response.attachments;
                            await this.setState({
                                formDetails: details,
                                formHistory: history,
                                formComments: comments,
                                formAttachments: attachments
                            });
                        }
                    }).catch((err) => {
                        return err;
                    });
            }
        }
        await this.setState({ rowData: rowData, role: rowData.role });

        if (this.props.formType == 'edit' && rowData.role == 'Client') {
            this.getFormFields('addClient');
        } else {
            await this.getFormFields();
        }

        this.setState({ editRowDataID: rowData._id })
        let keys = Object.keys(rowData)
        let formData = [];
        if (this.props.type == 'Issues' && rowData.name) {
            let formFields = this.state.formFields;
            formFields.splice(2, 0,
                { required: true, value: '', type: 'textarea', name: 'name', label: 'Issue', id: 'Issue', placeholder: 'Issue' },
            );
            this.setState({ formFields: formFields });

        }
        if (this.props.type == 'Leaves') {
            rowData.employeeId = { _id: rowData.employeeId, displayName: rowData.employeeName }
        }
        for (let item of this.state.formFields) {
            if (keys.indexOf(item.name) !== -1) {
                if (item.name == 'startDate' || item.name == 'endDate' || item.name == 'dateOfBirth' || item.name == 'joinDate') {
                    item.value = dateFormats.formatDate(rowData[item.name], config.dateDashFormat);
                }
                else {
                    item.value = rowData[item.name];
                }
            } else {
                item.value = ' ';
            }
            formData.push(item);
        }

        // to set min , max date of project start, end date in tasks 
        if (this.props.type == 'Tasks' || this.props.type == 'leaves') {
            if (rowData.projectId) {
                this.setState({
                    projectId: rowData.projectId._id,
                });
                if (rowData.startDate && this.props.type == 'leaves') {
                    this.setState({ minDate: new Date(rowData.projectId.startDate) });
                }
                if (rowData.endDate) {
                    this.setState({ maxDate: new Date(rowData.projectId.endDate) });
                }
                if (this.props.type == 'Tasks') {
                    let emps = [];

                    if (rowData.projectId && rowData.projectId.assignEmployees && rowData.projectId.assignEmployees.length > 0) {
                        emps = rowData.projectId.assignEmployees;
                    }

                    if (rowData.projectId && rowData.projectId.teamLead && rowData.projectId.teamLeadName) {
                        emps.push({ _id: rowData.projectId.teamLead, displayName: rowData.projectId.teamLeadName });
                    }

                    this.setState({ assignedEmps: emps });
                }
            }
            // to remove exiting teamLead in edit
        } else if (this.props.type == 'Projects') {
            let ids = [];
            if (rowData.teamLead && rowData.teamLead._id) {
                ids.push(rowData.teamLead._id);
            }
            this.setState({ filterIds: ids });
            // to remove existing task in edit issues
        } else if (this.props.type == 'Issues') {
            let ids = [];
            if (rowData.taskId && rowData.taskId._id) {
                ids.push(rowData.taskId._id);
            }
            this.setState({ filterIds: ids });
        }
        let file = { name: rowData.image, path: config.imgUrl };
        this.setState({ file: file });

        await this.setState({ formFields: formData });
        // if (this.props.type == 'Timesheet' && rowData['otherProject']) {
        //     await this.checkProjectOrNot(rowData['otherProject'], rowData, "otherProject");
        // }
        if ((this.props.type == 'Timesheet' || this.props.type == 'Tasks') && this.props.formType == 'edit') {
            if (rowData && rowData.projectId) {
                this.setState({
                    projectName: rowData.projectId.name ? rowData.projectId.name : null,
                    projectId: rowData.projectId._id ? rowData.projectId._id : null
                })

            }
        }
        if (this.props.type == 'Leaves') {
            if (rowData && rowData.halfDayType) {
                this.formattedLeavesFormData();
            }
        }
        // to load intail values of form
        if (this.props.load) {
            await this.props.load(rowData);
        }
        await this.setState({ isLoading: false });

    }
    componentWillUnmount() {
        this.props.onRef(null);
    }

    resetFields = (formName, fieldsObj) => {
        Object.keys(fieldsObj).forEach(fieldKey => {

            //reset the field's value
            this.props.dispatch(change(formName, fieldKey, fieldsObj[fieldKey]));

            //reset the field's error
            this.props.dispatch(untouch(formName, fieldKey));

        });
        this.setState({
            projectId: fieldsObj.projectId
        })
    }

    //send data to server
    saveDataToServer = async (formValues) => {
        let details = [];
        this.setState({ isLoading: true });
        if (this.props.type == 'Timesheet' && formValues.status !== "Approved") {
            formValues.hours = parseFloat(formValues.hours);
            // When Project type is changed from Project to Non-Project or vice-versa empty other values
            if (formValues.otherProject === 'Project') {
                if (formValues.taskId && formValues.taskId.name && formValues.taskId.name !== 'other') {
                    formValues['otherTask'] = null
                }
            } else {
                if (!formValues.projectId) {
                    formValues['projectName'] = null
                }
                if (!formValues.taskId) {
                    formValues['taskName'] = null
                }
            }
            // Add Task code/serail number to timesheet
            if (this.state.taskCode) {
                formValues.taskCode = this.state.taskCode
            }
        }
        if (this.props.type == "Employees" && this.props.formType == 'edit') {
            delete formValues.password;
            delete formValues.__v;
        }
        if (formValues.estimatedHours) {
            formValues.estimatedHours = parseFloat(formValues.estimatedHours);
        }
        if (formValues.totalLeaves) {
            formValues.totalLeaves = parseFloat(formValues.totalLeaves);
        } else if (formValues.totalLeaves === '' || formValues.totalLeaves === 'undefined') {
            formValues.totalLeaves = 0;
        }
        if (this.props.formType == 'edit' && this.props.type == 'Inventory') {
            if (formValues.assignedTo == '') {
                formValues.assignedToName = '';
            }
        }
        let validationExists;
        if (!validationExists) {
            if (formValues) {
                let method, apiUrl;
                if (this.props.type == 'DailyStatus' && this.props.formType == 'edit') {
                    method = 'PUT'
                    apiUrl = this.props.apiUrl;
                }
                else if (this.props.formType == 'edit') {
                    method = 'PUT';
                    if (this.state.editRowDataID) {
                        apiUrl = `${this.props.apiUrl}/${this.state.editRowDataID}`;
                    } else {
                        apiUrl = `${this.props.apiUrl}/multipleTaskUpdate`;
                    }
                } else {
                    method = 'POST';
                    apiUrl = this.props.apiUrl;
                }
                let userBody = formValues;
                if ((this.props.role == 'Admin' || this.props.role == 'Director' || this.props.role == 'TeamLead' || this.props.role == 'SuperAdmin') && this.props.formType == 'add' && this.props.type == 'Tasks') {
                    userBody.status = 'Active'
                }

                if (this.props.type === 'DailyStatus') {
                    const formatedData = this.state.getProjectName;
                    for (let project of formatedData) {
                        const currentDate = new Date();
                        const formattedDate = currentDate.toISOString(); // Format as "YYYY-MM-DDTHH:mm:ss.sssZ"

                        details.push({
                            employeeName: this.state.employeeName,
                            taskStatus: project.taskStatus,
                            planedTask: project.planedTask,
                            projectName: this.props.formType === 'edit' ? project.projectName : project.name,
                            date: formattedDate,
                        });
                    }
                    userBody.details = details;
                }
                if (this.props.type === 'Tasks' && Array.isArray(this.state.rowData) && this.state.rowData.every(item => typeof item === 'string')) {
                    userBody.taskIds = this.state.rowData;
                }

                return fetch(method, apiUrl, userBody)
                    .then(async (response) => {
                        if (response && response.respCode && response.respCode === 204) {
                            this.clearFormFields();
                            if (!this.state.isSaveAndAdd) {
                                this.setState({ checked: false, allowDuplicates: false });
                                this.props.closeFormModal();

                            } else {
                                this.resetFields('Common Form', {
                                    projectId: '',
                                    taskId: '',
                                    description: '',
                                    hours: '',
                                    otherTask: ''
                                });

                                await this.setState({ checked: true, allowDuplicates: true });

                                //Below code may not required because "checkProjectOrNot" function not using now
                                // if (formValues && formValues.date) {
                                //     let data = {};
                                //     //     data.date = formValues.date;
                                // data.otherProject = formValues.otherProject;
                                //     //     if (this.props.type == 'Timesheet') {
                                //     //         data.type = 'Regular';
                                //     //     }
                                //     //     this.props.load(data);

                                //     //     this.props.change('date', formValues.date);
                                // this.checkProjectOrNot(data.otherProject, data, "otherProject");
                                // }
                            }
                            this.props.closeRejectModal();
                            this.props.reset;

                            this.setState({ isSaveDisable: false });
                            showToasterMessage(response.respMessage, 'success');
                        } else if (response && response.errorMessage) {
                            showToasterMessage(response.errorMessage, 'error');

                            this.setState({ isSaveDisable: false });
                            this.setState({ isLoading: false });
                        } else {
                            this.setState({ isLoading: false });
                        }
                        this.setState({ isLoading: false });
                        await this.props.getTableListDataFromServer();
                    }).catch((err) => {
                        return err;
                    });
            } else {
                return;
            }
        }
    }
    clearFormFields = async () => {
        // to empty form
        if (this.props.load) {
            this.props.load({});
        }
        let formFields = [];
        let formData = [...this.state.formFields];
        formData.forEach((item, index) => {
            item.value = '';
            item.invalid = false;
            item.message = ''
        });
        await this.setState({ formFields: formData })
        this.getFormFields();
    }

    //check if any validation exists or not
    checkValidationExists = () => {
        this.formValidations();
        let formData = [...this.state.formFields];
        let validationExists = false
        formData.forEach((item, index) => {
            if (item.required == true) {
                if (item.invalid == true) {
                    validationExists = true
                }
            }
        })
        return validationExists;
    }

    //validating form fields
    formValidations = () => {
        let formData = [...this.state.formFields];
        let formFields = [];
        formData.forEach((item, index) => {
            if (item.required == true) {
                let validation = validate.checkFieldValidations(item, this.state.DOB);
                if (validation && validation.invalid && validation.message) {
                    if (config.borderValidation == true) {
                        item.invalid = validation.invalid;
                    }
                    if (config.messages == true) {
                        item.message = validation.message;
                    }
                }
            } else {
                item.invalid = '';
                item.message = '';
            }
            formFields.push(item);
            this.setState({ formFields: formFields });
        })
    }


    closeFormModal = () => {
        this.clearFormFields();
        this.props.reset;
        this.props.closeFormModal();
    }


    getEditorText = (e, name) => {
        this.setState({ editorData: { [name]: e.htmlValue } });
    }
    //handle fields data
    handleData = async (value, name, type, searchName, otherField) => {
        let formValues = this.state.formValues;
        let formFields = [...this.state.formFields];
        // for auto complete values
        if (type && type == 'autoComplete') {
            if (value._id) {
                formValues[name] = value._id;
            }
            if (otherField && searchName && value[searchName]) {
                formValues[otherField] = value[searchName];
            }
        }
        formFields.forEach(async (item, index) => {
            if (item.name == name) {
                item.value = value;
                item.invalid = false;
                item.message = '';
                if (item.name == 'dateOfBirth') {
                    this.setState({ DOB: item.value })
                }
            }
        })
        formValues[name] = value;
        await this.setState({ formFields: formFields, formValues: formValues })

        if (name == 'reason' && type == 'changeStatus') {
            this.saveDataToServer(formValues.reason);
        } else if (name == 'status' && type == 'changeStatus') {
            this.saveDataToServer(formValues.status);
        } else if (name == 'wfhApproved' && type == 'changeStatus') {
            this.saveDataToServer(formValues.wfhApproved);
        }
    }

    setDayOfHoliday = (values) => {
        let date = new Date(values.date);
        let dayOfWeek = date.getDay()

        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let day = daysOfWeek[dayOfWeek];

        return values['day'] = day;
    }

    // form Submit
    submit = (values) => {
        if (this.state.file && this.state.file.name) {
            values['image'] = this.state.file.name;
        }
        if (this.state.uploadedDocuments) {
            values['fileName'] = this.state.uploadedDocuments.map(item => ({
                name: item.name,
                path: item.path,
                size: item.size
            }));
        }
        this.setState({ isSaveDisable: true })
        if (values) {
            // let taskType = typeof values.taskId
            let projectType = typeof values.projectId

            // if (taskType == 'string') {
            //     this.setState({ taskError: true });
            //     return;
            // }
            if (projectType === 'string') {
                this.setState({ projectError: true });
                return;
            }
        }
        if (this.props.type === 'Public Holidays' && values && values.date) {
            this.setDayOfHoliday(values);
        }
        if (values['taskName'] && (values['taskName'] && values['taskName'].name == 'Other')) {
            values['taskName'] = '';
        }
        if (values && values.taskId && values.taskId.name) {
            values['taskName'] = values.taskId.name;
        }
        if (values && values.employeeId && values.employeeId.displayName) {
            values['employeeName'] = values.employeeId.displayName;
        }
        if (values && values.projectId && values.projectId.name) {
            values['projectName'] = values.projectId.name;
        }
        if (values && values.reportingTo && values.reportingTo.displayName) {
            values['reportingToName'] = values.reportingTo.displayName;
        }
        if (values && values.teamLead && values.teamLead.displayName) {
            values['teamLeadName'] = values.teamLead.displayName;
        }
        if (values && values.assignedTo && values.assignedTo.displayName) {
            values['assignedToName'] = values.assignedTo.displayName;
        }
        //To avoid displaying _id in respective form fields while saving/updating || CodeByTej  
        if (values && values.assignedTo && values.assignedTo._id) {
            values['assignedTo'] = { '_id': values.assignedTo._id, 'displayName': values.assignedTo.displayName };
        }
        if (values && values.projectId && values.projectId._id) {
            values['projectId'] = { '_id': values.projectId._id, 'name': values.projectId.name };
        }
        if (values && values.taskId && values.taskId._id) {
            values['taskId'] = { '_id': values.taskId._id, 'name': values.taskId.name };
        }

        if (values && values.numberOfDays) {
            delete values.numberOfDays;
        }
        if (this.props.type == 'Leaves' && values.employeeId && values.employeeId._id) {
            values.employeeId = values.employeeId._id;
        }

        if (values && values.branchName) {
            let x = config.nameRegex.test(values.branchName)
            if (x == false) {
                this.setState({ branchNameError: true })
                return;
            }
        }
        if (values && values.bankName) {
            let x = config.nameRegex.test(values.bankName)
            if (x == false) {
                this.setState({ bankNameError: true })
                return;
            }
        }
        if (values && values.accountNo) {
            let x = config.accountNumberRegex.test(values.accountNo)
            if (x == false) {
                this.setState({ accountNoError: true })
                return;
            }
        }
        if (values && values.ifscCode) {
            let x = config.idRegx.test(values.ifscCode)
            if (x == false) {
                this.setState({ ifscCodeError: true });
                return;

            }
        }
        if (values && values.qualification) {
            let x = config.nameRegex.test(values.qualification)
            if (x == false) {
                this.setState({ qualificationError: true })
                return;
            }
        }
        if (values && values.previousExperience) {
            let x = config.nameRegex.test(values.previousExperience)
            if (x == false) {
                this.setState({ previousExperienceError: true })
                return;
            }
        }
        if (values && values.previousCompaney) {
            let x = config.nameRegex.test(values.previousCompaney)
            if (x == false) {
                this.setState({ previousCompaneyError: true })
                return;
            }
        }
        if (values && values.consultancy) {
            let x = config.nameRegex.test(values.consultancy)
            if (x == false) {
                this.setState({ consultancyError: true })
                return;
            }
        }
        if (values && values.uanNumber) {
            let x = config.uanNumberRegex.test(values.uanNumber)
            if (x == false) {
                this.setState({ uanNumberError: true })
                return;
            }
        }
        if (values && values.pancardNumber) {
            let x = config.pancardNumberRegex.test(values.pancardNumber)
            if (x == false) {
                this.setState({ pancardNumberError: true })
                return;
            }
        }
        if (values && values.clientPhoneNumber) {
            let x = config.phoneNumberRegex.test(values.clientPhoneNumber)
            if (x == false) {
                this.setState({ clientPhoneNumberError: true })
                return;
            }
        }
        if (this.props.type == 'Clients') {
            values.role = 'Client';
            values.companyEmail = values.email;
        }

        //"BiometricId already exists" error in employee update(03/04/2020) codeByTej
        if (this.props.type == 'Employees' && this.state.rowData && values && this.state.rowData.biometricId == values.biometricId) {
            delete values.biometricId;
            delete values.Sno;
            delete values._id;
        }

        // Removing halfdayType when leave is edited from halfday to fullday
        if (this.props.type === 'Leaves' && values.leaveDay === 'Full Day') {
            values.halfDayType = null;
        }

        let formValues = values;
        if (Object.keys(values).length > 0) {
            if ((this.props.type == 'Issues' || this.props.type == 'Inventory') && this.state.editorData && this.state.editorData.comments) {
                this.saveComments(this.state.editorData.comments);
            } else {
                if (this.state.editorData) {
                    formValues = { ...values, ...this.state.editorData };
                }
            }

            // If no employee is assigned to task by default assign to Projects Team Lead
            if (this.props.type === 'Tasks' && formValues && !formValues.assignedTo && this.state.teamLead) {
                let assignTo = {
                    displayName: this.state.teamLead.displayName,
                    _id: this.state.teamLead._id,
                }
                formValues['assignedTo'] = assignTo;
                formValues['assignedToName'] = assignTo.displayName;
            }

            this.saveDataToServer(formValues);
        } else {
            return;
        }
    }
    // to save comments in issues edit
    saveComments = (body) => {
        let data = {};
        if (this.props.type == 'Issues') {
            data.contextId = { 'issue': this.state.editRowDataID };
            data.contextType = "issue";
        }
        else {
            data.contextId = { 'inventory': this.state.editRowDataID };
            data.contextType = "inventory";
        }

        data.eventType = "COMMENTS";
        data.comments = body;

        return fetch('POST', 'events', data)
            .then(async (response) => {
                if (response && response.respCode) {
                    showToasterMessage(response.respMessage, 'success');

                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }

            }).catch((err) => {
                return err;
            });
    }



    handleAutoData = async (value, name) => {
        if (this.props.type == 'Tasks' && this.props.formType == 'add' && name === 'projectId') {
            let addField = value && value.showsTaskId && value.showsTaskId === 'Yes' ? true : false;
            let taskFields = this.state.formFields.filter(el => el.name != 'taskID');

            if (addField) {
                taskFields.splice(1, 0,
                    {
                        required: false,
                        value: '',
                        type: 'text',
                        name: 'taskID',
                        label: 'TaskID',
                    },
                );
            }
            await this.setState({
                formFields: taskFields
            });
        }
        if (this.props.type == 'Issues' || this.props.type == 'Timesheet' || this.props.type == 'Tasks') {
            if (name == 'taskId') {
                let taskCode = null
                if (value.serialNumber) {
                    taskCode = value.serialNumber
                }
                this.setState({
                    isTaskSet: true,
                    taskError: false,
                    taskCode: taskCode,
                });
                this.props.change('taskId', '')
            } else if (name == 'projectId') {
                // setTimeout(() => {
                await this.setState({
                    projectId: value._id,
                    projectName: value.name,
                    projectError: false
                });
                // }, 0)
                this.props.change('taskId', '')
                this.props.change('sprint', '')
            }
        }
        if (name == 'projectId') {
            if (this.props.type == 'Tasks' || this.props.type == 'Issues') {
                let assignEmp = [];
                if (value && value.assignEmployees && value.assignEmployees.length > 0) {
                    assignEmp = value.assignEmployees;
                }
                if (value && value.teamLead) {
                    assignEmp.push(value.teamLead);

                    // By Default Change AssignedTo value if Project is changed in Add/Edit Form
                    if (this.props.change) {
                        this.props.change('assignedTo', value.teamLead)
                    }
                }

                // Default state of Assigned Employees and Team Laed of selected project 
                setTimeout(() => {
                    this.setState({
                        assignedEmps: assignEmp,
                        teamLead: value.teamLead
                    });
                }, 0)
            }


            if (this.props.type == 'Tasks' || this.props.type == 'leaves') {
                if (value.startDate && this.props.type == 'leaves') {
                    this.setState({ minDate: new Date() });
                }
                if (value.endDate) {
                    this.setState({ maxDate: new Date(value.endDate) });
                }
            }
        }
        //Commenting this code because in the task dropdown not using 'other' value and otherTask field also not using 
        // if (name == 'taskId' && this.props.type == 'Timesheet') {
        //     let formFields = this.state.formFields;
        //     if (value && value.name == 'other') {
        //         // To Avoid adding of Duplicate Other Task Field if Task selected is 'other'
        //         let otherTaskField = formFields.find(ob => ob.name === 'otherTask');
        //         if (!otherTaskField) {
        //             formFields.splice(4, 0,
        //                 {
        //                     required: false,
        //                     value: '',
        //                     type: 'input',
        //                     name: 'otherTask',
        //                     label: 'Task Name',
        //                     id: 'otherTask',
        //                     placeholder: 'Task Name'
        //                 },
        //             );
        //         }
        //         this.setState(
        //             {
        //                 formFields: formFields
        //             },
        //             async () => {
        //                 // in edit screen if task chnages load items's work type
        //                 if (this.state.rowData) {
        //                     await this.props.change('type', this.state.rowData.type)
        //                 }
        //             }
        //         );
        //     } else {
        //         // To Remove Unwanted Other Task field if selected task is other than 'other'
        //         let otherTaskField = formFields.find(ob => ob.name === 'otherTask');
        //         if (this.state.rowData && otherTaskField) {
        //             formFields.splice(4, 1);
        //             this.setState(
        //                 async () => {
        //                     // in edit screen if task chnages load items's work type
        //                     if (this.state.rowData) {
        //                         await this.props.change('type', this.state.rowData.type)
        //                     }
        //                 }
        //             );
        //         }
        //     }
        // }
    }

    // to set formfields based on project or non-project in Timesheet form
    //Not using this function because no where this function is needed because project type is removed from the timesheet
    checkProjectOrNot = async (type, rowData, fieldName) => {
        if (fieldName === "otherProject") {
            let formFields;
            if (!this.state.allFields) {
                formFields = this.state.formFields;
                this.setState({ allFields: formFields });
            } else if (this.state.allFields) {
                formFields = this.state.allFields;
            }

            if (type == 'Project') {
                let data = rowData;
                if (!rowData) {
                    data = this.state.rowData;
                }
                if (data && data.taskName && data.taskName == 'other') {
                    let index = formFields.findIndex(field => field.name === 'otherTask');
                    if (index == -1) {
                        formFields.splice(4, 0, { required: false, value: '', type: 'input', name: 'otherTask', label: 'Task Name', id: 'otherTask', placeholder: 'Task Name' });
                    }
                }
                else {
                    formFields = formFields.filter((field) => {
                        return field.name != 'otherTask';
                    })
                }

            } else {

                let index = formFields.findIndex(field => field.name === 'otherTask');
                if (index == -1) {
                    formFields.splice(3, 0, { required: false, value: '', type: 'input', name: 'otherTask', label: 'Task Name', id: 'otherTask', placeholder: 'Task Name' });
                }

                formFields = formFields.filter((field) => {
                    return field.name != 'projectId';
                })
                formFields = formFields.filter((field) => {
                    return field.name != 'taskId';
                })
            }
            await this.setState({
                formFields: formFields,
            });
            // When Project type is changed from Project to Non-Project or vice-versa empty other values
            if (this.props.type === 'Timesheet' && this.props.change) {
                if (type === 'Project') {
                    if (rowData && rowData.taskName && rowData.taskName !== 'other') {
                        this.props.change('otherTask', null)
                    }
                } else {
                    this.props.change('projectId', null)
                    this.props.change('taskId', null)
                    this.setState({
                        projectName: null,
                        projectId: null,
                    })
                }
                this.props.change('otherProject', type)
            }
        }
    }

    // Onchange checkbox
    onChange = (value) => {
        if (value == 'isClientAdded' && this.state.isClientAdded) {
            this.setState({
                isClientAdded: false
            })
        }
    }
    handleFieldChange = async (value, key, index) => {
        const projects = this.state.getProjectName; // Create a copy of the array
        projects[index] = {
            ...projects[index], // Spread the existing properties of the object
            [key]: value // Update the specified key with the new value
        };
        await this.setState({
            getProjectName: projects
        });

    };


    handleProjectSelect = (event) => {
        for (var obj of event) {
            obj.projectName = obj.projectName ? obj.projectName : obj.name

        }
        this.setState({ getProjectName: event })
    }

    handleFile = (data) => {
        this.setState({ file: data[0] })
    }
    getRadioChage = (event, name) => {
        if (event == 'Yes' && name == 'Do you have dependencies') {
            this.setState({
                showDependencies: true, nameDependency: name
            })

        } else if (event == 'Yes') {
            this.setState({
                showPlannedWork: true,
                fieldDepency: name
            })
        }
        else if (event == 'No' && name == 'Do you have dependencies') {
            this.setState({
                showDependencies: false
            })
        } else {
            this.setState({
                showPlannedWork: false,
            })
        }
    }

    getCommentsText = (e) => {
        this.setState({
            text: e.htmlValue
        })
    }

    sendCommentsToserver = () => {
        this.sendCommentsAttachmentsDataToServer(this.state.text, 'comments')
    }

    /**
     * 
     * @param {Object} body 
     * @param {String} type 
     * @returns the api call based on type FileUpload,Comments and attatchments
     */
    sendCommentsAttachmentsDataToServer = async (body, type) => {
        if (this.props.formType == 'add' && (this.props.type == 'Tasks' || this.props.type == 'Projects')) {
            let uploadedDocuments = [...body];
            this.setState({
                uploadedDocuments: uploadedDocuments
            })
        } else if (this.props.type == 'Employees') {
            this.setState({ file: body[0] })
        } else if (this.props.formType == 'edit') {
            let data = {};

            if (type === 'comments') {
                data.eventType = "COMMENTS";
                data.comments = body;
            } else {
                data.eventType = "attachments";
                data.attachment = body;
            }
            if (this.props.type == 'Tasks') {
                data.contextId = { 'task': this.state.rowId };
                data.contextType = "task";
            } else if (this.props.type == 'Projects') {
                data.contextId = { 'project': this.state.rowData };
                data.contextType = "PROJECT";
            }
            try {
                const response = await fetch('POST', 'events', data);
                if (response && response.respCode) {
                    this.closeEditorModal();
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            } catch (err) {
                return err;
            }
        }
    }

    sendCancelDocuments = () => {
        if (this.state && this.state.uploadedDocuments && this.state.uploadedDocuments.length) {
            let uploadedDocuments = this.state.uploadedDocuments;
            uploadedDocuments.length ? this.deleteUploadedFiles(uploadedDocuments) : null
        }
    }

    deleteUploadedFiles = (values) => {
        let body = {}
        body.fileNames = values
        fetch('POST', 'events/removedAttachment', body)
            .then(async (response) => {
                if (response && response.respCode && response.respCode === 204) {
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            })
    }

    openEditorModal = () => {
        this.setState({ openEditorModal: true })

    }
    closeEditorModal = () => {
        this.setState({ openEditorModal: false })
    }
    render() {
        const { file } = this.state;
        const imgExt = ['.png', '.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.gif', '.svg'];
        const docExt = ['.doc', '.pdf', '.docx', '.xls', '.xlsx', '.csv', '.txt'];

        const { activeTab } = this.state;
        const { t } = this.props;
        let d = new Date();
        //In Add Time sheet modal display current date by default
        //And disable future dates for selection ||CodeByTej
        if (this.props.formType != 'edit') {
            this.state.currentDate = d;
        }
        const { handleSubmit } = this.props;
        const modalClass = classNames({
            'modal-dialog--colored': this.state.colored,
            'modal-dialog--header': this.state.header,
        });
        return (
            <div style={{ display: 'flex' }}>
                <Modal
                    isOpen={this.props.openFormModal}
                    className={`modal-dialog-centered modal-dialog--primary  ${modalClass} ${this.props.type === 'DailyStatus' ? 'modal-big' : null} ${this.props.type === 'Projects' ? 'view-big' : null}`}
                >
                    <ModalHeader className="modal__header">
                        <button className="lnr lnr-cross modal__close-btn" type="button" onClick={this.closeFormModal} />
                        <p className="bold-text  modal__title">{this.props.formType && this.props.formType == 'edit' ? 'Edit ' : 'Add '}{(this.props.formType == 'addClient' || this.state.role == 'Client') ? 'Client' : this.props.formName ? this.props.formName : this.props.type}</p>
                    </ModalHeader>
                    <ModalBody className="modal__body mb-0 " >
                        <Card className='m-0 p-0'>
                            <CardBody className='m-0 p-0'>
                                <Loader loader={this.state.isLoading} />
                                <form key={this.props.type} onSubmit={handleSubmit(this.submit)} >
                                    <div className='col-sm-12'>
                                        <ButtonToolbar className=" modal__footer footerData">
                                            <Button color='primary' type="button" onClick={this.closeFormModal}>{t('forms.cancel')}</Button>
                                            {this.props.formType && this.props.formType == 'add' && this.props.type == 'Timesheet' ?
                                                <Button color='primary' disabled={this.state.isSaveDisable} outline type="submit" onClick={() => this.save('save&Add')} >{t('forms.save')}</Button> : null}
                                            {this.props.formType && this.props.formType == 'edit' ?
                                                <Button color='primary' outline type="submit" >{t('forms.update')}</Button> :
                                                <Button color='primary' disabled={this.state.isSaveDisable} outline type="submit" onClick={() => this.save('save')} >
                                                    {this.props.type == 'Timesheet' ? t('forms.save&close') : t('forms.save')}</Button>}
                                        </ButtonToolbar>
                                    </div>
                                    <div className="row form" >
                                        {this.state.formFields ? this.state.formFields.map((item, i) => {
                                            return <div key={this.props.type + i} className={item.type == 'editor' ? 'col-sm-12' : 'col-sm-6'}  >
                                                <div className="form__form-group" >
                                                    <label className="form__form-group-label text-capitalize">{item.label == 'Client Added' ? null : item.label}<span style={item.required ? { color: 'red' } : { display: 'none' }}> *</span></label>
                                                    {item.type == 'dropDown' ?
                                                        <div className="form__form-group-field">
                                                            <Field key={i}
                                                                name={item.name}
                                                                component={Select}
                                                                type={this.props.type}
                                                                options={item.options}
                                                                formType={this.props.formType}
                                                                defaultValue={this.props.formType && this.props.formType == 'add' ? item.defaultValue : null}
                                                                placeholder={item.placeholder}
                                                                leavesFormData={this.formattedLeavesFormData}
                                                                inventoryStatus={this.inventoryStatusChange}
                                                                getFormFields={this.getFormFields}
                                                                // onChange={this.props.type == 'Timesheet' ? e => this.checkProjectOrNot(e, null, item.name) : null}
                                                                isDisable={(this.props.type == 'Tasks' && item.name == 'status' && (this.props.role != 'Admin' && this.props.role != 'Director' && this.props.role != 'TeamLead' && this.props.role != 'SuperAdmin')) ? true : (this.props.type == 'Projects' && this.props.formType == 'add' && item.name == 'status') ? true : item.disable ? item.disable : false}
                                                            />
                                                        </div>
                                                        : item.type == 'multiSelect' ?
                                                            <div key={i} className="form__form-group">
                                                                <div className="form__form-group-field" >
                                                                    <Field key={i}
                                                                        name={item.name}
                                                                        component={MultiSelect}
                                                                        options={item.options}
                                                                    />
                                                                </div>
                                                            </div>
                                                            : item.type == 'date' ?
                                                                <div key={i} className="form__form-group">
                                                                    <div className="form__form-group-field">
                                                                        <Field key={i}
                                                                            name={item.name ? item.name : null}
                                                                            placeholder={item.placeholder ? item.placeholder : null}
                                                                            id={item.id ? item.id : null}
                                                                            component={DatePicker}
                                                                            minDate={this.state.minDate ? this.state.minDate : null}
                                                                            maxDate={this.state.maxDate ? this.state.maxDate : null}
                                                                            // maxDate={item.name == 'startDate' ? new Date() : this.state.maxDate ? this.state.maxDate : null}
                                                                            screen={this.props.type}
                                                                            currentDate={(item.name == 'date' || item.name == 'startDate') ? this.state.currentDate : null}
                                                                            disabled={(this.props.type == 'Attendance' || (this.props.type == 'Inventory' && this.props.formType == 'edit')) ? true : false}
                                                                        />
                                                                    </div>
                                                                    <div style={{ color: '#dc3545' }}>{item.message ? item.message : null}</div>
                                                                </div>
                                                                : item.type == 'file' ?
                                                                    <div key={i} className="form__form-group">
                                                                        <div className="form__form-group-field overflowhidden primefileupload">
                                                                            <Field key={i}
                                                                                className='p-fileupload'
                                                                                name={item.name ? item.name : null}
                                                                                component={FileUpload}
                                                                                onRef={(ref) => (this.fileUploadRef = ref)}
                                                                                url={`events/uploadAttachment`}
                                                                                chooseLabel={item.chooseLabel}
                                                                                acceptType="image/*,.pdf,.doc,.docx,.xlsx"
                                                                                getFileName={this.sendCommentsAttachmentsDataToServer}
                                                                                onCancelDocuments={this.sendCancelDocuments}
                                                                                multiple={item.isMultiple}
                                                                                customUpload={true}
                                                                            />
                                                                        </div>
                                                                        <div className='col-md-10' style={{ padding: '10px' }}>
                                                                            <div style={{ justifyContent: 'center' }}>
                                                                                {(file && file.name && file.path) && (
                                                                                    <div>
                                                                                        {imgExt.some(ext => file.name.toLowerCase().includes(ext)) ? (
                                                                                            <div>
                                                                                                <a href={`${config.imgUrl}attachment/${file.name}`} target="_blank" rel="noopener noreferrer">
                                                                                                    <img src={`${config.imgUrl}attachment/${file.name}`} alt={file.name} className='detailsImgStyle' />
                                                                                                </a>
                                                                                            </div>
                                                                                        ) : (
                                                                                            docExt.some(ext => file.name.toLowerCase().includes(ext)) ? (
                                                                                                <div>
                                                                                                    <a href={`${config.imgUrl}attachment/${file.name}`} download>{file.name}</a>
                                                                                                </div>
                                                                                            ) : null
                                                                                        )}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    : item.type == 'empty' ?
                                                                        <div> </div>
                                                                        : item.type == 'radio' ?
                                                                            <div className='mb-0'>
                                                                                <div className='row'>
                                                                                    {item.options.map((option, ind) => {
                                                                                        return <div key={ind} className={this.props.type == 'Timesheet' ? "form__form-group col-sm-12 mb-0" : "form__form-group col-sm-6 col-md-4 mb-0"}>
                                                                                            <div className="form__form-group-field">
                                                                                                <Field key={this.props.type + i + ind}
                                                                                                    name={item.name ? item.name : null}
                                                                                                    component={RadioButton}
                                                                                                    label={option.label}
                                                                                                    radioValue={option.value}
                                                                                                    defaultChecked={option.defaultChecked == true ? true : false}
                                                                                                    validate={item.name == 'type' ? [radioRequired] : null}
                                                                                                    onChange={(e) => this.getRadioChage(e, item.name)}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    })
                                                                                    }
                                                                                </div>

                                                                                <div style={{ color: '#dc3545' }}>{item.message ? item.message : null}</div>
                                                                                {(this.state.showDependencies && this.state.nameDependency === item.name) && this.props.type == 'DailyStatus' ?
                                                                                    <div>
                                                                                        <Field
                                                                                            name="dependencies"
                                                                                            component={DefaultTextArea}
                                                                                            type={"textArea"}
                                                                                            placeholder={"Dependency"}
                                                                                        />
                                                                                    </div>
                                                                                    : null}
                                                                                {(this.state.showPlannedWork && this.state.fieldDepency === item.name) && this.props.type == 'DailyStatus' ?
                                                                                    <div>
                                                                                        <Field
                                                                                            name="planedWorks"
                                                                                            component={DefaultTextArea}
                                                                                            type={"textArea"}
                                                                                            placeholder={"PlanedWorks"}
                                                                                        />
                                                                                    </div>
                                                                                    : null}
                                                                            </div>
                                                                            : item.type == 'autoComplete' ?
                                                                                <div>
                                                                                    <Field key={i}
                                                                                        name={item.name ? item.name : null}
                                                                                        component={AutoComplete}
                                                                                        type={item.type}
                                                                                        filterField={item.filterField}
                                                                                        filterValue={item.filterValue}
                                                                                        filterType={item.isNotEq}
                                                                                        multiple={item.isMultiple}
                                                                                        placeholder={item.placeholder ? item.placeholder : ''}
                                                                                        searchApi={item.searchApi}
                                                                                        searchField={item.searchField}
                                                                                        otherField={item.otherField}
                                                                                        assignedEmps={(this.state.assignedEmps && this.state.assignedEmps.length) ?
                                                                                            this.state.assignedEmps : null}
                                                                                        projectTeamLead={this.state.teamLead}
                                                                                        filterIds={this.state.filterIds}
                                                                                        onChange={this.props.type == "DailyStatus" ? (e) => this.handleProjectSelect(e) : (event) => this.handleAutoData(event, item.name)}
                                                                                        projectId={this.state.projectId ? this.state.projectId : null}
                                                                                        projectName={this.state.projectName ? this.state.projectName : null}
                                                                                        screen={this.props.type}
                                                                                        allow={this.state.allowDuplicates}
                                                                                        showItemTemplate={item.isShowItemTemplate}
                                                                                        isDisabled={this.props.type === 'Leaves' && this.props.formType === 'edit' ? true : item.disable ? item.disable : false}
                                                                                    />
                                                                                    {item.name == 'taskId' && this.state.taskError && this.state.taskError == true ?
                                                                                        <span className="form__form-group-error">{"Select Task from suggestions"}</span>
                                                                                        : null
                                                                                    }
                                                                                    {item.name === 'projectId' && this.state.projectError && this.state.projectError == true ?
                                                                                        <span className="form__form-group-error">{"Select Project from suggestions"}</span>
                                                                                        : null
                                                                                    }
                                                                                </div>
                                                                                : item.type == 'textarea' ?
                                                                                    <Field key={i}
                                                                                        name={item.name ? item.name : null}
                                                                                        component={DefaultTextArea}
                                                                                        type={item.type ? item.type : null}
                                                                                        placeholder={item.placeholder ? item.placeholder : null}
                                                                                        validate={!this.state.isTaskSet && item.name == 'otherTask' ? [required] : null}
                                                                                    />
                                                                                    : item.type == 'editor' ?
                                                                                        <Editor key={i}
                                                                                            getEditorText={this.getEditorText} name={item.name} value={item.value} />
                                                                                        : item.type == 'time' ?
                                                                                            <Field key={i}
                                                                                                name={item.name ? item.name : null}
                                                                                                component={TimePicker}
                                                                                                timeMode
                                                                                                placeholder={item.placeholder ? item.placeholder : null}
                                                                                            />
                                                                                            : item.type == 'checkBox' ?
                                                                                                <Field key={i}
                                                                                                    name={item.name ? item.name : null}
                                                                                                    component={CheckBox}
                                                                                                    checked={this.state.isClientAdded}
                                                                                                    onChange={() => this.onChange('isClientAdded')}
                                                                                                    label={t('issues.form_fields.is_client_added')}
                                                                                                />
                                                                                                : <div>
                                                                                                    <Field key={i}
                                                                                                        name={item.name ? item.name : null}
                                                                                                        component={DefaultInput}
                                                                                                        isDisable={(this.props.type != 'Attendance' && this.props.formType && this.props.formType == 'edit' ? item.disable : (this.props.type == 'Attendance')) || (this.props.type === 'Employees' && this.props.formType !== 'add' && (item.name === 'firstName' || item.name === 'lastName' || item.name === 'aadharcardNumber' || item.name === 'employeeId' || item.name === 'joinDate') && (this.props.role !== 'Admin' && this.props.role !== 'Director' && this.props.role !== 'TeamLead' && this.props.role !== 'SuperAdmin' && this.props.role !== 'QA')) ? true : null}
                                                                                                        type="text"
                                                                                                        disabled
                                                                                                        maxLength={item.maxLength ? item.maxLength : null}
                                                                                                        id={item.id ? item.id : null}
                                                                                                        placeholder={item.placeholder ? item.placeholder : null}
                                                                                                        validate={(!this.state.isTaskSet && item.name == 'screenName') ? [required] : null}
                                                                                                    />
                                                                                                    <div>
                                                                                                        {item.name && this.state[`${item.name}Error`] && this.state[`${item.name}Error`] === true ? (
                                                                                                            <span className="form__form-group-error">{configMessages[item.name]}</span>) : null}
                                                                                                    </div>

                                                                                                </div>

                                                    }
                                                </div>
                                            </div>

                                        })
                                            : null}
                                    </div>
                                    {/* Displaying sprint table in the project edit form */}
                                    {(this.props.type === 'Projects' && this.props.formType === 'edit' && this.state.editRowDataID) ?
                                        <SprintModal
                                            editRowDataID={this.state.editRowDataID}
                                        />
                                        : null}

                                    {(this.state.getProjectName && this.state.getProjectName.length > 0 && this.props.type === 'DailyStatus') ? (
                                        <div className='d-flex col-12 '>
                                            <div className='col-12 px-0'>
                                                <Table bordered={true} style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th style={{ background: 'white', color: 'black', fontWeight: 'bold', textAlign: 'center' }}>Project Name</th>
                                                            <th style={{ background: 'white', color: 'black', fontWeight: 'bold', textAlign: 'center' }}>Planned Tasks</th>
                                                            {this.props.formType != 'add' ?
                                                                <th style={{ background: 'white', color: 'black', fontWeight: 'bold', textAlign: 'center' }}>Task Status</th> : null}
                                                        </tr>
                                                    </thead>
                                                    <tbody style={{ maxHeight: 160 }}>
                                                        {this.state.getProjectName && this.state.getProjectName.length > 0
                                                            ? this.state.getProjectName.map((item, i) => (
                                                                // onChange={(e) => this.getRadioChange(e.target.value, item.name)}
                                                                < tr key={i} >
                                                                    <td style={{ padding: "10px", background: '#F0F4F5' }}>
                                                                        {this.props.formType == 'edit' && item.projectName ? item.projectName : item.name ? item.name : item}
                                                                    </td>
                                                                    <td style={{ padding: "10px", background: '#F0F4F5' }}>
                                                                        <InputTextarea
                                                                            style={{ width: '100%' }}
                                                                            key={i}
                                                                            name={item.name ? item.name : null}
                                                                            component={DefaultTextArea}
                                                                            type={item.type ? item.type : null}
                                                                            placeholder={item.placeholder ? item.placeholder : null}
                                                                            value={item.planedTask}
                                                                            onChange={(e) => this.handleFieldChange(e.target.value, "planedTask", i)}
                                                                        />
                                                                    </td>
                                                                    {
                                                                        this.props.formType != 'add' ?
                                                                            <td style={{ padding: "10px", background: '#F0F4F5' }}>
                                                                                <InputTextarea
                                                                                    style={{ width: '100%' }}
                                                                                    key={i}
                                                                                    name={item.name ? item.name : null}
                                                                                    component={DefaultTextArea}
                                                                                    type={item.type ? item.type : null}
                                                                                    placeholder={item.placeholder ? item.placeholder : null}
                                                                                    value={item.taskStatus}
                                                                                    onChange={(e) => this.handleFieldChange(e.target.value, "taskStatus", i)}
                                                                                />
                                                                            </td>
                                                                            : null
                                                                    }
                                                                </tr>
                                                            ))
                                                            : ""}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    ) : null}

                                    {this.props.formType == "edit" && this.props.type == 'Tasks' ?
                                        <Button color='primary' outline className='mt-0 mb-2 pt-0 pb-0 pr-2 pl-2'
                                            onClick={this.openEditorModal}>{t('task_details.add_comments')}
                                        </Button>
                                        : null
                                    }
                                    {this.state.openEditorModal ? <div className="modal__body p-1">
                                        <Editor
                                            getEditorText={this.getCommentsText}
                                        />
                                        <div className="modal__footer mb-3">
                                            <Button color='primary' outline
                                                onClick={this.closeEditorModal}>{t('forms.cancel')}
                                            </Button>
                                            <Button color='primary' outline
                                                onClick={this.sendCommentsToserver}>{t('forms.create')}
                                            </Button>
                                        </div>
                                    </div> : null
                                    }
                                    {this.props.formType == "edit" && (this.props.type == 'Inventory' || this.props.type == 'Tasks') ?
                                        <div className="tabs mt-4" >
                                            <div className="tabs__wrap">
                                                <Nav tabs>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classNames({ active: activeTab === '1' })}
                                                            onClick={() => { this.toggle('1') }}>{t('forms.nav_tabs.history')}</NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classNames({ active: activeTab === '2' })}
                                                            onClick={() => { this.toggle('2') }}>{t('forms.nav_tabs.comments')}</NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classNames({ active: activeTab === '3' })}
                                                            onClick={() => { this.toggle('3') }}>{t('forms.nav_tabs.attachments')}</NavLink>
                                                    </NavItem>
                                                </Nav>
                                                <TabContent activeTab={activeTab} style={{ overflow: 'auto' }}>
                                                    <TabPane tabId="1">
                                                        {this.state.formHistory && this.state.formHistory.length && this.state.formHistory.map((item, i) => {
                                                            return <div className='row  p-2 m-0'>
                                                                <div className="col-8 timeline__content" style={{ backgroundColor: '#f2f4f7' }}>
                                                                    <div className='row justify-content-between' style={{ padding: '0px 10px 0px 10px' }}>
                                                                        <h5 >{item.createdBy.employee.displayName}</h5>
                                                                        <h6 className='rightAlign'>{dateFormats.formatDate(item.created, config.dateDmyFormat)}</h6>
                                                                    </div>
                                                                    <p className='col-12'
                                                                        dangerouslySetInnerHTML={{ __html: item.description }}>
                                                                    </p>
                                                                </div>
                                                                <div className="timeline__icon">
                                                                    {item.createdBy.employee.photo ?
                                                                        <img src={`${config.imgUrl}employee/${item.createdBy.employee.photo}`} className='detailsImgStyle '
                                                                        />
                                                                        : <img src={configImages.defaultImg} style={{ borderRadius: '100px' }} alt='img' />
                                                                    }
                                                                </div>
                                                            </div>
                                                        })
                                                        }
                                                    </TabPane>

                                                    <TabPane tabId="2">
                                                        {this.state.formComments && this.state.formComments.map((item, i) => {
                                                            return (
                                                                <div className='row  p-2 m-0'>
                                                                    <div className=" col-8 timeline__content" style={{ backgroundColor: '#f2f4f7' }}>
                                                                        <div className='row justify-content-between' style={{ padding: '0px 10px 0px 10px' }}>
                                                                            <h5 >{item.createdBy.name}</h5>
                                                                            <h6 className='rightAlign'>{dateFormats.formatDate(item.created, config.dateDmyFormat)}</h6>
                                                                        </div>
                                                                        <p dangerouslySetInnerHTML={{ __html: item.comments }}></p>
                                                                    </div>
                                                                    <div className="timeline__icon">
                                                                        {item.createdBy.employee.photo ?
                                                                            <img src={`${config.imgUrl}employee/${item.createdBy.employee.photo}`} className='detailsImgStyle '
                                                                            />
                                                                            : <img src={configImages.defaultImg} style={{ borderRadius: '100px' }} alt='img' />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </TabPane>
                                                    <TabPane tabId="3">
                                                        <div >
                                                            {this.state.formAttachments &&
                                                                this.state.formAttachments.length > 0 &&
                                                                this.state.formAttachments.map((item, i) => (
                                                                    <div className=' row ml-0 mr-0 d-flex' key={i} style={{ width: "100%" }}>
                                                                        {item && item.attachment && item.attachment.map((attachment, j) => (
                                                                            <div key={j} className='col-sm-4'>
                                                                                {attachment && attachment.path ? (
                                                                                    <img src={`${config.imgUrl}attachment/${attachment.name}`} className='detailsImgStyle ' />
                                                                                ) : (
                                                                                    <img src={configImages.defaultImg} className='detailsImgStyle ' />
                                                                                )}
                                                                                <a target='_blank' href={`${config.imgUrl}attachment/${attachment.name}`}>
                                                                                    {attachment.name}
                                                                                </a>
                                                                            </div>
                                                                        ))}

                                                                    </div>
                                                                ))
                                                            }
                                                        </div>

                                                    </TabPane>
                                                </TabContent>
                                            </div>
                                        </div>
                                        : null}



                                </form>
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>
            </div >
        );
    }
}
// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
FormModal = reduxForm({
    form: "Common Form", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(FormModal);

// You have to connect() to any reducers that you wish to connect to yourself
FormModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(FormModal);

export default withTranslation('common')(FormModal);
