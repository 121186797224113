/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { Container,Row } from 'reactstrap';

import Project from './components/Project';
import fetch from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

class ProjectPage extends PureComponent {
    constructor(props) {
        super(props);
    }


    // submit form data

    handleSubmit = (formValues) => {
        if (formValues) {
            return fetch('PUT', 'projects/' + '5d4054d32550f77e460ab29e', formValues)
                .then(async (response) => {
                    if (response && response.respCode && response.respCode === 204) {
                        showToasterMessage(response.respMessage, 'success');
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                }).catch((err) => {
                    return err;
                });

        } else {
            return;
        }
    };

    render() {
        return (
            <Container>
            <Row>
                <Project onSubmit={this.handleSubmit} />
            </Row>
            </Container>
        );
    }
}

export default ProjectPage;
