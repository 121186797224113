import React from "react";
import { Card, CardBody, Col, Row, Button, Tooltip } from 'reactstrap';
import { Dropdown } from 'primereact/dropdown';
import { Redirect } from 'react-router';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalBody } from 'reactstrap';



//Comments Modal
import CommentsModal from '../Apraisals/commentsModal';


// Config 
import fetch from '../../../config/service';

// Toaster message
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

//Prompt for detecting page-navigation
import { Prompt } from 'react-router';

export default class EditableTable extends React.Component {

    constructor(props) {
        super(props);


        this.state = {
            heads: [
                { display: true, textAlign: 'left', width: 110, field: 'category', header: 'Category' },
                { display: true, textAlign: 'center', width: 170, field: 'subCategory', header: 'SubCategory' },
                { display: true, textAlign: 'left', width: 90, field: 'employeeRating', header: 'Employee Rating' },
                { display: true, textAlign: 'left', width: 115, field: 'employeeComment', header: 'Employee Comments' },
                { display: true, textAlign: 'left', width: 90, field: 'managerRating', header: 'Manager Rating' },
                { display: true, textAlign: 'center', width: 115, field: 'managerComment', header: 'Manager Comments' },
                { display: true, textAlign: 'left', width: 90, field: 'Actions', header: 'Actions' },
            ],
            rows: [],
            userid: '',
            employeedropdown: [],
            apraisalcycledropdown: [],
            apraisalsArray: [],
            changeapraisalstatus: false,
            isdropdown: true,
            apraisaldropdownvalue: '',
            individualapraisal: '',
            apraisalstatus: '',
            individualid: '',
            redirectTo: false,
            itemId: '',
            employeeComment: '',
            employeeRating: '',
            tooltipOpen: false,
            tooltipOpen1: false,
            isSaveAndSubmit: false,
            tempdata: '',
            tempindividualapraisal: '',
            dropDownValue: '',
            areCommentsSaved: true,

        }
    }

    componentWillMount = async () => {
        await this.getapraisals();
        await this.defaultrows();
        await this.getEmployeeDropdown();
        this.getAppraisalsDropdown();
    }

    // get appraisal status from login body

    getapraisals = () => {
        let loginBody = localStorage.getItem('loginBody');
        if (loginBody) {
            let user = JSON.parse(loginBody);
            return fetch('POST', 'auth/login', user)
                .then(async (response) => {
                    if (response && response.respCode == 200) {
                        let user = response.details ? response.details : {}
                        let columns = this.state.heads;

                        //if status is initiated or saved
                        if (user.appraisalStatus &&
                            (user.appraisalStatus == "Initiated") ||
                            (user.appraisalStatus == "Saved")) {
                            columns.forEach((item) => {
                                if (item.field == 'managerRating' || item.field == 'managerComment') {
                                    item.display = false;
                                } else {
                                    item.display = true;
                                }
                            });

                        }
                        // if status is  submitted 
                        if (user.appraisalStatus == 'Submitted') {
                            columns.forEach((item) => {
                                if (item.field == 'Actions' || item.field == 'managerRating' || item.field == 'managerComment') {
                                    item.display = false;
                                }
                            })
                        }
                        // if status is Reviewed
                        if (user.appraisalStatus == 'Reviewed') {
                            columns.forEach((item) => {
                                if (item.field == 'Actions') {
                                    item.display = false;
                                }
                            })
                        }
                        await this.setState({
                            heads: columns,
                            userid: user._id,
                            userrole: user.role,
                            apraisalstatus: user.appraisalStatus
                        });
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }

    // Get Appraisals List
    defaultrows = async () => {
        let AllEmployeesData = [],
            filterCriteria = {
                limit: "20",
                page: "1",
                sortfield: "created",
                direction: "desc"
            };
        let apiUrl = `apraisals?employeeId=${this.state.userid}`;
        let url = apiUrl + '&filter=' + JSON.stringify(filterCriteria);
        return fetch('GET', url)
            .then(async (response) => {
                let index = 0;
                for (let employee of response.apraisals) {
                    employee.Sno = index + 1;
                    index++;
                    AllEmployeesData.push(employee);
                }
                await this.setState({
                    allUsersData: AllEmployeesData,
                    rows: response.apraisals,
                    isLoading: false
                })
                // await this.editableRef.updaterows(this.state.rows)
                return AllEmployeesData;
            }).catch((err) => {
                return err;
            });
    }
    // Get apraisals cycle list
    getAppraisalsDropdown = async () => {
        let filterCriteria = {
            "limit": "10",
            "page": "1",
            "sortfield": "created",
            "direction": "desc"
        };
        let apiUrl = 'appraisalCycle';
        let url = apiUrl + '?filter=' + JSON.stringify(filterCriteria);
        return fetch('GET', url)
            .then(async (response) => {
                if (response && response.appraisalCycles && response.appraisalCycles.length > 0) {
                    let optionsarray = [], apraisaldropdownvalue = null;
                    response.appraisalCycles.forEach((item, index) => {
                        optionsarray.push({
                            label: item.cycleName,
                            value: item.cycleName
                        });
                        if (index == 0) {
                            apraisaldropdownvalue = item.cycleName;
                        }
                    })
                    await this.setState({
                        apraisalcycledropdown: optionsarray,
                        apraisaldropdownvalue: apraisaldropdownvalue
                    })
                }
            }).catch((err) => {
                return err;
            });

    }

    // Get Reporties list
    getEmployeeDropdown = () => {
        let url
        let filterCriteria = {
            "limit": "10",
            "page": "1",
            "sortfield": "created",
            "direction": "desc"
        };
        let apiUrl = `apraisals/getTeamMembers?employeeId=${this.state.userid}`;
        url = apiUrl;
        if (this.state.userrole == 'Admin' || this.state.userrole == 'Director' || this.state.userrole == 'SuperAdmin' ||
            this.state.userrole == 'TeamLead') {
            return fetch('GET', url)
                .then(async (response) => {
                    if (response) {
                        if (response.reporties && response.reporties.length > 0) {
                            let optionsarray = [];
                            if (this.state.userrole == 'Admin' || this.state.userrole == 'Director') {
                                response.reporties.forEach((item) => {
                                    optionsarray.push({
                                        label: item.displayName,
                                        value: item._id
                                    })
                                })
                            } else {
                                response.reporties.forEach((item) => {
                                    optionsarray.push({
                                        label: item.displayName,
                                        value: item._id
                                    })
                                })
                            }
                            await this.setState({
                                employeedropdown: optionsarray
                            })
                        }
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }

    // Apraisalcylce dropdown 
    listdropdown1 = async (value) => {
        await this.setState({
            apraisaldropdownvalue: value.value
        })
        if (this.state.dropDownValue) {
            this.listdropdown(this.state.dropDownValue)
        }
    }

    //Reporties dropdown
    listdropdown = async (value) => {
        // for getting the apraisal status for particular record
        let URL = `employees/${value.value}`;
        let individualapraisal, individualid
        fetch('GET', URL)
            .then(async (response) => {
                if (response && response.details) {
                    individualid = response.details._id
                    individualapraisal = response.details.appraisalStatus
                    await this.setState({
                        tempindividualapraisal: individualapraisal,
                        individualid: individualid,
                        employeedropdownvalue: value.value,
                        changeapraisalstatus: true,
                        dropDownValue: value
                    })
                    // await this.apraisalEmployeeDetails(value.value);
                }
            }).catch((err) => {
                return err;
            });

    }
    //for getting the list of apraisal for particular employee when clicked on Search
    apraisalEmployeeDetails = (value) => {
        let apiUrl;
        // appending url based on reporties dropdown selected or not
        if (this.state.changeapraisalstatus == true) {
            apiUrl = `apraisals?employeeId=${value}&cycleName=${this.state.apraisaldropdownvalue}`;
        } else {
            apiUrl = `apraisals?employeeId=${this.state.userid}&cycleName=${this.state.apraisaldropdownvalue}`;
        }
        // let filterCriteria = { "limit": "10", "page": "1", "sortfield": "created", "direction": "desc" };
        let url = apiUrl;
        fetch('GET', url)
            .then(async (response) => {
                // if employee is not submitted ,error message is displayed for admin ,superadmin, teamlead
                if ((this.state.userrole == 'Admin' || this.state.userrole == 'Director' || this.state.userrole == 'SuperAdmin' || this.state.userrole == 'TeamLead')
                    && (response && response.errorMessage)) {
                    showToasterMessage(response.errorMessage, 'error');
                    await this.setState({
                        allUsersData: '',
                        individualapraisal: this.state.tempindividualapraisal,
                    })
                }
                else {
                    let columns = this.state.heads;
                    // if response cycleStatus is initiated then getting details from loginbody
                    if (this.state.changeapraisalstatus == false && response.cycleStatus == 'Initiated') {
                        this.getapraisals();
                    }
                    // if response cycleStatus is closed
                    if (response && response.cycleStatus &&
                        response.cycleStatus == 'Closed') {
                        if (response.apraisals) {
                            columns.forEach(item => {
                                if (item.field == 'Actions') {
                                    item.display = false;
                                } else {
                                    item.display = true;
                                }
                            })
                        }
                        if (this.state.changeapraisalstatus == true) {
                            await this.setState({ tempindividualapraisal: 'Closed' })
                        } else {
                            await this.setState({ apraisalstatus: 'Closed' })
                        }

                    } else if (this.state.userrole == 'Admin' || this.state.userrole == 'Director') {
                        columns.forEach(item => {
                            if (item.field == 'Actions') {
                                item.display = false;
                            }
                            else {
                                item.display = true;
                            }
                        })
                    }
                    // if reporties dropdown is selected

                    else if (this.state.changeapraisalstatus == true) {
                        if (response && response.apraisals &&
                            this.state.tempindividualapraisal == 'Reviewed') {
                            columns.forEach(item => {
                                if (item.field == 'Actions') {
                                    item.display = false;
                                }
                                if (item.field == 'managerComment' || item.field == 'managerRating') {
                                    item.display = true;
                                }

                            })
                        }
                        else if (this.state.tempindividualapraisal == 'Submitted') {
                            if (this.state.individualid == this.state.userid) {
                                columns.forEach(item => {
                                    if (item.field == 'managerComment' || item.field == 'managerRating' ||
                                        item.field == 'Actions') {
                                        item.display = false;
                                    }
                                })
                            }
                            else {
                                columns.forEach(item => {
                                    if (item.field == 'managerComment' || item.field == 'managerRating') {
                                        item.display = true;
                                    }
                                    if (item.field == 'Actions') {
                                        item.display = true;
                                    }
                                })
                            }
                        }
                        else if (this.state.tempindividualapraisal == 'Initiated' || this.state.tempindividualapraisal == 'Saved') {
                            columns.forEach(item => {
                                if (item.field == 'managerComment' || item.field == 'managerRating') {
                                    item.display = false;
                                }
                                if (item.field == 'Actions') {
                                    item.display = true;
                                }
                            })
                        }
                    }
                    // if reporties dropdown is not selected

                    else if (this.state.changeapraisalstatus == false) {
                        if (response && response.apraisals &&
                            this.state.apraisalstatus == 'Initiated' || this.state.apraisalstatus == 'Saved') {
                            columns.forEach(item => {
                                if (item.field == 'Actions') {
                                    item.display = true;
                                }
                                if (item.field == 'managerComment' || item.field == 'managerRating') {
                                    item.display = false;
                                }

                            })
                        }
                        else if (response && response.apraisals &&
                            this.state.apraisalstatus == 'Submitted') {
                            columns.forEach(item => {
                                if (item.field == 'managerComment' || item.field == 'managerRating'
                                    || item.field == 'Actions') {
                                    item.display = false;
                                }
                                else {
                                    item.display = true;
                                }
                            })
                        }
                        else if (response && response.apraisals &&
                            this.state.apraisalstatus == 'Reviewed') {
                            columns.forEach(item => {
                                if (item.field == 'Actions') {
                                    item.display = false;
                                }
                                else {
                                    item.display = true;
                                }
                            })
                        }
                    }
                    await this.setState({
                        allUsersData: response.apraisals,
                        // individualapraisal: response.cycleStatus,
                        individualapraisal: this.state.tempindividualapraisal,
                        heads: columns,

                    })

                }


            }).catch((err) => {
                return err;
            });

    }
    // saving the comments locally
    sendCommentsToServer = async (event) => {
        let staticRows = this.state.allUsersData;
        for (let singleRow of staticRows) {
            if (this.state.itemId == singleRow._id) {
                if (event.employeeComment) {
                    singleRow.employeeComment = event.employeeComment;

                }
                if (event.employeeRating) {
                    singleRow.employeeRating = event.employeeRating;

                }
                if (event.managerRating) {
                    if (singleRow.managerRating) {
                        singleRow.managerRating = event.managerRating;
                    }
                    else {
                        singleRow['managerRating'] = event.managerRating
                    }
                }
                if (event.managerComment) {
                    if (singleRow.managerComment) {
                        singleRow.managerComment = event.managerComment;
                    } else {
                        singleRow['managerComment'] = event.managerComment
                    }

                }

            }
        }
        await this.setState({
            isOpen: false, allUsersData: staticRows, areCommentsSaved: false
        })
    }
    // when clicked on save & submit

    saveandsubmit = async () => {
        let appraisalStatus
        // if reporties dropdown is selected & selected employee is not as same as login employee

        if (this.state.changeapraisalstatus == true && this.state.individualid != this.state.userid) {
            appraisalStatus = 'Reviewed';
        }
        else {
            appraisalStatus = 'Submitted';
        }
        await this.setState({
            isSaveAndSubmit: true,
            tempdata: appraisalStatus
        })
        // this.callServer(appraisalStatus)
    }
    // when clicked on save or 'yes' in confirmation modal    
    callServer = (value, save) => {
        // when clicked on yes in confirmation modal
        if (save == 'yes') {
            if (this.state.changeapraisalstatus) {
                this.sendDataToServer(value, this.state.individualid)
            } else {
                this.sendDataToServer(value, this.state.userid)
            }
        }
        if (this.state.isSaveAndSubmit == true) {
            if (this.state.changeapraisalstatus) {
                this.sendDataToServer(value, this.state.individualid)
            } else {
                this.sendDataToServer(value, this.state.userid)
            }
        }
    }
    // send data to server

    sendDataToServer = async (value, individualid) => {
        let method = 'POST', url, body, appraisalStatus;
        if (value != true && value != false) {
            url = 'apraisals/updateMultiple';
            appraisalStatus = value;
            body = {
                appraisalStatus: value,
                apraisalArray: this.state.allUsersData,
                employeeId: individualid

            }
        }
        else {
            url = 'apraisals/updateMultiple';
            if (value != true) {
                appraisalStatus = 'Saved'
                body = {
                    apraisalArray: this.state.allUsersData,
                    appraisalStatus: 'Saved'
                }
            }
            else {
                if (this.state.changeapraisalstatus == true &&
                    this.state.individualid == this.state.userid) {
                    appraisalStatus = 'Saved'
                    body = {
                        appraisalStatus: 'Saved',
                        apraisalArray: this.state.allUsersData,
                    }
                }
                else {
                    appraisalStatus = 'Submitted'
                    body = {
                        apraisalArray: this.state.allUsersData,
                    }
                }
            }
        }
        return fetch(method, url, body)
            .then(async (response) => {
                if (response && response.respCode && response.respCode === 204) {
                    showToasterMessage(response.respMessage, 'success');
                    // displaying the columns based on apraisalStatus
                    let columns = this.state.heads
                    if (value == 'Submitted') {
                        columns.forEach(item => {
                            if (item.field == 'Actions') {
                                item.display = false;
                            }
                        })
                    }
                    else if (value == 'Reviewed') {
                        columns.forEach(item => {
                            if (item.field == 'Actions') {
                                item.display = false;
                            }
                            if (item.field == 'managerRating' || item.field == 'managerComment') {
                                item.display = true;
                            }
                        })
                    }
                    await this.setState({ heads: columns, isSaveAndSubmit: false, areCommentsSaved: true })
                    // updating the apraisal status
                    await this.statusupdate(appraisalStatus)
                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                }
            }).catch((err) => {
                return err;
            });

    }
    // updating the apraisal status state after sending  new apraisal status to server
    statusupdate = (appraisalStatus) => {
        // if reporties dropdown is selected
        if (this.state.changeapraisalstatus == true) {
            this.setState({ individualapraisal: appraisalStatus })
        }

        else {
            this.setState({ apraisalstatus: appraisalStatus })

        }
    }
    // Open modal for comments
    openFormModal = async (item) => {
        this.setState({
            isOpen: true,
            itemId: item._id
        })
        await this.commentsModalRef.loadComments(item);

    }
    // For closing modal
    closeModal = async () => {
        await this.setState({ isOpen: false })
        this.commentsModalRef.resetForm();

    }

    //Adding Edit Icon
    changeFieldValues = (item, column) => {
        if (item && column && column.field && item[column.field]) {
            if (column.field == 'employeeComment') {
                return (
                    // <span id={`TooltipRight${item._id}`}>{item.employeeComment}
                    //     <Tooltip
                    //         placement="bottom"
                    //         target={`TooltipRight${item._id}`}>{item.employeeComment}
                    //     </Tooltip>
                    // </span>

                    <span tooltip={item.employeeComment}
                        data-toggle="tool-tip"
                        title={item.employeeComment}>
                        {item.employeeComment}
                    </span>
                    // <span>
                    //     <Tooltip
                    //         placement="bottom"
                    //         isOpen={this.state.tooltipOpen1}
                    //         target="commentsTooltip"
                    //         toggle={this.toggle1}
                    //         style={{ maxWidth: 1000, marginLeft: '21%' }}>
                    //         {item.employeeComment}
                    //     </Tooltip>
                    //     <span id='commentsTooltip'>
                    //         {item.employeeComment}
                    //     </span>
                    // </span>
                )
            }
            else if (column.field == 'managerComment') {
                return (
                    <span tooltip={item.managerComment}
                        data-toggle="tool-tip"
                        title={item.managerComment}>
                        {item.managerComment}
                    </span>
                )
            }
            else {
                return item[column.field];
            }
        }
        else if (column.field == 'Actions') {
            return (
                <div className='row' style={{ justifyContent: 'center' }}>
                    <div>
                        <FontAwesomeIcon
                            className='genderIconAlignment'
                            tooltip="issues"
                            data-toggle="tool-tip"
                            title="Edit"
                            color='white'
                            icon='edit'
                            style={{ color: '#024a88', width: '15', marginRight: '10px' }}
                            onClick={() => this.openFormModal(item)} />


                    </div>
                </div>)
        }
    }

    //open and close tooltip
    toggle = () => {
        this.setState({ tooltipOpen: !this.state.tooltipOpen })
    }
    // when clicked on 'no' in confirmation save&submit modal 
    handleModalBox = () => {
        this.setState({ isSaveAndSubmit: false })
    }

    render() {
        const { rows } = this.state;
        return (
            <Row style={{ width: "1111px" }}>
                {!this.state.areCommentsSaved ?
                    <Prompt
                        message={location =>
                            location.pathname.startsWith("/Apraisals")
                                ? true
                                : `There are un-saved changes!! Are you sure you want to leave this page`
                        }
                    />
                    : null
                }
                <Col md={12}>
                    {/* <h3 className="page-title ml-4">{('Apraisals')}</h3> */}
                    <h3 className="ml-4">{('Apraisals ')}
                        <FontAwesomeIcon
                            id="TooltipExample" href="#"
                            color='white' icon='question-circle'
                            style={{ color: '#f17038', width: '15', marginRight: '10px', }}
                        />
                    </h3>
                    <Tooltip
                        placement="bottom"
                        isOpen={this.state.tooltipOpen}
                        target="TooltipExample"
                        toggle={this.toggle}
                        style={{ maxWidth: 1000, marginLeft: '21%' }}>
                        <Col className='m-0 p-0'>
                            < h4 >Instructions</h4>
                            <p>*Write the rating you think you are. 1 - Very bad, 10 - Exceptionally Good</p>
                            <p>*Explain the reasons why you put the rating  in 2 or more sentences.</p>
                            <p>*No need to mention rating for Goals</p>
                            <p>*Click on "Save" to save your comments.</p>
                            <p>*Click on "Save and Submit" to submit your appraisal to Reviewer</p>
                        </Col>
                    </Tooltip>

                </Col>
                <Row style={{ width: "1111px" }}>
                    <Col md={(this.state.userrole == 'Admin' || this.state.userrole == 'Director' || this.state.userrole == 'SuperAdmin' || this.state.userrole == 'TeamLead') ? 3 : 5}>
                        <h5 className="ml-5 mt-3">Apraisal Status  : {(this.state.changeapraisalstatus == false) ? <span>{this.state.apraisalstatus}</span> : <span> {this.state.individualapraisal}</span>}</h5>
                    </Col>
                    <Col md={(this.state.userrole == 'Admin' || this.state.userrole == 'Director' || this.state.userrole == 'SuperAdmin' || this.state.userrole == 'TeamLead') ? 9 : 7}>

                        <span>
                            <Dropdown
                                style={{ margin: '7px 20px 20px 0', padding: '0px', textAlign: 'left' }}
                                placeholder='Apraisal Cycle'
                                options={this.state.apraisalcycledropdown}
                                onChange={value => this.listdropdown1(value)}
                                value={this.state.apraisaldropdownvalue}

                            />
                            {(this.state.userrole == 'Admin' || this.state.userrole == 'Director' || this.state.userrole == 'SuperAdmin' || this.state.userrole == 'TeamLead') ?
                                <Dropdown
                                    style={{ margin: '7px 20px 20px 0', padding: '0px', textAlign: 'left' }}
                                    placeholder='Employee Name'
                                    options={this.state.employeedropdown}
                                    onChange={value => this.listdropdown(value)}
                                    value={this.state.employeedropdownvalue}
                                />
                                : null}

                            <Button color='primary'
                                onClick={() => this.apraisalEmployeeDetails(this.state.employeedropdownvalue)}
                            >Search</Button>
                            {(this.state.userrole != 'Admin' || this.state.userrole != 'Director') && ((this.state.apraisalstatus && (this.state.apraisalstatus != 'Reviewed'
                                && this.state.apraisalstatus != 'Submitted' && this.state.apraisalstatus != 'Closed') && this.state.changeapraisalstatus == false) ||
                                (this.state.changeapraisalstatus && (this.state.userrole == 'Admin' || this.state.userrole == 'Director' ||
                                    this.state.userrole == 'SuperAdmin' || this.state.userrole == 'TeamLead')
                                    && this.state.changeapraisalstatus == true && (this.state.individualapraisal != 'Reviewed' && this.state.individualapraisal != 'Closed'))) ?

                                < Button color='primary'
                                    onClick={this.saveandsubmit}
                                >Save And Submit</Button> : null}

                            {this.state.redirectTo == true ? <Redirect to="/dashboard" /> : null}
                        </span>


                        {(this.state.userrole != 'Admin' || this.state.userrole != 'Director') && ((this.state.apraisalstatus && (this.state.apraisalstatus != 'Reviewed'
                            && this.state.apraisalstatus != 'Submitted' && this.state.apraisalstatus != 'Closed') && this.state.changeapraisalstatus == false) ||
                            (this.state.changeapraisalstatus && (this.state.userrole == 'Admin' || this.state.userrole == 'Director' ||
                                this.state.userrole == 'SuperAdmin' || this.state.userrole == 'TeamLead')
                                && this.state.changeapraisalstatus == true && (this.state.individualapraisal != 'Reviewed' && this.state.individualapraisal != 'Closed'))) ?
                            <Button className="ml-3" color='primary'
                                onClick={() => this.callServer(this.state.changeapraisalstatus, 'yes')}
                            >Save</Button> : null}
                    </Col>
                </Row>
                <Col md={12} lg={12}>
                    <Card className="ml-3">
                        <CardBody>
                            <DataTable
                                rowClassName={this.rowClassName}
                                ref={(el) => this.dt = el}
                                value={this.state.allUsersData}
                                paginator={false}
                                lazy={true}
                                resizableColumns={true}
                                columnResizeMode="expand"
                                globalFilter={this.state.globalFilter}
                                scrollable={true}
                                selection={this.props.type == 'Employees' || this.props.type == 'Issues' ? this.state.selectedRows : false}
                                scrollHeight="1000px" style={{ marginTop: '0px', }}
                                sortMode="single"
                                selectionMode='multiple'
                                metaKeySelection={false}
                            >
                                {this.state.heads && this.state.heads.length ?
                                    this.state.heads.map((item, i) => {
                                        return item.display ? <Column key={item.field + i}
                                            style={{
                                                maxwidth: item.width, padding: '4px',
                                            }}
                                            bodyStyle={{
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap', textAlign: item.textAlign ? item.textAlign : 'left'
                                            }}
                                            selectionMode={item.selectionMode}
                                            field={item.field}
                                            body={this.changeFieldValues}

                                            header={item.header}
                                            headerStyle={{ padding: '4px', fontWeight: '500', width: item.width, fontSize: 13, color: 'black', }}
                                            // filter={item.filter ? true : false}
                                            // sortable={item.sortable ? true : false}
                                            filterPlaceholder={item.placeholder ? item.placeholder : 'Search'}
                                        /> : null

                                    })
                                    : null}
                            </DataTable>
                            <CommentsModal
                                changestatus={this.state.changestatus}
                                onRef={(ref) => (this.commentsModalRef = ref)}
                                sendCommentsToServer={(e) => this.sendCommentsToServer(e)}
                                closeModal={() => this.closeModal()}
                                isOpen={this.state.isOpen}
                                role={this.state.userrole}
                                loginStatus={this.state.apraisalstatus}
                                employeeStatus={this.state.individualapraisal}
                                dropdownSelected={this.state.changeapraisalstatus}
                                saveCommentsId={this.state.saveCommentsId}
                            />
                            <Modal isOpen={this.state.isSaveAndSubmit} className='deleteModalHeight'>
                                <ModalBody className='deleteModalBody'>
                                    <div style={{ marginBottom: 10 }}>
                                        {(this.state.changeapraisalstatus && this.state.changeapraisalstatus == true &&
                                            (this.state.individualid != this.state.userid) && (this.state.userrole == 'TeamLead')) ?
                                            <span>You cannot update ratings/comments after Save and Submit.<span><br></br>Do you want to continue?</span></span>
                                            : <span>Appraisal will be submitted for Review and you cannot update ratings/comments after Save and Submit.<span><br></br>Do you want to continue?</span></span>}</div >
                                    <Button color="primary" outline onClick={this.handleModalBox} className='deleteModalBtn marginRight'>No</Button>
                                    <Button color="primary" outline onClick={() => this.callServer(this.state.tempdata, '')}
                                        className='deleteModalBtn'>Yes</Button>
                                </ModalBody>
                            </Modal>
                        </CardBody>
                    </Card>
                </Col>
            </Row >
        );
    }
}
