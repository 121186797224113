import React from 'react';
import TableRender from '../../CommonDataTable/TableRender';
import config from '../../../../config/config';
import configImages from '../../../../config/configImages';
import RolePermissions from '../../CommonDataTable/Permissions';
import { withTranslation } from 'react-i18next';
import fetch from '../../../../config/service';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';

// const projectTypesForm = [
//     { label: 'Project', value: 'Project' },
//     { label: 'Non-Project', value: 'Non-Project' }
// ]

// config file
class TimesheetTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employeesList: [],
            loginUser: '',
        };
    }

    componentDidMount = async () => {
        let url;
        let filterObj = {
            "criteria": [{ 'key': 'status', 'value': 'Active', 'type': 'eq' }]
        };
        url = `employees?filter=${JSON.stringify(filterObj)}`;

        fetch('GET', url)
            .then(async (response) => {
                if (response && response.employees && response.employees.length > 0) {
                    let employees = response.employees;
                    let modifiedLabels = [];
                    for (let label of employees) {
                        modifiedLabels.push({
                            label: label.companyEmail,
                            value: {
                                labelName: label.companyEmail,
                                id: label._id,
                            },
                        })
                    }
                    await this.setState({
                        employeesList: modifiedLabels
                    })
                }
            }).catch((err) => {
                return err;
            });

        let userDetails = JSON.parse(localStorage.getItem("loginCredentials"))
        await this.setState({ loginUser: userDetails.companyEmail })
    }

    //Function for formatting array of strings to dispalying data in the ',' seperated values
    flattenArray = (arrayVal) => {
        let val = '';
        if (Array.isArray(arrayVal)) {
            val = arrayVal.join(' , ');
        }
        return val;
    }

    //Function for exporting timesheet data report 
    getTimesheetReports = async (startDate, endDate) => {
        if (startDate && endDate) {
            let filterCriteria = {
                limit: 20,
                page: 1,
                criteria: []
            };
            startDate = moment(startDate).format('YYYY-MM-DD');
            endDate = moment(endDate).format('YYYY-MM-DD');
            filterCriteria['criteria'].push(
                { 'key': 'date', 'value': startDate, 'type': 'gte' },
                { 'key': 'date', 'value': endDate, 'type': 'lte' }
            );
            try {
                const response = await fetch('GET', `timeSheet/projectWiseTimesheets?filter=${JSON.stringify(filterCriteria)}`);
                if (response) {

                    const workbook = new ExcelJS.Workbook();

                    // Sheet 1: Employees
                    const sheet1 = workbook.addWorksheet('Employees');
                    const headers1 = [
                        { label: 'Employee Name', key: 'employeeName' },
                        { label: 'Company Employee ID', key: 'companyEmpId' },
                        { label: 'Project Name', key: 'projectName' },
                        { label: 'Total Hours', key: 'totalHours' },
                    ];
                    sheet1.addRow(headers1.map(header => header.label));
                    response.employees.forEach(employee => {
                        const row = headers1.map(header => employee[header.key]);
                        sheet1.addRow(row);
                    });

                    // Sheet 2: Projects
                    const sheet2 = workbook.addWorksheet('Projects');
                    const headers2 = [
                        { label: 'Project Name', key: 'projectName' },
                        { label: 'Task Name', key: 'taskName' },
                        { label: 'Sprint Name', key: 'sprintName' },
                        { label: 'Total Hours', key: 'totalHours' },
                    ];
                    sheet2.addRow(headers2.map(header => header.label));
                    response.projects.forEach(project => {
                        project.sprintName = this.flattenArray(project['sprintName']);
                        const row = headers2.map(header => project[header.key]);
                        sheet2.addRow(row);
                    });

                    // Sheet 3: Tasks
                    const sheet3 = workbook.addWorksheet('Tasks');
                    const headers3 = [
                        { label: 'Project Name', key: 'projectName' },
                        { label: 'Employee Name', key: 'employeeName' },
                        { label: 'Task Name', key: 'taskName' },
                        { label: 'Task Code', key: 'taskCode' },
                        { label: 'Sprint Name', key: 'sprintName' },
                        { label: 'Estimated Hours', key: 'estimatedHours' },
                        { label: 'Total Hours', key: 'totalHours' },
                    ];
                    sheet3.addRow(headers3.map(header => header.label));
                    response.tasks.forEach(task => {
                        task.sprintName = this.flattenArray(task['sprintName']);
                        const row = headers3.map(header => task[header.key]);
                        sheet3.addRow(row);
                    });

                    // Save workbook
                    const buffer = await workbook.xlsx.writeBuffer();
                    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                    // Using FileSaver to trigger the download
                    saveAs(blob, 'Report.xlsx');

                    this.setState({
                        isLoading: false,
                    });
                }
            } catch (err) {
                console.error(err);
            }
        } else {
            alert('No dates selected');
        }
    }


    getTableFields = async () => {
        const { t } = this.props;
        let workTypes = [
            { label: 'All', value: null },
            { label: 'Regular', value: 'Regular' },
            { label: 'Client Location', value: 'Client Location' },
            { label: 'Work From Home', value: 'Work From Home' },
        ];

        let projectTypes = JSON.parse(localStorage.getItem('projectTypes'));
        let projectValues = JSON.parse(localStorage.getItem('projectValues'));

        let statusTypes = [
            { label: t('timesheet.table_fields.labels.pending'), value: 'Pending' },
            { label: t('timesheet.table_fields.labels.approved'), value: 'Approved' },
            { label: t('timesheet.table_fields.labels.rejected'), value: 'Rejected' }
        ]
        let statusValue = ['Pending', 'Approved', 'Rejected'];

        let data = [
            { textAlign: 'center', width: 30, field: 'Sno', header: 'timesheet.table_fields.s_no', filter: false, sortable: false, },
            { textAlign: 'center', width: 65, field: 'companyEmpId', header: 'timesheet.table_fields.employeeId', filter: true, sortable: true, placeholder: 'timesheet.table_fields.ph_search' },
            { textAlign: 'left', width: 90, field: 'employeeName', header: 'timesheet.table_fields.employee', filter: true, sortable: true, placeholder: 'timesheet.table_fields.ph_search' },
            { textAlign: 'center', width: 55, field: 'date', header: 'timesheet.table_fields.date', filter: true, sortable: true, placeholder: config.dateDmyFormat },
            { textAlign: 'center', width: 55, field: 'created', header: 'timesheet.table_fields.created', filter: true, sortable: true, placeholder: config.dateDmyFormat },
            { textAlign: 'left', width: 70, field: 'projectName', header: 'timesheet.table_fields.project', filterElement: projectTypes, filter: true, sortable: true, defaultValues: projectValues },
            { textAlign: 'left', width: 100, field: 'taskName', header: 'timesheet.table_fields.task', filter: true, sortable: true, placeholder: 'timesheet.table_fields.ph_search' },
            { textAlign: 'left', width: 60, field: 'taskCode', header: 'timesheet.table_fields.taskId', filter: true, sortable: true, placeholder: 'timesheet.table_fields.ph_search' },
            { textAlign: 'left', width: 70, field: 'sprintName', type: 'array', header: 'timesheet.table_fields.sprint_name', filter: true, sortable: true, placeholder: 'tasks.table_fields.ph_search' },
            // { textAlign: 'left', width: 70, field: 'otherTask', header: 'timesheet.table_fields.other_task', filter: true, sortable: true, placeholder: 'timesheet.table_fields.ph_search' },
            { textAlign: 'center', width: 60, field: 'type', header: 'timesheet.table_fields.work_type', filterElement: workTypes, filter: true, sortable: false },
            { textAlign: 'center', width: 35, field: 'hours', header: 'timesheet.table_fields.hours', sortable: true, },
            { textAlign: 'left', width: 110, field: 'description', header: 'timesheet.table_fields.desc', filter: true, },
            { textAlign: 'center', width: 60, field: 'createdBy', header: 'timesheet.table_fields.created_by', filter: true, sortable: false },
            { textAlign: 'center', width: 60, field: 'status', header: 'tasks.table_fields.status', filterElement: statusTypes, filter: true, sortable: false, defaultValues: statusValue },
            { textAlign: 'center', width: 60, field: 'Actions', header: 'timesheet.table_fields.actions', filter: false, sortable: false },
        ];

        let screenPermissions = RolePermissions.screenPermissions('Timesheet');
        if (screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    getFormFields = () => {
        const { t } = this.props;
        let workTypes = [
            { label: 'Regular', value: 'Regular', defaultChecked: true },
            { label: 'Client Location', value: 'Client Location', defaultChecked: false },
            { label: 'Work From Home', value: 'Work From Home', defaultChecked: false },
        ];
        return ([{ required: true, value: '', type: 'date', name: 'date', label: t('timesheet.form_fields.date'), id: 'date', placeholder: config.dateDashFormat },
        // {
        //         required: false, value: '', type: 'radio', name: 'otherProject', label: '', id: 'otherProject',
        //         options: [{ value: 'Project', label: 'Project',defaultChecked:true }, { value: 'Non-Project', label: 'Non-Project',defaultChecked:false }],
        //       },
        { required: false, value: '', type: 'dropDown', name: 'employee', label: "Select Employee", id: 'employee', options: this.state.employeesList, defaultValue: this.state.loginUser },
        // { required: false, value: '', type: 'dropDown', name: 'otherProject', label: t('timesheet.form_fields.project_type'), id: 'otherProject', options: projectTypesForm, defaultValue: true, disable: true },
        { required: false, value: '', type: 'autoComplete', name: 'projectId', label: t('timesheet.form_fields.project'), id: 'Project', searchApi: 'projects', searchField: 'name', placeholder: 'Search Project', otherField: 'projectName' },
        { required: false, value: '', type: 'autoComplete', name: 'taskId', label: t('timesheet.form_fields.task'), id: 'task', placeholder: 'task', searchApi: 'tasks', searchField: 'name', placeholder: 'Search Task', otherField: 'taskName', filterField: 'projectName', isShowItemTemplate: true, filterValue: 'Active' },
        // { required: false, value: '', type: 'input', name: 'otherTask', label: 'Task Name', id: 'otherTask', placeholder: 'Task Name' },
        { required: true, value: '', type: 'radio', name: 'type', label: t('timesheet.form_fields.work_type'), id: 'workType', options: workTypes },
        { required: true, value: '', type: 'number', name: 'hours', label: t('timesheet.form_fields.hours'), id: 'hours', },
        { required: true, value: '', type: 'textarea', name: 'description', label: t('timesheet.form_fields.desc'), id: 'description' },
        ]);
    }
    render() {
        const { t } = this.props;
        return (
            <div>
                <TableRender type='Timesheet'
                    globalSearch={t('timesheet.globalsearch')}
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields}
                    bulkapi='timeSheet'
                    sample={configImages.timeSheetSampleXL}
                    getTimesheetReports={this.getTimesheetReports}
                />
            </div>
        );
    }
}
export default withTranslation('common')(TimesheetTable);




