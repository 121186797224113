import React from 'react';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import configMessages from '../../../../config/configMessages';
import validate from '../../../Validations/validate';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../../../redux/reducers/commonReducer';
import PropTypes from 'prop-types';
import fetch from '../../../../config/service';
import DefaultInput from '../../../../shared/components/form/DefaultInput';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import InitiateAC from './initiateAC';
import RadioButton from '../../../../shared/components/form/RadioButton';
import IssueStatusArray from '../../Settings/components/issueStatusSettings';
import { withTranslation } from 'react-i18next';
const required = value => (value == '' || value == undefined ? configMessages.fillField : undefined);
class SettingsForm extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount = async () => {
        this.getSettingsDataFromServer();
    }

    //Get Settings Details from Server
    getSettingsDataFromServer = async () => {
        fetch('GET', 'settings')
            .then(async (response) => {
                if (response) {
                    let data;
                    data = response.settings[0];
                    if (data) {
                        if (data.enableMails == true) {
                            data.enableMails = 'true'
                        }
                        if (data.enableMails == false) {
                            data.enableMails = 'false'
                        }
                    }
                    this.props.load(data);
                }
            }).catch((err) => {
                return err;
            });
    }

    //Send updated Settings data to server
    submit = async (formValues) => {
        if (formValues) {
            let userBody = formValues;
            if (userBody) {
                if (userBody.enableMails == 'true') {
                    userBody.enableMails = true
                }
                if (userBody.enableMails == 'false') {
                    userBody.enableMails = false
                }
            }
            let id = userBody['_id'];
            return fetch('PUT', `settings/${id}`, userBody)
                .then(async (response) => {
                    if (response && response.respCode == 204) {
                        showToasterMessage("Settings Updated Succeffully", 'success');
                        this.getSettingsDataFromServer();
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }

    render() {
        const { handleSubmit, reset, t } = this.props;
        return (
            <div>
                <Card className='px-3'>
                    <CardHeader className='cardHeader'>
                        <div className="row m-0">
                            <div className='col-4 pl-0 pr-0' ><h4 className='bold-text'>{t('settings.title')}</h4></div>
                        </div>
                    </CardHeader>
                    <CardBody className='cardHeader'>
                        <form className='row form' onSubmit={handleSubmit(this.submit)} >
                            <div className='col-12'>
                                <div className='row justify-content-end pr-3'>
                                    <Button color='primary' outline type="button" onClick={reset} >{t('settings.button.reset')}</Button>
                                    <Button color='primary' type="submit" >{t('settings.button.update')}</Button>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="form__form-group">
                                    <span className="form__form-group-label bold-text">{t('settings.label.admin_mail')}</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name='adminEmail'
                                            component={DefaultInput}
                                            type="email"
                                            validate={required}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label bold-text">{t('settings.label.cf_leaves')}</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name='carryForwardLeaves'
                                            component={DefaultInput}
                                            type="number"
                                            validate={required}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label bold-text">{t('settings.label.enable_mails')}</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name='enableMails'
                                            component={RadioButton}
                                            label={t('settings.label.yes')}
                                            radioValue='true'
                                        />
                                        <Field
                                            name='enableMails'
                                            component={RadioButton}
                                            label={t('settings.label.no')}
                                            radioValue='false'
                                            defaulChecked={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="form__form-group">
                                    <span className="form__form-group-label bold-text">{t('settings.label.sendgrid_mail')}</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name='sendGridFromEmail'
                                            component={DefaultInput}
                                            type="email"
                                            validate={required}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label bold-text">{t('settings.label.leave_credit')}</span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name='leaveCreditBalance'
                                            component={DefaultInput}
                                            type="number"
                                            validate={required}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <IssueStatusArray />
                        <InitiateAC />
                    </CardBody>
                </Card>
            </div >
        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
SettingsForm = reduxForm({
    form: "Settings Form", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(SettingsForm);

// You have to connect() to any reducers that you wish to connect to yourself
SettingsForm = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(SettingsForm);

export default withTranslation('common')(SettingsForm);