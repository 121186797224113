const serverCalls = {
    // apiUrl: 'http://localhost:4002/api/',
    // imgUrl: 'http://localhost:4002/',

    // apiUrl: 'https://tracktestapi.dosystemsinc.com/api/',
    // imgUrl: 'https://tracktestapi.dosystemsinc.com/',

    apiUrl: 'https://track3.api.jayeesha.com/api/',
    imgUrl: 'https://track3.api.jayeesha.com/',

    // apiUrl: 'https://track2api.jayeesha.com/api/',
    // imgUrl: 'https://track2api.jayeesha.com/',

    serverErrMessage: 'Could Not reach server',

    //regex
    borderValidation: false,
    messages: true,
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    passwordRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/,
    aadharcardNumberRegex: /^([0-9]){12}$/,
    numberRegex: /^([0-9]){10}$/,
    uanNumberRegex: /^([0-9]){12}$/,
    employeeIdRegex: /^[A-Za-z0-9]+$/,
    pancardNumberRegex: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
    phoneNumberRegex: /^[+]?(\d{1,2})?[\s.-]?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    nameRegex: /^[A-Za-z ]+$/,
    bankNameRegex: /^[A-Za-z]+$/,
    idRegx: /^[A-Za-z0-9]+$/,
    hoursRegex: /^(?:\d*\.\d{1,2}|\d+)$/,
    accountNumberRegex: /^\d+$/,
    hoursRegex: /^(?:\d*\.\d{1,2}|\d+)$/,

    // server response codes^
    updateResCode: 205,
    deleteResCode: 206,

    datePlaceholder: '--/--/----',
    dateFormat: 'MM/DD/YYYY',
    dateDashFormat: 'YYYY-MM-DD',
    basicDateFromat: 'MM/DD/YYYY HH:mm A',
    descDateFromat: 'MMM DD YYYY HH:mm A',
    dateDmyFormat: 'DD-MM-YYYY',
    fullDateDmyFormat: 'DD-MM-YYYY hh:mm:ss A',
    utcFormat: 'YYYY-MM-DD[T]00:00:00.000[Z]',

    timeFormat: 'HH:mm',
    syncTimeFormat: 'hh:mm A, MM-DD-YYYY',
    lastModifiedDateFormat: 'MM/DD/YYYY HH:mm',
    dateTimeFormat: 'MM/DD/YYYY hh:mm',
    fullDateFormat: 'YYYY-MM-DD HH:mm:ss',
    serverDateFormat: 'YYYY-MM-DDTHH:mm:ss',
    dbDateFormat: 'YYYY-MM-DD[T]HH:mm:ss.SSS',
    dbOnlyDateFormat: 'YYYY-MM-DD[T]00:00:00',
    ESTTimezone: "America/New_York",

    noView: 'noView',
    edit: 'edit',
    view: 'view'
};
export default serverCalls;