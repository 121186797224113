import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarLanguage from './TopbarLanguage';

class Topbar extends PureComponent {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };
  constructor(props) {
    super(props);
    this.state = {
      loginUserRole: '',
    }
  }
  componentDidMount = async () => {
    await this.getUserRole();
  }
  getUserRole = async () => {
    let user = JSON.parse(localStorage.getItem('loginCredentials'));
    this.setState({ loginUserRole: user.role });
  }
  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility } = this.props;
    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            {this.state.loginUserRole &&
              this.state.loginUserRole == 'Client' ?
              <Link className="topbar__logo" to="/issues" />
              : <Link className="topbar__logo" to="/dashboard" />
            }
          </div>
          <div className="topbar__right">
            <TopbarLanguage />
            <TopbarProfile />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
