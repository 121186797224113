import React from 'react';
import { Button, Input, FormGroup, Label, Col, FormFeedback, FormText } from 'reactstrap';
import { FileUpload } from 'primereact/fileupload';
import fetchMethodRequest from '../../../config/service';
// toaster message
import showToasterMessage from '../../../containers/UI/ToasterMessage/toasterMessage';
import Loader from '../../App/Loader';
// config file
export default class ImageUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            picture: [],
            fileName: '',
        };
    }

    componentWillUnmount() {
        this.props.onRef(null);
    }


    componentDidMount() {
        this.props.onRef(this);
    }
    OnUpload(event) {
    }
    onSelect = async (event) => {
        await this.setState({ picture: event.files[0], files: event.files });
        if (this.props.type && this.props.type == 'bulkUpload') {
            return;
        } else {
            if (event) {
                this.UploadFileToServer(event.files);
            }
        }

    }

    UploadFileToServer() {
        this.setState({ isLoading: true });
        if (this.state.picture) {
            let data = {};
            if (this.props.projectType == 'attach') {
                if (this.props.projectId) {
                    data.contextId = { 'project': this.props.projectId };
                    data.contextType = "project";
                } else if (this.props.taskId) {
                    data.contextId = { 'task': this.props.taskId };
                    data.contextType = "task";
                } else if (this.props.issueId) {
                    data.contextId = { 'issue': this.props.issueId };
                    data.contextType = "issue";
                }

                data.eventType = "attachments";
                data.attachment = this.state.picture;
            }

            fetchMethodRequest('POST', this.props.url, this.state.picture, 'upload')
                .then(async (response) => {
                    if (response && response.respCode) {
                        if (response.fileName || response.fineName) {
                            let fileName = response.fileName ? response.fileName : response.fineName;
                            this.setState({ fileName: fileName });
                            // call function in parent
                            if (this.props.getFileName) {
                                this.props.getFileName(fileName);
                            }
                            if (this.props.type == 'profile') {
                                if (fileName) {
                                    this.props.input.onChange(fileName);
                                }
                            }
                            showToasterMessage(response.respMessage, 'success');
                        }
                        if (response.errorCode) {
                            showToasterMessage(response.errorCode, 'error');
                        }

                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    } else {
                        if (response.sucess && response.sucess.respMessage) {
                            showToasterMessage(response.sucess.respMessage, 'success', 3000);
                        }
                        if (response.failure && response.failure.errorMessage) {
                            showToasterMessage(response.failure.errorMessage, 'error', 3000);
                        }
                        if (this.props.close) {
                            this.props.close();
                        }
                    }
                    this.setState({ isLoading: false });
                }).catch((err) => {
                    return err;
                });
        } else {
            return '';
        }
    }
    //code for Multiple file upload 
    UploadMultipleFilesToServer(event) {
        fetchMethodRequest('POST', this.props.url, event.files, 'upload', 'multiple')
            .then(async (response) => {
                if (response && response.fileName && response.respMessage) {
                    this.props.getFileName(response.fileName)
                } else if (response && response.errorMessage) {
                    this.props.getFileName(response.respMessage)
                }
            }).catch((err) => {
                return err;
            });

    }

    clear = async (event) => {
        if (this.props && this.props.onCancelDocuments) {
            this.props.onCancelDocuments(event)
        }
    }

    render() {
        return (
            <div>
                <Loader loader={this.state.isLoading} />
                <FileUpload
                    onSelect={!this.props.multiple ? this.onSelect : ''}
                    mode={this.props.multiple ? "advanced" : 'basic'}
                    name="file"
                    uurl="./upload.php"
                    maxFileSize={this.props.maxFileSize ? this.props.maxFileSize : 500000}
                    auto={false}
                    chooseLabel={this.props.chooseLabel ? this.props.chooseLabel : 'Choose File'}
                    accept={this.props.acceptType}
                    multiple={this.props.multiple}
                    onClear={(event) => this.clear(event)}
                    uploadHandler={this.props.customUpload && this.props.customUpload === true ? (event) => this.UploadMultipleFilesToServer(event) : ""}
                    customUpload={this.props.customUpload ? this.props.customUpload : false}
                    emptyTemplate={<p className="p-m-0">Drag and drop files to here to upload.</p>}
                />
            </div>
        )
    }
}
// 1000000 maxTej