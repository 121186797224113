import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import IssuesTable from './components/IssuesTable';

const IssuesPage = (props) => (
    <Container>
        <IssuesTable {...props}/>
    </Container>
);

IssuesPage.propTypes = {
    t: PropTypes.func.isRequired,
};

export default withTranslation('common')(IssuesPage);
