import React from 'react';
import TableRender from '../../CommonDataTable/TableRender';
import config from '../../../../config/config';
import RolePermissions from '../../CommonDataTable/Permissions';
import { withTranslation } from 'react-i18next';

// config file
class EmployeesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getTableFields = () => {
        const { t } = this.props;
        let data = [
            { textAlign: 'center', width: 59, field: 'Sno', header: 'activities.table_fields.s_no', filter: false, sortable: false, },
            { textAlign: 'left', width: 100, field: 'context', header: 'activities.table_fields.context', filter: true, sortable: true, placeholder: 'activities.table_fields.ph_search' },
            { textAlign: 'left', width: 100, field: 'desc', header: 'activities.table_fields.description', filter: true, sortable: true, placeholder: 'activities.table_fields.ph_search' },
            { textAlign: 'center', width: 100, field: 'contextType', header: 'activities.table_fields.context_type', filter: true, sortable: true, placeholder: 'activities.table_fields.ph_search' },
            { textAlign: 'left', width: 150, field: 'createdBy', header: 'activities.table_fields.created_by', filter: true, placeholder: 'activities.table_fields.ph_search' },
            { textAlign: 'center', width: 150, field: 'created', header: 'activities.table_fields.created', sortable: true, filter: true, placeholder: 'activities.table_fields.ph_search' },
        ];
        let screenPermissions = RolePermissions.screenPermissions('Activities');
        if (screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };



    render() {
        const { t } = this.props;
        return (
            <div>
                <TableRender type='Activities'
                    globalSearch={t('activities.table.globalsearch')}
                    tableFields={this.getTableFields}
                />
            </div>
        );
    }
}
export default withTranslation('common')(EmployeesTable);