import React from 'react';
import { Input, FormFeedback, Button } from 'reactstrap';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
// login styles
// import '../../../../scss/loginStyles.css';

// fecth method from service.js file
import fetch from '../../../../config/service';
import Loader from '../../../App/Loader';

// show message 
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

// config file
import config from '../../../../config/config';
import { withTranslation } from 'react-i18next';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            validate: {
                emailState: false,
                isLoading: false
            },
        };
    }

    // on value change in input
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    // handle login user data
    handleUserPassword = () => {
        this.setState({ isLoading: true });
        if (this.state.validate && this.state.validate.emailState != 'has-danger') {
            return fetch('POST', `employees/forgotPassword?employeeName=${this.state.email}`)
                .then((response) => {
                    if (response && response.respCode && response.respCode === 200) {
                        // display message
                        this.setState({
                            email: '',
                            validate: {},
                            isLoading: false
                        })
                        showToasterMessage(response.respMessage, 'success');
                    } else if (response && response.errorMessage) {
                        // display message
                        showToasterMessage(response.errorMessage, 'error');
                        this.setState({ isLoading: false });

                    }
                }).catch((err) => {
                    return err;
                });
        }
    }

    checkCredentials = () => {
        const { validate } = this.state
        if (!this.state.email) {
            validate.emailState = 'has-danger'
        }
        this.setState({ validate })
    }

    // validate email address
    validateEmail(e) {
        const { validate } = this.state
        if (e.target.value && e.target.value.length > 6) {
            if (config.emailRegex.test(e.target.value)) {
                validate.emailState = 'has-success'
            } else {
                validate.emailState = 'has-danger'
            }
        }
        this.setState({ validate })
    }
    backtoLogin = () => {
        this.setState({
            isLogin: true
        })
    }

    render() {
        const { t } = this.props;
        return (
            <form className="form">
                <Loader loader={this.state.isLoading} />
                <div className="form__form-group">
                    <span className="form__form-group-label">{t('forgot_pw.email')}</span>
                    <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                            <AccountOutlineIcon />
                        </div>
                        <Input
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={this.state.email}
                            valid={this.state.validate.emailState === 'has-success'}
                            invalid={this.state.validate.emailState === 'has-danger'}
                            onChange={(e) => {
                                this.validateEmail(e)
                                this.handleChange(e)
                            }}
                        />
                    </div>
                    {!this.state.email && this.state.validate && this.state.validate.emailState == 'has-danger' ?
                        <span className="formErrorText">{t('forgot_pw.error_req')}</span> : null}
                    {this.state.email && this.state.validate && this.state.validate.emailState == 'has-danger' ?
                        <span className="formErrorText">{t('forgot_pw.error_valid')}</span> : null}
                </div>

                <div className="account__btns forgotPasswordLoginLink">
                    <Button className="account__btn" color="primary"
                        onClick={this.state.email ? this.handleUserPassword :
                            this.checkCredentials}>{t('forgot_pw.submit')}</Button>
                </div>
                <div >
                    <a href="/tracking/log_in">{t('forgot_pw.revert')}</a>
                </div>
            </form>
        );
    }
}
export default withTranslation('common')(ForgotPassword);