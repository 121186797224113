import React from "react";
import TableRender from "../../CommonDataTable/TableRender";
import config from "../../../../config/config";
import configImages from "../../../../config/configImages";
import RolePermissions from "../../CommonDataTable/Permissions";
import { withTranslation } from "react-i18next";

// config file
class DailyStatusTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    //Table fields of Daily Status
    getTableFields = () => {
        const { t } = this.props;
        let data = [
            { textAlign: 'left', width: 130, field: 'employeeName', header: 'dailyStatus.table_fields.employeeName', filter: true, sortable: true, placeholder: 'dailyStatus.table_fields.ph_search' },
            { textAlign: 'left', width: 130, field: 'projectName', header: 'dailyStatus.table_fields.projectName', filter: true, sortable: true, placeholder: 'dailyStatus.table_fields.ph_search' },
            { textAlign: 'center', width: 100, field: 'date', header: 'dailyStatus.table_fields.date', filter: true, sortable: true, placeholder: config.dateDmyFormat, type: 'date' },
            { textAlign: 'center', width: 100, field: 'planedTask', header: 'dailyStatus.table_fields.planedTasks', filter: true, sortable: true },
            { textAlign: 'center', width: 100, field: 'taskStatus', header: 'dailyStatus.table_fields.taskStatus', filter: true, sortable: true },
            { textAlign: 'center', width: 100, field: 'planedWorks', header: 'dailyStatus.table_fields.planedWorks', filter: true, sortable: true },
            { textAlign: 'center', width: 100, field: 'dependencies', header: 'dailyStatus.table_fields.dependencies', filter: true, sortable: true },


        ];

        let screenPermissions = RolePermissions.screenPermissions('DailyStatus');
        if (screenPermissions != 'edit') {
            data.pop();
        }
        return data;
    };

    //form Fields of Daily status 
    getFormFields = () => {
        const { t } = this.props;
        return ([
            {
                required: false, value: '', type: 'radio', name: 'Do you have planned work for tomorrow', label: t('dailyStatus.form_fields.Do you have planned work for tomorrow'), id: 'plannedWork',
                options: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }],
            },
            {
                required: false, value: '', type: 'radio', name: 'Do you have dependencies', defaultChecked: false, radioValue: 'No', label: t('dailyStatus.form_fields.Do you have dependencies'), id: 'isDependency',
                options: [{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No', }],
            },
            { required: true, value: '', type: 'autoComplete', name: 'projectName', label: t('dailyStatus.form_fields.projectName'), id: 'projectName', searchApi: 'projects', searchField: 'name', isMultiple: true },
        ]);


    }
    render() {
        const { t } = this.props;
        return (
            <div>
                <TableRender type='DailyStatus'
                    globalSearch={t('dailyStatus.globalsearch')}
                    tableFields={() => this.getTableFields()}
                    formFields={this.getFormFields}
                    sample={configImages.tasksSampleXL}
                />
            </div>
        );
    }

}
export default withTranslation('common')(DailyStatusTable);