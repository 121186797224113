import React from 'react';
import { AutoComplete } from 'primereact/autocomplete';
import fetch from '../../../config/service';
import PropTypes from 'prop-types';

export default class autoComplete extends React.Component {
    static propTypes = {
        onChange: PropTypes.func,
        meta: PropTypes.shape({
            touched: PropTypes.bool,
            error: PropTypes.string,
        })
    };
    constructor(props) {
        super(props);
        this.state = {
            projectVal: false,
            filteredSuggestions: [],
            teamLeadValue: ''
        };
        this.getAllSuggestions('', 'cont');

    }
    /**
     * @param {all props} prevProps 
     * After selecting project value depending field is not getting call, so using below code
     */
    componentDidUpdate(prevProps) {
        if (this.props.projectId && prevProps.projectId !== this.props.projectId) {
            this.getAllSuggestions();
        }
    }
    componentWillUnmount() {
        localStorage.removeItem('teamLead')
    }

    // to get all suggestions in autocomplete
    getAllSuggestions = (event, type) => {
        let limit; let showLimit = true;
        this.setState({
            projectVal: false,
            noData: false,
        });
        if (this.props.screen == 'Issues' || this.props.screen == 'Timesheet') {
            if (this.props && this.props.input && this.props.input.name) {
                if (this.props.input.name == 'taskId') {
                    showLimit = false;
                    if (!this.props.projectId) {
                        this.setState({ projectVal: true });
                        this.setState({
                            filteredSuggestions: []
                        })
                        return;
                    } else {
                        this.setState({ projectVal: false });
                    }
                }
            }
        }
        if (showLimit) {
            limit = 10;
        }

        let filterCriteria = {
            "limit": limit,
            "page": "1",
            "sortfield": "created",
            "direction": "desc"
        };
        filterCriteria['criteria'] = [];
        if (this.props.searchApi != 'tasks' && this.props.searchApi != 'sprints') {
            filterCriteria['criteria'].push({
                "key": "status",
                "value": "Active",
                "type": "eq"
            });
        }
        if (this.props.filterField && this.props.projectName) {
            let project_name = this.props.projectName;
            project_name = project_name.replace('&', 'ampd');
            filterCriteria['criteria'].push({
                "key": this.props.filterField,
                "value": project_name,
                "type": "eq"
            });
            filterCriteria['criteria'].push({
                "key": "status",
                "value": this.props.filterValue,
                "type": "in"
            });
        } else if (this.props.filterField && this.props.filterValue) {
            if (Array.isArray(this.props.filterValue)) {
                filterCriteria['criteria'].push({
                    "key": this.props.filterField,
                    "value": this.props.filterValue,
                    "type": "in"
                });
            } else {
                filterCriteria['criteria'].push({
                    "key": this.props.filterField,
                    "value": this.props.filterValue,
                    "type": "eq"
                });
            }
        }
        if (event && event.query) {
            filterCriteria['criteria'].push({
                "key": this.props.searchField,
                "value": event.query,
                "type": "regexOr"
            });
        };
        let apiUrl = this.props.searchApi;
        let url = apiUrl + '?filter=' + JSON.stringify(filterCriteria) + '';

        return fetch('GET', url)
            .then((response) => {
                if (response && response[apiUrl] && response[apiUrl].length && response[apiUrl].length > 0) {
                    let resData = response[apiUrl];

                    if (this.props && this.props.assignedEmps && this.props.searchApi == 'employees') {
                        this.setSuggestions(this.props.assignedEmps);
                    } else {
                        this.setSuggestions(resData);
                    }

                } else {
                    this.setState({ filteredSuggestions: [] });
                    if (event) {
                        this.setState({ noData: true });
                    }
                }
            }).catch((err) => {
                return err;
            });

    }
    // set filtered sugeestions, removing duplicates
    setSuggestions = async (resData) => {
        if (this.props.input && this.props.input.name && this.props.input.name == "teamLead") {
            localStorage.setItem('teamLead', this.state.teamLeadValue)
        }

        if (this.props.input && this.props.input.value) {
            if (this.props.input.value.length > 0) {
                let teamLeadId = localStorage.getItem('teamLead')
                if (teamLeadId) {
                    resData = resData.filter((item) => item._id !== teamLeadId);
                } else {
                    let values = this.props.input.value;
                    if (Array.isArray(values)) {
                        values.forEach(element => {
                            resData = resData.filter((item) => item._id !== element._id);
                        });
                    }
                }
            }
        }
        // if teamlead is selected taking teamlead ID and filtering in the dropdowns 
        let teamLeadId = localStorage.getItem('teamLead')
        if (teamLeadId) {
            if (this.props.filterIds) {
                this.props.filterIds[0] = teamLeadId
            }
        }
        if (this.props.filterIds) {
            this.props.filterIds.forEach(id => {
                resData = resData.filter((item) => item._id !== id);
            });
        }
        await this.setState({ filteredSuggestions: resData });
    }

    // on selected value from suggestions
    onSelectRecord(e) {
        this.setState({ noData: false, projectVal: false });
        if (this.props.input) {
            const { name, onChange } = this.props.input;
            onChange(e.value);
            if (e && e.value) {
                // onChange(e.value);
                if (e.value) {
                    this.removeDuplicates(e.value, name);
                }
            }
        }

    }

    // to remove duplicates
    removeDuplicates = async (value, name) => {
        //Taking selected Teamlead from the project screen 
        await this.setState({
            teamLeadValue: value._id
        })
        let ids = [];
        if (Array.isArray(value)) {
            if (value && value.length > 0) {
                let reqData = value;
                reqData.forEach(element => {
                    ids.push(element._id);
                });
            }
        } else if (value && value._id) {
            ids.push(value._id);
        }
        if (ids.length > 0) {
            this.setState({ selectedIds: ids });
        }
        if (this.state.filteredSuggestions) {
            this.setSuggestions(this.state.filteredSuggestions);
        }
        // call method in parent
        if (name == 'teamLead' && this.props.handleAutocompleteData && ids) {
            this.props.handleAutocompleteData('teamLead', ids);
        }
    }

    itemTemplate = (item) => {
        if (item.taskID) {
            return (
                <div className="country-item">
                    <div>{item.taskID}- {item.name}</div>
                </div>
            );
        } else {
            return (
                <div className="country-item">
                    <div>{item.employeeId}- {item.displayName}</div>
                </div>
            );
        }
    };

    render() {
        const {
            value, placeholder, input, multiple, assignedEmps, isDisabled, showItemTemplate
        } = this.props;
        const { name } = this.props.input;
        const { touched, error } = this.props.meta;
        return (
            <div className="date-picker">
                <span className="p-fluid">
                    <AutoComplete style={{ background: 'white' }}
                        multiple={multiple}
                        value={(input && input.value) ? input.value : null}
                        suggestions={this.state.filteredSuggestions}
                        completeMethod={(e) => this.getAllSuggestions(e)}
                        size={20}
                        minLength={3}
                        placeholder={placeholder}
                        dropdown={true}
                        onChange={(e) => this.onSelectRecord(e)}
                        field={this.props.searchField}
                        itemTemplate={showItemTemplate ? this.itemTemplate : false}
                        disabled={isDisabled}
                        virtualScrollerOptions={{ itemSize: 20 }}
                    />
                </span>
                {touched && error && <span className="form__form-group-error mt-1">{error}</span>}
                {this.state.noData && <span className="form__form-group-error">
                    {this.props.searchApi == 'tasks' ? 'No Tasks Found' : this.props.searchApi == 'projects' ? 'No Projects Assigned' : 'No Data Found'}
                </span>}
                {!this.props.projectId && this.state.projectVal ?
                    <span className="form__form-group-error">{'Please Select Project First'}</span>
                    : null
                }
            </div>
        )
    }
}