import React from 'react';
import TableRender from '../../CommonDataTable/TableRender';
import config from '../../../../config/config';
import { withTranslation } from 'react-i18next';
class InventoryHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    getTableFields = () => {
        const { t } = this.props;
        let data = [
            { textAlign: 'center', width: 59, field: 'Sno', header: 'inv_history.table_fields.s_no', filter: false, sortable: false, },
            { textAlign: 'left', width: 100, field: 'name', header: 'inv_history.table_fields.serialNumber', filter: true, sortable: true, placeholder: 'inv_history.table_fields.ph_search' },
            { textAlign: 'left', width: 150, field: 'description', header: 'inv_history.table_fields.desc', filter: true, sortable: true, placeholder: 'inv_history.table_fields.ph_search' },
            { textAlign: 'center', width: 100, field: 'fromDate', header: 'inv_history.table_fields.from', filter: true, sortable: true, placeholder: config.dateDmyFormat },
            { textAlign: 'center', width: 100, field: 'toDate', header: 'inv_history.table_fields.to', filter: true, sortable: true, placeholder: config.dateDmyFormat },
            { textAlign: 'left', width: 130, field: 'assignedToName', header: 'inv_history.table_fields.assigned_to', filter: true, sortable: true, placeholder: 'inv_history.table_fields.ph_search' },
            { textAlign: 'center', width: 100, field: 'status', header: 'inv_history.table_fields.status', filter: true, sortable: true, placeholder: 'inv_history.table_fields.ph_search' },
        ];
        return data;
    };

    getFormFields = () => {
        const { t } = this.props;
        return ([
            { required: false, value: '', type: 'autoComplete', name: 'assignedToName', label: t('inv_history.form_fields.assigned_to'), id: 'AssignedTo', searchApi: 'employees', searchField: 'displayName', placeholder: 'Search Employees', otherField: 'assignedToName' },
            { value: '', type: 'text', name: 'serialNumber', label: t('inv_history.form_fields.serialNumber'), id: 'serialNumber' },
            { value: '', type: 'text', name: 'count', label: t('inv_history.form_fields.count'), id: 'count' },
            { value: '', type: 'textarea', name: 'description', label: t('inv_history.form_fields.desc'), id: 'description' },
            { value: '', type: 'date', name: 'purchaseDate', label: t('inv_history.form_fields.purchase_date'), id: 'date', placeholder: 'DD/MM/YYYY' },
        ]);
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <TableRender type='Inventory History'
                    globalSearch={t('inv_history.globalsearch')}
                    tableFields={this.getTableFields}
                    formFields={this.getFormFields}
                />
            </div>
        );
    }
}
export default withTranslation('common')(InventoryHistory);