import React from 'react';
import { Button, Table } from 'reactstrap';
import { Modal, ModalBody, ButtonToolbar } from 'reactstrap';

import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import fetch from '../../../../config/service';
import DefaultInput from '../../../../shared/components/form/DefaultInput';
import DatePicker from '../../../../shared/components/form/DatePicker';
import CalendarBlankIcon from 'mdi-react/CalendarBlankIcon';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import dateFormats from '../../../UI/FormatDate/formatDate';
import config from '../../../../config/config';
import configMessages from '../../../../config/configMessages';
import validate from '../../../Validations/validate';
import { Dropdown } from 'primereact/dropdown';
import Loader from '../../../App/Loader';
import { withTranslation } from 'react-i18next';



const required = value => (value == '' || value == undefined ? configMessages.fillField : undefined);
class InitiateAC extends React.Component {
    static propTypes = {
        handleSubmit: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props);
        this.state = {
            appraisalCycleList: '',
            status: '', changestatus: false,
            cycleid: '', cyclestatus: '',
            dropdownvalue: '',
            isLoading: false,

        };
    }
    componentDidMount = async () => {
        this.getAppraislaCycleListFromServer();
    }

    //Get Appraisal Cycle list
    getAppraislaCycleListFromServer = async () => {
        fetch('GET', 'appraisalCycle')
            .then(async (response) => {
                if (response) {
                    let appraisalCycleList = response.appraisalCycles;
                    await this.setState({ appraisalCycleList: appraisalCycleList, status: appraisalCycleList[0].status });
                }
            }).catch((err) => {
                return err;
            });
    }


    //Add new Appraisal Cycle to the list
    submitApprisalCycle = async (values) => {
        if (values) {
            await this.setState({ isLoading: true })
            let userBody = values;
            return fetch('POST', 'apraisals/addIntiatedAppraisalToEmployee', userBody)
                .then(async (response) => {
                    if (response && response.respCode == 200) {
                        await this.setState({ isLoading: false })
                        showToasterMessage("Appraisal Cycle Initiated", 'success');
                        this.getAppraislaCycleListFromServer();
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }
    statusclose = async () => {
        if (this.state.cyclestatus) {
            await this.setState({ changestatus: false });
            let URL = `appraisalCycle/${this.state.cycleid}`
            let body = {
                status: this.state.cyclestatus
            }
            fetch('PUT', URL, body)
                .then(async (response) => {
                    if (response && response.respCode && response.respCode === 204) {
                        showToasterMessage(response.respMessage, 'success');
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                    }
                    this.getAppraislaCycleListFromServer();

                }).catch((err) => {
                    return err;
                });
        }
        else {
            showToasterMessage('please select ', 'error');
        }

    }
    openmodal = async (id) => {
        await this.setState({ changestatus: true, cycleid: id })

    }
    senddetailstoserver = async (value) => {

        await this.setState({ cyclestatus: value.value, dropdownvalue: value.value })

    }
    closeModal = () => {
        this.setState({ changestatus: false })
    }
    render() {
        const { handleSubmit, t } = this.props;
        return (
            <div>
                <form className='row form px-3' onSubmit={handleSubmit(this.submitApprisalCycle)}>
                    <Loader loader={this.state.isLoading} />

                    <div className="form__form-group">
                        <span className="form__form-group-label bold-text">{t('settings.apraisal.title')}</span>
                        <div className='row'>
                            <div className='col-sm-3'>
                                <span className="form__form-group-label ">{t('settings.apraisal.cycle_name')}</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name='cycleName'
                                        component={DefaultInput}
                                        type='boolean'
                                        validate={required}
                                        placeholder='Cycle Name'
                                    />
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <span className="form__form-group-label ">{t('settings.apraisal.from_date')}</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name='fromDate'
                                        component={DatePicker}
                                        validate={required}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <span className="form__form-group-label ">{t('settings.apraisal.to_date')}</span>
                                <div className="form__form-group-field">
                                    <Field
                                        name='toDate'
                                        component={DatePicker}
                                        validate={required}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <span className="form__form-group-label ">{t('settings.apraisal.initiate')}</span>
                                <div className="form__form-group-field">
                                    {this.state.status == 'Initiated' ?
                                        <Button color='primary' disabled={true} type='submit'>{t('settings.apraisal.initiate')}</Button>
                                        :
                                        <Button color='primary' type='submit'>{t('settings.apraisal.initiate')}</Button>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <Table className="table--bordered table--head-accent" responsive hover>
                    <thead>
                        <tr>
                            <th style={{ width: 5 + '%' }}>#</th>
                            <th style={{ width: 15 + '%' }}>{t('settings.apraisal.cycle_name')}</th>
                            <th style={{ width: 15 + '%' }}>{t('settings.apraisal.from_date')}</th>
                            <th style={{ width: 15 + '%' }}>{t('settings.apraisal.to_date')}</th>
                            <th style={{ width: 15 + '%' }}>{t('settings.apraisal.status')}</th>
                            <th style={{ width: 15 + '%' }}>{t('settings.apraisal.action')}</th>

                        </tr>
                    </thead>
                    <tbody>
                        {this.state.appraisalCycleList && this.state.appraisalCycleList.length > 0 ?
                            this.state.appraisalCycleList.map((cycle, i) => {
                                return <tr key={i}>
                                    <td style={{ width: 5 + '%' }}>{i + 1} </td>
                                    <td style={{ width: 15 + '%' }}>{cycle.cycleName}</td>
                                    <td style={{ width: 15 + '%' }}>{dateFormats.formatDate(cycle.fromDate, config.dateFormat)}</td>
                                    <td style={{ width: 15 + '%' }}>{dateFormats.formatDate(cycle.toDate, config.dateFormat)}</td>
                                    <td style={{ width: 15 + '%' }}>
                                        {cycle.status}

                                    </td>
                                    {/* {cycle.status == 'Closed' ?
                                        <td><Button color="primary" disabled={true} >Close</Button></td>
                                        :
                                        <td><Button color="primary" onClick={() => this.statusclose(cycle._id)} >Close</Button></td>
                                    } */}
                                    <td><Button color="primary" onClick={() => this.openmodal(cycle._id)} >{t('settings.apraisal.change_status')}</Button></td>

                                </tr>
                            }) : null}
                    </tbody>
                </Table>

                <Modal isOpen={this.state.changestatus} className='changemodal'>
                    <ModalBody className='deleteModalBody'>
                        <form className="form">
                            <div className="form__form-group">
                                <div className="form__form-group" style={{ fontSize: '13px', textTransform: 'uppercase', textAlign: 'left' }}>{t('settings.apraisal.save_conf')}</div>

                                {<div className="form__form-group-field mb-5" >
                                    <Dropdown
                                        style={{ width: '100%' }}
                                        options={[{ label: '', value: '' },
                                        { label: 'Initiate', value: 'Initiated' },
                                        { label: 'Close', value: 'Closed' }
                                        ]}
                                        // placeholder={this.props.formType == 'edit' ? this.props.placeholder : ''}
                                        onChange={value => this.senddetailstoserver(value)}
                                        value={this.state.dropdownvalue}
                                        validate={required}
                                        placeholder='select'

                                    />
                                </div>}
                            </div>
                            <div className="form__form-group" >

                                <ButtonToolbar className="form__button-toolbar m-0" style={{ justifyContent: 'center' }}>
                                    <Button color="primary" onClick={() => this.statusclose()}>{t('settings.apraisal.save')}</Button>
                                    <Button type="button" onClick={() => this.closeModal()}>{t('settings.apraisal.cancel')}</Button>
                                </ButtonToolbar>
                            </div>
                        </form>
                    </ModalBody>
                </Modal >
            </div >
        )
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
InitiateAC = reduxForm({
    form: "AC Form", // a unique identifier for this form
    validate,
})(InitiateAC);

export default withTranslation('common')(InitiateAC);
