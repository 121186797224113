import React, { PureComponent } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import { Button } from 'reactstrap';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

//config
import fetch from '../../../../config/service';
import configMessages from '../../../../config/configMessages';

// Toaster message
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

// checkbox render 
import renderCheckBoxField from '../../../../shared/components/form/CheckBox';

// Input render
import DefaultInput from '../../../../shared/components/form/DefaultInput';

import { load as loadAccount } from '../../../../redux/reducers/commonReducer';

// Validate 
import validate from '../../../Validations/validate';

// Loader
import Loader from '../../../App/Loader';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField)

class LogInForm extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      isLoginDisabled: false,
      isLoading: false,
      loginRememberInfo: null,
      remember_me: false,
      role: '',
    };
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true })
    await this.checkLoginRememberInfo();
  }

  checkLoginRememberInfo = async () => {
    let loginRememberInfo = localStorage.getItem('loginRememberInfo');
    loginRememberInfo = loginRememberInfo ? await JSON.parse(loginRememberInfo) : null;
    await this.props.load(loginRememberInfo);

    this.setState({
      loginRememberInfo: loginRememberInfo,
      remember_me: loginRememberInfo && loginRememberInfo.remember_me ? loginRememberInfo.remember_me : false,
      isLoading: false
    })
  }

  // Passowrd Visible handler
  showPassword = (e) => {
    e.preventDefault();
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  // Submit handler when click on signin
  submit = async (values) => {
    if (values.email && values.password) {
      this.setState({
        remember_me: values.remember_me,
        isLoginDisabled: true,
        isLoading: true
      })
      await this.handleLoginUser(values)
    }
  }

  getProjectsListData = async (userId) => {
    let body = { userId: userId };
    let fillterCriteria = {
      criteria: [{
        key: "status",
        value: "Active",
        type: "eq"
      }]
    }
    fetch('POST', `projects/projectList?filter=${JSON.stringify(fillterCriteria)}`, body).then(async (response) => {
      if (response && response.userProjectList && response.userProjects) {
        let projectTypes = response.userProjects;
        let projectValues = response.userProjectList;

        if (!projectTypes) {
          projectTypes = []
        }
        if (!projectValues) {
          projectValues = []
        }

        // projectTypes.push({ label: 'Non-Project', value: null });
        projectValues.push(null)

        let projectSortValues = response.userProjects.sort((a, b) => a.label.localeCompare(b.label));

        localStorage.setItem('projectTypes', JSON.stringify(projectSortValues))
        localStorage.setItem('projectValues', JSON.stringify(response.userProjectList))
      }
    }).catch((err) => {
      return err;
    });
  }

  // Signin Handler
  handleLoginUser = async (values) => {
    let userBody = {
      email: values.email,
      password: values.password,
      entityType: "employee",
    };
    localStorage.setItem('loginBody', JSON.stringify(userBody));

    return fetch('POST', 'auth/login', userBody).then(async (response) => {
      if (response && response.respCode && response.respCode === 200) {
        let userDetails = response.details;
        if (userDetails && userDetails._id) {
          await this.getProjectsListData(userDetails._id);
          if (response.accessToken) {
            let tokenInfo = {
              accessToken: response.accessToken,
              refreshToken: response.refreshToken,
              tokenExpires: response.tokenExpires,
            };
            userDetails = { ...userDetails, ...tokenInfo };
            // save user credentials in storage
            localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
          }

          // save user permissions in storage
          if (response.permissions) {
            let permissions = response.permissions;
            localStorage.setItem('rolePermissions', JSON.stringify(permissions));
          }
        }
        showToasterMessage(response.respMessage, 'success');

        if (this.state.remember_me) {
          var data = {
            email: values.email,
            password: values.password,
            remember_me: this.state.remember_me
          };
          if (this.state.loginRememberInfo && this.state.loginRememberInfo.email &&
            this.state.loginRememberInfo.email != values.email) {
            localStorage.setItem('loginRememberInfo', JSON.stringify(data));
          } else if (!this.state.loginRememberInfo) {
            localStorage.setItem('loginRememberInfo', JSON.stringify(data));
          }
        } else {
          localStorage.removeItem('loginRememberInfo');
        };

        this.setState({
          isLoginSuccess: true,
          role: userDetails.role,
          isLoading: false
        })
      } else if (response && response.errorMessage) {
        setTimeout(
          () => this.setState({
            isLoginDisabled: false,
            isLoading: false
          }),
          2000
        );
        showToasterMessage(response.errorMessage, 'error');
        return true;
      }
    }).catch((err) => {
      return err
    });
  }

  // Onchange checkbox
  onChange = (value) => {
    if (value == 'remember' && this.state.remember_me) {
      this.setState({
        remember_me: false
      })
    }
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, } = this.props;
    const { showPassword } = this.state;
    return (
      <form className="form" onSubmit={handleSubmit(this.submit)}>
        <Loader loader={this.state.isLoading} />
        <div className="form__form-group">
          <span className="form__form-group-label">Email</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <AccountOutlineIcon />
            </div>
            <Field
              name="email"
              component={DefaultInput}
              type="email"
              placeholder="Email"
              validate={required}
            />
          </div>
        </div>
        <div className="form__form-group">
          <span className="form__form-group-label">Password</span>
          <div className="form__form-group-field">
            <div className="form__form-group-icon">
              <KeyVariantIcon />
            </div>
            <Field
              name="password"
              component={DefaultInput}
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              validate={required}
            />
            <button
              type="button"
              className={`form__form-group-button ${showPassword ? ' active' : ''}`}
              onClick={e => this.showPassword(e)}
            ><EyeIcon />
            </button>
          </div>

        </div>
        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="remember_me"
              component={renderCheckBoxField}
              label="Remember me"
              checked={this.state.remember_me}
              onChange={() => this.onChange('remember')}
            />
          </div>

          <div className="account__forgot-password" >
            <a href="/tracking/forgot_password" style={{ marginTop: '20px' }}>Forgot password?</a>
          </div>
        </div>

        {/* Sign or Reset */}
        <div className="account__btns forgotPasswordLoginLink">
          <Button
            className="ml-auto"
            color="primary"
            disabled={this.state.isLoginDisabled}>Sign In</Button>
          <Button
            type="button"
            onClick={reset}
          // disabled={pristine || submitting}
          >Reset</Button>
        </div>

        {/* Redirect to # after successful login */}
        {this.state.isLoginSuccess ?
          this.state.role == 'Client' ?
            <Redirect to="/Issues" /> :
            <Redirect to="/dashboard" /> :
          null
        }
      </form>
    );
  }
}

LogInForm = reduxForm({
  form: 'log_in_form', // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(LogInForm);

// You have to connect() to any reducers that you wish to connect to yourself
LogInForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(LogInForm);

export default LogInForm;